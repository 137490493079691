// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useNavigationDOM,
  useResponsive,
  useQuery,
  useState,
} from '@supermove/hooks';
import {JobModel, UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import GlobalSearchItem from 'modules/App/Global/components/GlobalSearchItem';
import Line from 'modules/App/components/Line';
import useAppContext from 'modules/App/context/useAppContext';

const ClearButton = Styled.ButtonV2`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px;
`;

const getUniqueValue = ({search, jobs, job}: {search: string; jobs: JobModel[]; job: JobModel}) => {
  return `${search}_${jobs.length}_${job.id}`;
};

const getOptions = ({
  search,
  jobs,
  viewer,
}: {
  search: string;
  jobs: JobModel[];
  viewer: UserModel;
}) => {
  if (search) {
    return jobs.map((job: any, index: any) => {
      const uniqueValue = getUniqueValue({search, jobs, job});
      return {
        label: uniqueValue,
        value: uniqueValue,
        job,
        index,
        organization: viewer.viewingOrganization,
      };
    });
  }

  return [];
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <GlobalSearchItem.LoadingComponent />
      <Line />
      <GlobalSearchItem.LoadingComponent />
      <Line />
      <GlobalSearchItem.LoadingComponent />
    </React.Fragment>
  );
};

const GlobalSearchModal = ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {viewer} = useAppContext();
  const [input, setInput] = useState('');
  const [search, setSearch] = useState(input);

  const handleUpdateSearch = useDebouncedCallback((text) => setSearch(text), 300);

  const {data, loading} = useQuery(GlobalSearchModal.query, {
    fetchPolicy: 'network-only',
    skip: !search || !viewer,
    variables: {
      organizationId: viewer?.viewingOrganization.id,
      searchQuery: search,
    },
  });

  return (
    <Modal
      width={600}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      screenContainerStyle={{
        justifyContent: 'flex-start',
        paddingTop: responsive.mobile ? 0 : 96,
        paddingBottom: 0,
      }}
      style={search ? {borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : null}
    >
      <DropdownInput
        style={{flex: 1, height: 48, borderWidth: 0}}
        placeholder={'Search jobs by job number, customer name, email, or phone number'}
        autoFocus
        menuIsOpen
        isClearable
        isPortaled
        isJoinedList
        isLoading={loading}
        fontSize={14}
        inputStyle={{position: 'relative', fontSize: 14, paddingLeft: 32}}
        placeholderStyle={{paddingLeft: 30}}
        menuListStyle={{maxHeight: 600}}
        setFieldValue={() => {}}
        onInputChange={(text) => {
          // The default behavior of the dropdown is to clear the search text
          // anytime you click on the dropdown. This logic prevents that from
          // happening so long as the search text is more than one character.
          // We allow clearing the field if there is only one character because
          // this could be the user actually deleting the text.
          if (text || input.length === 1) {
            setInput(text);
            handleUpdateSearch(text);
          }
        }}
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
        value={null}
        inputValue={input}
        options={data ? getOptions({search, jobs: data.jobs, viewer: data.viewer}) : []}
        noOptionsMessage={() => 'No jobs found.'}
        onChangeValue={(value, data) => {
          if (value) {
            navigator.push(`/jobs/${data.job.uuid}`);
            handleClose();
          }
        }}
        components={{
          DropdownIndicator: () => null,
          LoadingIndicator: () => null,
          Option: ({data, ...optionProps}: any) => {
            return (
              <React.Fragment>
                {data.index > 0 && <Line />}
                <GlobalSearchItem
                  {...optionProps}
                  job={data.job}
                  index={data.index}
                  isEnabledCancelProject={data.organization.features.isEnabledCancelProject}
                />
              </React.Fragment>
            );
          },
          ...(!search ? {Menu: () => null} : {}),
          ...(loading ? {MenuList: LoadingComponent} : {}),
        }}
      />
      <Icon
        source={Icon.MagnifyingGlass}
        size={16}
        color={colors.gray.tertiary}
        style={{position: 'absolute', top: 16, left: 16}}
      />
      <ClearButton
        onPress={() => {
          setInput('');
          setSearch('');
        }}
      >
        <Icon source={Icon.Xmark} size={16} color={colors.gray.secondary} />
      </ClearButton>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalSearchModal.query = gql`
  ${GlobalSearchItem.fragment}

  query GlobalSearchModal(
    $organizationId: Int!,
    $searchQuery: String!,
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
        }
      }
    }
    jobs: searchJobsByQuery(
      organizationId: $organizationId,
      searchQuery: $searchQuery,
    ) {
      id
      name
      uuid
      ...GlobalSearchItem
    }
  }
`;

export default GlobalSearchModal;
