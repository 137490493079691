// Supermove
import {gql} from '@supermove/graphql';
import {RateTableMetricModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import RateTableMetricKind, {
  RateTableMetricKindType,
} from '@shared/modules/RateTable/enums/RateTableMetricKind';

const _new = ({kind, isPrimary = false}: {kind: RateTableMetricKindType; isPrimary?: boolean}) => ({
  rateTableMetricId: undefined,
  kind,
  name: RateTableMetricKind.getName(kind),
  isPrimary,
});

const edit = withFragment(
  (rateTableMetric: RateTableMetricModel) => {
    return {
      rateTableMetricId: rateTableMetric.id,
      kind: rateTableMetric.kind,
      name: rateTableMetric.name,
      isPrimary: rateTableMetric.isPrimary,
    };
  },
  gql`
    fragment RateTableMetricForm_edit on RateTableMetric {
      id
      kind
      name
      isPrimary
    }
  `,
);

const duplicate = withFragment(
  (rateTableMetric: RateTableMetricModel) => {
    return {
      ...edit(rateTableMetric),
      rateTableMetricId: undefined,
    };
  },
  gql`
    ${edit.fragment}
    fragment RateTableMetricForm_duplicate on RateTableMetric {
      id
      ...RateTableMetricForm_edit
    }
  `,
);

export interface RateTableMetricFormType {
  rateTableMetricId: string | undefined;
  kind: RateTableMetricKindType;
  name: string;
  isPrimary: boolean;
}

const toForm = ({rateTableMetricId, kind, name, isPrimary}: RateTableMetricFormType) => ({
  rateTableMetricId,
  kind,
  name,
  isPrimary,
});

export type RateTableMetricFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({rateTableMetricId, kind, name, isPrimary}: RateTableMetricFormToFormType) => ({
  rateTableMetricId,
  kind,
  name,
  isPrimary,
});

const RateTableMetricForm = {
  new: _new,
  edit,
  duplicate,
  toForm,
  toMutation,
};

export default RateTableMetricForm;
