// Libraries
import React from 'react';

// Supermove
import {useToast, useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import {ItemAttachmentFormType} from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import RemoveInventoryItemModal from 'modules/Inventory/Edit/components/RemoveInventoryItemModal';

const InventoryItemActionSheet = ({
  isOpen,
  handleClose,
  editInventoryItemSheet,
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  removeItemToast,
  handleScrollRoomToBottom,
}: any) => {
  const duplicateItemToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Item added!',
  });
  const removeInventoryItemModal = useModal({name: 'Remove Inventory Item Modal'});
  const hasActivePhotoAttachments = itemForm.itemAttachmentForms.some(
    (itemAttachmentForm: ItemAttachmentFormType) => !itemAttachmentForm.isDeleted,
  );
  const handleRemoveItem = () => {
    InventoryRoomsForm.handleDeleteToggle({
      inventoryRoomsForm,
      isDeleted: true,
      roomItemsFormIndex,
      itemIndex: itemForm.index,
    });
    handleClose();
    removeItemToast.handleToast({message: `${itemForm.name} removed.`});
  };

  return (
    <React.Fragment>
      <DropdownSheet
        isOpen={isOpen}
        handleClose={handleClose}
        headerText={'Actions'}
        isSearchable={false}
        options={[
          {
            label: 'Edit item',
            handleAction: () => {
              editInventoryItemSheet.handleOpen();
              handleClose();
            },
          },
          {
            label: 'Duplicate item',
            handleAction: () => {
              const newItemForm = InventoryRoomsForm.handleDuplicate({
                inventoryRoomsForm,
                roomItemsFormIndex,
                itemForm,
              });
              handleClose();
              duplicateItemToast.handleToast({message: `${newItemForm.name} added!`});
              handleScrollRoomToBottom();
            },
          },
          {
            label: 'Remove item',
            handleAction: () => {
              if (hasActivePhotoAttachments) {
                removeInventoryItemModal.handleOpen();
              } else {
                handleRemoveItem();
              }
            },
            // @ts-expect-error TS(2322): Type '{ label: string; handleAction: () => void; c... Remove this comment to see the full error message
            color: colors.red.warning,
          },
        ]}
      />
      <RemoveInventoryItemModal
        removeInventoryItemModal={removeInventoryItemModal}
        itemForm={itemForm}
        handleRemoveItem={handleRemoveItem}
      />
    </React.Fragment>
  );
};

export default InventoryItemActionSheet;
