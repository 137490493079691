// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {Form, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import {RateTableFormToFormType} from '@shared/modules/RateTable/forms/RateTableForm';
import AdditionalRatesTable from 'modules/Organization/Settings/Billing/components/AdditionalRatesTable';
import RateTablePanelActions from 'modules/Organization/Settings/Billing/components/RateTablePanelActions';
import RatesTable from 'modules/Organization/Settings/Billing/components/RatesTable';

const ContentWidthContainer = Styled.View`
  width: fit-content;
`;

const Column = Styled.View``;

const CHECKBOX_CELL_WIDTH = 32;
const CELL_WIDTH = 120;
const CELL_STYLE = {
  padding: 8,
  borderRightWidth: 1,
  borderColor: colors.gray.border,
  height: '100%',
  alignItems: 'center',
};

const RateTablePanelBody = ({
  form,
  selectedRowIndexes,
  setSelectedRowIndexes,
  rateTableErrors,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  selectedRowIndexes: number[];
  setSelectedRowIndexes: (selectedRowIndexes: number[]) => void;
  rateTableErrors: string[];
}) => {
  return (
    <React.Fragment>
      {!_.isEmpty(rateTableErrors) && (
        <React.Fragment>
          {/* Container  */}
          <ContentWidthContainer>
            <ErrorCallout
              text={`Please fix the errors below:${_.map(rateTableErrors, (error) => `\n • ${error}`)}`}
            />
          </ContentWidthContainer>
          <Space height={16} />
        </React.Fragment>
      )}
      <ScrollView horizontal>
        <Column>
          <ContentWidthContainer>
            <RatesTable
              form={form}
              selectedRowIndexes={selectedRowIndexes}
              setSelectedRowIndexes={setSelectedRowIndexes}
              cellWidth={CELL_WIDTH}
              checkboxCellWidth={CHECKBOX_CELL_WIDTH}
              cellStyle={CELL_STYLE}
            />
          </ContentWidthContainer>
          <Space height={16} />
          <ContentWidthContainer>
            <AdditionalRatesTable
              form={form}
              cellWidth={CELL_WIDTH}
              checkboxCellWidth={CHECKBOX_CELL_WIDTH}
              cellStyle={CELL_STYLE}
            />
          </ContentWidthContainer>
        </Column>
      </ScrollView>
    </React.Fragment>
  );
};

const RateTablePanel = ({
  index,
  form,
  rateTableErrors,
}: {
  index: number;
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  rateTableErrors: string[];
}) => {
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<number[]>([]);
  return (
    <React.Fragment>
      <ActionPanel
        title={'Hourly Rates'}
        index={index}
        BodyComponent={RateTablePanelBody}
        bodyComponentProps={{form, selectedRowIndexes, setSelectedRowIndexes, rateTableErrors}}
        ActionButtonComponent={RateTablePanelActions}
        actionButtonComponentProps={{form, selectedRowIndexes, setSelectedRowIndexes}}
      />
    </React.Fragment>
  );
};

export default RateTablePanel;
