// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useDebouncedCallback, usePopover, useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import TaskFiltersButton from 'modules/TaskManagement/Tasks/List/components/TaskFiltersButton';

const FiltersRow = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const PendingButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : colors.gray.background)};
  padding-horizontal: 12px;
  height: 36px;
  width: 108px;
`;

const CompletedButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : colors.gray.background)};
  padding-horizontal: 12px;
  height: 36px;
  width: 108px;
`;

const ButtonText = Styled.Text`
  ${Typography.Label}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const StatusDivider = Styled.View`
  height: 36px;
  width: 1px;
  background-color: ${colors.blue.interactive};
`;

const DATE_VALUE = {
  today: Datetime.toDate(Datetime.today),
  tomorrow: Datetime.toDate(Datetime.tomorrow),
  yesterday: Datetime.toDate(Datetime.yesterday),
  startOfMonth: Datetime.startOfMonth,
  endOfMonth: Datetime.endOfMonth,
  startOfWeek: Datetime.startOfWeek,
  endOfWeek: Datetime.endOfWeek,
  EMPTY: 'empty',
  NOW: 'now',
};

const DATE_VIEW_OPTION = {
  ALL: 'All',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  THIS_MONTH: 'This Month',
  THIS_WEEK: 'This Week',
  PAST_DUE: 'Past Due',
  NO_DUE_DATE: 'No Due Date',
};

const getButtonTextColor = (isSelected: any) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  return colors.gray.secondary;
};

const handleTogglePending = ({urlFilters, isViewPending, isViewCompleted}: any) => {
  if (isViewPending && isViewCompleted) {
    return urlFilters.handleUpdate({isCompleted: 'true'});
  }
  if (!isViewPending) {
    return urlFilters.handleUpdate({isCompleted: null});
  }
};

const handleToggleCompleted = ({urlFilters, isViewPending, isViewCompleted}: any) => {
  if (isViewPending && isViewCompleted) {
    return urlFilters.handleUpdate({isCompleted: 'false'});
  }
  if (!isViewCompleted) {
    return urlFilters.handleUpdate({isCompleted: null});
  }
};

const handleUpdateDates = ({urlFilters, fromDueDate, toDueDate, isPending}: any) => {
  urlFilters.handleUpdate({
    fromDueDate,
    toDueDate,
    ...(isPending ? {isCompleted: 'false'} : {}),
  });
};

const getCurrentViewOption = ({fromDueDate, toDueDate}: any) => {
  if (!fromDueDate && !toDueDate) {
    return DATE_VIEW_OPTION.ALL;
  }
  if (fromDueDate === DATE_VALUE.today && toDueDate === DATE_VALUE.today) {
    return DATE_VIEW_OPTION.TODAY;
  }
  if (fromDueDate === DATE_VALUE.tomorrow && toDueDate === DATE_VALUE.tomorrow) {
    return DATE_VIEW_OPTION.TOMORROW;
  }
  if (fromDueDate === DATE_VALUE.startOfMonth && toDueDate === DATE_VALUE.endOfMonth) {
    return DATE_VIEW_OPTION.THIS_MONTH;
  }
  if (fromDueDate === DATE_VALUE.startOfWeek && toDueDate === DATE_VALUE.endOfWeek) {
    return DATE_VIEW_OPTION.THIS_WEEK;
  }
  if (!fromDueDate && toDueDate === DATE_VALUE.NOW) {
    return DATE_VIEW_OPTION.PAST_DUE;
  }
  if (fromDueDate === DATE_VALUE.EMPTY && toDueDate === DATE_VALUE.EMPTY) {
    return DATE_VIEW_OPTION.NO_DUE_DATE;
  }
  return 'View';
};

const getViewOptions = ({params, urlFilters}: any) => {
  const {fromDueDate, toDueDate} = params;
  const currentViewOption = getCurrentViewOption({fromDueDate, toDueDate});
  return [
    {
      text: DATE_VIEW_OPTION.ALL,
      onPress: () => handleUpdateDates({urlFilters, fromDueDate: null, toDueDate: null}),
      isSelected: currentViewOption === DATE_VIEW_OPTION.ALL,
    },
    {
      text: DATE_VIEW_OPTION.TODAY,
      onPress: () =>
        handleUpdateDates({
          urlFilters,
          fromDueDate: DATE_VALUE.today,
          toDueDate: DATE_VALUE.today,
        }),
      isSelected: currentViewOption === DATE_VIEW_OPTION.TODAY,
    },
    {
      text: DATE_VIEW_OPTION.TOMORROW,
      onPress: () =>
        handleUpdateDates({
          urlFilters,
          fromDueDate: DATE_VALUE.tomorrow,
          toDueDate: DATE_VALUE.tomorrow,
        }),
      isSelected: currentViewOption === DATE_VIEW_OPTION.TOMORROW,
    },
    {
      text: DATE_VIEW_OPTION.THIS_WEEK,
      onPress: () =>
        handleUpdateDates({
          urlFilters,
          fromDueDate: DATE_VALUE.startOfWeek,
          toDueDate: DATE_VALUE.endOfWeek,
        }),
      isSelected: currentViewOption === DATE_VIEW_OPTION.THIS_WEEK,
    },
    {
      text: DATE_VIEW_OPTION.THIS_MONTH,
      onPress: () =>
        handleUpdateDates({
          urlFilters,
          fromDueDate: DATE_VALUE.startOfMonth,
          toDueDate: DATE_VALUE.endOfMonth,
        }),
      isSelected: currentViewOption === DATE_VIEW_OPTION.THIS_MONTH,
    },
    {
      text: DATE_VIEW_OPTION.PAST_DUE,
      onPress: () =>
        handleUpdateDates({
          urlFilters,
          fromDueDate: null,
          toDueDate: DATE_VALUE.NOW,
          isPending: true,
        }),
      isSelected: currentViewOption === DATE_VIEW_OPTION.PAST_DUE,
    },
    {
      text: DATE_VIEW_OPTION.NO_DUE_DATE,
      onPress: () => handleUpdateDates({urlFilters, fromDueDate: 'empty', toDueDate: 'empty'}),
      isSelected: currentViewOption === DATE_VIEW_OPTION.NO_DUE_DATE,
    },
  ];
};

const SearchInput = ({urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const handleUpdateQuery = useDebouncedCallback((text) => {
    urlFilters.handleUpdate({query: text});
  }, 500);
  return (
    <SearchBar
      key={urlFilters.resetKey}
      onChangeText={handleUpdateQuery}
      placeholder='Search by task name, project identifier, or client name'
      style={{width: '436px'}}
      defaultValue={params.query}
    />
  );
};

const StatusFilters = ({urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const isViewPending = !params.isCompleted || params.isCompleted === 'false';
  const isViewCompleted = !params.isCompleted || params.isCompleted === 'true';

  return (
    <React.Fragment>
      <PendingButton
        isSelected={isViewPending}
        disabled={isViewPending && !isViewCompleted}
        onPress={() => {
          handleTogglePending({urlFilters, isViewPending, isViewCompleted});
        }}
      >
        <ButtonText color={getButtonTextColor(isViewPending)}>Pending</ButtonText>
      </PendingButton>
      <StatusDivider />
      <CompletedButton
        isSelected={isViewCompleted}
        disabled={isViewCompleted && !isViewPending}
        onPress={() => {
          handleToggleCompleted({urlFilters, isViewPending, isViewCompleted});
        }}
      >
        <ButtonText color={getButtonTextColor(isViewCompleted)}>Completed</ButtonText>
      </CompletedButton>
    </React.Fragment>
  );
};

const TasksListFilters = ({urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const {fromDueDate, toDueDate} = params;
  const taskFiltersPopover = usePopover();

  return (
    <FiltersRow>
      <SearchInput urlFilters={urlFilters} />
      <Space width={10} />
      <StatusFilters urlFilters={urlFilters} />
      <Space width={10} />
      <TaskFiltersButton popover={taskFiltersPopover} urlFilters={urlFilters} />
      <Space style={{flex: 1}} />
      <DropdownButton
        text={getCurrentViewOption({fromDueDate, toDueDate})}
        menuPosition={DropdownButton.MENU_POSITION.RIGHT}
        menuWidth={138}
        actions={getViewOptions({params, urlFilters})}
        ButtonComponent={SecondaryButton}
      />
    </FiltersRow>
  );
};

export default TasksListFilters;
