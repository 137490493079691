// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ModalType, useInlineFormMutation, useModal} from '@supermove/hooks';
import {CancellationReasonModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

const EditCancellationReasonDrawerContent = ({
  handleClose,
  cancellationReason,
  cautionModal,
  refetch,
}: {
  handleClose: () => void;
  cancellationReason: CancellationReasonModel;
  cautionModal: ModalType;
  refetch: () => void;
}) => {
  const {form, submitting, handleSubmit} = useInlineFormMutation({
    name: 'cancellationReasonForm',
    form: {
      cancellationReasonId: cancellationReason.id,
      organizationId: cancellationReason.organizationId,
      name: cancellationReason.name,
    },
    mutation: gql`
      mutation EditCancellationReasonDrawerMutation($cancellationReasonForm: CancellationReasonForm!) {
        response: updateCancellationReason(cancellationReasonForm: $cancellationReasonForm) {
          ${gql.errors}
          cancellationReason {
            id
            name
          }
        }
      }
    `,
    onSuccess: () => {
      refetch();
      cautionModal.handleClose();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
      cautionModal.handleClose();
    },
  });

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Reason Name'}
        name={'cancellationReasonForm.name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter name',
        }}
      />
      <CautionModal
        isOpen={cautionModal.isOpen}
        title={`Update ${cancellationReason.name}`}
        message={`This update will be applied to all the projects that are using this reason.`}
        primaryActionText={'Save'}
        secondaryActionText={'Cancel'}
        handlePrimaryAction={handleSubmit}
        handleSecondaryAction={cautionModal.handleClose}
        isSubmitting={submitting}
      />
    </React.Fragment>
  );
};

const EditCancellationReasonDrawer = ({
  isOpen,
  handleClose,
  cancellationReason,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  cancellationReason: CancellationReasonModel;
  refetch: () => void;
}) => {
  const cautionModal = useModal();

  return (
    <DrawerWithAction
      headerText={`Edit ${cancellationReason.name}`}
      description={`This will apear in the 'Cancellation Reason' dropdown when canceling a project.`}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={cautionModal.handleOpen}
      primaryActionText={'Save'}
    >
      <EditCancellationReasonDrawerContent
        key={`${isOpen}`}
        handleClose={handleClose}
        cancellationReason={cancellationReason}
        cautionModal={cautionModal}
        refetch={refetch}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCancellationReasonDrawer.fragment = gql`
  fragment EditCancellationReasonDrawer on CancellationReason {
    id
    organizationId
    name
  }
`;

export default EditCancellationReasonDrawer;
