// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useResponsive, useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useCreateProjectTypeMutation from '@shared/modules/Project/hooks/useCreateProjectTypeMutation';
import ProjectTypeFields from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeFields';

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const Link = Styled.ButtonV2`
`;

const CreateProjectTypeDrawerContent = ({
  isOpen,
  organization,
  isStorageSettingsPage,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  organization: OrganizationModel;
  isStorageSettingsPage: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const createProjectTypeSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Project type created!`,
  });
  const projectTypeForm = ProjectTypeForm.new({
    organization,
    category: isStorageSettingsPage ? ProjectTypeCategory.STORAGE : ProjectTypeCategory.MOVE,
  });

  const {form, handleSubmit, submitting} = useCreateProjectTypeMutation({
    projectTypeForm,
    onSuccess: () => {
      refetch();
      handleClose();
      createProjectTypeSuccessToast.handleToast();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={() => {
        form.resetForm();
        handleClose();
      }}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Create Project Type'}
      description={
        <Text responsive={responsive}>
          {`Select a billing library for this project type. ` +
            `You can also create a new billing library via the `}
          <Link
            responsive={responsive}
            onPress={() => navigator.push('/settings/billing/billing-libraries')}
          >
            <LinkText responsive={responsive}>Billing Libraries Settings</LinkText>
          </Link>
          {`.`}
        </Text>
      }
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
    >
      <ProjectTypeFields
        form={form}
        organization={organization}
        isStorageSettingsPage={isStorageSettingsPage}
      />
    </DrawerWithAction>
  );
};

const CreateProjectTypeDrawer = ({
  isOpen,
  organization,
  isStorageSettingsPage = false,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  organization: OrganizationModel;
  isStorageSettingsPage?: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery<{organization: OrganizationModel}>(
    CreateProjectTypeDrawerQuery,
    {
      variables: {organizationId: organization.id},
      fetchPolicy: 'cache-and-network',
      skip: !isOpen,
    },
  );

  if (!data) return null;

  return (
    <CreateProjectTypeDrawerContent
      isOpen={isOpen}
      organization={data.organization}
      isStorageSettingsPage={isStorageSettingsPage}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const CreateProjectTypeDrawerQuery = gql`
  ${ProjectTypeFields.fragment}
  ${ProjectTypeForm.new.fragment}
  query CreateProjectTypeDrawer($organizationId: Int!) {
    ${gql.query}
    organization(organizationId: $organizationId) {
      id
      ...ProjectTypeFields
      ...ProjectTypeForm_new
    }
  }
`;

export default CreateProjectTypeDrawer;
