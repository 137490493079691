// Libraries
import _ from 'lodash';
import React, {useEffect} from 'react';

// Supermove
import {Icon, Loading, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {PopoverType, usePopover, useQuery, useResponsive, useState} from '@supermove/hooks';
import {OrganizationModel, ProjectTypeModel} from '@supermove/models';
import {sortByAlphabetical} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInputComponent from '@shared/design/components/MultiDropdownInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import UserFlowRunStatus from '@shared/modules/UserFlows/enums/UserFlowRunStatus';
import {OrganizationSettingsDocumentFlowRusUrlFilters} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowRunsFiltersType';

const LoadingIndicator = Styled.Loading`
`;

type OrganizationSettingsDocumentFlowRunsFilterQueryType = {
  viewer: {
    id: string;
    viewingOrganization: OrganizationModel & {
      projectTypesForCategory: ProjectTypeModel[];
    };
  };
};

interface OrganizationSettingsDocumentFlowRunsFilterProps {
  urlFilters: OrganizationSettingsDocumentFlowRusUrlFilters;
  handleApply: (props: {projectTypeIds: string[]; statuses: string[]}) => void;
}

const toFlatArray = (value: string[] | string | undefined) => {
  return value ? _.flatten([value]) : [];
};

const OrganizationSettingsDocumentFlowRunsFilter = ({
  urlFilters,
  handleApply,
}: OrganizationSettingsDocumentFlowRunsFilterProps) => {
  const filterPopover = usePopover({
    name: 'Global dashboards library page global dashboards filter modal popover',
  });

  const filtersCount = urlFilters.getFilterCount({
    filterKeys: ['projectTypeIds', 'statuses'],
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={filterPopover.ref}>
        <SecondaryButton
          text={`Filters${filtersCount ? ` (${filtersCount})` : ''}`}
          onPress={filterPopover.handleOpen}
          iconLeft={Icon.Filter}
          isWidthOfContainer
          isResponsive
        />
      </Popover.Content>
      <OrganizationSettingsDocumentFlowRunsFilterPopover
        selectedProjectTypeIds={urlFilters.get('projectTypeIds')}
        selectedStatuses={urlFilters.get('statuses')}
        popover={filterPopover}
        handleApply={handleApply}
      />
    </React.Fragment>
  );
};

interface OrganizationSettingsDocumentFlowRunsFilterPopoverProps {
  popover: PopoverType;
  selectedProjectTypeIds: string[] | undefined;
  selectedStatuses: string[] | undefined;
  handleApply: OrganizationSettingsDocumentFlowRunsFilterProps['handleApply'];
}

const OrganizationSettingsDocumentFlowRunsFilterPopover = ({
  popover,
  selectedProjectTypeIds,
  selectedStatuses,
  handleApply,
}: OrganizationSettingsDocumentFlowRunsFilterPopoverProps) => {
  const responsive = useResponsive();

  const [newSelectedProjectTypeIds, setNewSelectedProjectTypeIds] = useState(
    toFlatArray(selectedProjectTypeIds),
  );
  const [newSelectedStatuses, setNewSelectedStatuses] = useState(toFlatArray(selectedStatuses));

  const {data, loading} = useQuery<OrganizationSettingsDocumentFlowRunsFilterQueryType>(
    OrganizationSettingsDocumentFlowRunsFilter.query,
    {
      variables: {
        projectTypeCategory: ProjectTypeCategory.MOVE,
      },
      fetchPolicy: 'cache-and-network',
      skip: !popover.isOpen,
    },
  );

  useEffect(() => {
    if (popover.isOpen) {
      setNewSelectedProjectTypeIds(toFlatArray(selectedProjectTypeIds));
      setNewSelectedStatuses(toFlatArray(selectedStatuses));
    }
  }, [popover.isOpen]);

  const filtersCount =
    (newSelectedProjectTypeIds.length ? 1 : 0) + (newSelectedStatuses.length ? 1 : 0);

  return (
    <PopoverFilter
      activeFiltersCount={filtersCount}
      popover={popover}
      responsive={responsive}
      handleApply={() => {
        handleApply({
          projectTypeIds: newSelectedProjectTypeIds,
          statuses: newSelectedStatuses,
        });

        popover.handleClose();
      }}
      handleClear={() => {
        setNewSelectedProjectTypeIds([]);
        setNewSelectedStatuses([]);
      }}
    >
      <Loading loading={loading || !data} as={LoadingIndicator}>
        {() => (
          <OrganizationSettingsDocumentFlowRunsFilterBody
            projectTypes={data!.viewer.viewingOrganization.projectTypesForCategory}
            newSelectedProjectTypeIds={newSelectedProjectTypeIds}
            setNewSelectedProjectTypeIds={setNewSelectedProjectTypeIds}
            newSelectedStatuses={newSelectedStatuses}
            setNewSelectedStatuses={setNewSelectedStatuses}
          />
        )}
      </Loading>
    </PopoverFilter>
  );
};

interface OrganizationSettingsDocumentFlowRunsFilterBodyProps {
  projectTypes: ProjectTypeModel[];
  newSelectedProjectTypeIds: string[];
  setNewSelectedProjectTypeIds: (value: string[]) => void;
  newSelectedStatuses: string[];
  setNewSelectedStatuses: (value: string[]) => void;
}

const OrganizationSettingsDocumentFlowRunsFilterBody = ({
  projectTypes,
  newSelectedProjectTypeIds,
  setNewSelectedProjectTypeIds,
  newSelectedStatuses,
  setNewSelectedStatuses,
}: OrganizationSettingsDocumentFlowRunsFilterBodyProps) => {
  const sortedProjectTypeOptions = projectTypes
    .map((projectType) => ({
      label: projectType.name,
      value: projectType.id,
    }))
    .sort(sortByAlphabetical('label'));

  const sortedStatusOptions = UserFlowRunStatus.ALL_STATUSES.map((status) => ({
    label: UserFlowRunStatus.convertUserFlowRunStatusToLabel(status),
    value: status,
  }));

  return (
    <React.Fragment>
      <FieldInput
        index={0}
        component={MultiDropdownInputComponent}
        label={'Project Types'}
        input={{
          options: sortedProjectTypeOptions,
          name: 'Project type',
          value: newSelectedProjectTypeIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedProjectTypeIds(value),
          placeholder: 'Select project types',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        index={1}
        component={MultiDropdownInputComponent}
        label={'Document Flow Statuses'}
        input={{
          options: sortedStatusOptions,
          name: 'Document flow Status',
          value: newSelectedStatuses,
          setFieldValue: (name: string, value: string[]) => setNewSelectedStatuses(value),
          placeholder: 'Select document flow statuses',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsDocumentFlowRunsFilter.query = gql`
  query OrganizationSettingsDocumentFlowRunsFilter($projectTypeCategory: String) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        projectTypesForCategory(category: $projectTypeCategory) {
          id
          name
        }
      }
    }
  }
`;

export default OrganizationSettingsDocumentFlowRunsFilter;
