// Supermove
import {gql} from '@supermove/graphql';
import {RateTableAdditionalValueModel} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

import RateTableMetricForm, {
  RateTableMetricFormType,
} from '@shared/modules/RateTable/forms/RateTableMetricForm';

const _new = ({
  rateTablePrimaryMetricForm,
  rateTableSecondaryMetricForm,
  secondaryMetricValue,
}: {
  rateTablePrimaryMetricForm: RateTableMetricFormType;
  rateTableSecondaryMetricForm?: RateTableMetricFormType;
  secondaryMetricValue?: string;
}): RateTableAdditionalValueFormType => ({
  rateTableAdditionalValueId: undefined,
  rateTablePrimaryMetricForm,
  rateTableSecondaryMetricForm,
  secondaryMetricValue,
  rate: undefined,
  isDeleted: false,
});

const edit = withFragment(
  (rateTableAdditionalValue: RateTableAdditionalValueModel): RateTableAdditionalValueFormType => ({
    rateTableAdditionalValueId: rateTableAdditionalValue.id,
    rateTablePrimaryMetricForm: RateTableMetricForm.edit(
      rateTableAdditionalValue.rateTablePrimaryMetric,
    ),
    rateTableSecondaryMetricForm: rateTableAdditionalValue.rateTableSecondaryMetric
      ? RateTableMetricForm.edit(rateTableAdditionalValue.rateTableSecondaryMetric)
      : undefined,
    secondaryMetricValue: rateTableAdditionalValue.secondaryMetricValue,
    rate: rateTableAdditionalValue.rate,
    isDeleted: rateTableAdditionalValue.isDeleted,
  }),
  gql`
    ${RateTableMetricForm.edit.fragment}
    fragment RateTableAdditionalValueForm_edit on RateTableAdditionalValue {
      id
      secondaryMetricValue
      rate
      isDeleted
      rateTablePrimaryMetric {
        id
        ...RateTableMetricForm_edit
      }
      rateTableSecondaryMetric {
        id
        ...RateTableMetricForm_edit
      }
    }
  `,
);

const duplicate = withFragment(
  (rateTableAdditionalValue: RateTableAdditionalValueModel): RateTableAdditionalValueFormType => ({
    ...edit(rateTableAdditionalValue),
    rateTableAdditionalValueId: undefined,
    rateTablePrimaryMetricForm: RateTableMetricForm.duplicate(
      rateTableAdditionalValue.rateTablePrimaryMetric,
    ),
    rateTableSecondaryMetricForm: rateTableAdditionalValue.rateTableSecondaryMetric
      ? RateTableMetricForm.duplicate(rateTableAdditionalValue.rateTableSecondaryMetric)
      : undefined,
  }),
  gql`
    ${edit.fragment}
    ${RateTableMetricForm.duplicate.fragment}
    fragment RateTableAdditionalValueForm_duplicate on RateTableAdditionalValue {
      id
      rateTablePrimaryMetric {
        id
        ...RateTableMetricForm_duplicate
      }
      rateTableSecondaryMetric {
        id
        ...RateTableMetricForm_duplicate
      }
      ...RateTableAdditionalValueForm_edit
    }
  `,
);

export interface RateTableAdditionalValueFormType {
  rateTableAdditionalValueId?: string;
  rateTablePrimaryMetricForm: RateTableMetricFormType;
  rateTableSecondaryMetricForm?: RateTableMetricFormType;
  secondaryMetricValue?: string;
  rate?: number;
  isDeleted: boolean;
}

const toForm = ({
  rateTableAdditionalValueId,
  rateTablePrimaryMetricForm,
  rateTableSecondaryMetricForm,
  secondaryMetricValue,
  rate,
  isDeleted,
}: ReturnType<typeof _new>) => ({
  rateTableAdditionalValueId,
  rateTablePrimaryMetricForm: RateTableMetricForm.toForm(rateTablePrimaryMetricForm),
  rateTableSecondaryMetricForm: rateTableSecondaryMetricForm
    ? RateTableMetricForm.toForm(rateTableSecondaryMetricForm)
    : undefined,
  secondaryMetricValue,
  rate: Currency.toForm(rate),
  isDeleted,
});

export type RateTableAdditionalValueFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({
  rateTableAdditionalValueId,
  rateTablePrimaryMetricForm,
  rateTableSecondaryMetricForm,
  secondaryMetricValue,
  rate,
  isDeleted,
}: RateTableAdditionalValueFormToFormType) => ({
  rateTableAdditionalValueId,
  rateTablePrimaryMetricForm: RateTableMetricForm.toMutation(rateTablePrimaryMetricForm),
  rateTableSecondaryMetricForm: rateTableSecondaryMetricForm
    ? RateTableMetricForm.toMutation(rateTableSecondaryMetricForm)
    : undefined,
  secondaryMetricValue,
  rate: rate ? Currency.toMutation(rate) : null,
  isDeleted,
});

const RateTableAdditionalValueForm = {
  new: _new,
  edit,
  duplicate,
  toForm,
  toMutation,
};

export default RateTableAdditionalValueForm;
