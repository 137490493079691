// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {Form, ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TableV2Deprecated from '@shared/design/components/TableV2Deprecated';
import RateTableMetricKind from '@shared/modules/RateTable/enums/RateTableMetricKind';
import {RateTableFormToFormType} from '@shared/modules/RateTable/forms/RateTableForm';
import RateTableMetricValuesForm, {
  RateTableMetricValuesFormToFormType,
} from '@shared/modules/RateTable/forms/RateTableMetricValuesForm';
import RateTableValueForm from '@shared/modules/RateTable/forms/RateTableValueForm';
import RateTableRateInput from 'modules/Organization/Settings/Billing/components/RateTableRateInput';
import RateTableSecondaryMetricHeader from 'modules/Organization/Settings/Billing/components/RateTableSecondaryMetricHeader';

const handleAddRow = ({form}: {form: Form<{rateTableForm: RateTableFormToFormType}>}) => {
  const {rateTableForm} = form.values;
  const {rateTableMetricValuesForms, rateTableSecondaryMetric1Form} = rateTableForm;
  const lastMetricValuesForm = rateTableMetricValuesForms.length
    ? rateTableMetricValuesForms[rateTableMetricValuesForms.length - 1]
    : null;
  const primaryMetricValue = lastMetricValuesForm
    ? _.toNumber(lastMetricValuesForm.rateTablePrimaryMetric1Value) + 1
    : 1;
  const rateTableValueForms = rateTableSecondaryMetric1Form
    ? RateTableMetricKind.getSecondaryColumns(rateTableSecondaryMetric1Form.kind).map(
        ({name, value}) =>
          RateTableValueForm.new({
            primaryMetric1Value: primaryMetricValue,
            secondaryMetric1Value: value,
          }),
      )
    : [RateTableValueForm.new({primaryMetric1Value: primaryMetricValue})];
  const rateTableMetricValueForm = RateTableMetricValuesForm.new({
    rateTablePrimaryMetric1Value: primaryMetricValue,
    rateTableValueForms,
  });
  form.setFieldValue('rateTableForm.rateTableMetricValuesForms', [
    ...rateTableMetricValuesForms,
    rateTableMetricValueForm,
  ]);
};

const handleChangePrimaryMetric = ({
  form,
  field,
  metricValue,
  metricField,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  metricValue: string;
  metricField: string;
}) => {
  const rateTableMetricValuesForm: RateTableMetricValuesFormToFormType = _.get(form.values, field);
  const {rateTableValueForms} = rateTableMetricValuesForm;
  const newRateTableValueForms = rateTableValueForms.map((rateTableValueForm) => {
    return {
      ...rateTableValueForm,
      [metricField]: metricValue,
    };
  });
  form.setFieldValue(`${field}.rateTableValueForms`, newRateTableValueForms);
};

const handleCopyRateColumn = ({
  form,
  field,
  rateTableMetricValuesForms,
  sourceColumnIndex,
  destinationColumnIndexes,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  rateTableMetricValuesForms: RateTableMetricValuesFormToFormType[];
  sourceColumnIndex: number;
  destinationColumnIndexes: number[];
}) => {
  _.forEach(rateTableMetricValuesForms, (rateTableMetricValuesForm, rowIndex) => {
    const {rate} = rateTableMetricValuesForm.rateTableValueForms[sourceColumnIndex];
    _.forEach(destinationColumnIndexes, (destinationColumnIndex) => {
      form.setFieldValue(
        `${field}.${rowIndex}.rateTableValueForms.${destinationColumnIndex}.rate`,
        rate,
      );
    });
  });
};

const handleCopyRateCell = ({
  form,
  field,
  rate,
  rowIndex,
  columnIndex,
  copyDirection,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  rate: string;
  rowIndex: number;
  columnIndex: number;
  copyDirection: 'ROW' | 'COLUMN';
}) => {
  if (copyDirection === 'ROW') {
    const rateTableMetricValuesForm: RateTableMetricValuesFormToFormType = _.get(
      form.values,
      `${field}.${rowIndex}`,
    );
    const {rateTableValueForms} = rateTableMetricValuesForm;
    const newRateTableValueForms = rateTableValueForms.map((rateTableValueForm, index) => {
      return {
        ...rateTableValueForm,
        rate,
      };
    });
    form.setFieldValue(`${field}.${rowIndex}.rateTableValueForms`, newRateTableValueForms);
  }
  if (copyDirection === 'COLUMN') {
    const {rateTableMetricValuesForms} = form.values.rateTableForm;
    _.forEach(rateTableMetricValuesForms, (rateTableMetricValuesForm, index) => {
      form.setFieldValue(`${field}.${index}.rateTableValueForms.${columnIndex}.rate`, rate);
    });
  }
};

const getRateTableColumnDefinitions = ({
  form,
  field,
  selectedRowIndexes,
  setSelectedRowIndexes,
  cellWidth,
  checkboxCellWidth,
  cellStyle,
  responsive,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  selectedRowIndexes: number[];
  setSelectedRowIndexes: (selectedRowIndexes: number[]) => void;
  cellWidth: number;
  checkboxCellWidth: number;
  cellStyle: React.CSSProperties;
  responsive: ResponsiveType;
}) => {
  const {rateTableForm} = form.values;
  const {
    rateTablePrimaryMetric1Form,
    rateTablePrimaryMetric2Form,
    rateTableMetricValuesForms,
    rateTableSecondaryMetric1Form,
  } = rateTableForm;
  const isAllSelected = rateTableMetricValuesForms.length === selectedRowIndexes.length;
  return [
    {
      cellStyle: {...cellStyle, justifyContent: 'center'},
      width: checkboxCellWidth,
      headerContent: () => {
        return (
          <Checkbox
            isResponsive
            checkboxIcon={isAllSelected ? Icon.Check : Icon.Minus}
            isChecked={!!selectedRowIndexes.length}
            handleToggle={() => {
              rateTableMetricValuesForms.length === selectedRowIndexes.length
                ? setSelectedRowIndexes([])
                : setSelectedRowIndexes(_.range(rateTableMetricValuesForms.length));
            }}
          />
        );
      },
      cellContent: ({rowIndex}: {rowIndex: number}) => {
        return (
          <Checkbox
            isResponsive
            isChecked={_.includes(selectedRowIndexes, rowIndex)}
            handleToggle={() => {
              setSelectedRowIndexes(_.xor(selectedRowIndexes, [rowIndex]));
            }}
          />
        );
      },
      footerContent: () => null,
    },
    {
      cellStyle,
      width: cellWidth,
      headerContent: () => {
        return (
          <TableV2Deprecated.HeaderText responsive={responsive} numberOfLines={1}>
            {rateTablePrimaryMetric1Form.name}
          </TableV2Deprecated.HeaderText>
        );
      },
      cellContent: ({rowIndex}: {rowIndex: number}) => (
        <FieldInput
          {...form}
          name={`${field}.${rowIndex}.rateTablePrimaryMetric1Value`}
          isResponsive
          input={{
            placeholder: 'Enter value',
            style: {
              textAlign: 'center',
            },
          }}
          handleBlur={(event: any) =>
            handleChangePrimaryMetric({
              form,
              field: `${field}.${rowIndex}`,
              metricValue: event.target.value,
              metricField: 'primaryMetric1Value',
            })
          }
          style={{flex: 1}}
          showErrorMessage={false}
        />
      ),
      footerContent: () => (
        <TertiaryButton
          isResponsive
          text={RateTableMetricKind.getActionName(rateTablePrimaryMetric1Form.kind)}
          iconLeft={Icon.Plus}
          onPress={() => handleAddRow({form})}
        />
      ),
    },
    ...List.insertIf(!!rateTablePrimaryMetric2Form, {
      cellStyle,
      width: cellWidth,
      headerContent: () => {
        return (
          <TableV2Deprecated.HeaderText responsive={responsive} numberOfLines={1}>
            {rateTablePrimaryMetric2Form!.name}
          </TableV2Deprecated.HeaderText>
        );
      },
      cellContent: ({rowIndex}: {rowIndex: number}) => (
        <FieldInput
          {...form}
          name={`${field}.${rowIndex}.rateTablePrimaryMetric2Value`}
          isResponsive
          input={{
            placeholder: 'Enter value',
            style: {
              textAlign: 'center',
            },
          }}
          handleBlur={(event: any) =>
            handleChangePrimaryMetric({
              form,
              field: `${field}.${rowIndex}`,
              metricValue: event.target.value,
              metricField: 'primaryMetric2Value',
            })
          }
          style={{flex: 1}}
          showErrorMessage={false}
        />
      ),
      footerContent: () => null,
    }),
    ...List.insertIf(
      !_.isEmpty(rateTableMetricValuesForms),
      ...(rateTableMetricValuesForms[0]?.rateTableValueForms.map(
        (rateTableValueForm, columnIndex) => ({
          cellStyle:
            columnIndex === rateTableMetricValuesForms[0].rateTableValueForms.length - 1
              ? {padding: 8}
              : cellStyle,
          width: cellWidth,
          headerContent: () => {
            const hasMoreThanOneColumn = rateTableMetricValuesForms.length > 1;
            const isAllColumnRowsEmpty =
              hasMoreThanOneColumn &&
              rateTableMetricValuesForms.every(
                (rateTableMetricValuesForm) =>
                  !rateTableMetricValuesForm.rateTableValueForms[columnIndex].rate,
              );
            return (
              <RateTableSecondaryMetricHeader
                rateTableSecondaryMetric1Form={rateTableSecondaryMetric1Form}
                columnIndex={columnIndex}
                isCopyVisible={hasMoreThanOneColumn}
                isCopyDisabled={isAllColumnRowsEmpty}
                handleSubmit={(selectedColumns: number[]) =>
                  handleCopyRateColumn({
                    form,
                    field,
                    rateTableMetricValuesForms,
                    sourceColumnIndex: columnIndex,
                    destinationColumnIndexes: selectedColumns,
                  })
                }
              />
            );
          },
          cellContent: ({rowIndex}: {rowIndex: number}) => {
            const rateField = `${field}.${rowIndex}.rateTableValueForms.${columnIndex}.rate`;
            return (
              <FieldInput
                {...form}
                name={rateField}
                isResponsive
                component={RateTableRateInput}
                input={{
                  component: FieldInput.TextInput,
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  placeholder: 'Enter rate',
                  onCopy: (copyDirection: 'ROW' | 'COLUMN') => {
                    handleCopyRateCell({
                      form,
                      field,
                      rate: _.get(form.values, rateField),
                      rowIndex,
                      columnIndex,
                      copyDirection,
                    });
                  },
                }}
                style={{flex: 1}}
                showErrorMessage={false}
              />
            );
          },
          footerContent: () => null,
        }),
      ) || []),
    ),
  ];
};

const RatesTable = ({
  form,
  selectedRowIndexes,
  setSelectedRowIndexes,
  cellWidth,
  checkboxCellWidth,
  cellStyle,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  selectedRowIndexes: number[];
  setSelectedRowIndexes: (selectedRowIndexes: number[]) => void;
  cellWidth: number;
  checkboxCellWidth: number;
  cellStyle: React.CSSProperties;
}) => {
  const responsive = useResponsive();
  return (
    <TableV2Deprecated
      emptyStateText={'No rates.'}
      columnDefinitions={getRateTableColumnDefinitions({
        form,
        field: 'rateTableForm.rateTableMetricValuesForms',
        selectedRowIndexes,
        setSelectedRowIndexes,
        cellWidth,
        checkboxCellWidth,
        cellStyle,
        responsive,
      })}
      items={form.values.rateTableForm.rateTableMetricValuesForms}
      hasFooter
      isDense
      // This is required to have the borders per cell
      headerStyle={{paddingVertical: 0, backgroundColor: colors.gray.background}}
      rowStyle={{paddingVertical: 0}}
      calculateRowStyle={({rowIndex}: {rowIndex: number}) => ({
        backgroundColor: _.includes(selectedRowIndexes, rowIndex)
          ? colors.blue.accent
          : colors.white,
      })}
    />
  );
};

export default RatesTable;
