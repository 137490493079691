// Libraries
import React from 'react';

// Supermove
import {gql, useQuery} from '@supermove/graphql';
import {
  ModalType,
  PopoverType,
  useModal,
  useNavigationDOM,
  useState,
  useEffect,
} from '@supermove/hooks';
import {
  ProjectModel,
  EmailTemplateModel,
  ProjectTypeUserFlowModel,
  UserFlowModel,
} from '@supermove/models';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import SendEmailForProjectModal from 'modules/Email/Project/components/SendEmailForProjectModal';

const getActions = ({
  projectTypeUserFlows,
  sortedEmailTemplates,
  setSelectedEmailTemplateUuid,
  sendEmailForProjectModal,
  onDocumentFlowSelect,
}: {
  projectTypeUserFlows: ProjectTypeUserFlowModel[];
  sortedEmailTemplates: EmailTemplateModel[];
  setSelectedEmailTemplateUuid: (uuid: string) => void;
  sendEmailForProjectModal: ModalType;
  onDocumentFlowSelect: (userFlow: UserFlowModel) => void;
}) => {
  return [
    {
      label: 'Document Flow',
      actions: projectTypeUserFlows.map((projectTypeUserFlow) => ({
        text: projectTypeUserFlow.userFlow.name,
        onPress: () => {
          onDocumentFlowSelect(projectTypeUserFlow.userFlow);
        },
      })),
    },
    {
      label: 'Template',
      actions: sortedEmailTemplates.map((emailTemplate) => ({
        text: emailTemplate.name,
        onPress: () => {
          setSelectedEmailTemplateUuid(emailTemplate.uuid);
          sendEmailForProjectModal.handleOpen();
        },
      })),
    },
    {
      label: 'Custom',
      actions: [
        {
          text: 'Compose new email',
          onPress: () => {
            setSelectedEmailTemplateUuid('');
            sendEmailForProjectModal.handleOpen();
          },
        },
      ],
    },
  ];
};

const ProjectSendEmailOptionsPopover = ({
  actionMenuPopover,
  children,
  refetch,
}: {
  actionMenuPopover: PopoverType;
  children?: React.ReactNode;
  refetch?: () => void;
}) => {
  const {params, navigator} = useNavigationDOM();
  const [selectedEmailTemplateUuid, setSelectedEmailTemplateUuid] = useState('');

  const sendEmailForProjectModal = useModal({name: 'Send Email For Project Modal'});

  const {data} = useQuery<{project: ProjectModel}>(ProjectSendEmailOptionsPopover.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: params.projectUuid,
    },
    skip: !actionMenuPopover.isOpen,
  });

  // This is a workaround to keep the project data when the popover is opened
  // The modal use will go away when we will migrate to new email page
  const [project, setProject] = useState(data?.project);
  useEffect(() => {
    if (data) {
      setProject(data.project);
    }
  }, [data]);

  return (
    <React.Fragment>
      {project && (
        <SendEmailForProjectModal
          key={sendEmailForProjectModal.key}
          project={project}
          isOpen={sendEmailForProjectModal.isOpen}
          handleClose={() => {
            sendEmailForProjectModal.handleClose();
            setSelectedEmailTemplateUuid('');
            refetch?.();
          }}
          emailTemplateUuid={selectedEmailTemplateUuid}
        />
      )}
      <ActionMenuPopover
        popover={actionMenuPopover}
        actions={getActions({
          projectTypeUserFlows: project ? project.projectType.projectTypeUserFlows : [],
          sortedEmailTemplates: project ? project.organization.sortedEmailTemplates : [],
          setSelectedEmailTemplateUuid,
          sendEmailForProjectModal,
          onDocumentFlowSelect: (userFlow) => {
            navigator.push(`/projects/${params.projectUuid}/document-flows/${userFlow.uuid}/new`);
          },
        })}
        width={400}
        popoverMaxHeight={556}
      >
        {children}
      </ActionMenuPopover>
    </React.Fragment>
  );
};

ProjectSendEmailOptionsPopover.query = gql`
  ${SendEmailForProjectModal.fragment}
  query ProjectSendEmailOptionsPopover($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        sortedEmailTemplates {
          id
          name
          uuid
        }
      }
      projectType {
        id
        projectTypeUserFlows {
          id
          userFlow {
            id
            uuid
            name
          }
        }
      }
      ...SendEmailForProjectModal
    }
  }
`;

export default ProjectSendEmailOptionsPopover;
