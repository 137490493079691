// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useToast} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UserFlowForm from '@shared/modules/UserFlows/forms/UserFlowForm';
import useUpdateUserFlowMutation from '@shared/modules/UserFlows/hooks/useUpdateUserFlowMutation';
import {PageLoadingIndicator} from 'modules/App/components';
import DocumentFlowPage from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/DocumentFlowPage';

const EditDocumentFlowPageContent = ({userFlow}: {userFlow: UserFlowModel}) => {
  const {navigator} = useNavigationDOM();
  const createDocumentFlowSuccessToast = useToast({
    ToastComponent: SuccessToast,
  });

  const userFlowForm = UserFlowForm.edit(userFlow);

  const field = 'userFlowForm';
  const {form, submitting, handleSubmit} = useUpdateUserFlowMutation({
    userFlowForm,
    onSuccess: ({userFlow}) => {
      createDocumentFlowSuccessToast.handleToast({
        message: `'${userFlow.name}' updated!`,
      });
      navigator.replace(`/settings/documents/document-flows`);
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  return (
    <DocumentFlowPage
      form={form}
      field={field}
      submitting={submitting}
      handleSubmit={handleSubmit}
    />
  );
};

const EditDocumentFlowPage = () => {
  const {params} = useNavigationDOM();

  const {loading, data} = useQuery<EditDocumentFlowPageQueryType>(EditDocumentFlowPageQuery, {
    variables: {userFlowUuid: params.userFlowUuid},
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => <EditDocumentFlowPageContent userFlow={data!.userFlow} />}
    </Loading>
  );
};

type EditDocumentFlowPageQueryType = {
  userFlow: UserFlowModel;
};

const EditDocumentFlowPageQuery = gql`
  ${UserFlowForm.edit.fragment}
  query EditDocumentFlowPage($userFlowUuid: String!) {
    userFlow(uuid: $userFlowUuid) {
      id
      ...UserFlowForm_edit
    }
  }
`;

export default EditDocumentFlowPage;
