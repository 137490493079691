// Libraries
import React from 'react';

// Supermove
import {Icon, PageLoader, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useUrlFilters} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';

// App
import DocumentsSettingsPage from 'modules/Organization/Settings/Document/DocumentsSettingsPage';
import DocumentsSettingsDocumentFlowsPageContent from 'modules/Organization/Settings/Document/components/DocumentsSettingsDocumentFlowsPageContent';
import DocumentsSettingsDocumentFlowsPageHeader from 'modules/Organization/Settings/Document/components/DocumentsSettingsDocumentFlowsPageHeader';
import {OrganizationSettingsDocumentFlowsFiltersType} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFiltersType';

const Container = Styled.View`
  padding-vertical: 24px;
  flex: 1;
  max-width: 1200px;
`;

const DocumentsSettingsDocumentFlowsPage = () => {
  const {navigator} = useNavigationDOM();

  const urlFilters = useUrlFilters<OrganizationSettingsDocumentFlowsFiltersType>({
    getRoute: () => '/settings/documents/document-flows',
    filterKeys: ['searchQuery', 'projectTypeIds'],
  });

  const {data, loading, refetch} = useQuery<{
    filteredUserFlows: UserFlowModel[];
  }>(DocumentsSettingsDocumentFlowsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...urlFilters.getFilters(),
    },
  });

  return (
    <DocumentsSettingsPage
      title='Flows'
      description='Set up and configure the document flows to be sent to customers.'
      actionText='Create Flow'
      actionIcon={Icon.Plus}
      handleAction={() => navigator.push('/settings/documents/document-flows/create')}
    >
      <PageLoader loading={loading} data={data}>
        {({loadedData}) => {
          return (
            <Container>
              <DocumentsSettingsDocumentFlowsPageHeader urlFilters={urlFilters} />
              <Space height={24} />
              <DocumentsSettingsDocumentFlowsPageContent
                urlFilters={urlFilters}
                userFlows={loadedData.filteredUserFlows}
                refetch={refetch}
              />
              <Space height={32} />
            </Container>
          );
        }}
      </PageLoader>
    </DocumentsSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentsSettingsDocumentFlowsPage.query = gql`
  ${DocumentsSettingsDocumentFlowsPageContent.query}

  query DocumentsSettingsDocumentFlowsPage(
    $projectTypeIds: [String!]
    $searchQuery: String
  ) {
    ${gql.query}
    filteredUserFlows(
      projectTypeIds: $projectTypeIds,
      searchQuery: $searchQuery
    ) {
      id
      ...DocumentsSettingsDocumentFlowsPageContent
    }
  }
`;

export default DocumentsSettingsDocumentFlowsPage;
