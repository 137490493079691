// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, ResponsiveType} from '@supermove/hooks';
import {
  DocumentTemplateModel,
  JobTypeStepDocumentTemplateModel,
  JobTypeStepModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import {JobTypeStepDocumentTemplateFormType} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';

import JobTypeStepsDocumentActions from './JobTypeStepsDocumentActions';

const DocumentHeaderText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
  padding-vertical: 2px;
`;

const DocumentStepsContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
  padding-vertical: 4px;
  border-radius: 4px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const DeleteDocumentButton = Styled.ButtonV2`
  padding-vertical: 4px;
`;

const DocumentLeftTextContainer = Styled.View`
`;

const DocumentSectionCompleteCount = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const removeDocumentStep = ({
  form,
  documentTemplateKindsField,
  currentDocumentStepKind,
  jobTypeStepDocumentTemplateFormsField,
  documentTemplateId,
}: {
  form: Form<JobTypeStepDocumentTemplateFormType>;
  documentTemplateKindsField: string;
  currentDocumentStepKind: string;
  jobTypeStepDocumentTemplateFormsField: string;
  documentTemplateId: string;
}) => {
  const jobTypeStepDocumentTemplateKinds = _.get(form.values, documentTemplateKindsField);
  const updatedDocumentTemplateKinds = _.without(
    jobTypeStepDocumentTemplateKinds,
    currentDocumentStepKind,
  );
  const numberDocumentTemplateId = _.toNumber(documentTemplateId);
  const jobTypeStepDocumentTemplateForms = _.get(
    form.values,
    jobTypeStepDocumentTemplateFormsField,
  );
  const updatedJobTypeStepDocumentTemplateForms = _.reduce(
    jobTypeStepDocumentTemplateForms,
    (acc: JobTypeStepDocumentTemplateFormType[], item: JobTypeStepDocumentTemplateFormType) => {
      if (item.documentTemplateId === numberDocumentTemplateId) {
        // Don't add item
        return acc;
      }
      return [...acc, {...item, index: acc.length}]; // Set new position with element removed
    },
    [],
  );
  form.setFieldValue(
    jobTypeStepDocumentTemplateFormsField,
    updatedJobTypeStepDocumentTemplateForms,
  );
  form.setFieldValue(documentTemplateKindsField, updatedDocumentTemplateKinds);
  if (_.isEmpty(updatedDocumentTemplateKinds)) {
    form.setFieldValue('jobTypeStepForm.isEnabled', false);
  }
};

const DocumentTitleText = ({
  responsive,
  documentName,
}: {
  documentName: string;
  responsive: ResponsiveType;
}) => {
  return (
    <DocumentHeaderText responsive={responsive} numberOfLines={1} color={colors.blue.interactive}>
      {documentName}
    </DocumentHeaderText>
  );
};

const DocumentSectionItem = ({
  jobTypeStepDocumentTemplate,
  responsive,
  documentId,
  documentName,
  documentTemplateKind,
  documentTemplateKindsField,
  jobTypeStepDocumentTemplateFormsField,
  form,
  handleSelectDocumentSection,
  handleSubmit,
}: {
  jobTypeStepDocumentTemplate: JobTypeStepDocumentTemplateModel;
  responsive: ResponsiveType;
  documentId: string;
  documentName: string;
  documentTemplateKind: string;
  documentTemplateKindsField: string;
  jobTypeStepDocumentTemplateFormsField: string;
  form: Form<any>;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
  handleSubmit: () => void;
}) => {
  const {documentTemplate} = jobTypeStepDocumentTemplate;
  const {documentSectionsCount} = documentTemplate.activeDocumentTemplateVersion;
  const {editableSectionNames} = jobTypeStepDocumentTemplate;
  const readonlySectionCount = documentSectionsCount - editableSectionNames.length;

  return (
    <DocumentStepsContainer>
      <DocumentLeftTextContainer>
        <DocumentTitleText responsive={responsive} documentName={documentName} />
        {/* Don't show this if there's no sections */}
        {!!documentSectionsCount && (
          <React.Fragment>
            <Space height={8} />
            <TextTooltip
              placement='top'
              text={editableSectionNames?.join('\n')}
              isEnabledMobileToast={false}
            >
              <DocumentSectionCompleteCount
                responsive={responsive}
              >{`${documentSectionsCount - (readonlySectionCount ?? 0)}/${documentSectionsCount} sections to complete`}</DocumentSectionCompleteCount>
            </TextTooltip>
          </React.Fragment>
        )}
      </DocumentLeftTextContainer>
      <Space style={{flex: 1}} />
      <JobTypeStepsDocumentActions
        handleRemoveDocument={() => {
          removeDocumentStep({
            form,
            documentTemplateKindsField,
            currentDocumentStepKind: documentTemplateKind,
            jobTypeStepDocumentTemplateFormsField,
            documentTemplateId: documentId,
          });
          setImmediate(handleSubmit);
        }}
        handleSelectDocumentSections={() => handleSelectDocumentSection(documentTemplate)}
        // If they've set readonly sections, they know what this is
        skipSelectSectionConfirmation={readonlySectionCount > 0}
        // Disabled section select if there aren't multiple sections
        selectSectionsDisabled={documentSectionsCount < 2}
      />
    </DocumentStepsContainer>
  );
};

const JobTypeStepDocumentsSectionItem = ({
  documentTemplate: {name: documentName, id: documentId, identifier: documentTemplateKind},
  documentTemplateKindsField,
  jobTypeStepDocumentTemplateFormsField,
  form,
  handleSelectDocumentSection,
  handleSubmit,
  jobTypeStep,
  responsive,
}: {
  documentTemplateKindsField: string;
  jobTypeStepDocumentTemplateFormsField: string;
  jobTypeStep?: JobTypeStepModel;
  form: Form<any>;
  handleSubmit: () => void;
  responsive: ResponsiveType;
  handleSelectDocumentSection: (documentTemplate: DocumentTemplateModel) => void;
  documentTemplate: DocumentTemplateModel;
}) => {
  const jobTypeStepDocumentTemplate = jobTypeStep?.jobTypeStepDocumentTemplates.find(
    (jobTypeStepDocumentTemplate) =>
      jobTypeStepDocumentTemplate.documentTemplate.identifier === documentTemplateKind,
  );

  // If some kinds of steps never had documents and then one gets added, the DB step gets newly added
  // We may not have the jobTypeStep for it yet, so this handles that case while we refetch
  if (!jobTypeStepDocumentTemplate) {
    return (
      <DocumentStepsContainer>
        <DocumentTitleText responsive={responsive} documentName={documentName} />
      </DocumentStepsContainer>
    );
  }

  return (
    <DocumentSectionItem
      jobTypeStepDocumentTemplate={jobTypeStepDocumentTemplate}
      responsive={responsive}
      documentId={documentId}
      documentName={documentName}
      documentTemplateKind={documentTemplateKind}
      documentTemplateKindsField={documentTemplateKindsField}
      jobTypeStepDocumentTemplateFormsField={jobTypeStepDocumentTemplateFormsField}
      form={form}
      handleSelectDocumentSection={handleSelectDocumentSection}
      handleSubmit={handleSubmit}
    />
  );
};

JobTypeStepDocumentsSectionItem.fragment = gql`
  fragment JobTypeStepDocumentsSectionItem on JobTypeStep {
    id
    jobTypeStepDocumentTemplates {
      id
      jobTypeStepId
      editableSectionNames
      documentTemplate {
        id
        uuid
        identifier
        name
        activeDocumentTemplateVersion {
          id
          uuid
          documentSectionsCount
        }
      }
    }
  }
`;

export default JobTypeStepDocumentsSectionItem;
