// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePagination, useResponsive, useToast} from '@supermove/hooks';
import {PayrollReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PayrollReportStatus from '@shared/modules/Accounting/enums/PayrollReportStatus';
import useSyncPayrollReportMutation from '@shared/modules/Accounting/hooks/useSyncPayrollReportMutation';
import useUpdatePayrollPaidOutMutation from '@shared/modules/Accounting/hooks/useUpdatePayrollReportPaidOutMutation';
import CompensationReportsListV2 from 'modules/Accounting/CompensationReport/components/CompensationReportsListV2';
import Line from 'modules/App/components/Line';

const MAX_CONTENT_WIDTH = 1400;

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const Label = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary}
`;

const Text = Styled.Text`
  ${Typography.Body4}
`;

const ContentSectionContainer = Styled.View`
  max-width: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? MAX_CONTENT_WIDTH + 12 : MAX_CONTENT_WIDTH + 24)}px;
  padding-horizontal:${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 12 : 24)}px;
`;

const BackText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const DateText = Styled.Text`
  ${Typography.Label1};
`;

const PaidOutButton = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 12px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'ThemeP... Remove this comment to see the full error message
    disabled,
  }) => (disabled ? colors.gray.tertiary : colors.blue.interactive)};
  border-radius: 4px;
`;

const PayrollReportSummaryContainer = Styled.View`
  flex-direction: row;
  width: 294px;
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const getVariablesFromParams = (params: any) => {
  return {
    pagination: PaginationBar.DEFAULT_PAGINATION,
    ...params,
  };
};

const ContentHeader = ({payrollReport, refetch}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const syncPayrollReportSucessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Payroll report synced',
  });
  const {handleSubmit} = useUpdatePayrollPaidOutMutation({
    payrollReportId: payrollReport.id,
    onSuccess: refetch,
    onError: (errors: any) => console.log({errors}),
  });
  const {handleSubmit: handleSubmitPayrollReport, submitting: payrollSubmitting} =
    useSyncPayrollReportMutation({
      payrollReportId: payrollReport.id,
      onSuccess: () => {
        syncPayrollReportSucessToast.handleToast();
        refetch();
      },
      onError: (errors: any) => {},
    });
  const isPaidOut =
    payrollReport.status === PayrollReportStatus.PAID_OUT ||
    payrollReport.compensationReportsCount === 0;
  return (
    <ContentSectionContainer {...responsive}>
      <Space height={16} />
      <Button onPress={() => navigator.goBack()}>
        <Icon source={Icon.ArrowLeft} color={colors.gray.secondary} size={10} />
        <Space width={8} />
        <BackText>Back</BackText>
      </Button>
      <Space height={8} />
      <Row>
        <DateText>{`Payroll ${PayrollReport.getDateRange(payrollReport)}`}</DateText>
        <Space style={{flex: 1, minWidth: 16}} />
        <PaidOutButton onPress={() => handleSubmitPayrollReport()} disabled={payrollSubmitting}>
          <ButtonText color={colors.white}>Sync Payroll</ButtonText>
        </PaidOutButton>
        <Space width={16} />
        <PaidOutButton onPress={handleSubmit} disabled={isPaidOut}>
          <ButtonText color={colors.white}>Mark as Paid Out</ButtonText>
        </PaidOutButton>
      </Row>
      <Space height={16} />
    </ContentSectionContainer>
  );
};

const PayrollReportSummary = ({payrollReport}: any) => {
  return (
    <PayrollReportSummaryContainer>
      <Column>
        <Label>Number of Employees</Label>
        <Space height={4} />
        <Text>{payrollReport.compensationReportsCount}</Text>
      </Column>
      <Space style={{flex: 1}} />
      <Column>
        <Label>Total Compensation</Label>
        <Space height={4} />
        <Text>{Currency.display(payrollReport.totalCompensation)}</Text>
      </Column>
    </PayrollReportSummaryContainer>
  );
};

const ShowPayrollReportAccountingPageContentV2 = ({payrollReport, viewerRole, refetch}: any) => {
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params);
  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page),
    paginationMetadata: _.get(payrollReport, 'compensationReportsPaginatedList.paginationMetadata'),
    onChangePage: (page) => {
      navigator.replace(
        URL.getUrlFromVariables(`/accounting/payroll-reports/${payrollReport.uuid}`, {
          ...variables,
          pagination: {
            ...variables.pagination,
            page,
          },
        }),
      );
    },
  });
  return (
    <Container>
      <ContentHeader payrollReport={payrollReport} refetch={refetch} />
      <Line style={{maxWidth: MAX_CONTENT_WIDTH}} />
      <Space height={16} />
      <ContentSectionContainer style={{flex: 1}}>
        <PayrollReportSummary payrollReport={payrollReport} />
        <Space height={16} />
        <CompensationReportsListV2
          compensationReports={payrollReport.compensationReportsPaginatedList.compensationReports}
          organizationSlug={payrollReport.organization.slug}
          refetch={refetch}
          viewerRole={viewerRole}
          isCostAndCompensationEnabled={
            payrollReport.organization.settings.isCostAndCompensationEnabled
          }
          isEnabledCompensationCsvDownload={
            payrollReport.organization.features.isEnabledCompensationCsvDownload
          }
        />
        <Space height={32} />
        <PaginationBar pagination={pagination} />
        <Space height={32} />
      </ContentSectionContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowPayrollReportAccountingPageContentV2.fragment = gql`
  ${CompensationReportsListV2.fragment}
  ${PayrollReport.getDateRange.fragment}
  ${usePagination.fragment}

  fragment ShowPayrollReportAccountingPageContentV2 on PayrollReport {
    id
    uuid
    compensationReportsCount
    totalCompensation
    status
    organization {
      id
      slug
      settings {
        id
        isCostAndCompensationEnabled
      }
      features {
        isEnabledCompensationCsvDownload: isEnabled(feature: "COMPENSATION_CSV_DOWNLOAD")
      }
    }
    compensationReportsPaginatedList(pagination: $pagination) {
      compensationReports: results {
        id
        ...CompensationReportsListV2
      }
      paginationMetadata {
        ...usePagination
      }
    }
    ...PayrollReport_getDateRange
  }
`;

export default ShowPayrollReportAccountingPageContentV2;
