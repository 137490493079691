// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';

// App
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import Toast from '@shared/design/components/Toast';
import CancelProjectForm from '@shared/modules/Project/forms/CancelProjectForm';
import useUncancelProjectMutation from '@shared/modules/Project/hooks/useUncancelProjectMutation';

const RestoreProjectModal = ({
  project,
  isOpen,
  handleClose,
  refetch,
}: {
  project: ProjectModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const projectCancelledToast = useToast({ToastComponent: Toast, message: 'Project restored.'});
  const cancelProjectForm = CancelProjectForm.new({projectId: project.id});
  const {handleSubmit, submitting} = useUncancelProjectMutation({
    cancelProjectForm,
    onSuccess: () => {
      projectCancelledToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      icon={Icon.ArrowRotateRight}
      title={'Restore Project'}
      subtitle={'Are you sure you want to restore this project?'}
      isSubmitting={submitting}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RestoreProjectModal.fragment = gql`
  fragment RestoreProjectModal on Project {
    id
    organization {
      id
      slug
    }
  }
`;

export default RestoreProjectModal;
