// Libraries
import React from 'react';

// Supermove
import {Emoji, Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Document} from '@supermove/utils';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import CustomerDocumentV2 from 'modules/Document/DocumentV2/CustomerDocumentV2';

const PaddingContainer = Styled.View`
  padding: ${(props) => ((props as any).mobile ? '16px' : '24px')};
`;

const openPrintDialog = async ({url, setIsLoading}: any) => {
  setIsLoading(true);
  const filename = 'document.pdf';
  url = `${window.location.origin}${url}`;
  const pdf = await Document.generatePDFFromUrl({filename, url});
  Document.printFile({file: pdf});
  setIsLoading(false);
};

const DocumentSuccessStep = ({documentUuid, defaultCollapsed}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const responsive = useResponsive();
  const {loading, data} = useQuery(DocumentSuccessStep.query, {
    variables: {
      documentUuid,
    },
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Loading loading={loading}>
      {() => {
        const {document} = data;
        return (
          <CollapsibleContent
            TitleIconComponent={<Emoji style={{paddingVertical: 6}} name={'pencil'} />}
            title={document.displayName}
            // Disabling the primaryActionText on mobile will hide the button
            // TODO(jholston): We can turn this on when mobile printing is supported
            // @ts-expect-error TS(2322): Type 'false | "Print"' is not assignable to type '... Remove this comment to see the full error message
            primaryActionText={!responsive.mobile && 'Print'}
            handlePrimaryAction={() => {
              openPrintDialog({
                url: `/0/${document.organization.slug}/documents/v2/${document.uuid}`,
                setIsLoading,
              });
            }}
            defaultCollapsed={defaultCollapsed}
            isSubmittingPrimaryAction={isLoading}
          >
            <PaddingContainer {...responsive}>
              <CustomerDocumentV2 document={data.document} />
            </PaddingContainer>
          </CollapsibleContent>
        );
      }}
    </Loading>
  );
};

DocumentSuccessStep.query = gql`
  ${CustomerDocumentV2.fragment}
  query DocumentSuccessStep($documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      uuid
      displayName
      organization {
        id
        slug
      }
      ...CustomerDocumentV2
    }
  }
`;

export default DocumentSuccessStep;
