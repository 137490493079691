// Libraries
import React from 'react';

// App
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useDrawer, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';

// Components
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Tabs from '@shared/design/components/Tabs';
import UserRole from '@shared/modules/User/enums/UserRole';
import CreateTeamDrawer from 'modules/Organization/Settings/Staff/components/CreateTeamDrawer';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';
import StaffAdminSection from 'modules/Organization/Settings/Users/List/components/StaffAdminSection';
import StaffOfficeSection from 'modules/Organization/Settings/Users/List/components/StaffOfficeSection';
import InviteOfficeUserSuccessModal from 'modules/User/components/InviteOfficeUserSuccessModal';
import InviteUserDrawer from 'modules/User/components/InviteUserDrawer';

const TABS = {
  OFFICE_MEMBERS: 'office-members',
  ADMINS: 'admins',
  TEAMS: 'teams',
};

const Row = Styled.View`
  flex-direction: row;
`;

const PageContentContainer = Styled.View`
  flex: 1;
`;

const getTabIndex = ({tab}: any) => {
  switch (tab) {
    case TABS.OFFICE_MEMBERS:
      return 0;
    case TABS.ADMINS:
      return 1;
    case TABS.TEAMS:
      return 2;
    default:
      return 0;
  }
};

const handleTabPress = ({route, navigator}: any) => {
  navigator.push(`/settings/staff/office-staff/${route}`);
};

const getPageSubTabs = ({organization}: any) => {
  return [
    {
      label: 'Staff',
      count: organization.activeOfficeUsers.length,
      route: TABS.OFFICE_MEMBERS,
    },
    {label: 'Admins', count: organization.activeAdminUsers.length, route: TABS.ADMINS},
    {
      label: 'Teams',
      count: organization.officeTeams.length,
      route: TABS.TEAMS,
      prependSeparator: true,
    },
  ];
};

const OfficeStaffContent = ({
  tab,
  canImpersonate,
  organization,
  viewer,
  refetch,
  officeTeams,
}: any) => {
  switch (tab) {
    case TABS.OFFICE_MEMBERS:
      return (
        <StaffOfficeSection
          canImpersonate={canImpersonate}
          organization={organization}
          viewerRole={viewer.role}
          refetch={refetch}
        />
      );
    case TABS.ADMINS:
      return (
        <StaffAdminSection
          canImpersonate={canImpersonate}
          organization={organization}
          viewerRole={viewer.role}
          refetch={refetch}
        />
      );
    case TABS.TEAMS:
      return <TeamsTable teams={officeTeams} refetch={refetch} viewer={viewer} />;
    default:
      return null;
  }
};

const OrganizationSettingsStaffOfficeStaffContent = () => {
  const responsive = useResponsive();
  const createTeamDrawer = useDrawer({name: 'Create Team Drawer'});
  const inviteUserDrawer = useDrawer({name: 'Invite User Drawer'});
  const inviteOfficeUserSuccessModal = useModal({name: 'Invite Office User Success Modal'});
  const handleInviteAnotherUser = () => {
    inviteOfficeUserSuccessModal.handleClose();
    inviteUserDrawer.handleOpen();
  };
  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffOfficeStaffContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  const {navigator, params} = useNavigationDOM();
  const activeTabIndex = getTabIndex({tab: params.subTab});

  return (
    <Loading loading={loading}>
      {() => {
        const {viewingOrganization, role} = data.viewer;
        const organization = viewingOrganization;

        return (
          <ScrollView
            horizontal
            contentContainerStyle={{
              flex: 1,
              minWidth: 1000,
              paddingHorizontal: responsive.desktop ? 0 : 24,
            }}
          >
            <PageContentContainer>
              <Row>
                <Space style={{flex: 1}} />
                <SecondaryButton text={'Create Team'} onPress={createTeamDrawer.handleOpen} />
                {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(role) && (
                  <React.Fragment>
                    <Space width={12} />
                    <Button text={'Invite Office User'} onPress={inviteUserDrawer.handleOpen} />
                  </React.Fragment>
                )}
              </Row>
              <Space height={16} />
              <Tabs
                tabs={getPageSubTabs({organization})}
                handlePressTab={({route}) => handleTabPress({route, navigator})}
                activeTabIndex={activeTabIndex}
              />
              <OfficeStaffContent
                tab={params.subTab}
                canImpersonate={role === 'super'}
                organization={organization}
                viewer={data.viewer}
                refetch={refetch}
                officeTeams={organization.officeTeams}
              />
            </PageContentContainer>
            <InviteUserDrawer
              key={inviteUserDrawer.key}
              isOpen={inviteUserDrawer.isOpen}
              handleClose={inviteUserDrawer.handleClose}
              organizationId={organization.id}
              inviteOfficeUserSuccessModal={inviteOfficeUserSuccessModal}
              refetch={refetch}
              isEnabledOperationsAdmins={organization.features.isEnabledOperationsAdmins}
              viewerRole={role}
            />
            <InviteOfficeUserSuccessModal
              isOpen={inviteOfficeUserSuccessModal.isOpen}
              handleClose={inviteOfficeUserSuccessModal.handleClose}
              title={'User invited!'}
              subtitle={
                'An invitation link has been sent. This user has 7 days to accept the invitation.'
              }
              handleInviteAnotherUser={handleInviteAnotherUser}
            />
            <CreateTeamDrawer
              key={createTeamDrawer.key}
              isOpen={createTeamDrawer.isOpen}
              organization={organization}
              handleClose={() => {
                createTeamDrawer.handleClose();
                if (params.subTab === TABS.TEAMS) {
                  return refetch();
                }
                navigator.push(`/settings/staff/office-staff/${TABS.TEAMS}`);
              }}
            />
          </ScrollView>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffOfficeStaffContent.query = gql`
  ${CreateTeamDrawer.fragment}
  ${StaffAdminSection.fragment}
  ${StaffOfficeSection.fragment}
  ${TeamsTable.fragment}

  query OrganizationSettingsStaffOfficeStaffContent {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        activeAdminUsers: filteredActiveUsersByRole(roles: [${UserRole.getGraphqlArgs(
          // @ts-expect-error TS(2345): Argument of type 'readonly ("admin" | "CONTRACTOR"... Remove this comment to see the full error message
          UserRole.ADMIN_ROLES,
        )}]) {
          id
        }
        activeOfficeUsers: filteredActiveUsersByRole(roles: [${UserRole.getGraphqlArgs(
          // @ts-expect-error TS(2345): Argument of type 'readonly ("admin" | "CONTRACTOR"... Remove this comment to see the full error message
          UserRole.NON_ADMIN_OFFICE_ROLES,
        )}]) {
          id
        }
        officeTeams {
          id
          members {
            id
            role
          }
          ...TeamsTable
        }
        features {
          isEnabledOperationsAdmins: isEnabled(feature: "OPERATIONS_ADMINS")
        }
        ...CreateTeamDrawer
        ...StaffAdminSection
        ...StaffOfficeSection
      }
      ...TeamsTable_Viewer
    }
  }
`;

export default OrganizationSettingsStaffOfficeStaffContent;
