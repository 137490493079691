// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useModal, useResponsive, usePagination, useState} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useSyncIntegrationCustomersForCodatIntegrationMutation from '@shared/modules/Integration/hooks/useSyncIntegrationCustomersForCodatIntegrationMutation';

const TableContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  justify-content: space-between;
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'isMobile' does not exist on type 'ThemeP... Remove this comment to see the full error message
    isMobile,
  }) => (isMobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const TitleRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
`;

const FlexContainer = Styled.View`
  flex: 1;
`;

const RightPageContainer = Styled.View`
  flex: 1;
  padding-horizontal: 24px;
  background-color: ${colors.gray.background}
`;

const Column = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MicroGrayText = Styled.Text`
  padding-top: 10px;
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const EditClientLinkButton = ({client, name, responsive}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      <LinkButton
        onPress={() => {
          navigator.pushNewTab(`/clients/${client.uuid}`);
        }}
      >
        <LinkText responsive={responsive}>{name}</LinkText>
      </LinkButton>
    </React.Fragment>
  );
};

const getExternalIntegrationCustomersColumnDefinitions = ({viewer, refetch, responsive}: any) => [
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Customer Name</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return <Table.CellText>{item.name}</Table.CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Contact Name</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return <Table.CellText>{item.contactName}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => <Table.HeaderText>Email / Phone Number</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return (
        <Container>
          <Table.CellText>{item.email}</Table.CellText>
          <Table.CellText>{Phone.display(item.phone)}</Table.CellText>
        </Container>
      );
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return (
        <TitleRow>
          <Table.HeaderText>Linked To</Table.HeaderText>
          <Space width={4} />
          <TextTooltip
            isEnabledMobileToast={false}
            text={'Clicking on the client name will open their profile in a new tab.'}
          >
            <Container>
              <Icon source={Icon.ArrowUpRightFromSquare} size={10} color={colors.gray.secondary} />
            </Container>
          </TextTooltip>
        </TitleRow>
      );
    },
    cellContent: ({item}: any) => {
      return (
        <Table.CellText>
          {item.clientExports.map((clientExport: any, index: any) => (
            <React.Fragment key={index}>
              <EditClientLinkButton
                client={clientExport.client}
                name={clientExport.client.primaryContact.fullName}
                responsive={responsive}
              />
              {index !== item.clientExports.length - 1 && <Table.CellText>{', '}</Table.CellText>}
            </React.Fragment>
          ))}
        </Table.CellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Last Imported</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return (
        <Table.CellText>{Datetime.convertToDisplayDatetime(item.lastSyncedAt)}</Table.CellText>
      );
    },
  },
];

const AccountingExternalCustomersContent = ({
  organization,
  refetch,
  loading,
  pagination,
  viewer,
}: any) => {
  const responsive = useResponsive();
  const {codatIntegrationByUuid} = organization;
  const syncIntegrationCustomersModal = useModal({name: 'Sync Integration Customers Modal'});

  const {handleSubmit, submitting} = useSyncIntegrationCustomersForCodatIntegrationMutation({
    codatIntegrationForm: CodatIntegrationForm.edit(codatIntegrationByUuid),
    onSuccess: () => {
      syncIntegrationCustomersModal.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log(errors),
  });

  return (
    <RightPageContainer>
      <Space height={24} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '100%'}}>
        <Column>
          <TitleRow>
            <Title responsive={responsive}>Accounting Customers</Title>
            <Space width={16} />
            <Badge
              label={organization.codatIntegrationByUuid.sourceKindDisplayName}
              color={colors.gray.secondary}
              size={Badge.SIZE.LARGE}
            />
          </TitleRow>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export customers from your accounting integration ` +
              `to automatically match with your Supermove clients.`}
          </Description>
        </Column>
        {responsive.mobile && <Space height={12} />}
        <ButtonContainer>
          {codatIntegrationByUuid.codatLastSync && (
            <React.Fragment>
              <Space height={12} />
              <MicroGrayText
                responsive={responsive}
              >{`Last Imported: ${Datetime.convertToDisplayDatetime(
                codatIntegrationByUuid.codatLastSync,
              )}`}</MicroGrayText>
            </React.Fragment>
          )}
          <Space width={16} />
          <SecondaryButton
            text={'Import'}
            onPress={syncIntegrationCustomersModal.handleOpen}
            iconLeft={Icon.Sync}
          />
        </ButtonContainer>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table.FixedHeaderScroll
          columnDefinitions={getExternalIntegrationCustomersColumnDefinitions({
            viewer,
            refetch,
            responsive,
          })}
          emptyStateText='No customers to display'
          items={
            codatIntegrationByUuid.externalIntegrationCustomersPaginatedList
              .externalIntegrationCustomers
          }
          containerStyle={{overflow: 'visible'}}
          scrollViewStyle={{paddingBottom: 24}}
          style={{flex: 1}}
          loading={loading}
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
      <Container>
        <PaginationBar pagination={pagination} />
      </Container>
      <Space height={24} />
      <ConfirmationModal
        icon={Icon.Sync}
        title={'Import customers?'}
        subtitle={'Please allow 5-10 minutes for this to complete.'}
        isOpen={syncIntegrationCustomersModal.isOpen}
        key={syncIntegrationCustomersModal.key}
        handleSecondaryAction={syncIntegrationCustomersModal.handleClose}
        handlePressOutside={syncIntegrationCustomersModal.handleClose}
        secondaryActionText={'Cancel'}
        primaryActionText={'Confirm'}
        handlePrimaryAction={handleSubmit}
        isSubmitting={submitting}
      />
    </RightPageContainer>
  );
};

const AccountingExternalCustomerSettingsLoading = ({viewer, refetch}: any) => {
  const responsive = useResponsive();

  return (
    <RightPageContainer>
      <Space height={24} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '1280px'}}>
        <Column>
          <Title responsive={responsive}>Accounting Customers</Title>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export customers from your accounting integration ` +
              `to automatically match with your Supermove clients.`}
          </Description>
        </Column>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table
          columnDefinitions={getExternalIntegrationCustomersColumnDefinitions({
            viewer,
            refetch,
            responsive,
          })}
          emptyStateText='No customers to display'
          // @ts-expect-error TS(2322): Type '{ columnDefinitions: { flex: number; headerC... Remove this comment to see the full error message
          scrollViewStyle={{paddingBottom: 24}}
          style={{flex: 1}}
          items={[]}
          loading
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
    </RightPageContainer>
  );
};

const AccountingExternalCustomers = () => {
  const {params} = useNavigationDOM();
  const {uuid} = params;
  const [currentPage, setCurrentPage] = useState(1);
  const {loading, data, refetch} = useQuery(AccountingExternalCustomers.query, {
    // 'network-only' is used to avoid using empty cached values
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
      uuid,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.codatIntegrationByUuid.externalIntegrationCustomersPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  return (
    <FlexContainer>
      {loading ? (
        <AccountingExternalCustomerSettingsLoading />
      ) : (
        <AccountingExternalCustomersContent
          organization={data.viewer.viewingOrganization}
          refetch={refetch}
          pagination={pagination}
          viewer={data.viewer}
          loading={loading}
        />
      )}
    </FlexContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingExternalCustomers.query = gql`
  ${CodatIntegrationForm.edit.fragment}
  ${usePagination.fragment}

  query AccountingExternalCustomers(
    $pagination: PaginationInput!
    $uuid: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        apiToken
        codatIntegrationByUuid(uuid: $uuid){
          id
          codatLastSync
          sourceKindDisplayName
          externalIntegrationCustomersPaginatedList(
            pagination: $pagination
          ) {
            externalIntegrationCustomers: results {
              id
              name
              contactName
              email
              phone
              lastSyncedAt
              clientExports {
                id
                client {
                  id
                  uuid
                  primaryContact {
                    id
                    fullName
                  }
                }
              }
            }
            paginationMetadata {
              ...usePagination
            }
          }
      ...CodatIntegrationForm_edit
        }
      }
    }
  }
`;

export default AccountingExternalCustomers;
