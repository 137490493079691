// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useToast, HandleToastType, Form} from '@supermove/hooks';
import {Inventory, ItemTypeModel} from '@supermove/models';
import {Currency} from '@supermove/utils';

// App
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import CustomSurveyTypes from '@shared/modules/Inventory/enums/CustomSurveyTypes';
import InventoryRoomsForm, {
  InventoryRoomsFormType,
} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import ItemFormV2, {
  ItemFormV2Type,
  ItemFormV2PartialToFormType,
} from '@shared/modules/Inventory/forms/ItemFormV2';
import {MemoizedGqlComponent} from '@shared/utilities/typescript';
import EditInventoryItemInputFields from 'modules/Inventory/Edit/components/EditInventoryItemInputFields';

const validateForm = ({form}: {form: Form<ItemFormV2PartialToFormType>}) => {
  if (form.values.name === '') {
    form.setErrors({name: 'Please enter a name'});
    return true;
  }
  return false;
};

const handleSave = ({
  form,
  handleSubmit,
  inventoryRoomsForm,
  roomItemsFormIndex,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  handleSubmit: (params: {updatedValues: ItemFormV2Type; field: string; index?: number}) => void;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
}) => {
  const errors = validateForm({form});
  if (errors) return;

  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms`;
  const {price, take, takeCount, leaveCount, index, volume, weight} = form.values;
  const quantity = take ? takeCount : leaveCount;
  const updatedValues = {
    ...form.values,
    isDirty: true,
    price: Currency.toMutation(price),
    isDeleted: quantity === 0,
    // Formatting weight and volume removes a trailing decimal
    volume: Inventory.getFloatValue(volume),
    weight: Inventory.getFloatValue(weight),
  };

  InventoryRoomsForm.setDirtyForms({
    inventoryRoomsForm,
    roomItemsFormIndex,
    itemIndex: index,
  });

  // This action is different for add and update
  handleSubmit({updatedValues, field, index});
};

const BaseSheet = ({
  isOpen,
  handleClose,
  itemForm,
  sheetLabel,
  autoFocus,
  defaultDensityFactor,
  handleGoBack,
  primaryActionText,
  handlePrimaryAction,
  secondaryActionText,
  handleSecondaryAction,
  isEnabledSurveysTimeAdditives,
}: {
  isOpen: boolean;
  handleClose: () => void;
  itemForm: ItemFormV2Type;
  sheetLabel: string;
  autoFocus?: string;
  defaultDensityFactor: number;
  handleGoBack?: () => void;
  primaryActionText?: string;
  handlePrimaryAction: (params: {form: any}) => void;
  secondaryActionText?: string;
  handleSecondaryAction?: (params: {form: any}) => void;
  isEnabledSurveysTimeAdditives: boolean;
}) => {
  // ItemFormV2 does not toForm currency values, so we need to do that here
  const form = useForm({
    initialValues: {...itemForm, price: Currency.toForm(itemForm.price)},
  });

  return (
    <SheetWithFooter.PreventPropagationContainer style={{position: 'absolute'}}>
      <SheetWithFooter
        isFixedHeight
        height={'85%'}
        isFixedFooter
        handleGoBack={handleGoBack}
        shouldCloseOnClickOutside={false}
        headerText={sheetLabel}
        isOpen={isOpen}
        handleClose={handleClose}
        primaryActionText={primaryActionText || 'Save'}
        handlePrimaryAction={() => handlePrimaryAction({form})}
        secondaryActionText={secondaryActionText}
        // @ts-expect-error TS(2322): Type '(() => any) | null' is not assignable to typ... Remove this comment to see the full error message
        handleSecondaryAction={handleSecondaryAction ? () => handleSecondaryAction({form}) : null}
        isVerticallyStackedFooter
      >
        <EditInventoryItemInputFields
          form={form}
          autoFocus={autoFocus}
          defaultDensityFactor={defaultDensityFactor}
          isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
        />
      </SheetWithFooter>
    </SheetWithFooter.PreventPropagationContainer>
  );
};

const CustomInventoryItemSheet = React.memo(
  ({
    isOpen,
    handleClose,
    inventoryRoomsForm,
    roomItemsFormIndex,
    name,
    onSubmit,
    defaultDensityFactor,
    handleGoBack,
    isEnabledSurveysTimeAdditives,
  }: {
    isOpen: boolean;
    handleClose: () => void;
    inventoryRoomsForm: Form<InventoryRoomsFormType>;
    roomItemsFormIndex: number;
    name: string;
    onSubmit: () => void;
    defaultDensityFactor: number;
    handleGoBack: () => void;
    isEnabledSurveysTimeAdditives: boolean;
  }) => {
    const itemAddedToast = useToast({
      ToastComponent: SuccessToast,
      message: `Item added!`,
    });
    const customItemType = {...CustomSurveyTypes.ITEM, name};
    const selectedRoomItems = _.get(
      inventoryRoomsForm,
      `values.inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}`,
    );
    // When no room is selected, the sheet should not be open
    // And downstream logic should not be run
    if (roomItemsFormIndex === null || roomItemsFormIndex < 0 || !selectedRoomItems) {
      return null;
    }
    const currentRoomItems = selectedRoomItems.itemForms;
    const customItemForm = ItemFormV2.new({
      collectionId: selectedRoomItems.collectionId,
      name,
      weight: customItemType.weight,
      volume: customItemType.volume,
      price: customItemType.price,
      isDerivedWeight: customItemType.isDerivedWeight,
      itemTypeId: customItemType.id,
      index: selectedRoomItems.itemForms.length,
      kind: customItemType.kind,
    });
    return (
      <BaseSheet
        isOpen={isOpen}
        handleClose={handleClose}
        itemForm={customItemForm}
        sheetLabel={'Add Custom Item'}
        defaultDensityFactor={defaultDensityFactor}
        isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
        handleGoBack={handleGoBack}
        handlePrimaryAction={({form}: any) => {
          handleSave({
            form,
            handleSubmit: ({updatedValues, field}: any) => {
              inventoryRoomsForm.setFieldValue(field, [...currentRoomItems, updatedValues]);
              itemAddedToast.handleToast({message: `${updatedValues.name} added!`});
              onSubmit();
            },
            inventoryRoomsForm,
            roomItemsFormIndex,
          });
          handleClose();
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    // Memoizing the component so unnecessary re-renders are don't cause the sheet to close
    (prevProps as any).isOpen === (nextProps as any).isOpen,
);

interface NewInventoryItemSheetProps {
  isOpen: boolean;
  handleClose: () => void;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  onSubmit: () => void;
  defaultDensityFactor: number;
  itemType: ItemTypeModel | null;
  handleGoBack: () => void;
  isEnabledSurveysTimeAdditives: boolean;
}

const NewInventoryItemSheet: MemoizedGqlComponent<NewInventoryItemSheetProps> = React.memo(
  ({
    isOpen,
    handleClose,
    inventoryRoomsForm,
    roomItemsFormIndex,
    onSubmit,
    defaultDensityFactor,
    itemType,
    handleGoBack,
    isEnabledSurveysTimeAdditives,
  }: {
    isOpen: boolean;
    handleClose: () => void;
    inventoryRoomsForm: Form<InventoryRoomsFormType>;
    roomItemsFormIndex: number;
    onSubmit: () => void;
    defaultDensityFactor: number;
    itemType: ItemTypeModel | null;
    handleGoBack: () => void;
    isEnabledSurveysTimeAdditives: boolean;
  }) => {
    // itemType is set dynamically, so we return early if it's not set
    if (!itemType) return null;
    const selectedRoomItems = _.get(
      inventoryRoomsForm,
      `values.inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}`,
    );
    // When no room is selected, the sheet should not be open
    // And downstream logic should not be run
    if (roomItemsFormIndex === null || roomItemsFormIndex < 0 || !selectedRoomItems) {
      return null;
    }
    const currentRoomItems = selectedRoomItems.itemForms;
    const newItemForm = ItemFormV2.newFromItemType(itemType, {
      collectionId: selectedRoomItems.collectionId,
      index: selectedRoomItems.itemForms.length,
    });
    const handleSaveForm = ({form}: any) => {
      handleSave({
        form,
        handleSubmit: ({updatedValues, field}: any) => {
          inventoryRoomsForm.setFieldValue(field, [...currentRoomItems, updatedValues]);
          onSubmit();
        },
        inventoryRoomsForm,
        roomItemsFormIndex,
      });
    };
    return (
      <BaseSheet
        isOpen={isOpen}
        handleClose={handleClose}
        itemForm={newItemForm}
        sheetLabel={'Add Item'}
        defaultDensityFactor={defaultDensityFactor}
        isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
        handleGoBack={handleGoBack}
        primaryActionText={'Save & Add Another'}
        handlePrimaryAction={({form}: any) => {
          handleSaveForm({form});
          handleGoBack();
        }}
        secondaryActionText={'Save & Close'}
        handleSecondaryAction={({form}: any) => {
          handleSaveForm({form});
          handleClose();
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    // Memoizing the component so unnecessary re-renders are don't cause the sheet to close
    (prevProps as any).isOpen === (nextProps as any).isOpen,
);

const EditInventoryItemSheet = <T,>({
  isOpen,
  handleClose,
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  autoFocus,
  defaultDensityFactor,
  removeItemToast,
  isEnabledSurveysTimeAdditives,
}: {
  isOpen: boolean;
  handleClose: () => void;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  itemForm: ItemFormV2Type;
  autoFocus: string;
  defaultDensityFactor: number;
  removeItemToast: {handleToast: (params: HandleToastType<T>) => void};
  isEnabledSurveysTimeAdditives: boolean;
}) => {
  const itemUpdatedToast = useToast({
    ToastComponent: SuccessToast,
    message: `Item updated!`,
  });

  // We clone the form and make edits there so that all the changes are made at once
  const clonedItemForm = _.cloneDeep(itemForm);

  return (
    <BaseSheet
      isOpen={isOpen}
      handleClose={handleClose}
      itemForm={clonedItemForm}
      sheetLabel={`Edit ${itemForm.name}`}
      autoFocus={autoFocus}
      defaultDensityFactor={defaultDensityFactor}
      isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
      handlePrimaryAction={({form}: any) => {
        handleSave({
          form,
          handleSubmit: ({updatedValues, field, index}: any) => {
            inventoryRoomsForm.setFieldValue(`${field}.${index}`, updatedValues);
            if (updatedValues.isDeleted) {
              removeItemToast.handleToast({message: `${itemForm.name} removed.`});
            } else {
              itemUpdatedToast.handleToast({message: `${updatedValues.name} updated!`});
            }
          },
          inventoryRoomsForm,
          roomItemsFormIndex,
        });
        handleClose();
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
// The any type is necessary because NewInventoryItemSheet is a memoized component
NewInventoryItemSheet.fragment = gql`
  ${ItemFormV2.newFromItemType.fragment}
  fragment EditInventoryItemSheet_New on ItemType {
    id
    ...ItemFormV2_newFromItemType
  }
`;

EditInventoryItemSheet.Custom = CustomInventoryItemSheet;
EditInventoryItemSheet.New = NewInventoryItemSheet;

export default EditInventoryItemSheet;
