// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useModal, useResponsive} from '@supermove/hooks';

// App
import IconButton from '@shared/design/components/IconButton';
import TableV2Deprecated from '@shared/design/components/TableV2Deprecated';
import RateTableMetricKind from '@shared/modules/RateTable/enums/RateTableMetricKind';
import {RateTableMetricFormToFormType} from '@shared/modules/RateTable/forms/RateTableMetricForm';
import CopyRatesModal from 'modules/Organization/Settings/Billing/components/CopyRatesModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RateTableSecondaryMetricHeader = ({
  rateTableSecondaryMetric1Form,
  columnIndex,
  isCopyVisible,
  isCopyDisabled,
  handleSubmit,
}: {
  rateTableSecondaryMetric1Form?: RateTableMetricFormToFormType;
  columnIndex: number;
  isCopyVisible: boolean;
  isCopyDisabled: boolean;
  handleSubmit: (selectedColumns: number[]) => void;
}) => {
  const responsive = useResponsive();
  const copyRatesModal = useModal({name: 'Copy Rates Modal'});
  if (!rateTableSecondaryMetric1Form) {
    return null;
  }
  const columnHeader = RateTableMetricKind.getSecondaryColumns(rateTableSecondaryMetric1Form.kind)[
    columnIndex
  ].name;
  return (
    <Row style={{flex: 1}}>
      <TableV2Deprecated.HeaderText responsive={responsive} numberOfLines={1}>
        {columnHeader}
      </TableV2Deprecated.HeaderText>
      <Space flex={1} />
      {isCopyVisible && (
        <IconButton
          source={Icon.Copy}
          size={14}
          isSecondary
          onPress={copyRatesModal.handleOpen}
          isDisabled={isCopyDisabled}
          tooltip={isCopyDisabled ? 'Enter rates for this day to copy.' : undefined}
        />
      )}
      <CopyRatesModal
        key={copyRatesModal.key}
        isOpen={copyRatesModal.isOpen}
        handleClose={copyRatesModal.handleClose}
        columnHeader={columnHeader}
        secondaryMetricKind={rateTableSecondaryMetric1Form.kind}
        handleSubmit={(selectedColumns) => {
          handleSubmit(selectedColumns);
          copyRatesModal.handleClose();
        }}
      />
    </Row>
  );
};

export default RateTableSecondaryMetricHeader;
