// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useInlineFormMutation} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';

const AddCancellationReasonDrawer = ({
  isOpen,
  handleClose,
  organizationId,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  organizationId: string;
  refetch: () => void;
}) => {
  const {form, submitting, handleSubmit} = useInlineFormMutation({
    name: 'cancellationReasonForm',
    form: {
      organizationId,
      name: '',
    },
    mutation: gql`
      mutation AddCancellationReasonDrawerMutation($cancellationReasonForm: CancellationReasonForm!) {
        response: createCancellationReason(cancellationReasonForm: $cancellationReasonForm) {
          ${gql.errors}
          cancellationReason {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      refetch();
      form.resetForm();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DrawerWithAction
      headerText={'Add Cancellation Reason'}
      description={`This will apear in the 'Cancellation Reason' dropdown when cancelling a project.`}
      isOpen={isOpen}
      handleClose={() => {
        form.resetForm();
        handleClose();
      }}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
    >
      <FieldInput
        {...form}
        label={'Reason Name'}
        name={'cancellationReasonForm.name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter name',
        }}
      />
    </DrawerWithAction>
  );
};

export default AddCancellationReasonDrawer;
