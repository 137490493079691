// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {ModalType, ToastType} from '@supermove/hooks';

import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import {
  FormFileType,
  ItemFilesFormType,
} from 'modules/Inventory/Edit/components/EditInventoryItemPhotosDrawer';

const DeleteInventoryItemPhotoModal = ({
  formFile,
  deleteInventoryItemPhotoModal,
  itemFilesForm,
  handleClosePopoutDrawer,
  handleUpdateInventoryRoomsForm,
  photoDeletedToast,
}: {
  formFile: FormFileType;
  deleteInventoryItemPhotoModal: ModalType;
  itemFilesForm: ItemFilesFormType;
  handleClosePopoutDrawer: () => void;
  handleUpdateInventoryRoomsForm: (updatedFormFiles: FormFileType[]) => void;
  photoDeletedToast: ToastType;
}) => {
  return (
    <DeleteModal
      handleDelete={() => {
        handleClosePopoutDrawer();
        const currentFormFiles = _.get(itemFilesForm.values, 'files');
        const indexToUpdate = _.findIndex(currentFormFiles, {uuid: formFile.uuid});
        const updatedFormFiles = currentFormFiles.map((formFile: FormFileType, index: number) =>
          index === indexToUpdate ? {...formFile, isDeleted: true} : formFile,
        );

        itemFilesForm.setFieldValue('files', updatedFormFiles);
        handleUpdateInventoryRoomsForm(updatedFormFiles);
        photoDeletedToast.handleToast();
        deleteInventoryItemPhotoModal.handleClose();
      }}
      handleClose={deleteInventoryItemPhotoModal.handleClose}
      isOpen={deleteInventoryItemPhotoModal.isOpen}
      title={`Delete "${formFile.filename}"?`}
      titleNumberOfLines={1}
      subtitle={`You can't undo this action.`}
    />
  );
};

export default DeleteInventoryItemPhotoModal;
