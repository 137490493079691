export type CancelProjectFormType = {
  projectId: string;
  cancellationReasonId: string | undefined;
  details: string;
};

const _new = ({projectId}: {projectId: string}) => ({
  projectId,
  cancellationReasonId: undefined,
  details: '',
});

const toForm = ({projectId, cancellationReasonId, details}: CancelProjectFormType) => ({
  projectId,
  cancellationReasonId,
  details,
});

const toMutation = ({projectId, cancellationReasonId, details}: CancelProjectFormType) => ({
  projectId,
  cancellationReasonId,
  details,
});

const CancelProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CancelProjectForm;
