// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Document} from '@supermove/utils';

const Container = Styled.View`
  width: 796px;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding-vertical: 20px;
`;

const PrintButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding-vertical: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Text = Styled.Text`
  ${Typography.Body1}
`;

const Indicator = Styled.Loading`
`;

const openPrintDialog = async ({setIsLoading, documentUuid}: any) => {
  const filename = 'document.pdf';
  setIsLoading(true);
  const pdf = await Document.fetchPDFFromS3({filename, documentUuid});
  Document.printFile({file: pdf});
  setIsLoading(false);
};

const PrintPdfButton = ({documentUuid}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrintButton disabled={isLoading} onPress={() => openPrintDialog({setIsLoading, documentUuid})}>
      {isLoading ? (
        <Indicator size={'small'} color={colors.gray.secondary} />
      ) : (
        <React.Fragment>
          <Text>Print</Text>
          <Space width={6} />
          <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Print} />
        </React.Fragment>
      )}
    </PrintButton>
  );
};

const PrintDocumentV2PdfWrapper = ({documentUuid, children}: any) => {
  return (
    <ScrollView style={{flex: 1, backgroundColor: colors.gray.background}}>
      <ScrollView
        horizontal
        contentContainerStyle={{flexGrow: 1, justifyContent: 'center', paddingHorizontal: 32}}
      >
        <Container>
          <Header>
            <PrintPdfButton documentUuid={documentUuid} />
          </Header>
          {children}
        </Container>
        <Space height={64} />
      </ScrollView>
    </ScrollView>
  );
};

export default PrintDocumentV2PdfWrapper;
