// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';

// App
import TrucksContent from 'modules/Organization/Settings/Moves/components/TrucksContent';

const Container = Styled.View``;

const OrganizationSettingsTrucksPage = () => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(OrganizationSettingsTrucksPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => (
        <Container style={{paddingHorizontal: responsive.desktop ? 0 : 24}}>
          <TrucksContent viewer={data.viewer} refetch={refetch} />
        </Container>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsTrucksPage.query = gql`
  ${TrucksContent.fragment}
  query OrganizationSettingsTrucksPage {
    ${gql.query}
    viewer {
      id
      ...TrucksContent
    }
  }
`;

export default OrganizationSettingsTrucksPage;
