// Libraries
// @ts-expect-error TS(7016): Could not find a declaration file for module '@inc... Remove this comment to see the full error message
import {gql} from '@increment/graphql';
import _ from 'lodash';

// Supermove
import {EmailTemplate, Proposal} from '@supermove/models';
import {HTML, withFragment} from '@supermove/utils';

// App
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailRecipientForm from '@shared/modules/Email/forms/EmailRecipientForm';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';

interface EmailRecipientFormType {
  email: string;
}

interface EmailRecipientOptionType {
  value: string;
  label: string;
  email: string;
}

export interface EmailFormToFormType {
  organizationId: string;
  customerId: string;
  senderId: string;
  attachmentIds: string[];
  emailTemplateId: string;
  kind: string;
  toEmailRecipientForms: EmailRecipientFormType[];
  ccEmailRecipientForms: EmailRecipientFormType[];
  bccEmailRecipientForms: EmailRecipientFormType[];
  subject: string;
  body: string;
  documentTemplateKinds: string[];

  // Private
  isShowingCc: boolean;
  isShowingBcc: boolean;
  toEmailRecipientValues: string[];
  ccEmailRecipientValues: string[];
  bccEmailRecipientValues: string[];
  searchInputValue: string;
  emailRecipientOptions: EmailRecipientOptionType[];
  bodyKind: string;
}

const getRoleEmailRecipientForms = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ role, project }: { role: any;... Remove this comment to see the full error message
  ({role, project}) => {
    const projectEmailRecipient = _.find(
      project.projectEmailRecipients,
      (recipient) => _.toLower(role) === _.toLower(recipient.role),
    );
    const email = projectEmailRecipient?.email;
    return email
      ? email
          .split(',')
          .map((emailAddress: any) => EmailRecipientForm.new({email: emailAddress.trim()}))
      : null;
  },
  gql`
    fragment EmailForm_getRoleEmailRecipientForms on Project {
      id
      projectEmailRecipients {
        role
        email
      }
    }
  `,
);

const getEmailRecipientForms = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ emailTemplateRecipients, proj... Remove this comment to see the full error message
  ({emailTemplateRecipients, project}) => {
    if (!emailTemplateRecipients) {
      return [];
    }
    const emailRecipientForms = emailTemplateRecipients.flatMap((emailTemplateRecipient: any) => {
      const {email, user, role} = emailTemplateRecipient;
      if (role) {
        return getRoleEmailRecipientForms({role, project});
      }
      if (user && user.email) {
        // Make sure an employee is active
        if (
          UserRole.OFFICE_ROLES_PLUS_SUPER.includes(user.role) &&
          user.status !== UserStatus.ACTIVE
        ) {
          return [];
        }
        return EmailRecipientForm.new({email: user.email});
      }
      if (email) {
        return EmailRecipientForm.new({email});
      }
      return null;
    });

    return emailRecipientForms.filter((form: any) => !!form);
  },
  gql`
    ${getRoleEmailRecipientForms.fragment}

    fragment EmailForm_getEmailRecipientForms_Project on Project {
      id
      ...EmailForm_getRoleEmailRecipientForms
    }

    fragment EmailForm_getEmailRecipientForms_EmailTemplateRecipient on EmailTemplateRecipient {
      email
      role
      user {
        id
        email
        status
      }
    }
  `,
);

const getEmailRecipientValues = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ emailTemplateRecipients }: { ... Remove this comment to see the full error message
  ({emailTemplateRecipients}) => {
    if (!emailTemplateRecipients) {
      return [];
    }
    const emailRecipientValues = emailTemplateRecipients.map((emailTemplateRecipient: any) => {
      const {email, user, role} = emailTemplateRecipient;
      const userEmail = user ? user.email : null;
      return role || email || userEmail;
    });
    return emailRecipientValues.filter((value: any) => !!value);
  },
  gql`
    fragment EmailForm_getEmailRecipientValues on EmailTemplateRecipient {
      email
      role
      user {
        id
        email
      }
    }
  `,
);

const RECIPIENT_DISPLAY_VALUES = {
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_COORDINATOR]: 'Coordinator',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_CUSTOMER]: 'Customer',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_SALESPERSON]: 'Salesperson',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_BRANCH]: 'Branch Notification Emails',
  [EmailTemplate.RECIPIENT_VALUES.COMPANY_NOTIFICATION_EMAILS]: 'Company Notification Emails',
  [EmailTemplate.RECIPIENT_VALUES.ADDITIONAL_SALESPERSON_EMAILS]:
    'Project Additional Salesperson Emails',
};

const getDisplayRole = (role: any) => {
  return _.get(RECIPIENT_DISPLAY_VALUES, role);
};

// TODO(dan) Waiting on ProjectEmailTemplate to be setup. ProjectEmailTemplate should
// have projectEmailTemplateRecipients which should have email template recipients plus
// projectEmailRecipients.
const getEmailRecipientOptions = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ project, emailTemplate }: { p... Remove this comment to see the full error message
  ({project, emailTemplate}) => {
    const emailRecipientOptions = project.projectEmailRecipients.map(
      ({role, email, label}: any) => {
        return {
          value: role || email,
          label: `${role ? `${getDisplayRole(role)}: ` : ''}${label} <${email}>`,
          email,
        };
      },
    );
    if (!emailTemplate) {
      return emailRecipientOptions;
    }
    _.get(emailTemplate, 'toEmailTemplateRecipients', []).forEach((recipient: any) => {
      if (recipient.email) {
        emailRecipientOptions.push({
          value: recipient.email,
          label: recipient.email,
        });
      }
    });
    _.get(emailTemplate, 'ccEmailTemplateRecipients', []).forEach((recipient: any) => {
      if (recipient.email) {
        emailRecipientOptions.push({
          value: recipient.email,
          label: recipient.email,
        });
      }
    });
    _.get(emailTemplate, 'bccEmailTemplateRecipients', []).forEach((recipient: any) => {
      if (recipient.email) {
        emailRecipientOptions.push({
          value: recipient.email,
          label: recipient.email,
        });
      }
    });
    return emailRecipientOptions;
  },
  gql`
    fragment EmailForm_getEmailRecipientOptions on Project {
      id
      projectEmailRecipients {
        role
        label
        email
      }
    }
  `,
);

const _new = () => ({
  organizationId: undefined,
  customerId: undefined,
  senderId: undefined,
  emailTemplateId: '',
  kind: 'CUSTOM',
  toEmailRecipientForms: [],
  ccEmailRecipientForms: [],
  bccEmailRecipientForms: [],
  subject: '',
  body: '',
  attachmentIds: [],
  documentTemplateKinds: [],

  // Private
  isShowingCc: false,
  isShowingBcc: false,
  toEmailRecipientValues: [],
  ccEmailRecipientValues: [],
  bccEmailRecipientValues: [],
  searchInputValue: '',
  emailRecipientOptions: [],
});

const newFromProject = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ project, viewerId }: { projec... Remove this comment to see the full error message
  ({project, viewerId}) => {
    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewerId,
      emailTemplateId: '',
      kind: 'CUSTOM',
      toEmailRecipientForms: [],
      ccEmailRecipientForms: [],
      bccEmailRecipientForms: [],
      subject: '',
      body: '',
      attachmentIds: [],
      documentTemplateKinds: [],

      // Private
      isShowingCc: false,
      isShowingBcc: false,
      toEmailRecipientValues: [],
      ccEmailRecipientValues: [],
      bccEmailRecipientValues: [],
      searchInputValue: '',
      emailRecipientOptions: getEmailRecipientOptions({project}),
    };
  },
  gql`
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newFromProject on Project {
      id
      organizationId
      customerId
      ...EmailForm_getEmailRecipientOptions
    }
  `,
);

const newFromProjectWithEmailTemplate = withFragment<unknown, EmailFormToFormType, unknown>(
  // @ts-expect-error TS(2345): Argument of type '({ viewerId, project, emailTempl... Remove this comment to see the full error message
  ({viewerId, project, emailTemplate}) => {
    const toEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.toEmailTemplateRecipients,
    });
    const ccEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.ccEmailTemplateRecipients,
    });
    const bccEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.bccEmailTemplateRecipients,
    });

    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewerId,
      // TODO(dan) Once migrated over to ProjectEmailTemplate, we should be able
      // to remove lodash here and not have to account for the case where no value
      // is returned for emailTemplateAttachments.
      attachmentIds: _.get(emailTemplate, 'emailTemplateAttachments', []).map(
        (emailTemplateAttachment: any) => emailTemplateAttachment.attachment.id,
      ),
      emailTemplateId: emailTemplate.id,
      kind: emailTemplate.kind,
      toEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.toEmailTemplateRecipients,
        project,
      }),
      ccEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.ccEmailTemplateRecipients,
        project,
      }),
      bccEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.bccEmailTemplateRecipients,
        project,
      }),
      subject: emailTemplate.subject,
      body: emailTemplate.body,
      documentTemplateKinds: [],
      // Private
      isShowingCc: (ccEmailRecipientValues as any).length > 0,
      isShowingBcc: (bccEmailRecipientValues as any).length > 0,
      toEmailRecipientValues,
      ccEmailRecipientValues,
      bccEmailRecipientValues,
      searchInputValue: '',
      emailRecipientOptions: getEmailRecipientOptions({project, emailTemplate}),
      bodyKind: emailTemplate.bodyKind,
    };
  },
  gql`
    ${EmailTemplate.getCustomRecipientDropdownOptions.fragment}
    ${Proposal.getProjectTypeConfirmationStepsShownByDefault.fragment}
    ${getEmailRecipientForms.fragment}
    ${getEmailRecipientValues.fragment}
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newFromProjectWithEmailTemplate_Project on Project {
      id
      organizationId
      customerId
      organization {
        id
      }
      projectType {
        id
        ...Proposal_getProjectTypeConfirmationStepsShownByDefault
      }
      ...EmailForm_getEmailRecipientForms_Project
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_EmailTemplate on EmailTemplate {
      id
      kind
      bodyKind
      subject
      body
      toEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      ccEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      bccEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
      ...EmailTemplate_getCustomRecipientDropdownOptions
    }
  `,
);

const toForm = ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  emailTemplateId,
  kind,
  toEmailRecipientForms,
  ccEmailRecipientForms,
  bccEmailRecipientForms,
  subject,
  body,
  documentTemplateKinds,
  isShowingCc,
  isShowingBcc,
  toEmailRecipientValues,
  ccEmailRecipientValues,
  bccEmailRecipientValues,
  searchInputValue,
  emailRecipientOptions,
  bodyKind,
}: any) => ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  emailTemplateId,
  kind,
  toEmailRecipientForms: toEmailRecipientForms.map((form: any) => EmailRecipientForm.toForm(form)),
  ccEmailRecipientForms: ccEmailRecipientForms.map((form: any) => EmailRecipientForm.toForm(form)),
  bccEmailRecipientForms: bccEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toForm(form),
  ),
  subject,
  body,
  documentTemplateKinds,

  // Private
  isShowingCc,
  isShowingBcc,
  toEmailRecipientValues,
  ccEmailRecipientValues,
  bccEmailRecipientValues,
  searchInputValue,
  emailRecipientOptions,
  bodyKind,
});

const toMutation = ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  kind,
  toEmailRecipientForms,
  ccEmailRecipientForms,
  bccEmailRecipientForms,
  subject,
  body,
  documentTemplateKinds,
  bodyKind,
}: any) => ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  kind,
  toEmailRecipientForms: toEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toMutation(form),
  ),
  ccEmailRecipientForms: ccEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toMutation(form),
  ),
  bccEmailRecipientForms: bccEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toMutation(form),
  ),
  subject,
  body: bodyKind === EmailTemplateBodyKind.HTML ? body : HTML.fixRichTextEditor(body),
  documentTemplateKinds,
});

const EmailForm = {
  new: _new,
  newFromProject,
  newFromProjectWithEmailTemplate,
  toForm,
  toMutation,
};

export default EmailForm;
