// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {ResponsiveType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';

const PageTitle = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const HeaderContainer = Styled.View<{responsive: ResponsiveType}>`
  align-items: center;
  flex-direction: row;
  padding-vertical: 16px;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 12)}px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const HourlyRateTablePageHeader = ({
  title = '',
  handleSubmit,
  isSubmitting,
  handleExit,
}: {
  title?: string;
  handleSubmit?: () => void;
  isSubmitting?: boolean;
  handleExit?: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  return (
    <HeaderContainer responsive={responsive}>
      <IconButton
        isSecondary
        source={Icon.Xmark}
        onPress={() => (handleExit ? handleExit() : navigator.goBack())}
      />
      <Space width={16} />
      <PageTitle responsive={responsive}>{title}</PageTitle>
      <Space flex={1} />
      <Button
        iconLeft={Icon.Check}
        text={'Save'}
        isDisabled={!handleSubmit}
        onPress={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </HeaderContainer>
  );
};

export default HourlyRateTablePageHeader;
