// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useNavigationDOM, useQuery} from '@supermove/hooks';
import {ProjectModel, ThreadModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ThreadKind from '@shared/modules/Thread/enums/ThreadKind';

const NextPreviousThreadControlsRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PrevNextLabel = Styled.Text<{disabled: boolean}>`
  ${Typography.Responsive.Label}
  color: ${({disabled}) => (disabled ? colors.gray.tertiary : colors.blue.interactive)};
`;

interface NextPreviousThreadControlsProps {
  responsive: ResponsiveType;
  currentThreadId: string;
  project: ProjectModel;
}

const NextPreviousThreadControls = ({
  responsive,
  currentThreadId,
  project,
}: NextPreviousThreadControlsProps) => {
  const {navigator} = useNavigationDOM();
  // Fetching this data separately from the rest of the page because it's a bigger call not needed for 90% of the page
  const {data} = useQuery<{filteredThreads: ThreadModel[]}>(NextPreviousThreadControls.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectId: project.id,
      kind: ThreadKind.EMAIL,
    },
  });

  //  Not showing a loading state so don't need to use loading component
  if (!data) {
    return null;
  }

  const currentThreadIndex = data.filteredThreads.findIndex(
    (thread) => thread.id === currentThreadId,
  );

  const prevDisabled = currentThreadIndex === 0;
  const nextDisabled = currentThreadIndex === data.filteredThreads.length - 1;

  const handleGoToIndex = (index: number) => {
    const thread = data.filteredThreads[index];
    if (thread) {
      navigator.push(`/projects/${project.uuid}/emails/${thread.uuid}`);
    }
  };

  return (
    <NextPreviousThreadControlsRow>
      <TertiaryButton
        onPress={() => handleGoToIndex(currentThreadIndex - 1)}
        isDisabled={prevDisabled}
      >
        <Icon
          source={Icon.ChevronLeft}
          color={prevDisabled ? colors.gray.tertiary : colors.blue.interactive}
          size={12}
        />
        <Space width={8} />
        <PrevNextLabel responsive={responsive} disabled={prevDisabled}>
          Prev
        </PrevNextLabel>
      </TertiaryButton>
      <Space width={16} />
      <TertiaryButton
        onPress={() => handleGoToIndex(currentThreadIndex + 1)}
        isDisabled={nextDisabled}
      >
        <PrevNextLabel responsive={responsive} disabled={nextDisabled}>
          Next
        </PrevNextLabel>
        <Space width={8} />
        <Icon
          source={Icon.ChevronRight}
          color={nextDisabled ? colors.gray.tertiary : colors.blue.interactive}
          size={12}
        />
      </TertiaryButton>
    </NextPreviousThreadControlsRow>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NextPreviousThreadControls.query = gql`
  query NextPreviousThreadControls(
      $projectId: Int,
      $kind: String,
      ) {
      ${gql.query}
      filteredThreads(projectId: $projectId, kind: $kind) {
        id
        uuid
      }
  }
`;

NextPreviousThreadControls.fragment = gql`
  fragment NextPreviousThreadControls_Project on Project {
    id
    uuid
  }
`;

export default NextPreviousThreadControls;
