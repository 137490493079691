// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useHover, useState} from '@supermove/hooks';
import {EmailAttachmentModel, EmailModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl, downloadMultipleFromUrls} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Line from 'modules/App/components/Line';
import EmailAttachmentDetailsModal from 'modules/Communication/Email/components/EmailAttachmentDetailsModal';
import EmailAttachmentImage from 'modules/Communication/Email/components/EmailAttachmentImage';

const AttachmentsText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const HeaderDownloadAllRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DownloadAll = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
`;

const AttachmentsRow = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({responsive}) => (responsive.mobile ? 'center' : 'flex-start')};
  margin-top: ${({responsive}) => (responsive.mobile ? 8 : 0)}px;
`;

const LineContainer = Styled.View<{responsive: ResponsiveType}>`
    margin-horizontal: ${({responsive}) => (responsive.mobile ? -24 : 0)}px;
`;

// Put failed attachments first, then sort by id
const sortEmailAttachments = (a: EmailAttachmentModel, b: EmailAttachmentModel) => {
  if (a.status === 'SUCCESS' && b.status !== 'SUCCESS') {
    return 1;
  }
  if (a.status !== 'SUCCESS' && b.status === 'SUCCESS') {
    return -1;
  }
  return a.id.localeCompare(b.id);
};

const EmailAttachment = ({
  emailAttachment,
  responsive,
  handleSelectAttachment,
}: {
  emailAttachment: EmailAttachmentModel;
  responsive: ResponsiveType;
  handleSelectAttachment?: () => void;
}) => {
  const file = emailAttachment.attachment?.file;
  const {isHovered, ref} = useHover({hoverCheckType: 'enterLeave'});

  return (
    <EmailAttachmentImage
      file={file}
      status={emailAttachment.status}
      isHovered={isHovered}
      imageRef={ref}
      responsive={responsive}
      handleSelectAttachment={handleSelectAttachment}
      containerStyle={{marginBottom: 16}}
      showFilename
    >
      {isHovered && (
        <TertiaryButton
          style={{backgroundColor: colors.blue.interactive, width: 27, height: 27}}
          onPress={() => downloadFromUrl(file?.downloadUrl)}
        >
          <Icon source={Icon.Download} color={colors.white} size={10} />
        </TertiaryButton>
      )}
    </EmailAttachmentImage>
  );
};

const EmailAttachments = ({email, responsive}: {email: EmailModel; responsive: ResponsiveType}) => {
  const {emailAttachments} = email;
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<string | undefined>(undefined);
  const sortedEmailAttachments = emailAttachments.sort(sortEmailAttachments);

  return (
    <React.Fragment>
      <Space height={16} />
      <LineContainer responsive={responsive}>
        <Line />
      </LineContainer>
      <Space height={16} />
      <HeaderDownloadAllRow>
        <AttachmentsText
          responsive={responsive}
        >{`Attachments (${emailAttachments.length})`}</AttachmentsText>
        <TertiaryButton
          onPress={() =>
            downloadMultipleFromUrls(
              emailAttachments
                .filter((emailAttachments) => emailAttachments.status === 'SUCCESS')
                .map((emailAttachments) => emailAttachments.attachment.file.downloadUrl),
            )
          }
        >
          <Icon source={Icon.FileArrowDown} color={colors.blue.interactive} />
          <Space width={8} />
          <DownloadAll responsive={responsive}>Download All</DownloadAll>
        </TertiaryButton>
      </HeaderDownloadAllRow>
      <Space height={8} />
      <AttachmentsRow responsive={responsive}>
        {sortedEmailAttachments.map((emailAttachment) => (
          <EmailAttachment
            key={emailAttachment.id}
            emailAttachment={emailAttachment}
            responsive={responsive}
            handleSelectAttachment={() => setSelectedAttachmentId(emailAttachment.id)}
          />
        ))}
      </AttachmentsRow>
      <Space height={16} />
      <EmailAttachmentDetailsModal
        email={email}
        responsive={responsive}
        selectedEmailAttachmentId={selectedAttachmentId}
        setSelectedEmailAttachmentId={setSelectedAttachmentId}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachments.fragment = gql`
  ${EmailAttachmentDetailsModal.fragment}
  ${EmailAttachmentImage.fragment}

  fragment EmailAttachments on Email {
    id
    emailAttachments {
      id
      status
      attachment {
        id
        file {
          id
          name
          downloadUrl
          ...EmailAttachmentImage
        }
      }
    }
    ...EmailAttachmentDetailsModal
  }
`;

export default EmailAttachments;
