/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useState, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App

import ProjectTypeDayForm from '@shared/modules/Dispatch/forms/ProjectTypeDayForm';
import useUpdateProjectTypeDayMutation from '@shared/modules/Dispatch/hooks/useUpdateProjectTypeDayMutation';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeDayInfoModal from 'modules/Dispatch/Calendar/Day/components/ProjectTypeDayInfoModal';

const ProjectTypeDaysContent = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
`;

const ProjectTypeDaysBar = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
`;

const EmptySpace = Styled.View`
  flex: 1;
`;

const ProjectTypeItemContent = Styled.View`
  flex-direction: row;
  margin-bottom: 2px;
  align-items: center;
`;

const ProjectTypeText = Styled.H7`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
`;

const ProjectTypeNumber = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ProjectTypeNumberBackground = Styled.View`
  height: 18px;
  min-width: 18px;
  background-color: ${(props) => (props as any).color};
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  padding-vertical: 1px;
  padding-horizontal: 2px;
`;

const LockBackground = Styled.Touchable`
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${colors.gray.border};
`;

const CaretBackground = Styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props) => (props as any).color};
  justify-content: center;
  align-items: center;
`;

const InfoIconTouchable = Styled.ButtonV2`
`;

const LockToolTipText = Styled.H7`
  color: ${colors.white};
`;

const Indicator = Styled.Loading`
`;

const LockButton = ({projectTypeDay, refetch}: any) => {
  const projectTypeDayForm = ProjectTypeDayForm.edit(projectTypeDay);
  const {form, handleSubmit, submitting} = useUpdateProjectTypeDayMutation({
    projectTypeDayForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  if (submitting) {
    return <Indicator />;
  }

  return (
    <Tooltip
      placement={'bottomRight'}
      align={{
        offset: [10, 0],
      }}
      overlay={() => {
        return (
          <LockToolTipText>
            {projectTypeDay.isSalesLocked ? `Unlock sales?` : `Lock sales?`}
          </LockToolTipText>
        );
      }}
    >
      <LockBackground
        disabled={submitting}
        onPress={() => {
          form.setFieldValue('projectTypeDayForm.isSalesLocked', !projectTypeDay.isSalesLocked);
          setTimeout(() => handleSubmit(), 0);
        }}
      >
        {projectTypeDay.isSalesLocked ? (
          <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.Lock} />
        ) : (
          <Icon color={colors.gray.primary} size={Icon.Sizes.Small} source={Icon.LockOpen} />
        )}
      </LockBackground>
    </Tooltip>
  );
};

const ProjectTypeDayItem = ({projectTypeDay, refetch}: any) => {
  const {projectType} = projectTypeDay;
  return (
    <ProjectTypeItemContent>
      <Space width={8} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ProjectTypeNumberBackground color={projectType.color}>
        <ProjectTypeNumber>{projectTypeDay.jobsCount}</ProjectTypeNumber>
      </ProjectTypeNumberBackground>
      <Space width={6} />
      <ProjectTypeText color={projectType.color}>{projectType.name}</ProjectTypeText>
      <EmptySpace />
      <LockButton projectTypeDay={projectTypeDay} refetch={refetch} />
      <Space width={8} />
    </ProjectTypeItemContent>
  );
};

const ProjectTypeDaysSection = ({date}: any) => {
  const {loading, data, refetch} = useQuery(ProjectTypeDaysSection.query, {
    variables: {date},
  });
  const [isOpen, setIsOpen] = useState(true);
  const projectTypeInfoModal = useModal();
  const {ref, isHovered} = useHover();

  if (loading) {
    return <Indicator />;
  }

  return (
    <ProjectTypeDaysContent>
      <ProjectTypeDaysBar onPress={() => setIsOpen(!isOpen)}>
        <Space width={8} />
        <ProjectTypeText color={colors.gray.secondary}>Project Types</ProjectTypeText>
        <Space width={4} />
        <InfoIconTouchable onPress={projectTypeInfoModal.handleOpen}>
          <Icon color={colors.gray.tertiary} size={Icon.Sizes.Medium} source={Icon.InfoCircle} />
        </InfoIconTouchable>
        <ProjectTypeDayInfoModal
          isOpen={projectTypeInfoModal.isOpen}
          handleClose={projectTypeInfoModal.handleClose}
        />
        <EmptySpace />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <CaretBackground color={isHovered ? colors.hover : colors.white} ref={ref}>
          <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.CaretDown} />
        </CaretBackground>
        <Space width={8} />
      </ProjectTypeDaysBar>
      {isOpen && (
        <React.Fragment>
          {_.sortBy(data.day.activeProjectTypeDays, 'projectType.index').map(
            (projectTypeDay) =>
              projectTypeDay.projectType.category === ProjectTypeCategory.MOVE && (
                <ProjectTypeDayItem
                  key={projectTypeDay.id}
                  projectTypeDay={projectTypeDay}
                  refetch={refetch}
                />
              ),
          )}
          <Space height={4} />
        </React.Fragment>
      )}
    </ProjectTypeDaysContent>
  );
};

ProjectTypeDaysSection.query = gql`
  ${ProjectTypeDayForm.edit.fragment}

  query ProjectTypeDaysSection($date: String!) {
    ${gql.query}
    day(date: $date) {
      id
      activeProjectTypeDays {
        id
        jobsCount
        isSalesLocked
        projectType {
          id
          index
          color
          name
          category
        }
        ...ProjectTypeDay_edit
      }
    }
  }
`;

export default ProjectTypeDaysSection;
