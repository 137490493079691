// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {EmailModel, EmailAttachmentModel, FileModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import Sheet from '@shared/design/components/Sheet';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import EmailAttachmentImage from 'modules/Communication/Email/components/EmailAttachmentImage';

const HeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const DownloadCloseContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ModalBody = Styled.View`
  align-items: center;
`;

const IndexControlRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IndexText = Styled.Text`
`;

const PlaceholderView = Styled.View`
`;

const MobileSheetBody = Styled.View`
  align-items: center;
`;

interface EmailAttachmentDetailsModalProps {
  email: EmailModel;
  responsive: ResponsiveType;
  selectedEmailAttachmentId?: string;
  setSelectedEmailAttachmentId: (id?: string) => void;
}

const Header = ({
  file,
  responsive,
  handleClose,
}: {
  file?: FileModel;
  responsive: ResponsiveType;
  handleClose: () => void;
}) => {
  return (
    <HeaderRow>
      {file ? <HeaderText responsive={responsive}>{file.name}</HeaderText> : <PlaceholderView />}
      <DownloadCloseContainer>
        {file ? (
          <Button
            iconLeft={Icon.FileArrowDown}
            text={'Download'}
            onPress={() => downloadFromUrl(file.downloadUrl)}
          />
        ) : null}
        <Space width={16} />
        <TertiaryButton onPress={handleClose}>
          <Icon source={Icon.Xmark} />
        </TertiaryButton>
      </DownloadCloseContainer>
    </HeaderRow>
  );
};

const IndexControl = ({
  setSelectedEmailAttachmentId,
  selectedIndex,
  emailAttachments,
}: {
  setSelectedEmailAttachmentId: (id?: string) => void;
  selectedIndex: number;
  emailAttachments: EmailAttachmentModel[];
}) => {
  const firstDisabled = selectedIndex === 0;
  const lastDisabled = selectedIndex === emailAttachments.length - 1;
  return (
    <IndexControlRow>
      <TertiaryButton
        isDisabled={firstDisabled}
        onPress={() => setSelectedEmailAttachmentId(emailAttachments[selectedIndex - 1].id)}
      >
        <Icon
          source={Icon.ChevronLeft}
          color={firstDisabled ? colors.gray.disabled : colors.blue.interactive}
          size={16}
        />
      </TertiaryButton>
      <Space width={16} />
      <IndexText>{`${selectedIndex + 1} of ${emailAttachments.length}`}</IndexText>
      <Space width={16} />
      <TertiaryButton
        isDisabled={lastDisabled}
        onPress={() => setSelectedEmailAttachmentId(emailAttachments[selectedIndex + 1].id)}
      >
        <Icon
          source={Icon.ChevronRight}
          color={lastDisabled ? colors.gray.disabled : colors.blue.interactive}
          size={16}
        />
      </TertiaryButton>
    </IndexControlRow>
  );
};

const MobileCloseButton = ({handleClose}: {handleClose: () => void}) => {
  return (
    <TertiaryButton onPress={handleClose}>
      <Icon source={Icon.Xmark} color={colors.gray.secondary} />
    </TertiaryButton>
  );
};

const EmailAttachmentDetailsModal = ({
  email,
  responsive,
  selectedEmailAttachmentId,
  setSelectedEmailAttachmentId,
}: EmailAttachmentDetailsModalProps) => {
  const selectedEmailAttachment = email.emailAttachments.find(
    (emailAttachment) => emailAttachment.id === selectedEmailAttachmentId,
  );

  if (!selectedEmailAttachment) {
    return null;
  }

  const successfulEmailAttachments = email.emailAttachments.filter(
    (emailAttachment) => emailAttachment.status === 'SUCCESS',
  );

  const index = successfulEmailAttachments.findIndex(
    (emailAttachment) => emailAttachment.id === selectedEmailAttachment.id,
  );

  const handleClose = () => {
    setSelectedEmailAttachmentId(undefined);
  };

  const selectedFile = selectedEmailAttachment.attachment?.file;

  if (responsive.mobile) {
    return (
      <Sheet.PreventPropagationContainer>
        <SheetWithFooter
          isOpen={!!selectedEmailAttachment}
          handleClose={handleClose}
          headerText={selectedFile?.name}
          isFixedHeight={false}
          // Facing a weird mobile bug where the sheet is automatically closing itself if this isn't set,
          // will remove if this is fixed
          shouldCloseOnClickOutside={false}
          HeaderRightComponent={() => <MobileCloseButton handleClose={handleClose} />}
          primaryActionText={'Download'}
          primaryActionIcon={Icon.FileArrowDown}
          handlePrimaryAction={() => downloadFromUrl(selectedFile.downloadUrl)}
          style={{backgroundColor: colors.white}}
        >
          <MobileSheetBody>
            <EmailAttachmentImage
              file={selectedFile}
              status={selectedEmailAttachment.status}
              responsive={responsive}
              containerStyle={{height: 500, width: 250, borderWidth: '0px'}}
              imageStyle={{height: 500, width: 250, borderBottomWidth: 0}}
              isSmall={false}
            />
            <Space height={16} />
            <IndexControl
              selectedIndex={index}
              emailAttachments={successfulEmailAttachments}
              setSelectedEmailAttachmentId={setSelectedEmailAttachmentId}
            />
          </MobileSheetBody>
        </SheetWithFooter>
      </Sheet.PreventPropagationContainer>
    );
  }

  return (
    <LargeModal
      isResponsive
      isScrollable
      isOpen={!!selectedEmailAttachment}
      title={selectedFile?.name}
      handlePressOutside={handleClose}
      HeaderComponent={() => (
        <Header file={selectedFile} responsive={responsive} handleClose={handleClose} />
      )}
      style={{backgroundColor: colors.white, padding: 24}}
      bodyStyle={{backgroundColor: colors.white}}
    >
      <ModalBody>
        <EmailAttachmentImage
          file={selectedFile}
          status={selectedEmailAttachment.status}
          responsive={responsive}
          containerStyle={{height: 600, width: 700, border: '0px'}}
          imageStyle={{height: 600, width: 700, borderBottomWidth: 0}}
          isSmall={false}
        />
        <Space height={16} />
        <IndexControl
          selectedIndex={index}
          emailAttachments={successfulEmailAttachments}
          setSelectedEmailAttachmentId={setSelectedEmailAttachmentId}
        />
      </ModalBody>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentDetailsModal.fragment = gql`
  ${EmailAttachmentImage.fragment}

  fragment EmailAttachmentDetailsModal on Email {
    id
    emailAttachments {
      id
      status
      attachment {
        id
        file {
          id
          name
          downloadUrl
          ...EmailAttachmentImage
        }
      }
    }
  }
`;

export default EmailAttachmentDetailsModal;
