// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon, FileDragInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useResponsive} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import SearchBar from '@shared/design/components/SearchBar';
import AttachmentForm from '@shared/modules/File/forms/AttachmentForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import AttachmentViewModes from '@shared/modules/Project/enums/AttachmentViewModes';

const HeaderContainer = Styled.View`
`;

const ContentContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UploadAttachmentsButton = ({project, form, uploadAttachmentsModal, isCompactView}: any) => {
  return (
    <FileDragInput
      disableDrag
      onFilesChange={(files) => {
        // Put each file in an AttachmentForm
        const attachmentForms = files.map((file) => {
          return AttachmentForm.toForm({
            organizationId: project.organization.id,
            projectId: project.id,
            mimetype: file.type,
            filename: UploadFileForm.formatName(file.name),
            attachmentCategoryKinds: ['PROJECT_ATTACHMENT'],
            description: '',
            identifier: '',
            file,
          });
        });
        form.setFieldValue(`bulkAttachmentsForm.attachmentForms`, attachmentForms);
        uploadAttachmentsModal.handleOpen();
      }}
    >
      {({isDragActive}) => (
        <Button
          text={isCompactView ? undefined : 'Upload Attachment(s)'}
          iconLeft={Icon.Upload}
          iconSize={isCompactView ? 14 : 10}
          isResponsive
        />
      )}
    </FileDragInput>
  );
};

const AttachmentsContextSwitcher = ({selectedView, setSelectedView}: any) => {
  const responsive = useResponsive();
  return (
    <ContextSwitcher
      isFullWidth={!responsive.desktop}
      contextDefinitions={[
        {
          label: !responsive.desktop ? 'Grid View' : null,
          iconSource: responsive.desktop ? Icon.Grid2 : null,
          isSelected: selectedView === AttachmentViewModes.GRID,
          onPress: () => {
            setSelectedView(AttachmentViewModes.GRID);
          },
        },
        {
          label: !responsive.desktop ? 'List View' : null,
          iconSource: responsive.desktop ? Icon.Bars : null,
          isSelected: selectedView === AttachmentViewModes.LIST,
          onPress: () => {
            setSelectedView(AttachmentViewModes.LIST);
          },
        },
      ]}
    />
  );
};

const AttachmentsFilters = ({
  project,
  setSearchQuery,
  uploadAttachmentsModal,
  bulkAttachmentsForm,
  selectedView,
  setSelectedView,
  isLoading,
  isWidget,
}: any) => {
  const responsive = useResponsive();
  // Reset state of searchQuery based on searchbar input
  const handleChangeSearch = useDebouncedCallback((text) => {
    setSearchQuery(text);
  }, 500);

  return (
    <HeaderContainer>
      {!responsive.desktop && (
        <React.Fragment>
          <AttachmentsContextSwitcher
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          <Space height={12} />
        </React.Fragment>
      )}
      <FiltersContainers>
        <SearchBar
          onChangeText={handleChangeSearch}
          placeholder={'Search'}
          containerStyle={{flex: 1}}
          style={{maxWidth: '348px', minWidth: '100px', width: '100%'}}
          defaultValue={''}
          isLoading={isLoading}
          isResponsive
        />
        <ContentContainer>
          <Space width={12} />
          {responsive.desktop && (
            <React.Fragment>
              <AttachmentsContextSwitcher
                selectedView={selectedView}
                setSelectedView={setSelectedView}
              />
              <Space width={12} />
            </React.Fragment>
          )}
          <UploadAttachmentsButton
            project={project}
            form={bulkAttachmentsForm}
            uploadAttachmentsModal={uploadAttachmentsModal}
            isCompactView={responsive.mobile || isWidget}
          />
        </ContentContainer>
      </FiltersContainers>
      <Space height={16} />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentsFilters.fragment = gql`
  fragment AttachmentsFilters on Project {
    id
    organization {
      id
    }
  }
`;

export default AttachmentsFilters;
