// Libraries
import React from 'react';

// Supermove
import {PageLoader, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import DocumentsSettingsPage from 'modules/Organization/Settings/Document/DocumentsSettingsPage';
import OrganizationSettingsDocumentTemplateSettingsContent from 'modules/Organization/Settings/Document/components/OrganizationSettingsDocumentTemplateSettingsContent';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const DocumentsSettingsDocumentLibraryPage = ({
  viewer,
  refetch,
}: {
  viewer?: UserModel;
  refetch: () => void;
}) => {
  const {params, navigator} = useNavigationDOM();
  const {documentTemplateUuid} = params;

  return (
    <DocumentsSettingsPage isLoadingAction={!viewer}>
      <PageLoader loading={!viewer} data={{viewer}}>
        {({loadedData}) => {
          return (
            <Container>
              <OrganizationSettingsDocumentTemplateSettingsContent
                documentTemplateUuid={documentTemplateUuid}
                viewer={loadedData.viewer!}
                navigator={navigator}
                refetch={refetch}
              />
            </Container>
          );
        }}
      </PageLoader>
    </DocumentsSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentsSettingsDocumentLibraryPage.fragment = gql`
  ${OrganizationSettingsDocumentTemplateSettingsContent.fragment}

  fragment DocumentsSettingsDocumentLibraryPage on Query {
    viewer {
      id
      ...OrganizationSettingsDocumentTemplateSettingsContent
    }
  }
`;

export default DocumentsSettingsDocumentLibraryPage;
