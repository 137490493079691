// Libraries
import React from 'react';

// Supermove
import {Styled, PageLoader} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import Line from '@shared/design/components/Line';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import DocumentsSettingsDocumentLibraryPage from 'modules/Organization/Settings/Document/DocumentsSettingsDocumentLibraryPage';
import OrganizationSettingsDocumentTemplateHeader from 'modules/Organization/Settings/Document/components/OrganizationSettingsDocumentTemplateHeader';
import OrganizationSettingsDocumentTemplateSettingsContent from 'modules/Organization/Settings/Document/components/OrganizationSettingsDocumentTemplateSettingsContent';

const PageContainer = Styled.View`
  flex: 1;
`;

const OrganizationSettingsDocumentTemplateSettingsPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {documentTemplateUuid} = params;

  const {loading, data, refetch} = useQuery<{viewer: UserModel}>(
    OrganizationSettingsDocumentTemplateSettingsPage.query,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <PageLoader loading={loading} data={data}>
      {({loadedData: {viewer}}) => {
        if (viewer.viewingOrganization.features.isEnabledOfficeUserFlowSettings) {
          return <DocumentsSettingsDocumentLibraryPage viewer={viewer} refetch={refetch} />;
        } else {
          return (
            <SidebarPageV2 selected={'settings'}>
              <PageContainer>
                <OrganizationSettingsDocumentTemplateHeader />
                <Line />
                <OrganizationSettingsDocumentTemplateSettingsContent
                  documentTemplateUuid={documentTemplateUuid}
                  viewer={viewer}
                  navigator={navigator}
                  refetch={refetch}
                />
              </PageContainer>
            </SidebarPageV2>
          );
        }
      }}
    </PageLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsDocumentTemplateSettingsPage.query = gql`
  ${OrganizationSettingsDocumentTemplateSettingsContent.fragment}
  ${DocumentsSettingsDocumentLibraryPage.fragment}

  query OrganizationSettingsDocumentTemplateSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledOfficeUserFlowSettings: isEnabled(feature: "OFFICE_USER_FLOW_SETTINGS")
        }
      }
      ...OrganizationSettingsDocumentTemplateSettingsContent
    }
    ...DocumentsSettingsDocumentLibraryPage
  }
`;

export default OrganizationSettingsDocumentTemplateSettingsPage;
