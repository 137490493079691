// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigationDOM, usePopover} from '@supermove/hooks';
import {Job, Project, Tag} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import EmptyState from '@shared/design/components/EmptyState';
import JobDispatchStatus from 'modules/Project/V2/Show/Blocks/Job/components/JobDispatchStatus';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import TagPill from 'modules/Tag/components/TagPill';

const CARD_WIDTH_MIN = 240;
const CARD_WIDTH_MAX = 496;
const CARD_GAP_WIDTH = 16;

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobCardContainer = Styled.ButtonV2`
  width: ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width}px;
  padding: 12px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const IconContainer = Styled.View`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const getCardWidth = () => {
  // Design intent is for the second card to show a little bit of itself,
  // specifically to take up 20% of the screen width. This while staying
  // in the bounds of the min and max card widths.
  return Math.min(CARD_WIDTH_MAX, Math.max(CARD_WIDTH_MIN, window.innerWidth * 0.75));
};

const JobDetail = ({icon, text, isCancelled}: any) => {
  const responsive = useResponsive();
  const color = isCancelled ? colors.gray.tertiary : colors.gray.secondary;
  return (
    <Row>
      <IconContainer>
        <Icon source={icon} size={16} color={color} />
      </IconContainer>
      <Space width={8} />
      <MicroText responsive={responsive} color={color}>
        {text}
      </MicroText>
    </Row>
  );
};

const JobCard = ({job, project, width, urlFilters}: any) => {
  const responsive = useResponsive();
  const firstAndLastDisplayCityState = Job.getDisplayFirstAndLastCityState(job);
  const tags = Tag.getJobTagAndTagAssignment(job);
  const {isCancelled} = job;

  return (
    <JobCardContainer
      width={width}
      onPress={() => urlFilters.handleUpdate({jobUuid: job.uuid}, true)}
    >
      <LabelText
        responsive={responsive}
        color={isCancelled ? colors.gray.secondary : colors.gray.primary}
      >
        {job.fullName}
      </LabelText>
      <Space height={8} />
      {isCancelled && (
        <React.Fragment>
          <Row>
            <JobDispatchStatus
              job={job}
              showLabel
              isEnabledCancelProject={project.organization.features.isEnabledCancelProject}
            />
          </Row>
          <Space height={8} />
        </React.Fragment>
      )}
      <JobDetail
        icon={Icon.Calendar}
        text={Job.getDisplayDate(job, 'ddd, MM/DD/YY', 'TBD')}
        isCancelled={isCancelled}
      />
      <Space height={8} />
      <JobDetail
        icon={Icon.Clock}
        text={Job.getOriginArrivalWindow(job)}
        isCancelled={isCancelled}
      />
      <Space height={8} />
      <JobDetail
        icon={Icon.CalendarClock}
        text={Job.getDisplayEstimateHours(job)}
        isCancelled={isCancelled}
      />
      <Space height={8} />
      <Row>
        <JobDetail
          icon={Icon.Truck}
          text={`${job.totalAssignedTrucks} / ${job.numberOfTrucks}`}
          isCancelled={isCancelled}
        />
        <Space width={12} />
        <JobDetail
          icon={Icon.User}
          text={`${job.totalAssignedMovers} / ${job.numberOfMovers}`}
          isCancelled={isCancelled}
        />
      </Row>
      <Space height={8} />
      <JobDetail icon={Icon.MapPin} text={firstAndLastDisplayCityState} isCancelled={isCancelled} />
      {job.description !== '' && (
        <React.Fragment>
          <Space height={8} />
          <JobDetail icon={Icon.InfoCircle} text={job.description} isCancelled={isCancelled} />
        </React.Fragment>
      )}
      {job.officeNotes !== '' && (
        <React.Fragment>
          <Space height={8} />
          <JobDetail icon={Icon.StickyNote} text={job.officeNotes} isCancelled={isCancelled} />
        </React.Fragment>
      )}
      <Space height={8} />
      <Row style={{flexWrap: 'wrap'}}>
        {tags.map((tag: any) => {
          return (
            <Row key={tag.id}>
              <TagPill tag={tag} hasDeleteButton={false} isCancelled={isCancelled} />
              <Space width={8} />
            </Row>
          );
        })}
      </Row>
    </JobCardContainer>
  );
};

const MobileActionsComponent = ({project, urlFilters}: any) => {
  const {navigator, params} = useNavigationDOM();
  const actionsMenuPopover = usePopover();
  const allJobs = project.allJobsExcludingChildJobs.reduce(
    (result: any, job: any) => [
      ...result,
      ...(_.some(job.jobsForEstimatedRange) ? job.jobsForEstimatedRange : [job]),
    ],
    [],
  );
  const hiddenJobsCount = allJobs.filter((job: any) => job.isCancelled).length;
  const showCancelledJobs = urlFilters.get('showCancelledJobs');

  return (
    <Container style={{height: '100%'}}>
      <Row style={{alignItems: 'center'}}>
        {/* @ts-expect-error TS(2741): Property 'isDisabled' is missing in type '{ text: ... Remove this comment to see the full error message */}
        <MobileProjectBlockHeader.TextButton
          text={`Edit`}
          onPress={() => navigator.push(`/projects/${params.projectUuid}/edit/jobs`)}
        />
        <Space width={8} />
        <ActionMenuPopover
          popover={actionsMenuPopover}
          actions={[
            {
              text: `${showCancelledJobs ? 'Hide' : 'Show'} ${project.organization.features.isEnabledCancelProject ? 'removed' : 'cancelled'} jobs (${hiddenJobsCount})`,
              onPress: () =>
                urlFilters.handleUpdate({showCancelledJobs: showCancelledJobs ? null : 'true'}),
            },
          ]}
        >
          {/* @ts-expect-error TS(2741): Property 'isDisabled' is missing in type '{ onPres... Remove this comment to see the full error message */}
          <MobileProjectBlockHeader.EllipsisButton onPress={actionsMenuPopover.handleToggle} />
        </ActionMenuPopover>
      </Row>
    </Container>
  );
};

const ProjectJobsBlockContent = ({project, urlFilters}: any) => {
  const displayJobs = Project.getDisplayAllJobsExcludingChildJobs(project, {
    showCancelledJobs: urlFilters.get('showCancelledJobs'),
  });
  const cardWidth = getCardWidth();

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Jobs (${displayJobs.length})`}
        ActionsComponent={MobileActionsComponent}
        actionsComponentProps={{project, urlFilters}}
        style={{paddingHorizontal: 16}}
      />
      <Container>
        {_.some(displayJobs) ? (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <Space width={CARD_GAP_WIDTH} />
            {displayJobs.map((job: any, index: any) => (
              <React.Fragment key={job.id}>
                {index !== 0 && <Space width={CARD_GAP_WIDTH} />}
                <JobCard job={job} project={project} width={cardWidth} urlFilters={urlFilters} />
              </React.Fragment>
            ))}
            <Space width={CARD_GAP_WIDTH} />
          </ScrollView>
        ) : (
          <EmptyState
            isSmallTitle
            title={'No jobs.'}
            message={'Once you add a job, it will appear here.'}
            primaryActionIcon={Icon.Plus}
            primaryActionText={'Add New Job'}
            handlePrimaryAction={() => {}}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

const ProjectJobsBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
  projectBlockKind,
}: any) => {
  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectJobsBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      style={{paddingVertical: 24}}
      projectBlockKind={projectBlockKind}
    >
      {({data}: any) => {
        return <ProjectJobsBlockContent project={data.project} urlFilters={urlFilters} />;
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobsBlock.listener = gql`
  fragment ProjectJobsBlock_listener on Project {
    id
  }
`;

ProjectJobsBlock.fragment = gql`
  ${ProjectJobsBlock.listener}
  fragment ProjectJobsBlock on Project {
    id
    uuid
    ...ProjectJobsBlock_listener
  }
`;

ProjectJobsBlock.query = gql`
  ${ProjectJobsBlock.listener}
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}
  ${JobDispatchStatus.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.getOriginArrivalWindow.fragment}
  ${Job.getDisplayEstimateHours.fragment}
  ${Job.getDisplayFirstAndLastCityState.fragment}
  ${Tag.getJobTagAndTagAssignment.fragment}

  query ProjectJobsBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        features {
          isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
        }
      }
      allJobsExcludingChildJobs {
        id
        uuid
        fullName
        totalAssignedTrucks
        totalAssignedMovers
        numberOfTrucks
        numberOfMovers
        description
        officeNotes
        isCancelled
        jobsForEstimatedRange {
          id
          isCancelled
        }
        ...JobDispatchStatus
        ...Job_getDisplayDate
        ...Job_getOriginArrivalWindow
        ...Job_getEstimatedHours
        ...Job_getFirstAndLastDisplayCityState
        ...Tag_getJobTagAndTagAssignment
      }
      ...Project_getDisplayAllJobsExcludingChildJobs
      ...ProjectJobsBlock_listener
    }
  }
`;

export default ProjectJobsBlock;
