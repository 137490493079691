// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuperAdminOrganizationList from '@shared/modules/App/components/SuperAdminOrganizationList';

const Column = Styled.View``;
const Row = Styled.View`
  flex-direction: row;
`;

const UpdateSuperAdminOrganizationModal = ({
  viewer,
  isOpen,
  handleClose,
  supermoveProductKind,
}: {
  viewer?: UserModel;
  isOpen: boolean;
  handleClose: () => void;
  supermoveProductKind: string;
}) => {
  const responsive = useResponsive();

  return (
    <SmallModal
      isOpen={isOpen}
      handlePressOutside={handleClose}
      isMobileSheet
      headerComponent={
        <Row>
          <Column>
            <SmallModal.HeaderText responsive={responsive}>
              Change Organization
            </SmallModal.HeaderText>
            <Space height={4} />
            <SmallModal.Text responsive={responsive} style={{color: colors.gray.secondary}}>
              {`Current: ${viewer?.viewingOrganization.slug}`}
            </SmallModal.Text>
          </Column>
          <Space flex={1} />
          <IconButton size={20} isSecondary source={Icon.Xmark} onPress={handleClose} />
        </Row>
      }
    >
      <SuperAdminOrganizationList supermoveProductKind={supermoveProductKind} />
    </SmallModal>
  );
};

// ------------------------------
// Data
// ------------------------------
UpdateSuperAdminOrganizationModal.fragment = gql`
  fragment UpdateSuperAdminOrganizationModal on User {
    id
    viewingOrganization {
      id
      slug
    }
  }
`;

export default UpdateSuperAdminOrganizationModal;
