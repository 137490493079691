// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime, Distance, pluralize} from '@supermove/utils';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import JobDispatchPopoverActions from 'modules/Dispatch/Calendar/Day/components/JobDispatchPopoverActions';
import JobDispatchPopoverCrew from 'modules/Dispatch/Calendar/Day/components/JobDispatchPopoverCrew';
import JobDispatchPopoverHeader from 'modules/Dispatch/Calendar/Day/components/JobDispatchPopoverHeader';
import CompleteEstimatedRangeJobModal from 'modules/Job/components/CompleteEstimatedRangeJobModal';

const Row = Styled.View`
  flex-direction: row;
`;

const TitleText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => ((props as any).color ? (props as any).color : colors.gray.primary)};
`;

const DescriptionLabelText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary}
`;

const DescriptionValueText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.primary};
`;

const CrewOrganizationName = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const CompleteJobButtonContainer = Styled.View`
  padding-horizontal: 16px;
`;

const CompleteJobButton = Styled.ButtonV2`
  height: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isComplete' does not exist on type 'Them... Remove this comment to see the full error message
    isComplete,
  }) => (isComplete ? colors.green.accent : colors.gray.border)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isComplete' does not exist on type 'Them... Remove this comment to see the full error message
    isComplete,
  }) => (isComplete ? colors.green.accent : colors.white)};
`;

const CompleteJobText = Styled.Text`
  ${Typography.Label3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const getDisplayTime = (time: any) => {
  if (time) {
    return Datetime.toDisplayTime(Datetime.fromTime(time));
  }
  return 'TBD';
};

const JobHeader = ({job}: any) => {
  return (
    <Row>
      <TitleText>
        {job.fullName}: {job.customer.fullName}
      </TitleText>
    </Row>
  );
};

const MoveDetails = ({job}: any) => {
  const estimateHours1 = job.estimateHours1 ? job.estimateHours1 : 'TBD';
  const {isTotalAvailable, minTotal, maxTotal} = job.project.activeJobsAggregateBill;
  const {weight, volume} = job.project;

  return (
    <React.Fragment>
      <DescriptionLabelText>
        {`Contact Name: `}
        <DescriptionValueText>{job.customer.fullName}</DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Project Size: `}
        <DescriptionValueText>{job.project.size}</DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`${job.locations.length} Locations: `}
        <DescriptionValueText>
          {job.locations
            .map((location: any) => `${location.city}, ${location.zipCode}`)
            .join(' - ')}
        </DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Total Mileage: `}
        <DescriptionValueText>{Distance.display(job.totalDistance)}</DescriptionValueText>
      </DescriptionLabelText>
      <Space height={8} />
      <DescriptionLabelText>
        {`${pluralize('Labor Source', job.crews.length, true)}${job.crews.length > 0 ? ': ' : ''}`}
        <DescriptionValueText>
          {job.crews.map((crew: any) => crew.organization.name).join(', ')}
        </DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Inventory Weight: `}
        <DescriptionValueText>
          {weight > 0 ? pluralize('lb', weight, true) : 'N/A'}
        </DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Inventory Volume: `}
        <DescriptionValueText>{volume > 0 ? `${volume} ft³` : 'N/A'}</DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Price: `}
        <DescriptionValueText>
          {/* @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message */}
          {isTotalAvailable ? Currency.formatRange({min: minTotal, max: maxTotal}) : 'TBD'}
        </DescriptionValueText>
      </DescriptionLabelText>
      <Space height={8} />
      <DescriptionLabelText>
        {`Arrival Window: `}
        <DescriptionValueText>
          {job.kind === 'HOLD'
            ? `All Day`
            : `${getDisplayTime(job.startTime1)} - ${getDisplayTime(job.startTime2)}`}
        </DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Estimate Hours: `}
        <DescriptionValueText>
          {`${estimateHours1} ${job.estimateHours2 ? `- ${job.estimateHours2}` : ''}`}
        </DescriptionValueText>
      </DescriptionLabelText>
      <DescriptionLabelText>
        {`Estimated Start End Time: `}
        <DescriptionValueText>
          {`${getDisplayTime(job.startTime1)} - ${getDisplayTime(job.endTimeEstimate)}`}
        </DescriptionValueText>
      </DescriptionLabelText>
    </React.Fragment>
  );
};

const DispatchCrewSection = ({
  viewer,
  job,
  crewId,
  refetch,
  setIsClosable,
  handleClose,
  jobJobUsersScheduleModal,
}: any) => {
  return (
    <React.Fragment>
      {viewer.viewingOrganization.isPrimary ? (
        job.crews.map((crew: any) => {
          const requiredTrucks = pluralize('Truck', crew.numberOfRequiredTrucks, true);
          const requiredMovers = pluralize('Mover', crew.numberOfRequiredMovers, true);
          return (
            <React.Fragment key={crew.id}>
              <Space height={16} />
              <CrewOrganizationName>
                {`${crew.organization.name}: ${requiredTrucks}, ${requiredMovers}`}
              </CrewOrganizationName>
              <Space height={4} />
              <JobDispatchPopoverCrew
                key={crew.id}
                crewId={crew.id}
                refetch={refetch}
                setIsClosable={setIsClosable}
                handleClose={handleClose}
                jobJobUsersScheduleModal={jobJobUsersScheduleModal}
              />
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment>
          <Space height={16} />
          <JobDispatchPopoverCrew
            crewId={crewId}
            refetch={refetch}
            setIsClosable={setIsClosable}
            handleClose={handleClose}
            jobJobUsersScheduleModal={jobJobUsersScheduleModal}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CompleteEstimatedRangeJobAction = ({job, setIsClosable, refetch}: any) => {
  const completeEstimatedRangeJobModal = useModal();
  const handleOpen = () => {
    setIsClosable(false);
    completeEstimatedRangeJobModal.handleOpen();
  };
  const handleClose = () => {
    setIsClosable(true);
    completeEstimatedRangeJobModal.handleClose();
  };
  const isComplete = job.parentJob ? job.parentJob.isComplete : job.isComplete;

  return (
    <React.Fragment>
      {isComplete ? (
        <CompleteJobButton isComplete disabled>
          <Icon source={Icon.Check} size={10} color={colors.green.status} />
          <Space width={8} />
          <CompleteJobText color={colors.green.status}>This Job is Completed</CompleteJobText>
        </CompleteJobButton>
      ) : (
        <CompleteJobButton onPress={handleOpen}>
          <CompleteJobText color={colors.gray.primary}>Mark This Job Complete</CompleteJobText>
        </CompleteJobButton>
      )}
      <Space height={16} />
      <CompleteEstimatedRangeJobModal
        key={completeEstimatedRangeJobModal.key}
        isOpen={completeEstimatedRangeJobModal.isOpen}
        handleClose={handleClose}
        jobUuid={job.parentJob ? job.parentJob.uuid : job.uuid}
        refetchParent={refetch}
      />
    </React.Fragment>
  );
};

const JobDispatchPopoverContent = ({
  viewer,
  job,
  isSlot,
  crewId,
  organizationId,
  handleClose,
  refetch,
  setIsClosable,
  jobJobUsersScheduleModal,
  jobCancelModal,
  handleShowJob,
}: any) => {
  return (
    <ResponsivePopover.Container
      width={320}
      height={isSlot ? 580 : null}
      maxHeight={isSlot ? null : 580}
    >
      <JobDispatchPopoverHeader
        viewer={viewer}
        job={job}
        organizationId={organizationId}
        handleClose={handleClose}
        handleShowJob={handleShowJob}
      />
      <ScrollView style={{paddingHorizontal: 16}}>
        <Space height={8} />
        <JobDispatchPopoverActions
          job={job}
          crewId={crewId}
          handleClose={handleClose}
          setIsClosable={setIsClosable}
          jobCancelModal={jobCancelModal}
          refetch={refetch}
          isEnabledCancelProject={viewer.viewingOrganization.features.isEnabledCancelProject}
        />
        <Space height={16} />
        <JobHeader job={job} />
        <Space height={16} />
        <MoveDetails job={job} />
        {isSlot && (
          <DispatchCrewSection
            viewer={viewer}
            job={job}
            crewId={crewId}
            refetch={refetch}
            setIsClosable={setIsClosable}
            handleClose={handleClose}
            jobJobUsersScheduleModal={jobJobUsersScheduleModal}
          />
        )}
        <Space height={28} />
      </ScrollView>
      {(Job.getIsEstimatedRange(job) || Job.getIsChildJob(job)) && (
        <CompleteJobButtonContainer>
          <CompleteEstimatedRangeJobAction
            job={job}
            setIsClosable={setIsClosable}
            refetch={refetch}
          />
        </CompleteJobButtonContainer>
      )}
    </ResponsivePopover.Container>
  );
};

const JobDispatchPopover = ({
  job,
  crewId,
  organizationId,
  isSlot,
  isOpen,
  handleOpen,
  handleClose,
  popoverRef,
  refetch,
  jobJobUsersScheduleModal,
  jobCancelModal,
}: any) => {
  const [jobUuid, setJobUuid] = useState(job.uuid);
  const [selectedCrewId, setSelectedCrewId] = useState(crewId);
  const {
    loading,
    data,
    refetch: refetchPopover,
  } = useQuery(JobDispatchPopover.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: jobUuid,
    },
  });

  const [isClosable, setIsClosable] = useState(true);
  const handleRefetch = () => {
    refetch();
    refetchPopover();
  };
  const handleShowJob = ({jobUuid, crewId}: any) => {
    setJobUuid(jobUuid);
    setSelectedCrewId(crewId);
  };

  return (
    <Popover
      placement={Popover.Positions.Auto}
      initialPlacementOnClick
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={popoverRef}
      isClosableOutside={isClosable}
      offset={[0, 4]}
    >
      <Loading loading={loading || !data}>
        {() => {
          return (
            <JobDispatchPopoverContent
              viewer={data.viewer}
              job={data.job}
              isSlot={isSlot}
              crewId={selectedCrewId}
              organizationId={organizationId}
              handleClose={handleClose}
              refetch={handleRefetch}
              setIsClosable={setIsClosable}
              jobJobUsersScheduleModal={jobJobUsersScheduleModal}
              jobCancelModal={jobCancelModal}
              handleShowJob={handleShowJob}
            />
          );
        }}
      </Loading>
    </Popover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchPopover.query = gql`
  ${JobDispatchPopoverActions.fragment}
  ${JobDispatchPopoverHeader.fragment}
  ${Job.getIsChildJob.fragment}
  ${Job.getIsEstimatedRange.fragment}

  query JobDispatchPopover($uuid: String!) {
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        features {
          isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
        }
      }
    }
    job(uuid: $uuid) {
      id
      uuid
      kind
      fullName
      totalDistance
      startTime1
      startTime2
      endTimeEstimate
      estimateHours1
      estimateHours2
      parentJob {
        id
        isComplete
        uuid
      }
      isComplete
      jobEvents {
        id
        kind
        name
      }
      locations {
        id
        city
        zipCode
      }
      crews {
        id
        numberOfRequiredTrucks
        numberOfRequiredMovers
        organization {
          id
          name
        }
      }
      customer {
        id
        fullName
      }
      project {
        id
        size
        weight
        volume
        activeJobsAggregateBill {
          isTotalAvailable
          minTotal
          maxTotal
        }
      }
      ...JobDispatchPopoverActions
      ...JobDispatchPopoverHeader
      ...Job_getIsChildJob
      ...Job_getIsEstimatedRange
    }
  }
`;

JobDispatchPopover.fragment = gql`
  fragment JobDispatchPopover on Job {
    id
    uuid
  }
`;

export default JobDispatchPopover;
