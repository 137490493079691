// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import OrganizationMoverNotificationSettingsForm from '@shared/modules/Organization/forms/OrganizationMoverNotificationSettingsForm';

const useUpdateOrganizationMoverNotificationSettingsMutation = ({
  organizationMoverNotificationSettingsForm,
  onSuccess,
  onError,
}: {
  organizationMoverNotificationSettingsForm: any;
  onSuccess: ({organization}: {organization: OrganizationModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      organizationMoverNotificationSettingsForm: OrganizationMoverNotificationSettingsForm.toForm(
        organizationMoverNotificationSettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationMoverNotificationSettingsMutation.mutation,
    variables: {
      organizationMoverNotificationSettingsForm:
        OrganizationMoverNotificationSettingsForm.toMutation(
          form.values.organizationMoverNotificationSettingsForm,
        ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationMoverNotificationSettingsMutation.mutation = gql`
  ${OrganizationMoverNotificationSettingsForm.edit.fragment}
  mutation useUpdateOrganizationMoverNotificationSettingsMutation($organizationMoverNotificationSettingsForm: OrganizationMoverNotificationSettingsForm!) {
    response: updateOrganizationMoverNotificationSettings(organizationMoverNotificationSettingsForm: $organizationMoverNotificationSettingsForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationMoverNotificationSettingsForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationMoverNotificationSettingsMutation;
