// Libraries
import React from 'react';

// App
import {Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// Components
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import StaffMoverPositionsSectionV1 from 'modules/Organization/Settings/Users/List/components/StaffMoverPositionsSectionV1';
import StaffMoversSection from 'modules/Organization/Settings/Users/List/components/StaffMoversSection';

const Container = Styled.View`
  flex: 1;
`;

const OrganizationSettingsStaffMoversContent = () => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffMoversContent.query, {
    fetchPolicy: 'network-only',
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <Container style={{paddingHorizontal: responsive.desktop ? 0 : 24}}>
            <StaffMoversSection
              viewer={data.viewer}
              canImpersonate={data.viewer.role === 'super'}
              organization={data.viewer.viewingOrganization}
              refetch={refetch}
              source={data.viewer.source}
            />
          </Container>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffMoversContent.query = gql`
  ${StaffMoverPositionsSectionV1.fragment}
  ${StaffMoversSection.fragment}

  query OrganizationSettingsStaffMoversContent {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        ...StaffMoverPositionsSectionV1
        ...StaffMoversSection
      }
      ...StaffMoversSection_Viewer
    }
  }
`;

export default OrganizationSettingsStaffMoversContent;
