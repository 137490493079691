// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App

import LargeModal from '@shared/design/components/Modal/LargeModal';
import ProjectAttachmentUploader from '@shared/modules/File/components/ProjectAttachmentUploader';
import BulkAttachmentsForm from '@shared/modules/File/forms/BulkAttachmentsForm';
import useUpdateAttachmentsMutation from '@shared/modules/File/hooks/useUpdateAttachmentsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const UploadAttachmentsModalV2 = ({
  isOpen,
  handleClose,
  bulkAttachmentsForm,
  refetch,
  projectUuid,
}: any) => {
  const responsive = useResponsive();
  const {data, loading} = useQuery(UploadAttachmentsModalV2.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
    skip: !isOpen,
  });

  const {form, handleSubmit, submitting} = useUpdateAttachmentsMutation({
    updateAttachmentsForm: BulkAttachmentsForm.toUpdateAttachmentsForm(
      bulkAttachmentsForm.values.bulkAttachmentsForm,
    ),
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log(errors),
  });
  const isAllAttachmentsUploaded = _.isEmpty(
    _.filter(
      _.get(form.values, 'updateAttachmentsForm.updateAttachmentForms', []),
      (updateAttachmentForm) => updateAttachmentForm.attachmentId === undefined,
    ),
  );
  return (
    <LargeModal
      title={'Upload Attachment(s)'}
      primaryActionText={'Done'}
      isSubmitting={submitting}
      isDisabled={!isAllAttachmentsUploaded}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      isResponsive
      isScrollable
      bodyStyle={responsive.mobile ? {padding: 0, backgroundColor: colors.white} : undefined}
    >
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <ProjectAttachmentUploader
              form={form}
              field={'updateAttachmentsForm.updateAttachmentForms'}
              project={data.project}
              isEnabledIsVisibleToCrewColumn
              isEnabledHardDeleteAttachments
            />
          );
        }}
      </Loading>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UploadAttachmentsModalV2.query = gql`
  ${ProjectAttachmentUploader.fragment}

  query UploadAttachmentsModalV2($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...ProjectAttachmentUploader
    }
  }
`;

export default UploadAttachmentsModalV2;
