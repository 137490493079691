// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive, useState, useToast} from '@supermove/hooks';
import {OrganizationModel, RateTableModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import RateTableForm from '@shared/modules/RateTable/forms/RateTableForm';
import useCreateRateTableMutation from '@shared/modules/RateTable/hooks/useCreateRateTableMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import HourlyRateTablePage from 'modules/Organization/Settings/Billing/components/HourlyRateTablePage';
import HourlyRateTablePageHeader from 'modules/Organization/Settings/Billing/components/HourlyRateTablePageHeader';
import RateTableDetailsPanel from 'modules/Organization/Settings/Billing/components/RateTableDetailsPanel';
import RateTablePanel from 'modules/Organization/Settings/Billing/components/RateTablePanel';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const LoadingContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LoadingComponent = () => {
  return (
    <PageContainer>
      <HourlyRateTablePageHeader title={'Create Hourly Rate Table'} />
      <LoadingContainer>
        <PageLoadingIndicator />
      </LoadingContainer>
    </PageContainer>
  );
};

const CreateHourlyRateTablePageContent = ({
  organization,
  rateTable,
}: {
  organization: OrganizationModel;
  rateTable?: RateTableModel;
}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Hourly rate table created!',
  });
  const [rateTableErrors, setRateTableErrors] = useState<string[]>([]);
  const rateTableForm = rateTable
    ? RateTableForm.duplicate(rateTable)
    : RateTableForm.new({organizationId: organization.id});
  const {form, submitting, handleSubmit} = useCreateRateTableMutation({
    rateTableForm,
    onSuccess: ({rateTable}) => {
      setRateTableErrors([]);
      navigator.push('/settings/billing/hourly-rates');
      successToast.handleToast({message: `${rateTable.name} created!`});
    },
    onError: (errors) => {
      console.log({errors});
      setRateTableErrors(RateTableForm.getRateTableErrors({errors}));
    },
  });
  return (
    <HourlyRateTablePage
      title={'Create Hourly Rate Table'}
      isDirty={form.dirty}
      isSubmitting={submitting}
      handleSubmit={() => {
        setRateTableErrors([]);
        handleSubmit();
      }}
    >
      {responsive.desktop && <Space height={24} />}
      <RateTableDetailsPanel index={0} form={form} />
      {responsive.desktop && <Space height={24} />}
      <RateTablePanel index={1} form={form} rateTableErrors={rateTableErrors} />
    </HourlyRateTablePage>
  );
};

const CreateHourlyRateTablePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(CreateHourlyRateTablePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      rateTableUuid: params.rateTableUuid || '',
    },
  });
  return (
    <PageContainer>
      <Loading loading={loading} as={LoadingComponent}>
        {() => (
          <CreateHourlyRateTablePageContent
            organization={data.viewer.viewingOrganization}
            rateTable={data.rateTable}
          />
        )}
      </Loading>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateHourlyRateTablePage.query = gql`
  ${RateTableForm.duplicate.fragment}
  query CreateHourlyRateTablePage($rateTableUuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
    }
    rateTable(uuid: $rateTableUuid) {
      id
      ...RateTableForm_duplicate
    }
  }
`;

export default CreateHourlyRateTablePage;
