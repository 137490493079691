// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillItem} from '@supermove/models';
import {Currency, Percent, withFragment} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import CostItemForm from '@shared/modules/Billing/forms/CostItemForm';

const getDropdownLabel = (billItemForm: any) => {
  const label = `${getDisplayName(billItemForm)} (${
    billItemForm.kind === BillItemTypeKind.AMOUNT
      ? Currency.display(billItemForm.amount)
      : Percent.display(billItemForm.percentage)
  })`;
  if (billItemForm.description) {
    return `${label} – ${billItemForm.description}`;
  }
  return label;
};

const getDisplayName = (billItemForm: any) => {
  const possibleMoverPositionName =
    billItemForm.moverPositionName !== '' ? ` (${billItemForm.moverPositionName})` : '';
  return `${billItemForm.name}${possibleMoverPositionName}`;
};

const getIsHourly = (billItemForm: any) => {
  return billItemForm.unit === BillItemUnit.HOUR;
};

const getIsDiscount = (billItemForm: any) => {
  return billItemForm.category === BillItemTypeCategory.DISCOUNTS;
};

const getIsSupply = (billItemForm: any) => {
  return billItemForm.category === BillItemTypeCategory.SUPPLIES;
};

const getIsAmount = (billItemForm: any) => {
  return billItemForm.kind === BillItemTypeKind.AMOUNT;
};

const getIsPercentage = (billItemForm: any) => {
  return billItemForm.kind === BillItemTypeKind.PERCENTAGE;
};

const getHasQuantityFormula = (billItemForm: any) => {
  return !!billItemForm.minQuantityFormulaId || !!billItemForm.maxQuantityFormulaId;
};

const getSubtotals = (
  billItemForm: any,
  {salesTaxRate}: {salesTaxRate: number | null} = {salesTaxRate: null},
) => {
  const {isEnabledTbdBillItems} = billItemForm;
  const isTaxable = _.get(billItemForm, 'isTaxable');
  const taxRate = isTaxable ? salesTaxRate : 0;
  const amount = _.get(billItemForm, 'amount');
  const minQuantity = _.get(billItemForm, 'minQuantity');
  const maxQuantity = _.get(billItemForm, 'maxQuantity');
  // If minQuantity is TBD and maxQuantity is not (only possible via formulas),
  // use maxQuantity as minQuantity for calculations.
  const calculatedMinQuantity =
    isEnabledTbdBillItems && minQuantity === '' ? maxQuantity : minQuantity;
  const minSubtotal = Currency.convertToCents(amount) * Number(calculatedMinQuantity);
  const maxSubtotal = Currency.convertToCents(amount) * Number(maxQuantity);
  const minTaxTotal = minSubtotal * (taxRate || 0);
  const maxTaxTotal = maxSubtotal * (taxRate || 0);
  const minTotal = minSubtotal + minTaxTotal;
  const maxTotal = maxSubtotal + maxTaxTotal;
  const showTbd = isEnabledTbdBillItems
    ? calculatedMinQuantity === '' ||
      (calculatedMinQuantity === '' && maxQuantity === '') ||
      amount === ''
    : minSubtotal === 0 && maxSubtotal === 0;
  return {minSubtotal, maxSubtotal, showTbd, minTaxTotal, maxTaxTotal, minTotal, maxTotal};
};

const validate = ({billItemForm, field}: any) => {
  const errors = {};
  if (!billItemForm.category) {
    _.set(errors, `${field}.category`, 'Please select a category');
  }
  if (!billItemForm.kind) {
    _.set(errors, `${field}.kind`, 'Please select a kind');
  }
  if (!billItemForm.billStage) {
    _.set(errors, `${field}.billStage`, 'Please select pre subtotal or post subtotal');
  }
  if (!billItemForm.name) {
    _.set(errors, `${field}.name`, 'Please enter a name');
  }
  if (getIsDiscount(billItemForm)) {
    if (
      billItemForm.kind === BillItemTypeKind.AMOUNT &&
      Currency.convertToCents(billItemForm.amount) > 0
    ) {
      _.set(errors, `${field}.amount`, 'Discounts must be negative');
    }
    if (
      billItemForm.kind === BillItemTypeKind.PERCENTAGE &&
      Percent.toFloat(billItemForm.percentage) > 0
    ) {
      _.set(errors, `${field}.percentage`, 'Discounts must be negative');
    }
  } else {
    if (
      billItemForm.kind === BillItemTypeKind.AMOUNT &&
      Currency.convertToCents(billItemForm.amount) < 0
    ) {
      _.set(errors, `${field}.amount`, 'Please enter a positive value');
    }
    if (
      billItemForm.kind === BillItemTypeKind.PERCENTAGE &&
      Percent.toFloat(billItemForm.percentage) < 0
    ) {
      _.set(errors, `${field}.percentage`, 'Please enter a positive value');
    }
  }
  return errors;
};

const handleQuantityOnBlur = ({billItemForm, form, field}: any) => {
  const {minQuantity, maxQuantity} = billItemForm;
  if (maxQuantity) {
    if (!minQuantity) {
      form.setFieldValue(`${field}.minQuantity`, maxQuantity);
    } else if (Number(minQuantity) > Number(maxQuantity)) {
      form.setFieldValue(`${field}.maxQuantity`, '');
    }
  }
};

const getHasFormulas = (billItemForm: any) => {
  const {nameFormulaId, amountFormulaId, minQuantityFormulaId, maxQuantityFormulaId} = billItemForm;
  return {
    hasNameFormula: !!nameFormulaId,
    hasAmountFormula: !!amountFormulaId,
    hasQuantityFormula: !!minQuantityFormulaId || !!maxQuantityFormulaId,
  };
};

const _new = ({billId, kind, category, billStage, unit, index, isEnabledTbdBillItems}: any) => {
  return {
    nameFormulaId: null,
    amountFormulaId: null,
    minQuantityFormulaId: null,
    maxQuantityFormulaId: null,
    billItemId: null,
    billId,
    kind,
    category,
    billStage,
    unit,
    index,
    amount: '',
    description: '',
    maxQuantity: '',
    minQuantity: '',
    name: '',
    percentage: '',
    moverPositionId: null,
    isTaxable: category === BillItemTypeCategory.SUPPLIES,

    // Private
    total: '',
    displayPrice: '',
    isDisabledAdd: false,
    moverPositionName: '',
    isEnabledTbdBillItems,
  };
};

const edit = withFragment(
  (billItem, {costItemForm} = {}) => {
    const isEnabledTbdBillItems = (billItem as any).bill?.organization.features
      .isEnabledTbdBillItems;
    return {
      nameFormulaId: (billItem as any).nameFormulaId,
      amountFormulaId: (billItem as any).amountFormulaId,
      minQuantityFormulaId: (billItem as any).minQuantityFormulaId,
      maxQuantityFormulaId: (billItem as any).maxQuantityFormulaId,
      billItemId: (billItem as any).id,
      billItemTypeId: (billItem as any).billItemTypeId,
      billId: (billItem as any).billId,
      amount: (billItem as any).amount,
      billStage: (billItem as any).billStage,
      category: (billItem as any).category,
      description: (billItem as any).description || '',
      index: (billItem as any).index,
      isDeleted: (billItem as any).isDeleted,
      kind: (billItem as any).kind,
      maxQuantity: isEnabledTbdBillItems
        ? (billItem as any).maxQuantity === null
          ? ''
          : String((billItem as any).maxQuantity)
        : (billItem as any).maxQuantity
          ? String((billItem as any).maxQuantity)
          : '',
      minQuantity: isEnabledTbdBillItems
        ? (billItem as any).minQuantity === null
          ? ''
          : String((billItem as any).minQuantity)
        : (billItem as any).minQuantity
          ? String((billItem as any).minQuantity)
          : '',
      name: (billItem as any).name,
      percentage: (billItem as any).percentage,
      unit: (billItem as any).unit,
      costItemForm,
      moverPositionId: (billItem as any).moverPositionId,
      isTaxable: (billItem as any).isTaxable,
      // Private
      total: (billItem as any).total,
      displayPrice: BillItem.getDisplayPrice(billItem, {isEnabledTbdBillItems}),
      isDisabledAdd: false,
      moverPositionName: (billItem as any).moverPosition
        ? (billItem as any).moverPosition.name
        : '',
      isEnabledTbdBillItems,
    };
  },
  gql`
    ${BillItem.getDisplayPrice.fragment}
    fragment BillItemForm_edit on BillItem {
      id
      nameFormulaId
      amountFormulaId
      minQuantityFormulaId
      maxQuantityFormulaId
      billItemTypeId
      billId
      amount
      billStage
      category
      description
      index
      isDeleted
      kind
      maxQuantity
      minQuantity
      name
      percentage
      unit
      total
      moverPositionId
      moverPosition {
        id
        name
      }
      isTaxable
      ...BillItem_getDisplayPrice
      bill {
        id
        organization {
          id
          features {
            isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
          }
        }
      }
    }
  `,
);

const editFromBillItemType = withFragment(
  (billItemType, {billId, index}) => {
    const {isEnabledTbdBillItems} = (billItemType as any).organization.features;
    return {
      nameFormulaId: (billItemType as any).nameFormulaId,
      amountFormulaId: (billItemType as any).amountFormulaId,
      minQuantityFormulaId: (billItemType as any).minQuantityFormulaId,
      maxQuantityFormulaId: (billItemType as any).maxQuantityFormulaId,
      billItemId: null,
      billItemTypeId: (billItemType as any).id,
      billId,
      amount: (billItemType as any).amount,
      billStage: (billItemType as any).billStage,
      category: (billItemType as any).category,
      description: (billItemType as any).description || '',
      index,
      isDeleted: false,
      kind: (billItemType as any).kind,
      maxQuantity: isEnabledTbdBillItems
        ? (billItemType as any).maxQuantity === null
          ? ''
          : String((billItemType as any).maxQuantity)
        : (billItemType as any).maxQuantity
          ? String((billItemType as any).maxQuantity)
          : '',
      minQuantity: isEnabledTbdBillItems
        ? (billItemType as any).minQuantity === null
          ? ''
          : String((billItemType as any).minQuantity)
        : (billItemType as any).minQuantity
          ? String((billItemType as any).minQuantity)
          : '',
      name: (billItemType as any).name,
      percentage: (billItemType as any).percentage,
      unit: (billItemType as any).unit,
      moverPositionId: (billItemType as any).moverPositionId,
      isTaxable: (billItemType as any).isTaxable,
      // Private
      total: '',
      displayPrice: '',
      isDisabledAdd: false,
      moverPositionName: (billItemType as any).moverPosition
        ? (billItemType as any).moverPosition.name
        : '',
      isEnabledTbdBillItems,
    };
  },
  gql`
    fragment BillItemForm_editFromBillItemType on BillItemType {
      id
      nameFormulaId
      amountFormulaId
      minQuantityFormulaId
      maxQuantityFormulaId
      amount
      billStage
      category
      description
      kind
      maxQuantity
      minQuantity
      name
      percentage
      unit
      moverPositionId
      moverPosition {
        id
        name
      }
      isTaxable
      organization {
        id
        features {
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
      }
    }
  `,
);

const toForm = ({
  nameFormulaId,
  amountFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  billItemId,
  billItemTypeId,
  billId,
  amount,
  billStage,
  category,
  description,
  index,
  isDeleted,
  kind,
  maxQuantity,
  minQuantity,
  name,
  percentage,
  unit,
  costItemForm,
  total,
  displayPrice,
  isDisabledAdd,
  moverPositionName,
  moverPositionId,
  isTaxable,
  isEnabledTbdBillItems,
}: any) => {
  return {
    nameFormulaId,
    amountFormulaId,
    minQuantityFormulaId,
    maxQuantityFormulaId,
    billItemId,
    billItemTypeId,
    billId,
    amount: Currency.toForm(amount),
    billStage,
    category,
    description,
    index,
    isDeleted,
    kind,
    maxQuantity,
    minQuantity,
    name,
    percentage: Percent.toForm(percentage),
    unit,
    costItemForm: costItemForm ? CostItemForm.toForm(costItemForm) : null,
    moverPositionId,
    isTaxable,

    // Private
    total,
    displayPrice,
    isDisabledAdd,
    moverPositionName,
    isEnabledTbdBillItems,
  };
};

const toMutation = ({
  nameFormulaId,
  amountFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  billItemId,
  billItemTypeId,
  billId,
  amount,
  billStage,
  category,
  description,
  index,
  isDeleted,
  kind,
  maxQuantity,
  minQuantity,
  name,
  percentage,
  unit,
  costItemForm,
  moverPositionId,
  isTaxable,
  isEnabledTbdBillItems,
}: any) => {
  return {
    nameFormulaId,
    amountFormulaId,
    minQuantityFormulaId,
    maxQuantityFormulaId,
    billItemId,
    billItemTypeId,
    billId,
    amount: Currency.toMutation(amount, isEnabledTbdBillItems),
    billStage,
    category,
    description,
    index,
    isDeleted,
    kind,
    maxQuantity: isEnabledTbdBillItems
      ? maxQuantity || maxQuantity === '0'
        ? Number(maxQuantity)
        : null
      : Number(maxQuantity),
    minQuantity: isEnabledTbdBillItems
      ? minQuantity || minQuantity === '0'
        ? Number(minQuantity)
        : null
      : Number(minQuantity),
    name,
    percentage: Percent.toMutation(percentage, isEnabledTbdBillItems),
    unit,
    costItemForm: costItemForm ? CostItemForm.toMutation(costItemForm) : null,
    moverPositionId,
    isTaxable,
  };
};

const BillItemForm = {
  new: _new,
  edit,
  editFromBillItemType,
  toForm,
  toMutation,

  getDropdownLabel,
  getDisplayName,
  getIsHourly,
  getIsDiscount,
  getIsSupply,
  getIsAmount,
  getIsPercentage,
  getEstimateQuantity: BillItem.getEstimateQuantity,
  getHasQuantityFormula,
  getSubtotals,
  validate,
  handleQuantityOnBlur,
  getHasFormulas,
};

export default BillItemForm;
