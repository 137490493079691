// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UserFlowForm from '@shared/modules/UserFlows/forms/UserFlowForm';
import useCreateUserFlowMutation from '@shared/modules/UserFlows/hooks/useCreateUserFlowMutation';
import {PageLoadingIndicator} from 'modules/App/components';
import DocumentFlowPage from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/DocumentFlowPage';

const CreateDocumentFlowPageContent = ({organizationId}: {organizationId: string}) => {
  const {navigator} = useNavigationDOM();
  const createDocumentFlowSuccessToast = useToast({
    ToastComponent: SuccessToast,
  });

  const userFlowForm = UserFlowForm.new({organizationId});

  const field = 'userFlowForm';
  const {form, submitting, handleSubmit} = useCreateUserFlowMutation({
    userFlowForm,
    onSuccess: ({userFlow}) => {
      createDocumentFlowSuccessToast.handleToast({
        message: `'${userFlow.name}' created!`,
      });
      navigator.replace(`/settings/documents/document-flows`);
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DocumentFlowPage
      form={form}
      field={field}
      submitting={submitting}
      handleSubmit={handleSubmit}
    />
  );
};

const CreateDocumentFlowPage = () => {
  const {data, loading} = useQuery<{viewer: {viewingOrganization: OrganizationModel}}>(
    CreateDocumentFlowPage.query,
  );

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => <CreateDocumentFlowPageContent organizationId={data!.viewer.viewingOrganization.id} />}
    </Loading>
  );
};

CreateDocumentFlowPage.query = gql`
  query CreateDocumentFlowPageQuery {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
    }
  }
`;

export default CreateDocumentFlowPage;
