// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import OrganizationNotificationEmailsForm from '@shared/modules/Organization/forms/OrganizationNotificationEmailsForm';

const useUpdateOrganizationNotificationEmailsMutation = ({
  organizationNotificationEmailsForm,
  onSuccess,
  onError,
}: {
  organizationNotificationEmailsForm: any;
  onSuccess: ({organization}: {organization: OrganizationModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      organizationNotificationEmailsForm: OrganizationNotificationEmailsForm.toForm(
        organizationNotificationEmailsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationNotificationEmailsMutation.mutation,
    variables: {
      organizationNotificationEmailsForm: OrganizationNotificationEmailsForm.toMutation(
        form.values.organizationNotificationEmailsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationNotificationEmailsMutation.mutation = gql`
  mutation useUpdateOrganizationNotificationEmailsMutation($organizationNotificationEmailsForm: OrganizationNotificationEmailsForm!) {
    response: updateOrganizationNotificationEmails(organizationNotificationEmailsForm: $organizationNotificationEmailsForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOrganizationNotificationEmailsMutation;
