// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ModalType, useInlineFormMutation, useNavigationDOM, useToast} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import DeleteUserFlowForm, {
  DeleteUserFlowFormToFormType,
} from '@shared/modules/UserFlows/forms/DeleteUserFlowForm';

const Row = Styled.View`
  flex-direction: row;
`;

const StyledScrollView = Styled(ScrollView)`
  max-height: 400px;
  padding: 8px;
`;

const PaddedLoadingIndicator = () => {
  return (
    <React.Fragment>
      <Space height={32} />
      <PageLoadingIndicator />
      <Space height={32} />
    </React.Fragment>
  );
};

const ListItems = <T extends {id: string}>({
  listItems,
  getListItemText,
  getListItemUrl,
}: {
  listItems: T[];
  getListItemText: (listItem: T) => string;
  getListItemUrl: (listItem: T) => string;
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <StyledScrollView>
      {listItems.map((listItem) => (
        <Row key={listItem.id}>
          <TertiaryButton
            style={{cursor: 'pointer'}}
            onPress={() => navigator.pushNewTab(getListItemUrl(listItem))}
            text={`• ${getListItemText(listItem)}`}
          />
        </Row>
      ))}
    </StyledScrollView>
  );
};

const useDeleteUserFlowMutation = ({
  deleteUserFlowForm,
  onSuccess,
}: {
  deleteUserFlowForm: DeleteUserFlowFormToFormType;
  onSuccess: () => void;
}) => {
  return useInlineFormMutation({
    name: 'deleteUserFlowForm',
    form: deleteUserFlowForm,
    toForm: DeleteUserFlowForm.toForm,
    toMutation: DeleteUserFlowForm.toMutation,
    mutation: gql`
      mutation useDeleteUserFlowMutation($deleteUserFlowForm: DeleteUserFlowForm!) {
        response: deleteUserFlow(deleteUserFlowForm: $deleteUserFlowForm) {
          ${gql.errors}
          userFlow {
            id
          }
        }
      }
    `,
    onSuccess,
  });
};

const DeleteUserFlowModal = ({
  isOpen,
  userFlow,
  refetch,
  handleClose,
}: {
  isOpen: boolean;
  userFlow: UserFlowModel;
  refetch: () => void;
  handleClose: () => void;
}) => {
  const deleteUserFlowToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Email template deleted',
  });
  const {form, handleSubmit, submitting} = useDeleteUserFlowMutation({
    deleteUserFlowForm: DeleteUserFlowForm.edit(userFlow),
    onSuccess: () => {
      refetch();
      handleClose();
      deleteUserFlowToast.handleToast();
    },
  });

  const hasProjectTypeUserFlows = userFlow.projectTypeUserFlows.length > 0;

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${userFlow.name}?`}
      subtitle={
        hasProjectTypeUserFlows
          ? 'This flow is linked to the following project types:'
          : 'This flow is not linked to any project types.'
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    >
      <ListItems
        listItems={userFlow.projectTypeUserFlows.map(
          (projectTypeUserFlow) => projectTypeUserFlow.projectType,
        )}
        getListItemText={(projectType) => projectType.name}
        getListItemUrl={(projectType) =>
          `/settings/projects/project-types/${projectType.uuid}/document-flows`
        }
      />
    </DeleteModal>
  );
};

const CannotDeleteUserFlowConnectedToAutomationsModal = ({
  isOpen,
  userFlow,
  handleClose,
}: {
  isOpen: boolean;
  userFlow: UserFlowModel;
  handleClose: () => void;
}) => {
  return (
    <SuccessModal
      isOpen={isOpen}
      icon={undefined}
      title={'Unable To Delete'}
      subtitle={`“${userFlow.name}” is used in the following automations:`}
      primaryActionText='I understand'
      handlePrimaryAction={handleClose}
    >
      <ListItems
        listItems={userFlow.automationWorkflowSteps.map((workflowStep) => workflowStep.workflow)}
        getListItemText={(workflow) => workflow.name}
        getListItemUrl={(workflow) => `/settings/automations/${workflow.uuid}`}
      />
    </SuccessModal>
  );
};

const OrganizationSettingsDocumentFlowDeleteModal = ({
  userFlowUuid,
  deleteUserFlowModal,
  refetch,
}: {
  userFlowUuid: string;
  deleteUserFlowModal: ModalType;
  refetch: () => void;
}) => {
  const {data, loading} = useQuery<{userFlow: UserFlowModel}>(
    OrganizationSettingsDocumentFlowDeleteModalQuery,
    {
      variables: {uuid: userFlowUuid},
      skip: !deleteUserFlowModal.isOpen,
    },
  );

  if (!data) {
    return (
      <SmallModal
        handlePressOutside={deleteUserFlowModal.handleClose}
        isOpen={deleteUserFlowModal.isOpen}
      >
        <Loading loading={loading} as={PaddedLoadingIndicator} />
      </SmallModal>
    );
  } else {
    const hasAutomationWorkflowSteps = data.userFlow.automationWorkflowSteps.length > 0;
    if (hasAutomationWorkflowSteps) {
      return (
        <CannotDeleteUserFlowConnectedToAutomationsModal
          isOpen={deleteUserFlowModal.isOpen}
          userFlow={data.userFlow}
          handleClose={deleteUserFlowModal.handleClose}
        />
      );
    }
    return (
      <DeleteUserFlowModal
        isOpen={deleteUserFlowModal.isOpen}
        userFlow={data.userFlow}
        refetch={refetch}
        handleClose={deleteUserFlowModal.handleClose}
      />
    );
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const OrganizationSettingsDocumentFlowDeleteModalQuery = gql`
  query OrganizationSettingsDocumentFlowDeleteModalQuery($uuid: String!) {
    userFlow(uuid: $uuid) {
      id
      name
      projectTypeUserFlows {
        id
        projectType {
          id
          uuid
          name
        }
      }
      automationWorkflowSteps {
        id
        workflow {
          id
          uuid
          name
        }
      }
    }
  }
`;

export default OrganizationSettingsDocumentFlowDeleteModal;
