import React from 'react';
import ReactDOM from 'react-dom';

import {Browser} from '@supermove/app';

import AppWrapper from 'core/AppWrapper';

import {client} from './config';

ReactDOM.render(
  <React.StrictMode>
    <Browser client={client}>
      <AppWrapper />
    </Browser>
  </React.StrictMode>,
  document.getElementById('___gatsby'),
);
