// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useToast} from '@supermove/hooks';
import {CancellationReasonModel, ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import Toast from '@shared/design/components/Toast';
import CancelProjectForm from '@shared/modules/Project/forms/CancelProjectForm';
import useCancelProjectMutation from '@shared/modules/Project/hooks/useCancelProjectMutation';

const CancelProjectModal = ({
  project,
  isOpen,
  handleClose,
  refetch,
}: {
  project: ProjectModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const projectCancelledToast = useToast({ToastComponent: Toast, message: 'Project cancelled.'});
  const cancelProjectForm = CancelProjectForm.new({projectId: project.id});
  const {form, handleSubmit, submitting} = useCancelProjectMutation({
    cancelProjectForm,
    onSuccess: () => {
      projectCancelledToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const {data, loading} = useQuery(CancelProjectModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {slug: project.organization.slug},
  });

  const cancellationReasons = data ? data.organization.companySettings.cancellationReasons : [];
  const selectedCancellationReason = _.find(cancellationReasons, {
    id: form.values.cancelProjectForm.cancellationReasonId,
  });

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Cancel Project'}
      handleAction={handleSubmit}
      handleActionText={'Confirm'}
      handleActionColor={colors.red.warning}
      handleCloseText={'Go Back'}
      isSubmitting={submitting}
      isMobileSheet
      shouldCloseOnClickOutside={responsive.desktop}
    >
      <FieldInput
        {...form}
        label={'Cancellation Reason'}
        name={'cancelProjectForm.cancellationReasonId'}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          isLoading: loading,
          isPortaled: true,
          placeholder: 'Select cancellation reason',
          setFieldValue: form.setFieldValue,
          options: cancellationReasons.map(({id, name}: CancellationReasonModel) => ({
            label: name,
            value: id,
          })),
          style: {flex: 1},
        }}
        style={{flex: 1}}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        label={'Details'}
        name={'cancelProjectForm.details'}
        isRequired={selectedCancellationReason?.name === 'Other'}
        isResponsive
        input={{
          multiline: true,
          placeholder: 'Enter details',
          style: {
            height: 76,
            paddingVertical: 8,
            paddingHorizontal: 12,
          },
        }}
      />
      {responsive.desktop && <Space height={16} />}
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CancelProjectModal.fragment = gql`
  fragment CancelProjectModal on Project {
    id
    organization {
      id
      slug
    }
  }
`;

CancelProjectModal.query = gql`
  query CancelProjectModal($slug: String!) {
    organization(slug: $slug) {
      id
      companySettings {
        cancellationReasons {
          id
          name
        }
      }
    }
  }
`;

export default CancelProjectModal;
