// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';
import BillingLibraryActionsButton from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryActionsButton';
import CreateOrganizationBillingLibraryModal from 'modules/Organization/Settings/BillingLibraries/components/CreateOrganizationBillingLibraryModal';
import EditBillingLibraryModal from 'modules/Organization/Settings/BillingLibraries/components/EditBillingLibraryModal';

const Row = Styled.View`
  flex-direction: row;
`;

const ContentContainer = Styled.View`
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const CreateButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  background-color: ${colors.blue.interactive};
  border-radius: 4px;
`;

const CreateButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const TableContainer = Styled.View`
  max-width: 480px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const TableHeader = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  background-color: ${colors.gray.background};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const TableColumn = Styled.View`
`;

const TableRow = Styled.View`
  flex-direction: row;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const TableColumnRow = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

const BillingLibraryNameText = Styled.Text`
  ${Typography.Body3}
`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const ActionText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const DefaultBadge = Styled.View`
  border-radius: 20px;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.blue.accent};
`;

const DefaultText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const TableNameColumn = ({children}: any) => {
  const responsive = useResponsive();
  return (
    <TableColumn style={{flex: 1, paddingHorizontal: responsive.mobile ? 12 : 20}}>
      {children}
    </TableColumn>
  );
};

const TableActionsColumn = ({children}: any) => {
  return <TableColumn style={{width: 130}}>{children}</TableColumn>;
};

const BillingLibraryRow = ({billingLibrary, isDefaultBillingLibrary, refetch}: any) => {
  const {navigator} = useNavigationDOM();
  const editBillingLibraryModal = useModal();

  return (
    <TableRow>
      <TableNameColumn>
        <TableColumnRow>
          <BillingLibraryNameText>{billingLibrary.name}</BillingLibraryNameText>
          {isDefaultBillingLibrary && (
            <React.Fragment>
              <Space width={8} />
              <DefaultBadge>
                <DefaultText>Default</DefaultText>
              </DefaultBadge>
            </React.Fragment>
          )}
        </TableColumnRow>
      </TableNameColumn>
      <TableActionsColumn>
        <TableColumnRow>
          <ActionButton onPress={editBillingLibraryModal.handleOpen}>
            <Icon source={Icon.Pen} color={colors.gray.secondary} size={16} />
          </ActionButton>
          <Space width={12} />
          <BillingLibraryActionsButton billingLibrary={billingLibrary} refetch={refetch} />
          <Space width={12} />
          <ActionButton
            onPress={() =>
              navigator.push(
                `/settings/billing/billing-libraries/${billingLibrary.uuid}/items/fees`,
              )
            }
          >
            <ActionText>View</ActionText>
          </ActionButton>
        </TableColumnRow>
      </TableActionsColumn>
      <EditBillingLibraryModal
        key={editBillingLibraryModal.isOpen}
        isOpen={editBillingLibraryModal.isOpen}
        handleClose={editBillingLibraryModal.handleClose}
        billingLibrary={billingLibrary}
        refetch={refetch}
      />
    </TableRow>
  );
};

const OrganizationBillingLibrariesTable = ({organization, refetch}: any) => {
  return (
    <TableContainer>
      <TableHeader>
        <TableNameColumn>
          <TableHeaderText>Billing Library Name</TableHeaderText>
        </TableNameColumn>
        <TableActionsColumn>
          <TableHeaderText>Actions</TableHeaderText>
        </TableActionsColumn>
      </TableHeader>
      {organization.billingLibraries.map((billingLibrary: any) => {
        return (
          <BillingLibraryRow
            key={billingLibrary.id}
            billingLibrary={billingLibrary}
            isDefaultBillingLibrary={
              String(billingLibrary.id) === String(organization.defaultBillingLibraryId)
            }
            refetch={refetch}
          />
        );
      })}
    </TableContainer>
  );
};

const OrganizationSettingsBillingLibrariesPageContent = ({organization, refetch}: any) => {
  const createBillingLibraryModal = useModal();

  return (
    <ContentContainer>
      <HeaderText>
        {pluralize('Billing Library', organization.billingLibraries.length, true)}
      </HeaderText>
      <Space height={12} />
      <Row>
        <CreateButton onPress={createBillingLibraryModal.handleOpen}>
          <Icon source={Icon.Plus} color={colors.white} size={12} />
          <Space width={6} />
          <CreateButtonText>Create Billing Library</CreateButtonText>
        </CreateButton>
      </Row>
      <Space height={16} />
      <OrganizationBillingLibrariesTable organization={organization} refetch={refetch} />
      <CreateOrganizationBillingLibraryModal
        key={createBillingLibraryModal.isOpen}
        isOpen={createBillingLibraryModal.isOpen}
        handleClose={createBillingLibraryModal.handleClose}
        organization={organization}
        refetch={refetch}
      />
    </ContentContainer>
  );
};

const OrganizationSettingsBillingLibrariesPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsBillingLibrariesPage.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <OrganizationSettingsBillingSettingsPage>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <OrganizationSettingsBillingLibrariesPageContent
            organization={data.viewer.viewingOrganization}
            refetch={refetch}
          />
        )}
      </Loading>
    </OrganizationSettingsBillingSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingLibrariesPage.query = gql`
  ${BillingLibraryActionsButton.fragment}
  ${EditBillingLibraryModal.fragment}
  ${CreateOrganizationBillingLibraryModal.fragment}

  query OrganizationSettingsBillingLibrariesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        defaultBillingLibraryId
        billingLibraries {
          id
          uuid
          name
          ...BillingLibraryActionsButton
          ...EditBillingLibraryModal
        }
        ...CreateOrganizationBillingLibraryModal
      }
    }
  }
`;

export default OrganizationSettingsBillingLibrariesPage;
