// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, MultiDropdownInput, Space, Styled} from '@supermove/components';
import {EmailTemplate} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EmailTemplateRecipientForm from '@shared/modules/Email/forms/EmailTemplateRecipientForm';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Button = Styled.ButtonV2`
`;

const CcButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const OptionText = Styled.Text`
  ${Typography.Body3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const OptionAddText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const ErrorText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.red.warning};
`;

const getEmailRecipientForm = (email: any) => {
  return EmailTemplateRecipientForm.new({email});
};

const getIsNewCustomOption = ({option, searchInputValue}: any) => {
  return searchInputValue === option.label && searchInputValue === option.value;
};

const getUpdatedOptions = ({recipientOptions, searchInputValue}: any) => {
  const searchInputValueFirstChar = searchInputValue[0];
  const isSearchingForVariable = searchInputValueFirstChar === '{';
  const hasExactSearchMatch = !!_.find(
    recipientOptions,
    (option) => _.toLower(option.label) === _.toLower(searchInputValue),
  );
  if (!searchInputValue || isSearchingForVariable || hasExactSearchMatch) {
    return recipientOptions;
  }
  const customRecipientOption = {label: searchInputValue, value: searchInputValue};
  return [customRecipientOption, ...recipientOptions];
};

const getEmailWithRole = ({role, emailRecipientOptions}: any) => {
  const emailRecipientWithRole = _.find(
    emailRecipientOptions,
    (recipient) => recipient.value === role,
  );
  return emailRecipientWithRole.email;
};

const getIsRole = (value: any) => {
  return !!_.get(EmailTemplate.RECIPIENT_VALUES, value);
};

const handleUpdateRecipients = ({
  values,
  recipientOptions,
  form,
  field,
  recipientFormsField,
}: any) => {
  const emailRecipientOptions = _.get(form.values, `${field}.emailRecipientOptions`);
  const recipientForms = _.flatten(
    values.map((value: any) => {
      const isAddingNewRecipient = !_.find(
        recipientOptions,
        (option) => _.toLower(value) === _.toLower(option.value),
      );
      const emailString = getIsRole(value)
        ? getEmailWithRole({role: value, emailRecipientOptions})
        : value;
      const emails = emailString.split(',');

      if (isAddingNewRecipient) {
        form.setFieldValue(`${field}.emailRecipientOptions`, [
          ...emailRecipientOptions,
          {label: value, value},
        ]);
      }
      return emails.map((email: any) => getEmailRecipientForm(email));
    }),
  );
  form.setFieldValue(`${field}.${recipientFormsField}`, recipientForms);
};

const RecipientOption = ({searchInputValue, option, disabled, children}: any) => {
  return (
    <Row>
      {getIsNewCustomOption({option, searchInputValue}) && (
        <React.Fragment>
          <Icon source={Icon.Plus} size={10} color={colors.blue.interactive} />
          <Space width={4} />
          <OptionAddText>Add</OptionAddText>
          <Space width={8} />
        </React.Fragment>
      )}
      <OptionText color={disabled ? colors.gray.tertiary : colors.gray.primary}>
        {children}
      </OptionText>
    </Row>
  );
};

const RecipientsField = ({form, field, recipientType, recipientOptions}: any) => {
  const searchInputValue = _.get(form.values, `${field}.searchInputValue`);
  const recipientFormsField = `${recipientType}EmailRecipientForms`;
  const recipientValuesField = `${recipientType}EmailRecipientValues`;
  const recipientErrors = _.get(form.errors, `${field}.${recipientFormsField}`);

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={MultiDropdownInput}
        name={`${field}.${recipientValuesField}`}
        isResponsive
        input={{
          options: getUpdatedOptions({recipientOptions, searchInputValue}),
          placeholder: 'Enter recipients',
          setFieldValue: form.setFieldValue,
          isSearchable: true,
          isOptionDisabled: (option: any) => option.disabled,
          onChangeValue: (values: any) => {
            handleUpdateRecipients({values, recipientOptions, form, field, recipientFormsField});
          },
          onInputChange: (value: any) => {
            form.setFieldValue(`${field}.searchInputValue`, value);
          },
          components: {
            Option: (props: any) => {
              return (
                <MultiDropdownInput.Option
                  {...props}
                  optionComponentProps={{searchInputValue}}
                  OptionComponent={RecipientOption}
                />
              );
            },
          },
          style: {
            width: '100%',
            borderColor: recipientErrors ? colors.red.warning : colors.gray.tertiary,
          },
        }}
        style={{
          width: '100%',
        }}
      />
      {!!recipientErrors && (
        <React.Fragment>
          <Space height={4} />
          {/* TODO(dan) Once backend is updated, show message from backend for input */}
          <ErrorText>
            {_.get(
              _.filter(recipientErrors, (error) => !!error),
              '0.email',
              'Invalid email found.',
            )}
          </ErrorText>{' '}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const EmailRecipientsSection = ({field, form}: any) => {
  const {isShowingCc, isShowingBcc} = _.get(form.values, field);
  const recipientOptions = _.get(form.values, `${field}.emailRecipientOptions`);
  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={0}>
        <Row>
          <Label>Recipients</Label>
          <Space style={{flex: 1}} />
          {!isShowingCc && (
            <Button onPress={() => form.setFieldValue(`${field}.isShowingCc`, true)}>
              <CcButtonText>Cc</CcButtonText>
            </Button>
          )}
          {!isShowingBcc && (
            <React.Fragment>
              <Space width={12} />
              <Button onPress={() => form.setFieldValue(`${field}.isShowingBcc`, true)}>
                <CcButtonText>Bcc</CcButtonText>
              </Button>
            </React.Fragment>
          )}
        </Row>
        <Space height={2} />
        <RecipientsField
          form={form}
          field={field}
          recipientType={'to'}
          recipientOptions={recipientOptions}
        />
        <Space height={12} />
      </Section>
      {isShowingCc && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={1}>
          <Label>Cc</Label>
          <Space height={2} />
          <RecipientsField
            form={form}
            field={field}
            recipientType={'cc'}
            recipientOptions={recipientOptions}
          />
          <Space height={12} />
        </Section>
      )}
      {isShowingBcc && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={2}>
          <Label>Bcc</Label>
          <Space height={2} />
          <RecipientsField
            form={form}
            field={field}
            recipientType={'bcc'}
            recipientOptions={recipientOptions}
          />
          <Space height={12} />
        </Section>
      )}
    </Container>
  );
};

export default EmailRecipientsSection;
