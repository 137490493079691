// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon, ScrollView} from '@supermove/components';
import {useResponsive, ResponsiveType, useModal, ToastType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, downloadFromUrl} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import FieldValue from '@shared/design/components/Field/FieldValue';
import IconButton from '@shared/design/components/IconButton';
import DeleteInventoryItemPhotoModal from 'modules/Inventory/Edit/components/DeleteInventoryItemPhotoModal';
import {
  FormFileType,
  ItemFilesFormType,
} from 'modules/Inventory/Edit/components/EditInventoryItemPhotosDrawer';

const LoadingIndicator = Styled.Loading`
`;

const Image = Styled.Image`
  height: 400px;
  background-color: ${colors.gray.border};
`;

const PopoutPanelContentContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  padding-top: ${({responsive}) => (responsive.desktop ? '24px' : '16px')};
`;

const PhotoDetailsFieldsContainer = Styled.View`
  flex: 1;
`;

const PhotoDetailsNavigationContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  justify-content: center;
  padding-vertical: ${({responsive}) => (responsive.desktop ? '24px' : '16px')};
`;

const NavigationControlsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const NavigationControlsBodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const PopoutPanelFooterContainer = Styled.View<{responsive: ResponsiveType}>`
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding: ${({responsive}) => (responsive.desktop ? '24px' : '16px')};
  background-color: ${colors.white};
`;

const PhotoDetailsFooterActionsContainer = Styled.View`
  width: 100%;
  flex-direction: row;
`;

const PhotoDetailsFields = ({
  popoutFormFile,
  responsive,
}: {
  popoutFormFile: FormFileType;
  responsive: ResponsiveType;
}) => {
  return (
    <ScrollView
      style={{flex: 1}}
      contentContainerStyle={{paddingHorizontal: responsive.desktop ? 24 : 16}}
    >
      <Image
        resizeMode={'contain'}
        source={{uri: popoutFormFile.downloadUrl}}
        accessibilityLabel={popoutFormFile.filename}
      />
      <Space height={16} />
      <FieldValue label={'File Name'} value={popoutFormFile.filename} isResponsive />
      <Space height={16} />
      <FieldValue label={'Uploaded By'} value={popoutFormFile.uploadedBy} isResponsive />
      <Space height={16} />
      <FieldValue
        label={'Uploaded At'}
        value={Datetime.convertToDisplayDatetime(
          popoutFormFile.uploadedAt,
          Datetime.DISPLAY_DATETIME,
        )}
        isResponsive
      />
    </ScrollView>
  );
};

const EditInventoryItemPhotoDetailsPopoutPanel = ({
  filteredFormFiles,
  popoutFormFileIndex,
  setPopoutFormFileIndex,
  handleClose,
  itemFilesForm,
  handleClosePopoutDrawer,
  handleUpdateInventoryRoomsForm,
  photoDeletedToast,
}: {
  filteredFormFiles: FormFileType[];
  popoutFormFileIndex: number;
  setPopoutFormFileIndex: (index: number) => void;
  handleClose: () => void;
  itemFilesForm: ItemFilesFormType;
  handleClosePopoutDrawer: () => void;
  handleUpdateInventoryRoomsForm: (updatedFormFiles: FormFileType[]) => void;
  photoDeletedToast: ToastType;
}) => {
  const responsive = useResponsive();
  const popoutFormFile = filteredFormFiles[popoutFormFileIndex];
  const isUploadingFile = !popoutFormFile.fileId;
  const deleteInventoryItemPhotoModal = useModal({name: 'Delete Inventory Item Photo Modal'});

  return (
    <DrawerWithClose.PopoutPanel title={'Preview'} handleClose={handleClose}>
      <PopoutPanelContentContainer responsive={responsive}>
        <PhotoDetailsFieldsContainer>
          {isUploadingFile ? (
            <LoadingIndicator />
          ) : (
            <PhotoDetailsFields popoutFormFile={popoutFormFile} responsive={responsive} />
          )}
        </PhotoDetailsFieldsContainer>
        <PhotoDetailsNavigationContainer responsive={responsive}>
          <NavigationControlsContainer>
            <IconButton
              source={Icon.AngleLeft}
              size={responsive.desktop ? 16 : 18}
              isDisabled={popoutFormFileIndex === 0}
              onPress={() => setPopoutFormFileIndex(popoutFormFileIndex - 1)}
            />
            <Space width={16} />
            <NavigationControlsBodyText>{`${popoutFormFileIndex + 1} of ${filteredFormFiles.length}`}</NavigationControlsBodyText>
            <Space width={16} />
            <IconButton
              source={Icon.AngleRight}
              isDisabled={popoutFormFileIndex + 1 === filteredFormFiles.length}
              size={responsive.desktop ? 16 : 18}
              onPress={() => setPopoutFormFileIndex(popoutFormFileIndex + 1)}
            />
          </NavigationControlsContainer>
        </PhotoDetailsNavigationContainer>
      </PopoutPanelContentContainer>
      {!responsive.desktop && (
        <PopoutPanelFooterContainer responsive={responsive}>
          <PhotoDetailsFooterActionsContainer>
            <SecondaryButton
              text={'Delete'}
              onPress={deleteInventoryItemPhotoModal.handleOpen}
              textColor={colors.red.warning}
              isWidthOfContainer
              style={{flex: 1}}
              isResponsive
            />
            <Space width={16} />
            <Button
              text={'Download'}
              onPress={() => {
                downloadFromUrl(popoutFormFile.downloadUrl);
              }}
              isWidthOfContainer
              style={{flex: 1}}
              isResponsive
            />
          </PhotoDetailsFooterActionsContainer>
          <DeleteInventoryItemPhotoModal
            deleteInventoryItemPhotoModal={deleteInventoryItemPhotoModal}
            formFile={popoutFormFile}
            itemFilesForm={itemFilesForm}
            handleClosePopoutDrawer={handleClosePopoutDrawer}
            handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
            photoDeletedToast={photoDeletedToast}
          />
        </PopoutPanelFooterContainer>
      )}
    </DrawerWithClose.PopoutPanel>
  );
};

export default EditInventoryItemPhotoDetailsPopoutPanel;
