// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Json} from '@supermove/utils';

// App
import AssignOfficeStaffActionForm, {
  AssignOfficeStaffActionFormType,
} from '@shared/modules/Workflow/AssignOfficeStaffActionForm';
import CreateAndSendConfirmationForProjectActionForm, {
  CreateAndSendConfirmationForProjectActionFormType,
} from '@shared/modules/Workflow/CreateAndSendConfirmationForProjectActionForm';
import CreateAndSendUserFlowRunActionForm, {
  CreateAndSendUserFlowRunActionFormType,
} from '@shared/modules/Workflow/CreateAndSendUserFlowRunActionForm';
import CreateTaskFromTemplateForm, {
  CreateTaskFromTemplateFormType,
} from '@shared/modules/Workflow/CreateTaskFromTemplateForm';
import DelayWorkflowActionForm, {
  DelayWorkflowActionFormType,
} from '@shared/modules/Workflow/DelayWorkflowActionForm';
import FindTaskFromPreviousStepActionForm, {
  FindTaskFromPreviousStepActionFormType,
} from '@shared/modules/Workflow/FindTaskFromPreviousStepActionForm';
import GetDataAndStopIfActionForm, {
  GetDataAndStopIfActionFormType,
  GetDataAndStopIfActionFormMutationType,
} from '@shared/modules/Workflow/GetDataAndStopIfActionForm';
import GetJobDataActionForm, {
  GetJobDataActionFormType,
} from '@shared/modules/Workflow/GetJobDataActionForm';
import GetProjectDataActionForm, {
  GetProjectDataActionFormType,
} from '@shared/modules/Workflow/GetProjectDataActionForm';
import InvokeWebhookActionForm, {
  InvokeWebhookActionFormType,
} from '@shared/modules/Workflow/InvokeWebhookActionForm';
import RemoveTagAssignmentActionForm, {
  RemoveTagAssignmentActionFormType,
} from '@shared/modules/Workflow/RemoveTagAssignmentActionForm';
import SendEmailFromTemplateActionForm, {
  SendEmailFromTemplateActionFormType,
} from '@shared/modules/Workflow/SendEmailFromTemplateActionForm';
import SendSmsFromTemplateActionForm, {
  SendSmsFromTemplateActionFormType,
} from '@shared/modules/Workflow/SendSmsFromTemplateActionForm';
import SendSmsV2FromTemplateActionForm, {
  SendSmsV2FromTemplateActionFormType,
} from '@shared/modules/Workflow/SendSmsV2FromTemplateActionForm';
import SendVirtualWalkthroughActionForm, {
  SendVirtualWalkthroughActionFormType,
} from '@shared/modules/Workflow/SendVirtualWalkthroughActionForm';
import StopIfActionForm, {StopIfActionFormType} from '@shared/modules/Workflow/StopIfActionForm';
import ToggleTaskPropertyActionForm, {
  ToggleTaskPropertyActionFormType,
} from '@shared/modules/Workflow/ToggleTaskPropertyActionForm';
import UpdateTagAssignmentActionForm, {
  UpdateTagAssignmentActionFormType,
} from '@shared/modules/Workflow/UpdateTagAssignmentActionForm';
import UpdateTaskActionForm, {
  UpdateTaskActionFormType,
} from '@shared/modules/Workflow/UpdateTaskActionForm';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import AssignToBranchActionForm, {
  AssignToBranchActionFormType,
} from '@shared/modules/Workflow/forms/WorkflowStepAction/AssignToBranchActionForm';

export interface WorkflowStepFormType {
  // Properties
  workflowStepActionKind: string;
  order: number;

  // Core Actions
  delayWorkflowActionForm: DelayWorkflowActionFormType | null;
  stopIfActionForm: StopIfActionFormType | null;
  getDataAndStopIfActionForm: GetDataAndStopIfActionFormType | null;

  // Project Actions
  assignToBranchActionForm: AssignToBranchActionFormType | null;
  assignOfficeStaffActionForm: AssignOfficeStaffActionFormType | null;

  // Task Actions
  createTaskFromTemplateForm: CreateTaskFromTemplateFormType | null;
  toggleTaskPropertyActionForm: ToggleTaskPropertyActionFormType | null;
  updateTaskActionForm: UpdateTaskActionFormType | null;

  // Email Actions
  sendEmailFromTemplateActionForm: SendEmailFromTemplateActionFormType | null;
  createAndSendConfirmationForProjectActionForm: CreateAndSendConfirmationForProjectActionFormType | null;
  createAndSendUserFlowRunActionForm: CreateAndSendUserFlowRunActionFormType | null;

  // Query Actions
  findTaskFromPreviousStepActionForm: FindTaskFromPreviousStepActionFormType | null;
  getProjectDataActionForm: GetProjectDataActionFormType | null;
  getJobDataActionForm: GetJobDataActionFormType | null;

  // SMS Actions
  sendSmsFromTemplateActionForm: SendSmsFromTemplateActionFormType | null;
  sendSmsV2FromTemplateActionForm: SendSmsV2FromTemplateActionFormType | null;
  sendVirtualWalkthroughActionForm: SendVirtualWalkthroughActionFormType | null;

  invokeWebhookActionForm: InvokeWebhookActionFormType | null;

  // Tag Actions
  updateTagAssignmentActionForm: UpdateTagAssignmentActionFormType | null;
  removeTagAssignmentActionForm: RemoveTagAssignmentActionFormType | null;

  // Private
  slideIndex: number;
}
export interface WorkflowStepFormAsMutation {
  // Properties
  workflowStepActionKind: string;
  order: number;

  // Core Actions
  delayWorkflowActionForm: DelayWorkflowActionFormType | null;
  stopIfActionForm: StopIfActionFormType | null;
  getDataAndStopIfActionForm: GetDataAndStopIfActionFormMutationType | null;

  // Project Actions
  assignToBranchActionForm: AssignToBranchActionFormType | null;
  assignOfficeStaffActionForm: AssignOfficeStaffActionFormType | null;

  // Task Actions
  createTaskFromTemplateForm: CreateTaskFromTemplateFormType | null;
  toggleTaskPropertyActionForm: ToggleTaskPropertyActionFormType | null;
  updateTaskActionForm: UpdateTaskActionFormType | null;

  // Email Actions
  sendEmailFromTemplateActionForm: SendEmailFromTemplateActionFormType | null;
  createAndSendConfirmationForProjectActionForm: CreateAndSendConfirmationForProjectActionFormType | null;
  createAndSendUserFlowRunActionForm: CreateAndSendUserFlowRunActionFormType | null;

  // Query Actions
  findTaskFromPreviousStepActionForm: FindTaskFromPreviousStepActionFormType | null;
  getProjectDataActionForm: GetProjectDataActionFormType | null;
  getJobDataActionForm: GetJobDataActionFormType | null;

  // SMS Actions
  sendSmsFromTemplateActionForm: SendSmsFromTemplateActionFormType | null;
  sendSmsV2FromTemplateActionForm: SendSmsV2FromTemplateActionFormType | null;
  sendVirtualWalkthroughActionForm: SendVirtualWalkthroughActionFormType | null;

  invokeWebhookActionForm: InvokeWebhookActionFormType | null;

  // Tag Actions
  updateTagAssignmentActionForm: UpdateTagAssignmentActionFormType | null;
  removeTagAssignmentActionForm: RemoveTagAssignmentActionFormType | null;
}

export interface WorkflowStepType {
  id: number;
  workflowStepActionKind: string;
  inputMetadata: string;
  order: number;
}

const generateWorkflowStepDropdownValue = ({
  workflowStepForm,
  accessPropertySuffix,
  isFormula,
}: {
  workflowStepForm: WorkflowStepFormType;
  accessPropertySuffix: string;
  isFormula: boolean;
}): string => {
  const actionKind = workflowStepForm.workflowStepActionKind;
  const {actionForm, graphqlOperationName} = _.get(WorkflowStepActionKind.ATTRIBUTES, actionKind);
  const workflowRunStepUuid = _.get(workflowStepForm, `${actionForm}.workflowRunStepUuid`);

  switch (actionKind) {
    case WorkflowStepActionKind.CREATE_TASK:
    case WorkflowStepActionKind.UPDATE_TASK:
    case WorkflowStepActionKind.COMPLETE_TASK:
    case WorkflowStepActionKind.ARCHIVE_TASK:
      return `${
        !isFormula ? '$$$.' : ''
      }workflowRunSteps["${workflowRunStepUuid}"].outputMetadata.data.${graphqlOperationName}.task${accessPropertySuffix}`;
    case WorkflowStepActionKind.FIND_TASK_FROM_PREVIOUS_STEP:
      return `${
        !isFormula ? '$$$.' : ''
      }workflowRunSteps["${workflowRunStepUuid}"].outputMetadata.data.${graphqlOperationName}${accessPropertySuffix}`;
    case WorkflowStepActionKind.GET_JOB_DATA:
    case WorkflowStepActionKind.GET_PROJECT_DATA:
      return `${
        !isFormula ? '$$$.' : ''
      }workflowRunSteps["${workflowRunStepUuid}"].outputMetadata.data.${graphqlOperationName}${accessPropertySuffix}`;
    default:
      return '';
  }
};

const generateWorkflowStepUuidDropdownValue = ({
  workflowStepForm,
  accessPropertySuffix,
  isFormula,
}: {
  workflowStepForm: WorkflowStepFormType;
  accessPropertySuffix: string;
  isFormula: boolean;
}): string => {
  const actionKind = workflowStepForm.workflowStepActionKind;
  const {actionForm} = _.get(WorkflowStepActionKind.ATTRIBUTES, actionKind);
  const workflowRunStepUuid = _.get(workflowStepForm, `${actionForm}.workflowRunStepUuid`);

  switch (actionKind) {
    case WorkflowStepActionKind.CREATE_TASK:
    case WorkflowStepActionKind.UPDATE_TASK:
    case WorkflowStepActionKind.COMPLETE_TASK:
    case WorkflowStepActionKind.ARCHIVE_TASK:
    case WorkflowStepActionKind.FIND_TASK_FROM_PREVIOUS_STEP:
      return workflowRunStepUuid;
    default:
      return '';
  }
};

const _new = ({
  order,
  workflowStepActionKind,
}: {
  order: number;
  workflowStepActionKind: string;
}): WorkflowStepFormType => ({
  workflowStepActionKind,
  order,
  delayWorkflowActionForm: null,
  stopIfActionForm: null,
  getDataAndStopIfActionForm: null,
  assignToBranchActionForm: null,
  assignOfficeStaffActionForm: null,
  createTaskFromTemplateForm: null,
  toggleTaskPropertyActionForm: null,
  updateTaskActionForm: null,
  sendEmailFromTemplateActionForm: null,
  createAndSendConfirmationForProjectActionForm: null,
  createAndSendUserFlowRunActionForm: null,
  findTaskFromPreviousStepActionForm: null,
  getJobDataActionForm: null,
  getProjectDataActionForm: null,
  sendSmsFromTemplateActionForm: null,
  sendSmsV2FromTemplateActionForm: null,
  sendVirtualWalkthroughActionForm: null,
  invokeWebhookActionForm: null,
  updateTagAssignmentActionForm: null,
  removeTagAssignmentActionForm: null,

  // Private
  slideIndex: 0,
});

const edit = withFragment(
  (workflowStep: WorkflowStepType): WorkflowStepFormType => {
    const inputMetadataParsed = Json.toCamelCaseKeys(Json.parse(workflowStep.inputMetadata)) as any;
    return {
      workflowStepActionKind: workflowStep.workflowStepActionKind,
      order: workflowStep.order,

      // Core Actions
      delayWorkflowActionForm:
        inputMetadataParsed.delayWorkflowActionForm &&
        DelayWorkflowActionForm.edit(inputMetadataParsed.delayWorkflowActionForm),
      stopIfActionForm:
        inputMetadataParsed.stopIfActionForm &&
        StopIfActionForm.edit(inputMetadataParsed.stopIfActionForm),
      getDataAndStopIfActionForm:
        inputMetadataParsed.getDataAndStopIfActionForm &&
        GetDataAndStopIfActionForm.edit(inputMetadataParsed.getDataAndStopIfActionForm),

      // Project Actions
      assignToBranchActionForm:
        inputMetadataParsed.assignToBranchActionForm &&
        AssignToBranchActionForm.edit(inputMetadataParsed.assignToBranchActionForm),
      assignOfficeStaffActionForm:
        inputMetadataParsed.assignOfficeStaffActionForm &&
        AssignOfficeStaffActionForm.edit(inputMetadataParsed.assignOfficeStaffActionForm),

      // Task Actions
      createTaskFromTemplateForm:
        inputMetadataParsed.createTaskFromTemplateForm &&
        CreateTaskFromTemplateForm.edit(inputMetadataParsed.createTaskFromTemplateForm),
      toggleTaskPropertyActionForm:
        inputMetadataParsed.toggleTaskPropertyActionForm &&
        ToggleTaskPropertyActionForm.edit(inputMetadataParsed.toggleTaskPropertyActionForm),
      updateTaskActionForm:
        inputMetadataParsed.updateTaskActionForm &&
        UpdateTaskActionForm.edit(inputMetadataParsed.updateTaskActionForm),

      // Email Actions
      sendEmailFromTemplateActionForm:
        inputMetadataParsed.sendEmailFromTemplateActionForm &&
        SendEmailFromTemplateActionForm.edit(inputMetadataParsed.sendEmailFromTemplateActionForm),
      createAndSendConfirmationForProjectActionForm:
        inputMetadataParsed.createAndSendConfirmationForProjectActionForm &&
        SendEmailFromTemplateActionForm.edit(
          inputMetadataParsed.createAndSendConfirmationForProjectActionForm,
        ),
      createAndSendUserFlowRunActionForm:
        inputMetadataParsed.createAndSendUserFlowRunActionForm &&
        CreateAndSendUserFlowRunActionForm.edit(
          inputMetadataParsed.createAndSendUserFlowRunActionForm,
        ),

      // Query Actions
      findTaskFromPreviousStepActionForm:
        inputMetadataParsed.findTaskFromPreviousStepActionForm &&
        FindTaskFromPreviousStepActionForm.edit(
          inputMetadataParsed.findTaskFromPreviousStepActionForm,
        ),
      getProjectDataActionForm:
        inputMetadataParsed.getProjectDataActionForm &&
        GetProjectDataActionForm.edit(inputMetadataParsed.getProjectDataActionForm),
      getJobDataActionForm:
        inputMetadataParsed.getJobDataActionForm &&
        GetJobDataActionForm.edit(inputMetadataParsed.getJobDataActionForm),

      // SMS Actions
      sendSmsFromTemplateActionForm:
        inputMetadataParsed.sendSmsFromTemplateActionForm &&
        SendSmsFromTemplateActionForm.edit(inputMetadataParsed.sendSmsFromTemplateActionForm),

      sendSmsV2FromTemplateActionForm:
        inputMetadataParsed.sendSmsV2FromTemplateActionForm &&
        SendSmsV2FromTemplateActionForm.edit(inputMetadataParsed.sendSmsV2FromTemplateActionForm),

      sendVirtualWalkthroughActionForm:
        inputMetadataParsed.sendVirtualWalkthroughActionForm &&
        SendVirtualWalkthroughActionForm.edit(inputMetadataParsed.sendVirtualWalkthroughActionForm),

      invokeWebhookActionForm:
        inputMetadataParsed.invokeWebhookActionForm &&
        InvokeWebhookActionForm.edit(inputMetadataParsed.invokeWebhookActionForm),

      // Tag Actions
      updateTagAssignmentActionForm:
        inputMetadataParsed.updateTagAssignmentActionForm &&
        UpdateTagAssignmentActionForm.edit(inputMetadataParsed.updateTagAssignmentActionForm),

      removeTagAssignmentActionForm:
        inputMetadataParsed.removeTagAssignmentActionForm &&
        RemoveTagAssignmentActionForm.edit(inputMetadataParsed.removeTagAssignmentActionForm),
      // Private
      slideIndex: 2,
    };
  },
  gql`
    fragment WorkflowStepForm_edit on WorkflowStep {
      id
      workflowStepActionKind
      inputMetadata
      order
    }
  `,
);

const toForm = (workflowStepForm: WorkflowStepFormType): WorkflowStepFormType => ({
  workflowStepActionKind: workflowStepForm.workflowStepActionKind,
  order: workflowStepForm.order,
  delayWorkflowActionForm:
    workflowStepForm.delayWorkflowActionForm &&
    DelayWorkflowActionForm.toForm(workflowStepForm.delayWorkflowActionForm),
  stopIfActionForm:
    workflowStepForm.stopIfActionForm && StopIfActionForm.toForm(workflowStepForm.stopIfActionForm),
  getDataAndStopIfActionForm:
    workflowStepForm.getDataAndStopIfActionForm &&
    GetDataAndStopIfActionForm.toForm(workflowStepForm.getDataAndStopIfActionForm),
  assignToBranchActionForm:
    workflowStepForm.assignToBranchActionForm &&
    AssignToBranchActionForm.toForm(workflowStepForm.assignToBranchActionForm),
  assignOfficeStaffActionForm:
    workflowStepForm.assignOfficeStaffActionForm &&
    AssignOfficeStaffActionForm.toForm(workflowStepForm.assignOfficeStaffActionForm),
  createTaskFromTemplateForm:
    workflowStepForm.createTaskFromTemplateForm &&
    CreateTaskFromTemplateForm.toForm(workflowStepForm.createTaskFromTemplateForm),
  toggleTaskPropertyActionForm:
    workflowStepForm.toggleTaskPropertyActionForm &&
    ToggleTaskPropertyActionForm.toForm(workflowStepForm.toggleTaskPropertyActionForm),
  updateTaskActionForm:
    workflowStepForm.updateTaskActionForm &&
    UpdateTaskActionForm.toForm(workflowStepForm.updateTaskActionForm),
  sendEmailFromTemplateActionForm:
    workflowStepForm.sendEmailFromTemplateActionForm &&
    SendEmailFromTemplateActionForm.toForm(workflowStepForm.sendEmailFromTemplateActionForm),
  createAndSendConfirmationForProjectActionForm:
    workflowStepForm.createAndSendConfirmationForProjectActionForm &&
    CreateAndSendConfirmationForProjectActionForm.toForm(
      workflowStepForm.createAndSendConfirmationForProjectActionForm,
    ),
  createAndSendUserFlowRunActionForm:
    workflowStepForm.createAndSendUserFlowRunActionForm &&
    CreateAndSendUserFlowRunActionForm.toForm(workflowStepForm.createAndSendUserFlowRunActionForm),
  findTaskFromPreviousStepActionForm:
    workflowStepForm.findTaskFromPreviousStepActionForm &&
    FindTaskFromPreviousStepActionForm.toForm(workflowStepForm.findTaskFromPreviousStepActionForm),
  getProjectDataActionForm:
    workflowStepForm.getProjectDataActionForm &&
    GetProjectDataActionForm.toForm(workflowStepForm.getProjectDataActionForm),
  getJobDataActionForm:
    workflowStepForm.getJobDataActionForm &&
    GetJobDataActionForm.toForm(workflowStepForm.getJobDataActionForm),
  sendSmsFromTemplateActionForm:
    workflowStepForm.sendSmsFromTemplateActionForm &&
    SendSmsFromTemplateActionForm.toForm(workflowStepForm.sendSmsFromTemplateActionForm),
  sendSmsV2FromTemplateActionForm:
    workflowStepForm.sendSmsV2FromTemplateActionForm &&
    SendSmsV2FromTemplateActionForm.toForm(workflowStepForm.sendSmsV2FromTemplateActionForm),
  sendVirtualWalkthroughActionForm:
    workflowStepForm.sendVirtualWalkthroughActionForm &&
    SendVirtualWalkthroughActionForm.toForm(workflowStepForm.sendVirtualWalkthroughActionForm),
  invokeWebhookActionForm:
    workflowStepForm.invokeWebhookActionForm &&
    InvokeWebhookActionForm.toForm(workflowStepForm.invokeWebhookActionForm),
  updateTagAssignmentActionForm:
    workflowStepForm.updateTagAssignmentActionForm &&
    UpdateTagAssignmentActionForm.toForm(workflowStepForm.updateTagAssignmentActionForm),
  removeTagAssignmentActionForm:
    workflowStepForm.removeTagAssignmentActionForm &&
    RemoveTagAssignmentActionForm.toForm(workflowStepForm.removeTagAssignmentActionForm),

  // Private
  slideIndex: workflowStepForm.slideIndex,
});

const toMutation = (workflowStepForm: WorkflowStepFormType): WorkflowStepFormAsMutation => ({
  workflowStepActionKind: workflowStepForm.workflowStepActionKind,
  order: workflowStepForm.order,
  delayWorkflowActionForm:
    workflowStepForm.delayWorkflowActionForm &&
    DelayWorkflowActionForm.toMutation(workflowStepForm.delayWorkflowActionForm),
  stopIfActionForm:
    workflowStepForm.stopIfActionForm &&
    StopIfActionForm.toMutation(workflowStepForm.stopIfActionForm),
  getDataAndStopIfActionForm:
    workflowStepForm.getDataAndStopIfActionForm &&
    GetDataAndStopIfActionForm.toMutation(workflowStepForm.getDataAndStopIfActionForm),
  assignToBranchActionForm:
    workflowStepForm.assignToBranchActionForm &&
    AssignToBranchActionForm.toMutation(workflowStepForm.assignToBranchActionForm),
  assignOfficeStaffActionForm:
    workflowStepForm.assignOfficeStaffActionForm &&
    AssignOfficeStaffActionForm.toMutation(workflowStepForm.assignOfficeStaffActionForm),
  createTaskFromTemplateForm:
    workflowStepForm.createTaskFromTemplateForm &&
    CreateTaskFromTemplateForm.toMutation(workflowStepForm.createTaskFromTemplateForm),
  toggleTaskPropertyActionForm:
    workflowStepForm.toggleTaskPropertyActionForm &&
    ToggleTaskPropertyActionForm.toMutation(workflowStepForm.toggleTaskPropertyActionForm),
  updateTaskActionForm:
    workflowStepForm.updateTaskActionForm &&
    UpdateTaskActionForm.toMutation(workflowStepForm.updateTaskActionForm),
  sendEmailFromTemplateActionForm:
    workflowStepForm.sendEmailFromTemplateActionForm &&
    SendEmailFromTemplateActionForm.toMutation(workflowStepForm.sendEmailFromTemplateActionForm),
  createAndSendConfirmationForProjectActionForm:
    workflowStepForm.createAndSendConfirmationForProjectActionForm &&
    CreateAndSendConfirmationForProjectActionForm.toMutation(
      workflowStepForm.createAndSendConfirmationForProjectActionForm,
    ),
  createAndSendUserFlowRunActionForm:
    workflowStepForm.createAndSendUserFlowRunActionForm &&
    CreateAndSendUserFlowRunActionForm.toMutation(
      workflowStepForm.createAndSendUserFlowRunActionForm,
    ),
  findTaskFromPreviousStepActionForm:
    workflowStepForm.findTaskFromPreviousStepActionForm &&
    FindTaskFromPreviousStepActionForm.toMutation(
      workflowStepForm.findTaskFromPreviousStepActionForm,
    ),
  getJobDataActionForm:
    workflowStepForm.getJobDataActionForm &&
    GetJobDataActionForm.toMutation(workflowStepForm.getJobDataActionForm),
  getProjectDataActionForm:
    workflowStepForm.getProjectDataActionForm &&
    GetProjectDataActionForm.toMutation(workflowStepForm.getProjectDataActionForm),
  sendSmsFromTemplateActionForm:
    workflowStepForm.sendSmsFromTemplateActionForm &&
    SendSmsFromTemplateActionForm.toMutation(workflowStepForm.sendSmsFromTemplateActionForm),
  sendSmsV2FromTemplateActionForm:
    workflowStepForm.sendSmsV2FromTemplateActionForm &&
    SendSmsV2FromTemplateActionForm.toMutation(workflowStepForm.sendSmsV2FromTemplateActionForm),
  sendVirtualWalkthroughActionForm:
    workflowStepForm.sendVirtualWalkthroughActionForm &&
    SendVirtualWalkthroughActionForm.toMutation(workflowStepForm.sendVirtualWalkthroughActionForm),
  invokeWebhookActionForm:
    workflowStepForm.invokeWebhookActionForm &&
    InvokeWebhookActionForm.toMutation(workflowStepForm.invokeWebhookActionForm),
  updateTagAssignmentActionForm:
    workflowStepForm.updateTagAssignmentActionForm &&
    UpdateTagAssignmentActionForm.toMutation(workflowStepForm.updateTagAssignmentActionForm),
  removeTagAssignmentActionForm:
    workflowStepForm.removeTagAssignmentActionForm &&
    RemoveTagAssignmentActionForm.toMutation(workflowStepForm.removeTagAssignmentActionForm),
});

const WorkflowStepForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  generateWorkflowStepDropdownValue,
  generateWorkflowStepUuidDropdownValue,
};

export default WorkflowStepForm;
