// Libararies
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useDebouncedCallback, useResponsive, useState, ResponsiveType} from '@supermove/hooks';

// App
import SearchBar from '@shared/design/components/SearchBar';
import OrganizationSettingsDocumentFlowsFilter from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFilter';
import {OrganizationSettingsDocumentFlowsUrlFilters} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFiltersType';

const HeaderRow = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
`;

const DocumentsSettingsDocumentFlowsPageHeader = ({
  urlFilters,
}: {
  urlFilters: OrganizationSettingsDocumentFlowsUrlFilters;
}) => {
  const responsive = useResponsive();
  const [searchQuery, setSearchQuery] = useState(urlFilters.get('searchQuery'));

  const debouncedFilterUpdate = useDebouncedCallback(
    (query: string) => urlFilters.handleUpdate({searchQuery: query}),
    300,
  );
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    debouncedFilterUpdate(searchQuery);
  };

  return (
    <HeaderRow responsive={responsive}>
      <SearchBar
        containerStyle={{flex: 1}}
        style={{width: '100%'}}
        defaultValue={''}
        placeholder={`Search${responsive.desktop ? ' by flow name, description, project types' : ''}`}
        onChangeText={handleSearch}
        valueOverride={searchQuery}
        isClearable
        isResponsive
      />
      <Space width={8} />
      <OrganizationSettingsDocumentFlowsFilter
        urlFilters={urlFilters}
        handleApply={urlFilters.handleUpdate}
      />
    </HeaderRow>
  );
};
export default DocumentsSettingsDocumentFlowsPageHeader;
