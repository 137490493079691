// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import VariableSectionsCollapsedStates from '@shared/modules/Variable/enums/VariableSectionsCollapsedStates';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import BillingValueFields from 'modules/Project/Billing/components/BillingValueFields';
import ValueFields from 'modules/Project/Billing/components/ValueFields';

const CollapsibleContentContainer = Styled.View`
    flex: 1;
`;

const PaddingContainer = Styled.View`
  padding-horizontal: 16px;
  padding-bottom: 16px;
  padding-top: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 16 : 4)}px;
`;

const Divider = Styled.View`
  height: 4px;
  width: 100%;
  background-color: ${colors.gray.border};
`;

const SkeletonLoaderContainer = Styled.View`
  padding-horizontal: 16px;
`;

const MobileLoadingComponent = ({isFirst}: any) => {
  return (
    <React.Fragment>
      {!isFirst && <Divider />}
      <Space height={24} />
      <SkeletonLoaderContainer>
        <SkeletonLoader width={200} height={SkeletonLoader.HEIGHT.Heading2Text} />
      </SkeletonLoaderContainer>
      <Space height={12} />
    </React.Fragment>
  );
};

const ProjectBillingValuesSectionContent = ({
  variableSection,
  projectUuid,
  isCollapsedSections,
  setIsCollapsedSections,
  index,
  projectId,
  updateValuesObject,
  responsive,
  isDisabledValuesHidden,
}: any) => {
  const {loading, data} = useQuery(ProjectBillingValuesSectionContent.query, {
    fetchPolicy: 'network-only',
    variables: {
      projectUuid,
      projectTypeVariableSectionId: variableSection.id,
    },
  });

  return (
    <CollapsibleContent
      style={{
        backgroundColor: responsive.desktop ? colors.white : colors.gray.background,
        zIndex: 1000 - index,
      }}
      title={variableSection.name}
      isCollapsedOverride={VariableSectionsCollapsedStates.getIsSectionCollapsed({
        isCollapsedSections,
        sectionId: variableSection.id,
        projectOrJobId: projectId,
      })}
      handlePress={(isCollapsed) => {
        VariableSectionsCollapsedStates.toggleSectionCollapsed({
          isCollapsedSections,
          sectionId: variableSection.id,
          setIsCollapsedSections,
          isCollapsed,
          projectOrJobId: projectId,
        });
      }}
    >
      <PaddingContainer responsive={responsive}>
        <Loading loading={loading} as={ValueFields.SkeletonLoader}>
          {() => {
            return (
              <BillingValueFields
                orderedValues={data.project.orderedValues}
                updateValuesObject={updateValuesObject}
                isDisabledValuesHidden={isDisabledValuesHidden}
              />
            );
          }}
        </Loading>
      </PaddingContainer>
    </CollapsibleContent>
  );
};

const ProjectBillingValuesSection = ({
  variableSections,
  projectUuid,
  isCollapsedSections,
  setIsCollapsedSections,
  index,
  projectId,
  updateValuesObject,
  isDisabledValuesHidden,
}: any) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <CollapsibleContentContainer index={index}>
      {variableSections.map((variableSection: any, variableSectionIndex: any) => {
        return (
          <React.Fragment key={variableSection.id}>
            {!responsive.desktop && (variableSectionIndex > 0 || index > 0) && <Divider />}
            <Space height={12} />
            <ProjectBillingValuesSectionContent
              variableSection={variableSection}
              projectUuid={projectUuid}
              isCollapsedSections={isCollapsedSections}
              setIsCollapsedSections={setIsCollapsedSections}
              index={variableSectionIndex}
              projectId={projectId}
              updateValuesObject={updateValuesObject}
              responsive={responsive}
              isDisabledValuesHidden={isDisabledValuesHidden}
            />
          </React.Fragment>
        );
      })}
    </CollapsibleContentContainer>
  );
};

ProjectBillingValuesSection.SkeletonLoaderMobile = MobileLoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingValuesSectionContent.query = gql`
${BillingValueFields.fragment}
query ProjectBillingValuesSection($projectUuid: String!, $projectTypeVariableSectionId: Int!) {
  ${gql.query}
  project(uuid: $projectUuid){
    id
    orderedValues(projectTypeVariableSectionId: $projectTypeVariableSectionId){
      id
      ...BillingValueFields
    }
  }
}
`;

ProjectBillingValuesSection.fragment = gql`
  fragment ProjectBillingValuesSection on ProjectTypeVariableSection {
    id
    name
  }
`;

export default ProjectBillingValuesSection;
