// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {ResponsiveType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';

const TabsContainer = Styled.View<{responsive: ResponsiveType}>`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
`;

const getTabDefinitions = () => [
  {
    label: 'Libraries',
    value: `/settings/billing/billing-libraries`,
  },
  {
    label: 'Hourly Rates',
    value: `/settings/billing/hourly-rates`,
  },
  {
    label: 'Formulas',
    value: `/settings/billing/formulas`,
  },
  {
    label: 'Variables',
    value: `/settings/billing/variables`,
  },
  {
    label: 'Tables',
    value: `/settings/billing/tables`,
  },
];

const BillingSettingsPageNavigationTabs = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const tabs = getTabDefinitions();
  const activeTabIndex = _.findIndex(tabs, {value: navigator.location.pathname});
  return (
    <TabsContainer responsive={responsive}>
      <Tabs
        tabs={tabs}
        handlePressTab={({value}) => navigator.push(value)}
        activeTabIndex={activeTabIndex}
        scrollViewStyle={{paddingLeft: responsive.desktop ? 24 : 12}}
      />
    </TabsContainer>
  );
};

export default BillingSettingsPageNavigationTabs;
