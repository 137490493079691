// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useModal,
  useNavigationDOM,
  usePagination,
  useQuery,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {OrganizationModel, RateTableModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';

const Container = Styled.View`
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const HeaderContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 12)}px;
`;

const TableContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 0)}px;
`;

const getColumnDefinitions = ({navigator}: {navigator: SupermoveNavigatorType}) => [
  {
    flex: 3,
    headerLabel: 'Name',
    cellText: (rateTable: RateTableModel) => rateTable.name,
    mobileOptions: {
      isInHeader: true,
    },
    secondary: {
      headerLabel: 'Description',
      cellText: (rateTable: RateTableModel) => rateTable.description,
    },
  },
  {
    flex: 1,
    headerLabel: 'Last Updated',
    cellText: (rateTable: RateTableModel) => Datetime.convertToDisplayDatetime(rateTable.updatedAt),
    secondary: {
      cellText: (rateTable: RateTableModel) => `By ${rateTable.updatedBy.fullName}`,
    },
  },
  {
    minWidth: 60,
    headerLabel: 'Actions',
    actions: (rateTable: RateTableModel) => {
      return [
        {
          text: 'Edit',
          desktopIcon: Icon.Pen,
          onPress: ({handleOpen}: any) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {
              name: 'Edit Rate Table Caution Modal',
            },
            renderComponent: ({
              isOpen,
              handleClose,
            }: {
              isOpen: boolean;
              handleClose: () => void;
            }) => {
              return (
                <CautionModal
                  isOpen={isOpen}
                  handlePrimaryAction={() =>
                    navigator.push(`/settings/billing/hourly-rates/${rateTable.uuid}/edit`)
                  }
                  handleSecondaryAction={handleClose}
                  primaryActionText={'Confirm'}
                  secondaryActionText={'Cancel'}
                  title={`Edit '${rateTable.name}'?`}
                  message={
                    'Changing these hourly rates will apply to projects when they are next saved. Consider creating new rates or duplicating existing ones instead.'
                  }
                />
              );
            },
          },
        },
        {
          text: 'Duplicate',
          onPress: () => {
            navigator.push(`/settings/billing/hourly-rates/create?rateTableUuid=${rateTable.uuid}`);
          },
        },
      ];
    },
  },
];

const OrganizationSettingsBillingHourlyRatesPageContent = ({
  organization,
  pagination,
}: {
  organization: OrganizationModel;
  pagination: ReturnType<typeof usePagination>;
}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const {hourlyRateTablesPaginatedList} = organization;
  const {hourlyRateTables} = hourlyRateTablesPaginatedList;
  return (
    <Container style={{width: responsive.desktop ? '800px' : 'auto'}}>
      <HeaderContainer responsive={responsive}>
        <Container style={{flex: 1}}>
          <PageHeading responsive={responsive}>Hourly Rates</PageHeading>
          <Space height={16} />
          <PageDescription responsive={responsive}>
            Create and manage hourly rates to be used in formulas.
          </PageDescription>
        </Container>
        <Space width={16} />
        <Button
          iconLeft={Icon.Plus}
          text={'Create Hourly Rate'}
          onPress={() => {
            navigator.push('/settings/billing/hourly-rates/create');
          }}
        />
      </HeaderContainer>
      <Space height={24} />
      <TableContainer responsive={responsive}>
        <Table
          columnDefinitions={getColumnDefinitions({navigator})}
          items={hourlyRateTables}
          itemKey={'id'}
          emptyStateText={'To get started, create hourly rates.'}
          hasBorder={responsive.desktop}
          containerStyle={{
            borderColor: colors.gray.border,
            borderTopWidth: 1,
            borderBottomWidth: 1,
          }}
        />
        <Space height={16} />
        <PaginationBar pagination={pagination} />
      </TableContainer>
    </Container>
  );
};

const OrganizationSettingsBillingHourlyRatesPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const {loading, data, refetch} = useQuery(OrganizationSettingsBillingHourlyRatesPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
    },
  });
  const pagination = usePagination({
    currentPage,
    paginationMetadata:
      data?.viewer?.viewingOrganization?.hourlyRateTablesPaginatedList?.paginationMetadata || {},
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  return (
    <OrganizationSettingsBillingSettingsPage
      contentContainerStyle={{paddingLeft: 0, paddingRight: 0}}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <OrganizationSettingsBillingHourlyRatesPageContent
            organization={data.viewer.viewingOrganization}
            pagination={pagination}
          />
        )}
      </Loading>
    </OrganizationSettingsBillingSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingHourlyRatesPage.query = gql`
  ${usePagination.fragment}
  query OrganizationSettingsBillingHourlyRatesPage(
    $pagination: PaginationInput!,
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        hourlyRateTablesPaginatedList(
          pagination: $pagination,
        ) {
          hourlyRateTables: results {
            id
            uuid
            name
            description
            updatedAt
            updatedBy {
              id
              fullName
            }
          }
          paginationMetadata {
            totalResults
            ...usePagination
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsBillingHourlyRatesPage;
