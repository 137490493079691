// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {Avatar} from 'modules/App/components';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const UserInfo = Styled.View`
  flex: 1;
  margin-left: 10px;
`;

const OrganizationName = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const UserName = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const JobRequestBookedBy = ({user}: any) => (
  <Container>
    <Avatar text={User.getInitials(user)} size={40} />
    <UserInfo>
      <OrganizationName numberOfLines={1}>
        {`Your contact at ${user.organization.businessName || user.organization.name} is:`}
      </OrganizationName>
      <UserName numberOfLines={1}>{User.getFullName(user)}</UserName>
    </UserInfo>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestBookedBy.fragment = gql`
  fragment JobRequestBookedBy on User {
    id
    firstName
    lastName
    organization {
      id
      name
      businessName
    }
  }
`;

export default JobRequestBookedBy;
