// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {PopoverType, ResponsiveType} from '@supermove/hooks';
import {EmailModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Sheet from '@shared/design/components/Sheet';
import {parseJsonRecipients} from 'modules/Communication/Email/utils/parseJsonRecipients';

const PopoverBody = Styled.View<{responsive: ResponsiveType}>`
    width: 400px;
    border-radius: 5px;
    border: ${({responsive}) => (responsive.mobile ? '0px' : `1px solid ${colors.gray.border}`)};
    background-color: ${colors.white};
    overflow: visible;
    padding: 24px;
    padding-bottom: 8px;
`;

const HeaderRow = Styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Title = Styled.Text`
    ${Typography.Responsive.Heading2}
`;

const Label = Styled.Text`
    ${Typography.Responsive.Label}
    color: ${colors.gray.secondary};
`;

const Value = Styled.Text`
    ${Typography.Responsive.Body}
`;

interface EmailMetadataPopoverProps {
  email: EmailModel;
  responsive: ResponsiveType;
  popover: PopoverType;
}

const LabelValue = ({
  label,
  value,
  responsive,
}: {
  label: string;
  value: string;
  responsive: ResponsiveType;
}) => {
  return (
    <React.Fragment>
      <Label responsive={responsive}>{label}</Label>
      <Space height={4} />
      <Value responsive={responsive}>{value}</Value>
      <Space height={16} />
    </React.Fragment>
  );
};

const EmailMetadataPopoverContent = ({
  email,
  responsive,
  popover,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
  popover: PopoverType;
}) => {
  const toEmailRecipientsJson = parseJsonRecipients(email.toEmailRecipientsJson).filter(Boolean);
  const ccEmailRecipientsJson = parseJsonRecipients(email.ccEmailRecipientsJson).filter(Boolean);
  const bccEmailRecipientsJson = parseJsonRecipients(email.bccEmailRecipientsJson).filter(Boolean);
  return (
    <PopoverBody responsive={responsive}>
      {responsive.mobile ? null : (
        <React.Fragment>
          <HeaderRow>
            <Title>Details</Title>
            <TertiaryButton onPress={popover.handleClose}>
              <Icon source={Icon.Xmark} color={colors.gray.secondary} />
            </TertiaryButton>
          </HeaderRow>
          <Space height={16} />
        </React.Fragment>
      )}
      <LabelValue
        responsive={responsive}
        label={'From'}
        value={`${email.fromName} <${email.fromEmail}>`}
      />
      {toEmailRecipientsJson?.length ? (
        <LabelValue responsive={responsive} label={'To'} value={toEmailRecipientsJson.join('; ')} />
      ) : null}
      {ccEmailRecipientsJson?.length ? (
        <LabelValue responsive={responsive} label={'Cc'} value={ccEmailRecipientsJson.join('; ')} />
      ) : null}
      {bccEmailRecipientsJson?.length ? (
        <LabelValue
          responsive={responsive}
          label={'Bcc'}
          value={bccEmailRecipientsJson.join('; ')}
        />
      ) : null}
      <LabelValue
        responsive={responsive}
        label={'Date'}
        value={Datetime.convertToDisplayDatetime(email.sentAt || email.updatedAt)}
      />
      {email.messageId ? (
        <LabelValue responsive={responsive} label={'Message ID'} value={email.messageId} />
      ) : null}
    </PopoverBody>
  );
};

const EmailMetadataPopover = ({email, responsive, popover}: EmailMetadataPopoverProps) => {
  return responsive.desktop ? (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <EmailMetadataPopoverContent email={email} responsive={responsive} popover={popover} />
    </Popover>
  ) : (
    <Sheet.PreventPropagationContainer>
      <Sheet
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        handleGoBack={popover.handleClose}
        HeaderRightComponent={() => null}
        headerText={'Details'}
        isFixedHeight={false}
        headerContainerStyle={{backgroundColor: colors.white}}
      >
        <ScrollView>
          <EmailMetadataPopoverContent email={email} responsive={responsive} popover={popover} />
        </ScrollView>
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailMetadataPopover.fragment = gql`
  fragment EmailMetadataPopover on Email {
    id
    fromName
    fromEmail
    toEmailRecipientsJson
    ccEmailRecipientsJson
    bccEmailRecipientsJson
    sentAt
    messageId
  }
`;

export default EmailMetadataPopover;
