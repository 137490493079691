// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {RateTableModel} from '@supermove/models';

// App
import RateTableForm, {RateTableFormType} from '@shared/modules/RateTable/forms/RateTableForm';

const useCreateRateTableMutation = ({
  rateTableForm,
  onSuccess,
  onError,
}: {
  rateTableForm: RateTableFormType;
  onSuccess: ({rateTable}: {rateTable: RateTableModel}) => void;
  onError: (
    errors: {
      field: string;
      message: string;
    }[],
  ) => void;
}) => {
  const form = useForm({
    initialValues: {
      rateTableForm: RateTableForm.toForm(rateTableForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateRateTableMutation.mutation,
    variables: {
      rateTableForm: RateTableForm.toMutation(form.values.rateTableForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateRateTableMutation.mutation = gql`
  mutation useCreateRateTableMutation($rateTableForm: RateTableForm!){
    response: createRateTable(rateTableForm: $rateTableForm) {
      ${gql.errors}
      rateTable {
        id
        name
      }
    }
  }
`;

export default useCreateRateTableMutation;
