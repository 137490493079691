// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState, usePopover, useDrawer} from '@supermove/hooks';
import {
  DocumentTemplate,
  DocumentTemplateModel,
  DocumentTemplateVersionModel,
  OrganizationModel,
  UserModel,
} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import UserRole from '@shared/modules/User/enums/UserRole';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import BlockDeleteDefaultDocumentTemplateModal from 'modules/Organization/Settings/Document/components/BlockDeleteDefaultDocumentTemplateModal';
import CreateDocumentTemplateDrawer from 'modules/Organization/Settings/Document/components/CreateDocumentTemplateDrawer';
import DeleteDocumentTemplateModal from 'modules/Organization/Settings/Document/components/DeleteDocumentTemplateModal';
import DuplicateDocumentTemplateDrawer from 'modules/Organization/Settings/Document/components/DuplicateDocumentTemplateDrawer';
import ImportDocumentTemplateDrawer from 'modules/Organization/Settings/Document/components/ImportDocumentTemplateDrawer';
import OrganizationSettingsDocumentPreviewRenderer from 'modules/Organization/Settings/Document/components/OrganizationSettingsDocumentPreviewRenderer';
import UpdateDocumentTemplateDrawer from 'modules/Organization/Settings/Document/components/UpdateDocumentTemplateDrawer';

const PageContentContainer = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const DocumentTemplateTableContainer = Styled.View`
  padding: 14px;
  flex: 1;
`;

const DescriptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const PageContentTableContainer = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  flex: 1;
`;

const Container = Styled.View`
  min-height: 40px;
  justify-content: center;
`;

const ActionButtonContainer = Styled.View`
  display: flex;
  flex-direction: row;
`;

const PageContentRendererContainer = Styled.View`
  flex: 1;
`;

const PageContentTableHeaderContainer = Styled.View`
  padding: 10px 16px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label2}
;`;

const IconButton = Styled.ButtonV2`
`;

const IconContainer = Styled.View`
  align-items: center;
  width: 100%;
`;

const BadgesContainer = Styled.View`
`;

const DefaultBadge = Styled.View`
  padding: 2px 8px;
  border-radius: 8px;
  background-color: ${colors.gray.border};
  width: fit-content;
`;

const DefaultBadgeText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

// When a document_template_version is published, we want this time to be reflected in the document template table in settings
const getMostRecentUpdatedAtAndFullName = ({
  documentTemplate,
  activeDocumentTemplateVersion,
}: {
  documentTemplate: DocumentTemplateModel;
  activeDocumentTemplateVersion: DocumentTemplateVersionModel;
}) => {
  if (
    !!activeDocumentTemplateVersion &&
    Date.parse(activeDocumentTemplateVersion.updatedAt) > Date.parse(documentTemplate.updatedAt)
  ) {
    return {
      updatedAt: activeDocumentTemplateVersion.updatedAt,
      // Documents synced from config won't have an updatedBy
      fullName:
        activeDocumentTemplateVersion.updatedBy && activeDocumentTemplateVersion.updatedBy.fullName,
    };
  }

  return {
    updatedAt: documentTemplate.updatedAt,
    // TODO(shopkins): Fix data issue on Bluxome where templates don't have a updatedById
    fullName: documentTemplate.updatedBy && documentTemplate.updatedBy.fullName,
  };
};

const DefaultBadges = ({
  isDefaultConfirmationDocumentTemplate,
  isDefaultQuoteDocumentTemplate,
}: {
  isDefaultConfirmationDocumentTemplate: boolean;
  isDefaultQuoteDocumentTemplate: boolean;
}) => {
  const isBothDefaults = isDefaultConfirmationDocumentTemplate && isDefaultQuoteDocumentTemplate;
  return (
    <BadgesContainer>
      {isDefaultConfirmationDocumentTemplate && (
        <DefaultBadge>
          <DefaultBadgeText>Default Confirmation</DefaultBadgeText>
        </DefaultBadge>
      )}
      {isBothDefaults && <Space height={8} />}
      {isDefaultQuoteDocumentTemplate && (
        <DefaultBadge>
          <DefaultBadgeText>Default Quote</DefaultBadgeText>
        </DefaultBadge>
      )}
    </BadgesContainer>
  );
};

const getDocumentTemplateDefinition = ({
  organization,
  userId,
  refetch,
  setIsModalOpen,
  navigator,
}: {
  organization: OrganizationModel;
  userId: string;
  refetch: () => void;
  setIsModalOpen: (value: boolean) => void;
  navigator: SupermoveNavigatorType;
}) => {
  const columnDefinitions = [
    {
      flex: 4,
      headerContent: () => {
        return <TableHeaderText>Name</TableHeaderText>;
      },
      cellContent: ({item: documentTemplate}: {item: DocumentTemplateModel}) => {
        return (
          <React.Fragment>
            <Text numberOfLines={2}>{documentTemplate.name}</Text>
            <Space width={8} />
            <DefaultBadges
              isDefaultConfirmationDocumentTemplate={
                documentTemplate.isDefaultConfirmationDocumentTemplate
              }
              isDefaultQuoteDocumentTemplate={documentTemplate.isDefaultQuoteDocumentTemplate}
            />
          </React.Fragment>
        );
      },
    },
    {
      width: 160,
      headerContent: () => {
        return <TableHeaderText>Invoice Template</TableHeaderText>;
      },
      cellContent: ({item: documentTemplate}: {item: DocumentTemplateModel}) => {
        return (
          documentTemplate.category === DocumentTemplateCategory.INVOICE && (
            <IconContainer>
              <Icon source={Icon.Check} color={colors.gray.primary} size={16} />
            </IconContainer>
          )
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <TableHeaderText>Last Updated</TableHeaderText>;
      },
      cellContent: ({item: documentTemplate}: {item: DocumentTemplateModel}) => {
        const {updatedAt, fullName} = getMostRecentUpdatedAtAndFullName({
          documentTemplate,
          activeDocumentTemplateVersion: documentTemplate.activeDocumentTemplateVersion,
        });
        return (
          <Container>
            <Text numberOfLines={1}>{Datetime.convertToDisplayDatetime(updatedAt)}</Text>
            {!!fullName && (
              <React.Fragment>
                <Space height={4} />
                <DescriptionText>By {fullName}</DescriptionText>
              </React.Fragment>
            )}
          </Container>
        );
      },
    },
  ];
  if (organization.features.isEnabledCreateEditDocumentTemplates) {
    columnDefinitions.push({
      flex: 1,
      headerContent: () => {
        return <TableHeaderText>Actions</TableHeaderText>;
      },
      cellContent: ({item: documentTemplate}: {item: DocumentTemplateModel}) => {
        // eslint-disable-next-line
        const duplicateDocumentTemplateDrawer = useDrawer({
          name: 'Duplicate Document Template Drawer',
        });
        // eslint-disable-next-line
        const deleteDocumentTemplateModal = useModal({name: 'Delete Document Template Modal'});
        // eslint-disable-next-line
        const blockDeleteDefaultDocumentTemplateModal = useModal({
          name: 'Block Delete Default Document Template Modal',
        });
        // eslint-disable-next-line
        const updateDocumentTemplateDrawer = useDrawer({name: 'Update Document Template Drawer'});
        // eslint-disable-next-line
        const documentActionsPopover = usePopover();
        const isNotEditable =
          organization.features.isEnabledSyncDocumentTemplatesOnOrgSync &&
          !documentTemplate.createdById;
        if (isNotEditable) return <></>;
        return (
          <React.Fragment>
            <IconButton
              onPress={() => {
                updateDocumentTemplateDrawer.handleOpen();
                setIsModalOpen(true);
              }}
            >
              <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
            </IconButton>
            <Space width={12} />
            <Popover.Content innerRef={documentActionsPopover.ref}>
              <IconButton
                onPress={documentActionsPopover.handleToggle}
                style={{paddingHorizontal: 4}}
              >
                <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
              </IconButton>
            </Popover.Content>
            <ActionMenuPopover
              popover={documentActionsPopover}
              placement={Popover.Positions.Auto}
              offset={[0, 4]}
            >
              <ActionMenuPopover.MenuItem
                onPress={() => {
                  documentActionsPopover.handleClose();
                  navigator.push(
                    `/settings/documents/document-templates/${documentTemplate.uuid}` +
                      `/version/${documentTemplate.draftTemplateVersion?.uuid}/edit`,
                  );
                }}
              >
                Edit Template
              </ActionMenuPopover.MenuItem>
              <ActionMenuPopover.MenuItem
                onPress={() => {
                  duplicateDocumentTemplateDrawer.handleOpen();
                  setIsModalOpen(true);
                }}
              >
                Duplicate
              </ActionMenuPopover.MenuItem>
              <ActionMenuPopover.MenuItem
                onPress={() => {
                  const isDefault =
                    documentTemplate.isDefaultConfirmationDocumentTemplate ||
                    documentTemplate.isDefaultQuoteDocumentTemplate;
                  if (isDefault) {
                    blockDeleteDefaultDocumentTemplateModal.handleOpen();
                  } else {
                    deleteDocumentTemplateModal.handleOpen();
                  }
                  setIsModalOpen(true);
                }}
              >
                Delete
              </ActionMenuPopover.MenuItem>
            </ActionMenuPopover>
            <BlockDeleteDefaultDocumentTemplateModal
              key={blockDeleteDefaultDocumentTemplateModal.key}
              blockDeleteDefaultDocumentTemplateModal={blockDeleteDefaultDocumentTemplateModal}
              documentTemplate={documentTemplate}
              handleClose={() => {
                blockDeleteDefaultDocumentTemplateModal.handleClose();
                setIsModalOpen(false);
              }}
            />
            <DeleteDocumentTemplateModal
              deleteDocumentTemplateModal={deleteDocumentTemplateModal}
              key={deleteDocumentTemplateModal.key}
              userId={userId}
              documentTemplateUuid={documentTemplate.uuid!}
              refetch={refetch}
              setIsModalOpen={setIsModalOpen}
            />
            <DuplicateDocumentTemplateDrawer
              key={duplicateDocumentTemplateDrawer.key}
              duplicateDocumentTemplateDrawer={duplicateDocumentTemplateDrawer}
              documentTemplate={documentTemplate}
              userId={userId}
            />
            <UpdateDocumentTemplateDrawer
              documentTemplate={documentTemplate}
              updateDocumentTemplateDrawer={updateDocumentTemplateDrawer}
              userId={userId}
              key={updateDocumentTemplateDrawer.key}
              refetch={refetch}
              setIsModalOpen={setIsModalOpen}
            />
          </React.Fragment>
        );
      },
    });
  }
  return columnDefinitions;
};

const DocumentTemplateTable = ({
  documentTemplates,
  documentTemplateUuid,
  organization,
  navigator,
  userId,
  refetch,
}: {
  documentTemplates: DocumentTemplateModel[];
  documentTemplateUuid: string;
  organization: OrganizationModel;
  navigator: SupermoveNavigatorType;
  userId: string;
  refetch: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <DocumentTemplateTableContainer>
      <ScrollView style={{flex: 1, paddingBottom: 100}}>
        <Table
          columnDefinitions={getDocumentTemplateDefinition({
            organization,
            userId,
            refetch,
            setIsModalOpen,
            navigator,
          })}
          emptyStateText='No Templates to display'
          items={documentTemplates}
          // We need to make the row unclickable if the modal is open, since clicking anywhere on the
          // modal will fire off the onRowPress event. The modal is a child of the row and the
          // onRowPress event will treat it as a child of the row.
          isClickable={!isModalOpen}
          isDense
          activeRowIndex={_.findIndex(documentTemplates, (documentTemplate) => {
            if (documentTemplateUuid) {
              return documentTemplate.uuid === documentTemplateUuid;
            }
            return false;
          })}
          onRowPress={(item) => {
            navigator.replace(`/settings/documents/document-templates/${item.uuid}`);
          }}
        />
      </ScrollView>
    </DocumentTemplateTableContainer>
  );
};

const PageContentTableContainerHeader = ({
  documentCount,
  organization,
  viewer,
  refetch,
}: {
  documentCount: number;
  organization: OrganizationModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const createDocumentTemplateDrawer = useDrawer({name: 'Create Document Template Drawer'});
  const importDocumentTemplateDrawer = useDrawer({name: 'Import Document Template Drawer'});
  const isSuperUser = viewer.role === UserRole.SUPER_ADMIN;
  return (
    <React.Fragment>
      <PageContentTableHeaderContainer>
        <HeaderText>{`${pluralize(
          'Document Template',
          documentCount,
          false,
        )} (${documentCount})`}</HeaderText>
        <ActionButtonContainer>
          {isSuperUser && (
            <React.Fragment>
              <Button
                text={'Import Templates'}
                iconLeft={Icon.Plus}
                onPress={() => {
                  importDocumentTemplateDrawer.handleOpen();
                }}
              />
              <Space width={8} />
            </React.Fragment>
          )}
          {organization.features.isEnabledCreateEditDocumentTemplates && (
            <Button
              text={'Create Template'}
              iconLeft={Icon.Plus}
              onPress={createDocumentTemplateDrawer.handleOpen}
            />
          )}
        </ActionButtonContainer>
      </PageContentTableHeaderContainer>
      <CreateDocumentTemplateDrawer
        createDocumentTemplateDrawer={createDocumentTemplateDrawer}
        organizationId={organization.id}
        userId={viewer.id}
        key={createDocumentTemplateDrawer.key} // Ensures values are empty when modal is opened
      />
      <ImportDocumentTemplateDrawer
        key={importDocumentTemplateDrawer.key}
        handleClose={importDocumentTemplateDrawer.handleClose}
        organizationId={organization.id}
        isOpen={importDocumentTemplateDrawer.isOpen}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsDocumentTemplateSettingsContent = ({
  documentTemplateUuid,
  viewer,
  navigator,
  refetch,
}: {
  documentTemplateUuid: string;
  viewer: UserModel;
  navigator: SupermoveNavigatorType;
  refetch: () => void;
}) => {
  const organization = viewer.viewingOrganization;
  const documentTemplates = DocumentTemplate.sortDocumentTemplatesByName(
    organization.documentTemplates,
  );
  return (
    <PageContentContainer>
      <PageContentTableContainer>
        <PageContentTableContainerHeader
          documentCount={documentTemplates.length}
          organization={organization}
          viewer={viewer}
          refetch={refetch}
        />
        <DocumentTemplateTable
          navigator={navigator}
          documentTemplates={documentTemplates}
          documentTemplateUuid={documentTemplateUuid}
          organization={organization}
          userId={viewer.id}
          refetch={refetch}
        />
      </PageContentTableContainer>
      <PageContentRendererContainer>
        <OrganizationSettingsDocumentPreviewRenderer documentTemplateUuid={documentTemplateUuid} />
      </PageContentRendererContainer>
    </PageContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsDocumentTemplateSettingsContent.fragment = gql`
  ${BlockDeleteDefaultDocumentTemplateModal.fragment}
  ${DocumentTemplate.sortDocumentTemplatesByName.fragment}
  ${DuplicateDocumentTemplateDrawer.fragment}
  ${UpdateDocumentTemplateDrawer.fragment}

  fragment OrganizationSettingsDocumentTemplateSettingsContent on User {
    id
    role
    viewingOrganization {
      id
      documentTemplates {
        id
        name
        uuid
        updatedAt
        category
        isDefaultConfirmationDocumentTemplate
        isDefaultQuoteDocumentTemplate
        updatedBy {
          id
          fullName
        }
        activeDocumentTemplateVersion {
          id
          updatedAt
          updatedBy {
            id
            fullName
          }
        }
        createdById
        ...DocumentTemplate_sortDocumentTemplatesByName
        ...DuplicateDocumentTemplateDrawer
        ...UpdateDocumentTemplateDrawer
        draftTemplateVersion {
          id
          uuid
        }
        ...BlockDeleteDefaultDocumentTemplateModal
      }
      features {
        isEnabledCreateEditDocumentTemplates: isEnabled(feature: "CREATE_EDIT_DOCUMENT_TEMPLATES")
        isEnabledSyncDocumentTemplatesOnOrgSync: isEnabled(
          feature: "SYNC_DOCUMENT_TEMPLATES_ON_ORG_SYNC"
        )
      }
    }
  }
`;

export default OrganizationSettingsDocumentTemplateSettingsContent;
