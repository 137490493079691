const NUMBER_OF_MOVERS = 'NUMBER_OF_MOVERS' as const;
const NUMBER_OF_TRUCKS = 'NUMBER_OF_TRUCKS' as const;
const DAY_OF_WEEK = 'DAY_OF_WEEK' as const;

const RATE_TABLE_METRIC_KINDS = [NUMBER_OF_MOVERS, NUMBER_OF_TRUCKS, DAY_OF_WEEK] as const;
export type RateTableMetricKindType = (typeof RATE_TABLE_METRIC_KINDS)[number];
const PRIMARY_METRICS = [NUMBER_OF_MOVERS, NUMBER_OF_TRUCKS] as const;
const SECONDARY_METRICS = [DAY_OF_WEEK] as const;

const getName = (kind: RateTableMetricKindType) => {
  switch (kind) {
    case NUMBER_OF_MOVERS:
      return '# of Movers';
    case NUMBER_OF_TRUCKS:
      return '# of Trucks';
    case DAY_OF_WEEK:
      return 'Days of Week';
    default:
      return '';
  }
};

const getActionName = (kind: RateTableMetricKindType) => {
  switch (kind) {
    case NUMBER_OF_MOVERS:
      return 'Add Mover';
    case NUMBER_OF_TRUCKS:
      return 'Add Truck';
    default:
      return '';
  }
};

const getSecondaryColumns = (kind: RateTableMetricKindType) => {
  switch (kind) {
    case DAY_OF_WEEK:
      // Uses ISO format for day of week
      return [
        {name: 'Monday', value: '1'},
        {name: 'Tuesday', value: '2'},
        {name: 'Wednesday', value: '3'},
        {name: 'Thursday', value: '4'},
        {name: 'Friday', value: '5'},
        {name: 'Saturday', value: '6'},
        {name: 'Sunday', value: '7'},
      ];
    default:
      return [];
  }
};

const RateTableMetricKind = {
  NUMBER_OF_MOVERS,
  NUMBER_OF_TRUCKS,
  DAY_OF_WEEK,

  PRIMARY_METRICS,
  SECONDARY_METRICS,

  getName,
  getActionName,
  getSecondaryColumns,
};

export default RateTableMetricKind;
