// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {Form, ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TableV2Deprecated from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import RateTableMetricKind, {
  RateTableMetricKindType,
} from '@shared/modules/RateTable/enums/RateTableMetricKind';
import {RateTableAdditionalValueFormToFormType} from '@shared/modules/RateTable/forms/RateTableAdditionalValueForm';
import {RateTableFormToFormType} from '@shared/modules/RateTable/forms/RateTableForm';
import RateTableRateInput from 'modules/Organization/Settings/Billing/components/RateTableRateInput';
import RateTableSecondaryMetricHeader from 'modules/Organization/Settings/Billing/components/RateTableSecondaryMetricHeader';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const handleCopyAdditionalRateColumn = ({
  form,
  field,
  rateTableAdditionalValuesByPrimaryMetric,
  sourceColumnIndex,
  destinationColumnIndexes,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  rateTableAdditionalValuesByPrimaryMetric: Record<
    string,
    RateTableAdditionalValueFormToFormType[]
  >;
  sourceColumnIndex: number;
  destinationColumnIndexes: number[];
}) => {
  const columnCount =
    rateTableAdditionalValuesByPrimaryMetric[
      Object.keys(rateTableAdditionalValuesByPrimaryMetric)[0]
    ].length;
  _.forEach(
    Object.values(rateTableAdditionalValuesByPrimaryMetric),
    (rateTableAdditionalValueForms, rowIndex) => {
      const {rate} = rateTableAdditionalValueForms[sourceColumnIndex];
      _.forEach(destinationColumnIndexes, (destinationColumnIndex) => {
        form.setFieldValue(
          `${field}.${rowIndex * columnCount + destinationColumnIndex}.rate`,
          rate,
        );
      });
    },
  );
};

const handleCopyAdditionalRateCell = ({
  form,
  field,
  rowCount,
  columnCount,
  rate,
  rowIndex,
  columnIndex,
  copyDirection,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  rowCount: number;
  columnCount: number;
  rate: string;
  rowIndex: number;
  columnIndex: number;
  copyDirection: 'ROW' | 'COLUMN';
}) => {
  const destinationIndexes = copyDirection === 'ROW' ? _.range(columnCount) : _.range(rowCount);
  _.forEach(destinationIndexes, (destinationIndex) => {
    const destinationField =
      copyDirection === 'ROW'
        ? `${field}.${rowIndex * columnCount + destinationIndex}.rate`
        : `${field}.${destinationIndex * columnCount + columnIndex}.rate`;
    form.setFieldValue(destinationField, rate);
  });
};

const TOOLTIP_TEXT = `This rate applies to each mover or truck added beyond the number specified in the table above.\n\nFor instance, if hourly rates are set for up to 6 movers and a move requires 8, Supermove will take the per additional rate and multiply it by the 2 extra movers. For example, if your rate is $100 per additional mover, the extra cost would be $200. The same applies to trucks.`;

const getAdditionalRateTableColumnDefinitions = ({
  form,
  field,
  rateTableAdditionalValuesByPrimaryMetric,
  cellWidth,
  checkboxCellWidth,
  cellStyle,
  responsive,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
  rateTableAdditionalValuesByPrimaryMetric: Record<
    string,
    RateTableAdditionalValueFormToFormType[]
  >;
  cellWidth: number;
  checkboxCellWidth: number;
  cellStyle: React.CSSProperties;
  responsive: ResponsiveType;
}) => {
  const {rateTableForm} = form.values;
  const {rateTablePrimaryMetric1Form, rateTablePrimaryMetric2Form, rateTableSecondaryMetric1Form} =
    rateTableForm;
  const primaryRateTableAdditionalValues =
    rateTableAdditionalValuesByPrimaryMetric[rateTablePrimaryMetric1Form.kind];
  return [
    {
      cellStyle: {...cellStyle, alignItems: 'center'},
      width: checkboxCellWidth + cellWidth + (rateTablePrimaryMetric2Form ? cellWidth : 0),
      headerContent: () => {
        return (
          <Row style={{flex: 1}}>
            <TableV2Deprecated.HeaderText responsive={responsive} numberOfLines={1}>
              Per Additional
            </TableV2Deprecated.HeaderText>
            <Space flex={1} />
            <TextTooltip text={TOOLTIP_TEXT}>
              <Row>
                <Icon source={Icon.CircleInfo} size={12} color={colors.gray.secondary} />
              </Row>
            </TextTooltip>
          </Row>
        );
      },
      cellContent: ({item: rateTableMetricKind}: {item: RateTableMetricKindType}) => (
        <TableV2Deprecated.CellText responsive={responsive} numberOfLines={1}>
          {RateTableMetricKind.getName(rateTableMetricKind)}
        </TableV2Deprecated.CellText>
      ),
    },
    // Each set of metrics has the same number of columns, so we use the primary metric since it always exists
    ...primaryRateTableAdditionalValues.map((rateTableAdditionalValueForm, columnIndex) => ({
      cellStyle:
        columnIndex === primaryRateTableAdditionalValues.length - 1 ? {padding: 8} : cellStyle,
      width: cellWidth,
      headerContent: () => {
        const hasMoreThanOneColumn = primaryRateTableAdditionalValues.length > 1;
        const isAllColumnRowsEmpty =
          hasMoreThanOneColumn &&
          Object.values(rateTableAdditionalValuesByPrimaryMetric).every(
            (additionalValuesForMetric) => !additionalValuesForMetric[columnIndex].rate,
          );
        return (
          <RateTableSecondaryMetricHeader
            rateTableSecondaryMetric1Form={rateTableSecondaryMetric1Form}
            columnIndex={columnIndex}
            isCopyVisible={hasMoreThanOneColumn}
            isCopyDisabled={isAllColumnRowsEmpty}
            handleSubmit={(selectedColumns: number[]) =>
              handleCopyAdditionalRateColumn({
                form,
                field,
                rateTableAdditionalValuesByPrimaryMetric,
                sourceColumnIndex: columnIndex,
                destinationColumnIndexes: selectedColumns,
              })
            }
          />
        );
      },
      cellContent: ({rowIndex}: {rowIndex: number}) => {
        const rateField = `${field}.${rowIndex * primaryRateTableAdditionalValues.length + columnIndex}.rate`;
        return (
          <FieldInput
            {...form}
            name={rateField}
            isResponsive
            component={RateTableRateInput}
            input={{
              component: FieldInput.TextInput,
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
              placeholder: 'Enter rate',
              onCopy: (copyDirection: 'ROW' | 'COLUMN') => {
                handleCopyAdditionalRateCell({
                  form,
                  field,
                  rowCount: Object.keys(rateTableAdditionalValuesByPrimaryMetric).length,
                  columnCount: primaryRateTableAdditionalValues.length,
                  rate: _.get(form.values, rateField),
                  rowIndex,
                  columnIndex,
                  copyDirection,
                });
              },
            }}
            style={{flex: 1}}
            showErrorMessage={false}
          />
        );
      },
    })),
  ];
};

const AdditionalRatesTable = ({
  form,
  cellWidth,
  checkboxCellWidth,
  cellStyle,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  cellWidth: number;
  checkboxCellWidth: number;
  cellStyle: React.CSSProperties;
}) => {
  const responsive = useResponsive();
  const rateTableAdditionalValuesByPrimaryMetric = _.groupBy(
    form.values.rateTableForm.rateTableAdditionalValueForms,
    'rateTablePrimaryMetricForm.kind',
  );
  return (
    <TableV2Deprecated
      emptyStateText={'No additional rates.'}
      columnDefinitions={getAdditionalRateTableColumnDefinitions({
        form,
        field: 'rateTableForm.rateTableAdditionalValueForms',
        rateTableAdditionalValuesByPrimaryMetric,
        cellWidth,
        checkboxCellWidth,
        cellStyle,
        responsive,
      })}
      items={[
        form.values.rateTableForm.rateTablePrimaryMetric1Form.kind,
        ...List.insertIf(
          !!form.values.rateTableForm.rateTablePrimaryMetric2Form,
          form.values.rateTableForm.rateTablePrimaryMetric2Form?.kind,
        ),
      ]}
      isDense
      // This is required to have the borders per cell
      headerStyle={{paddingVertical: 0, backgroundColor: colors.gray.background}}
      rowStyle={{paddingVertical: 0}}
    />
  );
};

export default AdditionalRatesTable;
