// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {DocumentTemplateModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import IconButton from '@shared/design/components/IconButton';
import {UserFlowStepFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowStepForm';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
  padding: 12px 16px;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  align-items: center;
  background-color: ${colors.white};
`;

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ButtonGroup = Styled.View`
  flex-direction: row;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const DescriptionNone = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const DocumentFlowStepsListItem = ({
  index,
  userFlowStepForm,
  documentTemplate,
  handleEdit,
  handleDelete,
}: {
  index: number;
  userFlowStepForm: UserFlowStepFormToFormType;
  documentTemplate: DocumentTemplateModel;
  handleEdit: () => void;
  handleDelete: () => void;
}) => {
  const responsive = useResponsive();

  const {isDefault} = userFlowStepForm;

  const actionsPopover = usePopover();
  const actions = [{text: 'Delete', onPress: handleDelete}];

  return (
    <Container>
      <Column>
        <Row>
          <Title responsive={responsive}>{documentTemplate?.name}</Title>
          <Space width={8} />
          {isDefault && <ResponsiveBadge label={'Default'} />}
        </Row>
        <Space height={8} />
        {documentTemplate?.description ? (
          <Description responsive={responsive}>{documentTemplate?.description}</Description>
        ) : (
          <DescriptionNone responsive={responsive}>No description</DescriptionNone>
        )}
      </Column>
      <Space width={16} />
      <ButtonGroup>
        <IconButton onPress={handleEdit} source={Icon.Pen} />
        <Space width={8} />
        <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
          <IconButton onPress={actionsPopover.handleToggle} isSecondary source={Icon.EllipsisV} />
        </ActionMenuPopover>
      </ButtonGroup>
    </Container>
  );
};

DocumentFlowStepsListItem.fragment = gql`
  fragment DocumentFlowStepsListItem on DocumentTemplateV2 {
    id
    name
    description
  }
`;

export default DocumentFlowStepsListItem;
