// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {DocumentItemType, OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import {DocumentTemplateCategoryType} from '@shared/modules/Document/enums/DocumentTemplateCategory';
import {ReadonlySectionSettingsType} from '@shared/modules/Document/forms/DocumentItemForm';
import {
  SelectedDocumentItemInSectionType,
  SetSelectedDocumentItemType,
  DocumentContentJsonWithSectionsType,
  SetDocumentContentJsonWithSectionsType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import CreateSectionModal from 'modules/Organization/Settings/Document/components/CreateSectionModal';
import DocumentTemplateDocumentItems from 'modules/Organization/Settings/Document/components/DocumentTemplateDocumentItems';
import DocumentTemplateSectionSettingsButton from 'modules/Organization/Settings/Document/components/DocumentTemplateSectionSettingsButton';

const PaddingContainer = Styled.View`
  padding-horizontal: 24px;
`;

const TemplateStructureEditorContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View<{index?: number}>`
  flex-direction: row;
  width: 100%;
  z-index: ${({index = 0}) => 99999 - index};
`;

const DocumentItemText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const DocumentItemDescriptionText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 24px;
`;

const handleAddEmptyDocumentSection = ({
  name,
  readonlySectionSettings,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
}: {
  name: string;
  readonlySectionSettings?: ReadonlySectionSettingsType;
  setDraftDocumentContentJson: SetDocumentContentJsonWithSectionsType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
}) => {
  setDraftDocumentContentJson((prevState) => {
    const sectionDocumentItem = {
      ...DocumentItemKindV2.getNewDocumentItem(DocumentItemKindV2.SECTION, {
        name,
        documentItems: prevState.documentItems as DocumentItemType[],
      }),
      readonlySectionSettings,
    };
    setSelectedDocumentItem({});
    return {...prevState, documentItems: [...prevState.documentItems, sectionDocumentItem]};
  });
};

const SectionDocumentItemsPanel = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
}: {
  organization: OrganizationModel;
  setDraftDocumentContentJson: SetDocumentContentJsonWithSectionsType;
  draftDocumentContentJson: DocumentContentJsonWithSectionsType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemInSectionType;
  documentTemplateCategory: DocumentTemplateCategoryType;
}) => {
  const responsive = useResponsive();
  const createSectionModal = useModal({name: 'Create Section Modal', enableTracking: true});
  const sectionDocumentItem = _.get(
    draftDocumentContentJson,
    `documentItems.${selectedDocumentItem.sectionIndex}`,
  );
  const {documentItems} = sectionDocumentItem;

  return (
    <TemplateStructureEditorContainer>
      <HeaderContainer>
        <Space height={16} />
        <Row>
          <IconButton
            source={Icon.AngleLeft}
            onPress={() => setSelectedDocumentItem({})}
            isSecondary
          />
          <Space width={8} />
          <DocumentItemText responsive={responsive}>{sectionDocumentItem.name}</DocumentItemText>
          <Space style={{flex: 1}} />
          <DocumentTemplateSectionSettingsButton
            draftDocumentContentJson={draftDocumentContentJson}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
            setSelectedDocumentItem={setSelectedDocumentItem}
            selectedDocumentItem={selectedDocumentItem}
          />
        </Row>
        <Space height={16} />
        <DocumentItemDescriptionText responsive={responsive}>
          {'Edit this section by adding and editing document items.'}
        </DocumentItemDescriptionText>
        <Space height={16} />
      </HeaderContainer>
      <Line />
      <ScrollView>
        <Space height={16} />
        {documentItems.length === 0 && (
          <PaddingContainer>
            <DocumentItemDescriptionText
              responsive={responsive}
              style={{color: colors.gray.tertiary}}
            >
              {"This section doesn't have any items yet."}
            </DocumentItemDescriptionText>
            <Space height={16} />
          </PaddingContainer>
        )}
        <DocumentTemplateDocumentItems
          organization={organization}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          draftDocumentContentJson={draftDocumentContentJson}
          setSelectedDocumentItem={setSelectedDocumentItem}
          selectedDocumentItem={selectedDocumentItem}
          documentTemplateCategory={documentTemplateCategory}
        />
        <Space height={160} />
      </ScrollView>
      <CreateSectionModal
        documentContentJson={draftDocumentContentJson}
        isOpen={createSectionModal.isOpen}
        handleClose={createSectionModal.handleClose}
        handleCreateSection={(name, readonlySectionSettings) => {
          handleAddEmptyDocumentSection({
            name,
            readonlySectionSettings,
            setDraftDocumentContentJson,
            setSelectedDocumentItem,
          });
        }}
      />
    </TemplateStructureEditorContainer>
  );
};

SectionDocumentItemsPanel.fragment = gql`
  ${DocumentTemplateDocumentItems.fragment}

  fragment SectionDocumentItemsPanel on Organization {
    id
    ...DocumentTemplateDocumentItems
  }
`;

export default SectionDocumentItemsPanel;
