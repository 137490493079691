// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const TotalContainer = Styled.View<{responsive: ResponsiveType}>`
  min-height: 60px;
  justify-content: center;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}}
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TotalText = Styled.Text<{isLabel?: boolean; color?: string}>`
  ${({isLabel}) => (isLabel ? Typography.Responsive.Label : Typography.Responsive.Body)}
  ${({color}) => (color ? `color: ${color};` : '')}
`;

const TotalRow = ({
  responsive,
  title,
  min,
  max,
  isLoading,
  showTbd,
  isLabel,
  valueOverride,
  color,
}: {
  responsive: ResponsiveType;
  title: string;
  min: number;
  max: number;
  isLoading: boolean;
  showTbd: boolean;
  isLabel?: boolean;
  valueOverride?: string;
  color?: string;
}) => {
  return (
    <Row>
      <TotalText isLabel={isLabel} responsive={responsive} color={color}>
        {title}
      </TotalText>
      <Space style={{flex: 1, minWidth: 16}} />
      {isLoading ? (
        <SkeletonLoader width={100} height={SkeletonLoader.HEIGHT.Text} />
      ) : (
        <TotalText isLabel={isLabel} responsive={responsive} color={color}>
          {valueOverride ||
            (showTbd ? 'TBD' : Currency.formatRange({min, max, shouldHideCentsIfZero: false}))}
        </TotalText>
      )}
    </Row>
  );
};

const BillItemTotals = ({
  responsive,
  minSubtotal,
  maxSubtotal,
  showTbd,
  minTaxTotal,
  maxTaxTotal,
  minTotal,
  maxTotal,
  isLoading,
  isTaxable,
  salesTaxRate,
}: {
  responsive: ResponsiveType;
  minSubtotal: number;
  maxSubtotal: number;
  showTbd: boolean;
  minTaxTotal: number;
  maxTaxTotal: number;
  minTotal: number;
  maxTotal: number;
  isLoading: boolean;
  isTaxable: boolean;
  salesTaxRate: number;
}) => {
  return (
    <TotalContainer responsive={responsive}>
      <Space height={14} />
      <TotalRow
        responsive={responsive}
        title={'Subtotal'}
        min={minSubtotal}
        max={maxSubtotal}
        isLoading={isLoading}
        showTbd={showTbd}
        isLabel
        color={colors.gray.secondary}
      />
      <Space height={6} />
      <TotalRow
        responsive={responsive}
        title={`Sales Tax (${Percent.display(salesTaxRate)})`}
        min={minTaxTotal}
        max={maxTaxTotal}
        isLoading={isLoading}
        showTbd={showTbd}
        valueOverride={!isTaxable ? 'N/A' : undefined}
        color={colors.gray.secondary}
      />
      <Space height={6} />
      <TotalRow
        responsive={responsive}
        title={'Total'}
        min={minTotal}
        max={maxTotal}
        isLoading={isLoading}
        showTbd={showTbd}
        isLabel
      />
      <Space height={14} />
    </TotalContainer>
  );
};

export default BillItemTotals;
