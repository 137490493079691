// DEPRECATED
// This component is only used in admin + estimator, it should be removed once those pages are removed/cleaned up

// Libraries
import React from 'react';

// Supermove
import {Link, Loading, Query, ScrollView, Styled, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import LogOutModal from './LogOutModal';
import SidebarAvatar from './SidebarAvatar';
import SidebarItem from './SidebarItem';
import {SidebarItemWithSSERefetch} from './SidebarItemWithSSERefetch';

const Container = Styled.View`
  width: 80px;
  background-color: ${colors.black};
`;

const Wrapper = Styled.View`
  flex: 1;
  flex-direction: column;
`;

const Content = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  width: 100%;
  padding: 10px;
`;

const Logo = Styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: ${colors.black};
  border-radius: 3px;
`;

const Items = Styled.View`
  align-items: center;
`;

const User = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'row' : 'column')};
  ${(props) => ((props as any).mobile ? 'align-items: center;' : '')}
  margin-horizontal: 10px;
  ${(props) => ((props as any).mobile ? '' : 'margin-bottom: 10px;')}
`;

const Touchable = Styled.Touchable`
`;

type OwnProps = {
  selected: string;
  getItems: (...args: any[]) => any;
  query: any;
  getNotificationsCount?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Sidebar.defaultProps;

// @ts-expect-error TS(7022): 'Sidebar' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const Sidebar = ({query, isVisibleSignOut, selected, getItems, getNotificationsCount}: Props) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <Query {...query}>
        {({loading, data, startPolling, stopPolling, refetch}: any) => (
          <Loading loading={loading}>
            {() => (
              <Wrapper>
                <Content>
                  <Header>
                    <Link to={'/'}>
                      <Logo>
                        <SupermoveLogo.Icon />
                      </Logo>
                    </Link>
                  </Header>
                  <ScrollView>
                    <Items>
                      {getItems({
                        viewer: data.viewer,
                        responsive,
                      }).map(({key, to, name, source, sseRefetchTrigger}: any, index: any) =>
                        sseRefetchTrigger ? (
                          // @ts-expect-error TS(2741): Property 'handleClose' is missing in type '{ key: ... Remove this comment to see the full error message
                          <SidebarItemWithSSERefetch
                            key={key}
                            count={getNotificationsCount({key, data, selected})}
                            selected={key === selected}
                            to={to}
                            name={name}
                            source={source}
                            startPolling={startPolling}
                            stopPolling={stopPolling}
                            refetch={refetch}
                            sseRefetchTrigger={sseRefetchTrigger}
                            loading={loading}
                          />
                        ) : (
                          <SidebarItem
                            key={key}
                            count={getNotificationsCount({key, data, selected})}
                            selected={key === selected}
                            to={to}
                            name={name}
                            source={source}
                            responsive={responsive}
                          />
                        ),
                      )}
                    </Items>
                  </ScrollView>
                </Content>
                <User {...responsive}>
                  {isVisibleSignOut && (
                    <LogOutModal
                      trigger={({handleOpen}: any) => (
                        <Touchable onPress={handleOpen}>
                          <SidebarAvatar user={data.viewer} size={35} responsive={responsive} />
                        </Touchable>
                      )}
                    />
                  )}
                </User>
              </Wrapper>
            )}
          </Loading>
        )}
      </Query>
    </Container>
  );
};

Sidebar.Avatar = SidebarAvatar;
Sidebar.Item = SidebarItem;

Sidebar.defaultProps = {
  getNotificationsCount: () => 0,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
Sidebar.fragment = gql`
  ${SidebarAvatar.fragment}
  fragment Sidebar_viewer on User {
    id
    firstName
    lastName
    email
    role
    viewingOrganization {
      id
      slug
    }
    ...SidebarAvatar
  }
`;

export default Sidebar;
