// Libraries
import _ from 'lodash';

// App
import RateTableValueForm, {
  RateTableValueFormType,
} from '@shared/modules/RateTable/forms/RateTableValueForm';

const _new = ({
  rateTablePrimaryMetric1Value,
  rateTablePrimaryMetric2Value,
  rateTableValueForms,
}: {
  rateTablePrimaryMetric1Value: number;
  rateTablePrimaryMetric2Value?: number;
  rateTableValueForms: RateTableValueFormType[];
}) => ({
  rateTablePrimaryMetric1Value,
  rateTablePrimaryMetric2Value: rateTablePrimaryMetric2Value || undefined,
  rateTableValueForms,
});

export type RateTableMetricValuesFormType = ReturnType<typeof _new>;

const toForm = ({
  rateTablePrimaryMetric1Value,
  rateTablePrimaryMetric2Value,
  rateTableValueForms,
}: ReturnType<typeof _new>) => ({
  rateTablePrimaryMetric1Value: _.toString(rateTablePrimaryMetric1Value),
  rateTablePrimaryMetric2Value: _.toString(rateTablePrimaryMetric2Value),
  rateTableValueForms: rateTableValueForms.map((rateTableValueForm) =>
    RateTableValueForm.toForm(rateTableValueForm),
  ),
});

export type RateTableMetricValuesFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({
  rateTablePrimaryMetric1Value,
  rateTablePrimaryMetric2Value,
  rateTableValueForms,
}: RateTableMetricValuesFormToFormType) => ({
  rateTablePrimaryMetric1Value:
    rateTablePrimaryMetric1Value === '' ? null : _.toNumber(rateTablePrimaryMetric1Value),
  rateTablePrimaryMetric2Value:
    rateTablePrimaryMetric2Value === '' ? null : _.toNumber(rateTablePrimaryMetric2Value),
  rateTableValueForms: rateTableValueForms.map((rateTableValueForm) =>
    RateTableValueForm.toMutation(rateTableValueForm),
  ),
});

const RateTableMetricValuesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RateTableMetricValuesForm;
