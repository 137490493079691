// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon, IconSource} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {CapacityCalendarDayModel, CapacityCalendarModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';

const Row = Styled.View`
  flex-direction: row;
`;

const MetricContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const SecondaryMetricRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CapacityNumeratorText = Styled.Text`
  ${Typography.Heading3}
  color: ${colors.gray.primary};
`;

const CapacityDenominatorText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.tertiary};
`;

const MobileCapacityNumeratorText = Styled.Text`
  ${Typography.Desktop.Heading1}
  color: ${colors.gray.primary};
`;

const MobileCapacityDenominatorText = Styled.Text`
  ${Typography.Desktop.Heading1}
  color: ${colors.gray.tertiary};
`;

const SecondaryNumeratorText = Styled.Text`
  ${Typography.Desktop.Body}
  color: ${colors.gray.primary};
`;

const SecondaryDenominatorText = Styled.Text`
  ${Typography.Desktop.Body}
  color: ${colors.gray.tertiary};
`;

const CapacityDescriptionText = Styled.Text`
  ${Typography.Desktop.Micro}
  color: ${colors.gray.tertiary};
`;

const DayNotesText = Styled.Text`
  ${Typography.Desktop.Micro}
  color: ${colors.gray.secondary};
`;

const SummaryDayNotesText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const SummaryCountContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 64px;
`;

const SummaryCountText = Styled.Text<{color?: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color || colors.gray.primary};
`;

const SummaryDescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const NumeratorAndDenominatorWithSubtitle = ({
  numerator,
  denominator,
  subtitle,
  isSecondaryMetric,
  isSummary,
  icon,
}: {
  numerator: number;
  denominator: number;
  subtitle: string;
  isSecondaryMetric: boolean;
  isSummary: boolean;
  icon: IconSource;
}) => {
  const responsive = useResponsive();

  if (isSummary) {
    return (
      <Row>
        <SummaryCountContainer>
          <SummaryCountText responsive={responsive}>{numerator}</SummaryCountText>
          <SummaryCountText responsive={responsive} color={colors.gray.tertiary}>
            /{denominator}
          </SummaryCountText>
        </SummaryCountContainer>
        <Space width={16} />
        <SummaryDescriptionText numberOfLines={1} responsive={responsive}>
          {subtitle}
        </SummaryDescriptionText>
      </Row>
    );
  }

  return !isSecondaryMetric ? (
    <React.Fragment>
      {responsive.desktop ? (
        <MetricContainer>
          <CapacityNumeratorText>{numerator}</CapacityNumeratorText>
          <CapacityDenominatorText>/{denominator}</CapacityDenominatorText>
        </MetricContainer>
      ) : (
        <MetricContainer>
          <MobileCapacityNumeratorText>{numerator}</MobileCapacityNumeratorText>
          <MobileCapacityDenominatorText>/{denominator}</MobileCapacityDenominatorText>
        </MetricContainer>
      )}
      <CapacityDescriptionText>{subtitle}</CapacityDescriptionText>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TextTooltip
        style={{zIndex: 99999}}
        placement={'bottom'}
        text={subtitle}
        isEnabledMobileToast={false}
      >
        <SecondaryMetricRowContainer>
          <SecondaryNumeratorText>{numerator}</SecondaryNumeratorText>
          <SecondaryDenominatorText>/{denominator}</SecondaryDenominatorText>
          <Space width={4} />
          <Icon source={icon} color={colors.black} size={responsive.desktop ? 12 : 16} />
        </SecondaryMetricRowContainer>
      </TextTooltip>
    </React.Fragment>
  );
};

const NumberWithSubtitle = ({
  number,
  subtitle,
  isSecondaryMetric,
  isSummary,
  icon,
}: {
  number: number;
  subtitle: string;
  isSecondaryMetric: boolean;
  isSummary: boolean;
  icon: IconSource;
}) => {
  const responsive = useResponsive();

  if (isSummary) {
    return (
      <Row>
        <SummaryCountContainer>
          <SummaryCountText responsive={responsive}>{number}</SummaryCountText>
        </SummaryCountContainer>
        <Space width={16} />
        <SummaryDescriptionText numberOfLines={1} responsive={responsive}>
          {subtitle}
        </SummaryDescriptionText>
      </Row>
    );
  }

  return !isSecondaryMetric ? (
    <React.Fragment>
      <MetricContainer>
        <CapacityNumeratorText>{number}</CapacityNumeratorText>
      </MetricContainer>
      <CapacityDescriptionText>{subtitle}</CapacityDescriptionText>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TextTooltip
        style={{zIndex: 99999}}
        placement={'bottom'}
        text={subtitle}
        isEnabledMobileToast={false}
      >
        <SecondaryMetricRowContainer>
          <SecondaryNumeratorText>{number}</SecondaryNumeratorText>
          <Space width={4} />
          <Icon source={icon} color={colors.gray.secondary} size={responsive.desktop ? 12 : 16} />
        </SecondaryMetricRowContainer>
      </TextTooltip>
    </React.Fragment>
  );
};

const DayNotesMetric = ({dayNotes, isSummary}: {dayNotes?: string; isSummary: boolean}) => {
  const responsive = useResponsive();
  const displayNotes = dayNotes || '';

  if (isSummary) {
    return (
      <SummaryDayNotesText numberOfLines={1} responsive={responsive}>
        {displayNotes}
      </SummaryDayNotesText>
    );
  }
  return <DayNotesText numberOfLines={1}>{displayNotes}</DayNotesText>;
};

const DaySlotMetric = ({
  metric,
  capacityCalendarDay,
  capacityCalendar,
  shouldHideDayNotes = false,
  isSecondaryMetric = false,
  isSummary = false,
}: {
  metric?: string;
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
  shouldHideDayNotes?: boolean;
  isSecondaryMetric?: boolean;
  isSummary?: boolean;
}) => {
  const {positionName, secondaryPositionName} = capacityCalendar.additionalCapacityCalendarMetadata;
  switch (metric) {
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryTotalJobPositionCount
              : capacityCalendarDay.totalJobPositionCount
          }
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${isSecondaryMetric ? secondaryPositionName : positionName}s committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_DISPATCHED_BY_POSITION:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryTotalJobPositionDispatchedCount
              : capacityCalendarDay.totalJobPositionDispatchedCount
          }
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${isSecondaryMetric ? secondaryPositionName : positionName}s dispatched`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalTruckCount}
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${isSecondaryMetric ? secondaryPositionName : positionName}s committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.DAY_NOTES:
      if (!shouldHideDayNotes) {
        return <DayNotesMetric dayNotes={capacityCalendarDay.dayNotes} isSummary={isSummary} />;
      }
      return null;
    case CapacityCalendarSlotMetricKind.TOTAL_TRUCKS_AVAILABLE:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalTruckCount}
          denominator={capacityCalendarDay.availableTruckCount}
          subtitle={'Trucks committed'}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.Truck}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalAllJobUserCount}
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={'Crew committed'}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_JOBS:
      return (
        <NumberWithSubtitle
          number={capacityCalendarDay.totalJobCount}
          subtitle={'Total jobs'}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.BriefCaseBlank}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_COMMITTED:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalCrewCommitted}
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={'Crew committed'}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_DISPATCHED:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalCrewDispatched}
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={'Crew dispatched'}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_TRUCK_COMMITTED:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={capacityCalendarDay.totalTruckCommitted}
          denominator={capacityCalendarDay.availableTruckCount}
          subtitle={'Trucks committed'}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.Truck}
        />
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DaySlotMetric.fragment = gql`
  fragment DaySlotMetric on CapacityCalendar {
    capacityCalendarDays {
      totalJobPositionCount
      secondaryTotalJobPositionCount
      totalJobPositionDispatchedCount
      secondaryTotalJobPositionDispatchedCount
      availablePositionCount
      secondaryAvailablePositionCount
      dayNotes
      totalTruckCount
      totalJobCount
      availableTruckCount
      totalAllJobUserCount
      availableAllMoverCount
      totalCrewCommitted
      totalCrewDispatched
      totalTruckCommitted
    }
    additionalCapacityCalendarMetadata {
      positionName
      secondaryPositionName
    }
  }
`;

export default DaySlotMetric;
