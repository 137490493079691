// Libraries
import React from 'react';

// Supermove
import {Popover, Space} from '@supermove/components';

// App
import ActionMenu from '@shared/design/components/ActionMenu';

const SectionHeader = ({label, isFirst}: any) => {
  return (
    <React.Fragment>
      {!isFirst && (
        <React.Fragment>
          <Space height={12} />
          <ActionMenu.Divider />
          <Space height={12} />
        </React.Fragment>
      )}
      <ActionMenu.Label>{label}</ActionMenu.Label>
    </React.Fragment>
  );
};

const ActionMenuItem = ({handleClose, onPress, label, isDisabled, tooltip, color}: any) => {
  return (
    // @ts-expect-error TS(2322): Type '{ children: any; style: { paddingLeft: numbe... Remove this comment to see the full error message
    <ActionMenu.Item
      style={{paddingLeft: 24}}
      handleClose={handleClose}
      onPress={onPress}
      isDisabled={isDisabled}
      tooltip={tooltip}
      color={color}
    >
      {label}
    </ActionMenu.Item>
  );
};

const ProjectActionsPopover = ({projectActionPopover, projectActionSections}: any) => {
  return (
    <Popover
      key={projectActionPopover.key}
      placement={Popover.Positions.BottomStart}
      isOpen={projectActionPopover.isOpen}
      handleOpen={projectActionPopover.handleOpen}
      handleClose={projectActionPopover.handleClose}
      reference={projectActionPopover.ref}
      offset={[0, 4]}
    >
      <ActionMenu.Container width={400}>
        <Space height={12} />
        {projectActionSections.map(({label, actions}: any, index: any) => (
          <React.Fragment key={index}>
            <SectionHeader label={label} isFirst={index === 0} />
            {actions.map(
              (
                {label, onPress, handleClose, isDisabled, disabledExplanation, color}: any,
                actionIndex: any,
              ) => (
                <ActionMenuItem
                  key={actionIndex}
                  label={label}
                  onPress={onPress}
                  handleClose={handleClose}
                  isDisabled={isDisabled}
                  tooltip={isDisabled ? disabledExplanation : null}
                  color={color}
                />
              ),
            )}
          </React.Fragment>
        ))}
        <Space height={12} />
      </ActionMenu.Container>
    </Popover>
  );
};

export default ProjectActionsPopover;
