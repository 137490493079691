// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive, useState, useToast} from '@supermove/hooks';
import {RateTableModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import RateTableForm from '@shared/modules/RateTable/forms/RateTableForm';
import useUpdateRateTableMutation from '@shared/modules/RateTable/hooks/useUpdateRateTableMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import HourlyRateTablePage from 'modules/Organization/Settings/Billing/components/HourlyRateTablePage';
import HourlyRateTablePageHeader from 'modules/Organization/Settings/Billing/components/HourlyRateTablePageHeader';
import RateTableDetailsPanel from 'modules/Organization/Settings/Billing/components/RateTableDetailsPanel';
import RateTablePanel from 'modules/Organization/Settings/Billing/components/RateTablePanel';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const LoadingContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LoadingComponent = () => {
  return (
    <PageContainer>
      <HourlyRateTablePageHeader />
      <LoadingContainer>
        <PageLoadingIndicator />
      </LoadingContainer>
    </PageContainer>
  );
};

const EditHourlyRateTablePageContent = ({rateTable}: {rateTable: RateTableModel}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Hourly rate table updated!',
  });
  const [rateTableErrors, setRateTableErrors] = useState<string[]>([]);
  const rateTableForm = RateTableForm.edit(rateTable);
  const {form, submitting, handleSubmit} = useUpdateRateTableMutation({
    rateTableForm,
    onSuccess: ({rateTable}) => {
      setRateTableErrors([]);
      navigator.push('/settings/billing/hourly-rates');
      successToast.handleToast({message: `${rateTable.name} updated!`});
    },
    onError: (errors) => {
      console.log({errors});
      setRateTableErrors(RateTableForm.getRateTableErrors({errors}));
    },
  });
  return (
    <HourlyRateTablePage
      title={`Edit ${rateTable.name}`}
      isDirty={form.dirty}
      isSubmitting={submitting}
      handleSubmit={() => {
        setRateTableErrors([]);
        handleSubmit();
      }}
    >
      {responsive.desktop && <Space height={24} />}
      <RateTableDetailsPanel index={0} form={form} />
      {responsive.desktop && <Space height={24} />}
      <RateTablePanel key={rateTable.id} index={1} form={form} rateTableErrors={rateTableErrors} />
    </HourlyRateTablePage>
  );
};

const EditHourlyRateTablePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(EditHourlyRateTablePage.query, {
    // Network only is intentionally used here. When loading an existing table, don't use the cache at all.
    fetchPolicy: 'network-only',
    variables: {
      rateTableUuid: params.rateTableUuid,
    },
  });
  return (
    <PageContainer>
      <Loading loading={loading} as={LoadingComponent}>
        {() => <EditHourlyRateTablePageContent rateTable={data.rateTable} />}
      </Loading>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditHourlyRateTablePage.query = gql`
  ${RateTableForm.edit.fragment}
  query EditHourlyRateTablePage($rateTableUuid: String!) {
    ${gql.query}
    rateTable(uuid: $rateTableUuid) {
      id
      name
      ...RateTableForm_edit
    }
  }
`;

export default EditHourlyRateTablePage;
