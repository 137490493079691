// Libraries
import React from 'react';

// Supermove
import {Styled, DataLoader, IconSource} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import Callout from '@shared/design/components/Callout';
import SettingsPageTemplate from 'modules/Organization/Settings/components/SettingsPageTemplate';

const ContentContainer = Styled.View`
  flex: 1;
`;

const CalloutContainer = Styled.View`
  padding: 24px;
`;

const DocumentsSettingsPageContent = ({
  viewer,
  title,
  description,
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  children,
}: {
  viewer: UserModel;
  title?: string;
  description?: string;
  actionText?: string;
  actionIcon?: IconSource;
  handleAction?: () => void;
  isLoadingAction?: boolean;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const organization = viewer.viewingOrganization;

  return (
    <SettingsPageTemplate
      title={title}
      description={description}
      actionText={actionText}
      actionIcon={actionIcon}
      handleAction={handleAction}
      isLoadingAction={isLoadingAction}
      isDisabledAction={!organization.isOwnerOfSettings}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Documents'},
      ]}
      tabs={[
        {
          label: 'Templates',
          url: '/settings/documents/document-templates',
        },
        {
          label: 'Flows',
          url: '/settings/documents/document-flows',
        },
      ]}
    >
      <ContentContainer>
        {organization.isOwnerOfSettings ? (
          children
        ) : (
          <CalloutContainer>
            <Callout
              width={responsive.desktop ? 780 : undefined}
              text={
                `These settings are set by the company. Please contact the admins at ` +
                `${organization.company.primaryOrganization.name} to make adjustments.`
              }
            />
          </CalloutContainer>
        )}
      </ContentContainer>
    </SettingsPageTemplate>
  );
};

const DocumentsSettingsPage = ({
  title,
  description,
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  children,
}: {
  title?: string;
  description?: string;
  actionText?: string;
  actionIcon?: IconSource;
  handleAction?: () => void;
  isLoadingAction?: boolean;
  children: React.ReactNode;
}) => {
  const {loading, data} = useQuery(DocumentsSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <DataLoader loading={loading}>
      {() => {
        return (
          <DocumentsSettingsPageContent
            viewer={data.viewer}
            title={title}
            description={description}
            actionText={actionText}
            actionIcon={actionIcon}
            handleAction={handleAction}
            isLoadingAction={isLoadingAction}
          >
            {children}
          </DocumentsSettingsPageContent>
        );
      }}
    </DataLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentsSettingsPage.query = gql`
  query DocumentsSettingsPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        isOwnerOfSettings
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
      }
    }
  }
`;

export default DocumentsSettingsPage;
