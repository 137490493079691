// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Form, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {ProjectModel, UserFlowModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Switch from '@shared/design/components/Switch';
import {
  UserFlowExpirationUnitType,
  convertUserFlowExpirationUnitTypeToLabel,
} from '@shared/modules/UserFlows/enums/UserFlowExpirationUnitType';
import {UserFlowRunFormType} from '@shared/modules/UserFlows/forms/UserFlowRunForm';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';
import CreateDocumentFlowRunHeader from 'modules/Project/DocumentFlow/components/CreateDocumentFlowRunHeader';
import CreateDocumentFlowRunSteps from 'modules/Project/DocumentFlow/components/CreateDocumentFlowRunSteps';

const Container = Styled.View`
  flex: 1;
  max-width: 600px;
`;

const Column = Styled.View``;
const Row = Styled.View`
    flex-direction: row;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const toUserFlowExpirationUnitTypeOptions = () => {
  return Object.values(UserFlowExpirationUnitType).map((unitType) => ({
    label: convertUserFlowExpirationUnitTypeToLabel(unitType),
    value: unitType,
  }));
};

const CreateDocumentFlowRunPageContent = ({
  viewerId,
  project,
  userFlow,
  form,
  setIsGeneratingPreview,
}: {
  viewerId: string;
  project: ProjectModel;
  userFlow: UserFlowModel;
  form: Form<{userFlowRunForm: UserFlowRunFormType}>;
  setIsGeneratingPreview: (isGeneratingPreview: boolean) => void;
}) => {
  const responsive = useResponsive();

  const field = 'userFlowRunForm';
  const isExpirationEnabled = !!_.get(form.values, `${field}.expirationUnitType`);

  return (
    <ScrollView contentContainerStyle={{padding: 24, flex: 1, alignItems: 'center'}}>
      <Container>
        <CreateDocumentFlowRunHeader project={project} />
        <Space height={40} />
        <CreateDocumentFlowRunSteps
          userFlow={userFlow}
          form={form}
          field={`${field}.userFlowRunStepForms`}
        />
        <Space height={40} />
        <Row style={{alignItems: 'center'}}>
          <Title responsive={responsive}>Expiration</Title>
          <Space width={16} />
          <Switch
            isOn={isExpirationEnabled}
            onChange={() => {
              form.setFieldValue(
                `${field}.expirationUnitType`,
                isExpirationEnabled
                  ? undefined
                  : (userFlow.expirationUnitType ?? UserFlowExpirationUnitType.DAYS),
              );
              form.setFieldValue(
                `${field}.expirationValue`,
                isExpirationEnabled ? undefined : (userFlow.expirationValue ?? '1'),
              );
            }}
            labelRight={isExpirationEnabled ? 'Enabled' : 'Disabled'}
            isResponsive
          />
        </Row>
        <Space height={8} />
        <DescriptionText responsive={responsive}>
          When enabled, this process link will automatically expire after a specified timeframe.
        </DescriptionText>
        {isExpirationEnabled && (
          <React.Fragment>
            <Space height={16} />
            <Row>
              <FieldInput
                {...form}
                index={3}
                name={`${field}.expirationValue`}
                label={'Expires in'}
                style={{flex: 1}}
                isResponsive
                isRequired
                isNumeric
                input={{
                  placeholder: 'Enter number',
                  keyboardType: 'numeric',
                }}
              />
              <Space width={8} />
              <FieldInput
                {...form}
                index={4}
                name={`${field}.expirationUnitType`}
                label={''}
                isResponsive
                component={DropdownInput}
                input={{
                  options: toUserFlowExpirationUnitTypeOptions(),
                  value: _.get(form.values, `${field}.expirationUnitType`),
                  style: {width: '104px'},
                  setFieldValue: form.setFieldValue,
                  placeholder: 'Select a project',
                  isPortaled: true,
                }}
              />
            </Row>
          </React.Fragment>
        )}
        <Space height={40} />
        <Title>Email Draft</Title>
        <Space height={16} />
        <ProjectEmailFields
          form={form}
          field={`${field}.emailForm`}
          viewerId={viewerId}
          project={project}
          defaultEmailTemplateForm={form.values.defaultEmailTemplateForm}
          setIsGeneratingPreview={setIsGeneratingPreview}
          metadata={JSON.stringify({
            userFlowId: userFlow.id,
            userFlowRunUuid: form.values.userFlowRunForm.userFlowRunUuid,
          })}
        />
        <Space height={40} />
        <Column>
          <EmailAttachmentsSelector
            form={form}
            name={field ? `${field}.emailForm` : undefined}
            project={project}
          />
        </Column>
        <Space height={40} />
      </Container>
    </ScrollView>
  );
};

CreateDocumentFlowRunPageContent.fragment = gql`
  ${ProjectEmailFields.fragment}
  ${EmailAttachmentsSelector.fragment}
  ${CreateDocumentFlowRunHeader.fragment}
  ${CreateDocumentFlowRunSteps.fragment}
  fragment CreateDocumentFlowRunPageContentFragment_Project on Project {
    id
    ...ProjectEmailFields
    ...EmailAttachmentsSelector
    ...CreateDocumentFlowRunHeaderFragment_Project
  }

  fragment CreateDocumentFlowRunPageContentFragment_UserFlow on UserFlow {
    id
    expirationUnitType
    expirationValue
    ...CreateDocumentFlowRunStepsFragment
  }
`;

export default CreateDocumentFlowRunPageContent;
