// Libraries
import React from 'react';

// Supermove
import {DataLoader} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {UserFlowRunModel} from '@supermove/models';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import DocumentFlowRunStepsProgress from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowRunStepsProgress';

const DocumentFlowRunDetailsDrawerContent = ({
  userFlowRun,
  isOpen,
  handleClose,
}: {
  userFlowRun: UserFlowRunModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={`${userFlowRun.userFlow.name} Details`}
      width={480}
    >
      <DocumentFlowRunStepsProgress userFlowRunSteps={userFlowRun.userFlowRunSteps} />
    </DrawerWithClose>
  );
};

const DocumentFlowRunDetailsDrawer = ({
  isOpen,
  userFlowRunUuid,
  handleClose,
}: {
  isOpen: boolean;
  userFlowRunUuid: string;
  handleClose: () => void;
}) => {
  const {data} = useQuery<{userFlowRun: UserFlowRunModel}>(DocumentFlowRunDetailsDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: userFlowRunUuid,
    },
    skip: !isOpen,
  });

  return (
    <DataLoader loading={!data} data={data}>
      {({loadedData}) => (
        <DocumentFlowRunDetailsDrawerContent
          userFlowRun={loadedData.userFlowRun}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}
    </DataLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunDetailsDrawer.query = gql`
  ${DocumentFlowRunStepsProgress.fragment}

  query DocumentFlowRunDetailsDrawer($uuid: String!) {
    ${gql.query}
    userFlowRun(uuid: $uuid) {
      id
      userFlow {
        name
      }
      userFlowRunSteps {
        id
        ...DocumentFlowRunStepsProgress
      }
    }
  }
`;

export default DocumentFlowRunDetailsDrawer;
