import {colors} from '@supermove/styles';

const ALL_STATUSES = ['INCOMPLETE', 'COMPLETED'] as const;

const convertUserFlowRunStepStatusToLabel = (status: (typeof ALL_STATUSES)[number]) => {
  switch (status) {
    case 'INCOMPLETE':
      return 'Incomplete';
    case 'COMPLETED':
      return 'Completed';
  }
};

const convertStatusToColor = (status: (typeof ALL_STATUSES)[number]) => {
  switch (status) {
    case UserFlowRunStepStatus.COMPLETED:
      return colors.green700;
    case UserFlowRunStepStatus.INCOMPLETE:
      return colors.orange700;
  }
};

const UserFlowRunStepStatus = {
  INCOMPLETE: 'INCOMPLETE',
  COMPLETED: 'COMPLETED',

  ALL_STATUSES,

  // Helpers
  convertUserFlowRunStepStatusToLabel,
  convertStatusToColor,
} as const;

export default UserFlowRunStepStatus;
