// Libararies
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useDebouncedCallback, useResponsive, useState, ResponsiveType} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SearchBar from '@shared/design/components/SearchBar';
import OrganizationSettingsDocumentFlowRunsFilter from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowRunsFilter';
import {OrganizationSettingsDocumentFlowRusUrlFilters} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowRunsFiltersType';

const Container = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const DocumentsSettingsDocumentFlowRunsPageHeader = ({
  title,
  urlFilters,
}: {
  title: string;
  urlFilters: OrganizationSettingsDocumentFlowRusUrlFilters;
}) => {
  const responsive = useResponsive();
  const [searchQuery, setSearchQuery] = useState(urlFilters.get('searchQuery'));

  const debouncedFilterUpdate = useDebouncedCallback(
    (query: string) => urlFilters.handleUpdate({searchQuery: query}),
    300,
  );
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    debouncedFilterUpdate(searchQuery);
  };

  return (
    <Container responsive={responsive}>
      <Title responsive={responsive}>{title}</Title>
      <Space height={responsive.desktop ? 24 : 16} />
      <HeaderRow>
        <SearchBar
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          defaultValue={''}
          placeholder={`Search${responsive.desktop ? ' by project identifier' : ''}`}
          onChangeText={handleSearch}
          valueOverride={searchQuery}
          isClearable
          isResponsive
        />
        <Space width={8} />
        <OrganizationSettingsDocumentFlowRunsFilter
          urlFilters={urlFilters}
          handleApply={urlFilters.handleUpdate}
        />
      </HeaderRow>
    </Container>
  );
};
export default DocumentsSettingsDocumentFlowRunsPageHeader;
