// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';
import Line from 'modules/App/components/Line';

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2};
`;

const Row = Styled.View`
  flex-direction: row;
`;

type CreateDocumentFlowRunPageHeaderProps = {
  userFlow: UserFlowModel;
  isLoading: boolean;
  handleSend: () => void;
};

const CreateDocumentFlowRunPageHeader = ({
  userFlow,
  isLoading,
  handleSend,
}: CreateDocumentFlowRunPageHeaderProps) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      <Space height={16} />
      <HeaderContainer>
        <Row style={{alignItems: 'center', flex: 1}}>
          <Space width={responsive.desktop ? 24 : 16} />
          <IconButton source={Icon.Xmark} isSecondary onPress={navigator.goBack} size={20} />
          <Space width={16} />
          <Title responsive={responsive}>Send {userFlow.name}</Title>
        </Row>
        <Space />
        <Row>
          <Button
            text={'Send'}
            iconLeft={Icon.Check}
            onPress={handleSend}
            isSubmitting={isLoading}
          />
          <Space width={responsive.desktop ? 24 : 16} />
        </Row>
      </HeaderContainer>
      <Space height={16} />
      <Line />
    </React.Fragment>
  );
};

CreateDocumentFlowRunPageHeader.fragment = gql`
  fragment CreateDocumentFlowRunPageHeaderFragment on UserFlow {
    id
    name
  }
`;

export default CreateDocumentFlowRunPageHeader;
