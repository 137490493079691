// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Config} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {Document} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const handleFileDownload = (codatIntegration: any) => async () => {
  const url = `${Config.getAPIHost()}/v1/files/tax_rates/${codatIntegration.id}`;
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${codatIntegration.organization.apiToken}`,
    },
  });
  const blob = await response.blob();
  // @ts-expect-error TS(2739): Type 'Blob' is missing the following properties fr... Remove this comment to see the full error message
  Document.clickFile({file: blob, filename: 'filename.csv'});
};

const DownloadTaxRateButton = ({codatIntegration}: any) => {
  return (
    <SecondaryButton onPress={handleFileDownload(codatIntegration)} text='Download Tax Rates CSV' />
  );
};

const ExternalInvoiceItemsDropdown = ({form, field, label, options, isDisabled = false}: any) => {
  return (
    <FieldInput
      {...form}
      label={label}
      name={`codatIntegrationForm.${field}`}
      component={DropdownInput}
      input={{
        isPortaled: true,
        options,
        isClearable: true,
        placeholder: 'Select default item',
        setFieldValue: form.setFieldValue,
        style: {
          flex: 1,
        },
      }}
      style={{
        flex: 1,
      }}
    />
  );
};

const ViewCell = ({label, placeholder = 'Select default item', value}: any) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const CodatSalesTaxSettingsPanelEdit = ({form, codatIntegration}: any) => {
  const options = codatIntegration.organization.variables.map((variable: any) => ({
    label: variable.name,
    value: variable.id,
  }));
  const externalInvoiceOptions = codatIntegration.externalInvoiceItems.map(
    (externalInvoiceOption: any) => ({
      value: _.toString(externalInvoiceOption.id),
      label: externalInvoiceOption.name,
    }),
  );

  const {isEnabledCustomSalesTaxExports} = form.values.codatIntegrationForm;

  return (
    <React.Fragment>
      <Row>
        <Column>
          <EditPanel.LabelText>{'Enable Custom Sales Tax Exports'}</EditPanel.LabelText>
          <Space height={8} />
          <SwitchField
            label={'Enable Custom Sales Tax Exports'}
            // @ts-expect-error TS(2322): Type '{ label: string; name: string; form: any; fi... Remove this comment to see the full error message
            name={'codatIntegrationForm.isEnabledCustomSalesTaxExports'}
            form={form}
            field={'codatIntegrationForm.isEnabledCustomSalesTaxExports'}
            onChangeValue={(value) => {
              if (!value) {
                form.setFieldValue('codatIntegrationForm.customSalesTaxRateVariableId', null);
              } else {
                form.setFieldValue('codatIntegrationForm.salesTaxExternalInvoiceItemId', null);
              }
            }}
          />
        </Column>
      </Row>
      <Space height={16} />

      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Custom Sales Tax Rate Variable'}
            name={`codatIntegrationForm.customSalesTaxRateVariableId`}
            isRequired={isEnabledCustomSalesTaxExports}
            component={DropdownInput}
            input={{
              disabled: !isEnabledCustomSalesTaxExports,

              isPortaled: true,
              options,
              placeholder: 'Choose Source Variable',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              flex: 1,
            }}
          />
        </Column>
      </Row>
      <Space height={16} />

      <Row>
        <Column>
          <ExternalInvoiceItemsDropdown
            form={form}
            field={'salesTaxExternalInvoiceItemId'}
            label={'Sales Tax External Invoice Item'}
            options={externalInvoiceOptions}
            isDisabled={isEnabledCustomSalesTaxExports}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const CodatSalesTaxSettingsPanel = ({codatIntegration}: any) => {
  const {
    salesTaxExternalInvoiceItem,
    isEnabledCustomSalesTaxExports,
    customSalesTaxRateVariableId,
  } = codatIntegration;
  const matchingVariable = _.find(codatIntegration.organization.variables, {
    id: String(customSalesTaxRateVariableId),
  });

  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Enable Custom Sales Tax Exports'}
            value={isEnabledCustomSalesTaxExports ? 'Yes' : 'No'}
          />
        </Column>
        <Space style={{flex: 1}} />
        <Column>
          <DownloadTaxRateButton codatIntegration={codatIntegration} />
        </Column>
      </Row>
      <Space height={16} />
      <Row>
        <Column>
          <ViewCell
            label={
              isEnabledCustomSalesTaxExports
                ? 'Custom Sales Tax Rate Variable'
                : 'Sales Tax External Invoice Item'
            }
            value={
              isEnabledCustomSalesTaxExports
                ? matchingVariable?.name
                : salesTaxExternalInvoiceItem?.name
            }
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

CodatSalesTaxSettingsPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
      <Space height={16} />
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

CodatSalesTaxSettingsPanel.fragment = gql`
  fragment CodatSalesTaxSettingsPanel on CodatIntegration {
    id
    salesTaxExternalInvoiceItemId
    salesTaxExternalInvoiceItem {
      id
      name
    }
    externalInvoiceItems {
      id
      name
    }
    isEnabledCustomSalesTaxExports
    customSalesTaxRateVariableId
    organization {
      variables
      id
      name
    }
  }
`;

CodatSalesTaxSettingsPanel.Edit = CodatSalesTaxSettingsPanelEdit;

export default CodatSalesTaxSettingsPanel;
