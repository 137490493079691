// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useInlineFormMutation} from '@supermove/hooks';
import {CancellationReasonModel} from '@supermove/models';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';

const DeleteCancellationReasonModal = ({
  isOpen,
  handleClose,
  cancellationReason,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  cancellationReason: CancellationReasonModel;
  refetch: () => void;
}) => {
  const {submitting, handleSubmit} = useInlineFormMutation({
    name: 'cancellationReasonForm',
    form: {
      cancellationReasonId: cancellationReason.id,
      organizationId: cancellationReason.organizationId,
      name: cancellationReason.name,
    },
    mutation: gql`
      mutation DeleteCancellationReasonModalMutation($cancellationReasonForm: CancellationReasonForm!) {
        response: deleteCancellationReason(cancellationReasonForm: $cancellationReasonForm) {
          ${gql.errors}
          cancellationReason {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete "${cancellationReason.name}"`}
      subtitle={`Deleting this reason will not affect any project that is using it.`}
      handleDelete={handleSubmit}
      handleClose={handleClose}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteCancellationReasonModal.fragment = gql`
  fragment DeleteCancellationReasonModal on CancellationReason {
    id
    organizationId
    name
  }
`;

export default DeleteCancellationReasonModal;
