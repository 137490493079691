import {colors} from '@supermove/styles';

const ALL_STATUSES = ['CREATED', 'SENT', 'VIEWED', 'IN_PROGRESS', 'COMPLETED'] as const;

const convertUserFlowRunStatusToLabel = (status: (typeof ALL_STATUSES)[number]) => {
  switch (status) {
    case UserFlowRunStatus.CREATED:
      return 'Created';
    case UserFlowRunStatus.SENT:
      return 'Sent';
    case UserFlowRunStatus.VIEWED:
      return 'Viewed';
    case UserFlowRunStatus.IN_PROGRESS:
      return 'In Progress';
    case UserFlowRunStatus.COMPLETED:
      return 'Completed';
  }
};

const convertUserFlowRunStatusToColor = (status: (typeof ALL_STATUSES)[number]) => {
  switch (status) {
    case UserFlowRunStatus.CREATED:
      return colors.orange700;
    case UserFlowRunStatus.SENT:
      return colors.orange700;
    case UserFlowRunStatus.VIEWED:
      return colors.purple700;
    case UserFlowRunStatus.IN_PROGRESS:
      return colors.blue400;
    case UserFlowRunStatus.COMPLETED:
      return colors.green700;
  }
};

const UserFlowRunStatus = {
  CREATED: 'CREATED',
  SENT: 'SENT',
  VIEWED: 'VIEWED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',

  ALL_STATUSES,

  // Helpers
  convertUserFlowRunStatusToLabel,
  convertUserFlowRunStatusToColor,
} as const;

export default UserFlowRunStatus;
