// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useModal,
  useResponsive,
  usePagination,
  useState,
  useDrawer,
} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {Config} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';
import {Document, Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useSyncCodatLineItemsForCodatIntegrationMutation from '@shared/modules/Integration/hooks/useSyncCodatLineItemsForCodatIntegrationMutation';
import EditBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeDrawer';
import ImportExternalInvoiceItemsModal from 'modules/Organization/Settings/Company/components/ImportExternalInvoiceItemsModal';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const TableContainer = Styled.View`
  width: 100%;
`;

const Row = Styled.View`
  justify-content: space-between;
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'isMobile' does not exist on type 'ThemeP... Remove this comment to see the full error message
    isMobile,
  }) => (isMobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const TitleRow = Styled.View`
  flex-direction: row
`;

const Container = Styled.View``;

const Column = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MicroGrayText = Styled.Text`
  padding-top: 10px;
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const getExternalInvoiceItemsColumnDefinitions = ({viewer, refetch, responsive}: any) => [
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Name</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return <Table.CellText>{item.name}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => <Table.HeaderText>Description</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return <Table.CellText>{item.description}</Table.CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Category</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return <Table.CellText>{item.category}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => <Table.HeaderText>Bill Item Types</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return (
        <Table.CellText>
          {item.billItemTypes.map((billItemType: any, index: any) => (
            <React.Fragment key={index}>
              <EditBillItemTypeLinkButton
                billItemType={billItemType}
                viewer={viewer}
                refetch={refetch}
                responsive={responsive}
              />
              {index !== item.billItemTypes.length - 1 && <Table.CellText>{', '}</Table.CellText>}
            </React.Fragment>
          ))}
        </Table.CellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Last Imported</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return (
        <Table.CellText>{Datetime.convertToDisplayDatetime(item.lastSyncedAt)}</Table.CellText>
      );
    },
  },
];

const EditBillItemTypeLinkButton = ({billItemType, viewer, refetch, responsive}: any) => {
  const editBillItemTypeDrawer = useDrawer({
    name: 'Edit Bill Item Type Drawer',
    // @ts-expect-error TS(2345): Argument of type '{ name: string; useTracking: boo... Remove this comment to see the full error message
    useTracking: true,
  });
  const {parentBillItemType} = billItemType;
  return (
    <React.Fragment>
      <LinkButton onPress={editBillItemTypeDrawer.handleOpen}>
        <LinkText responsive={responsive}>{`${billItemType.name}${
          _.isEmpty(billItemType.moverPosition) ? '' : ` (${billItemType.moverPosition.name})`
        }`}</LinkText>
      </LinkButton>
      <EditBillItemTypeDrawer
        key={editBillItemTypeDrawer.key}
        isOpen={editBillItemTypeDrawer.isOpen}
        handleClose={editBillItemTypeDrawer.handleClose}
        billItemType={parentBillItemType || billItemType}
        refetch={refetch}
        userId={viewer.id}
      />
    </React.Fragment>
  );
};

const handleFileDownload = (organization: any) => async () => {
  const url = `${Config.getAPIHost()}/v1/files/external_invoice_items/${
    organization.codatIntegrationByUuid.id
  }`;
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${organization.apiToken}`,
    },
  });
  const blob = await response.blob();
  // @ts-expect-error TS(2739): Type 'Blob' is missing the following properties fr... Remove this comment to see the full error message
  Document.clickFile({file: blob, filename: 'filename.csv'});
};

const AccountingItemsContent = ({organization, refetch, pagination, viewer}: any) => {
  const responsive = useResponsive();
  const importExternalInvoiceItemsModal = useModal({name: 'Import External Invoice Items Modal'});
  const syncCodatLineItemsModal = useModal({name: 'Sync Codat Line Items Modal'});
  const {codatIntegrationByUuid} = organization;
  const textObject = {
    title: 'Import CSV File: External Invoice Items',
    description:
      'Upload a CSV file of external invoice items into the system. The headers of the CSV file must exactly match the list below.',
    textValidationNames: [
      'ID: required',
      'Identifier:Required',
      'Name: required',
      'Description: required',
      'Category: required',
      'Bill Item Type ID: required',
    ],
    textHeaders: 'ID,Identifier,Name,Description,Category,Bill Item Type ID',
  };
  const {handleSubmit, submitting} = useSyncCodatLineItemsForCodatIntegrationMutation({
    codatIntegrationForm: CodatIntegrationForm.edit(codatIntegrationByUuid),
    onSuccess: () => {
      syncCodatLineItemsModal.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log(errors),
  });

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '100%'}}>
        <Column>
          <TitleRow>
            <Title responsive={responsive}>Accounting Items Settings</Title>
            <Space width={16} />
            <Badge
              label={organization.codatIntegrationByUuid.sourceKindDisplayName}
              color={colors.gray.secondary}
              size={Badge.SIZE.LARGE}
            />
          </TitleRow>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export items from your accounting integration ` +
              `to automatically match with your Supermove billing libraries.`}
          </Description>
        </Column>
        {responsive.mobile && <Space height={12} />}
        <ButtonContainer>
          {codatIntegrationByUuid.codatLastSync && (
            <React.Fragment>
              <Space height={12} />
              <MicroGrayText
                responsive={responsive}
              >{`Last imported: ${Datetime.convertToDisplayDatetime(
                codatIntegrationByUuid.codatLastSync,
              )}`}</MicroGrayText>
              <Space width={16} />
            </React.Fragment>
          )}
          <SecondaryButton
            text={'Import'}
            onPress={syncCodatLineItemsModal.handleOpen}
            iconLeft={Icon.Sync}
          />
          <Space width={16} />
          <DropdownButton
            text={'CSV'}
            actions={[
              {
                text: 'Import via CSV',
                onPress: importExternalInvoiceItemsModal.handleOpen,
              },
              {
                text: 'Export via CSV',
                onPress: handleFileDownload(organization),
              },
            ]}
            isDisabled
            ButtonComponent={SecondaryButton}
            // @ts-expect-error TS(2322): Type '{ text: string; actions: { text: string; onP... Remove this comment to see the full error message
            iconRight={Icon.ChevronDown}
            menuWidth={160}
            menuPosition={DropdownButton.MENU_POSITION.RIGHT}
          />
        </ButtonContainer>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table
          columnDefinitions={getExternalInvoiceItemsColumnDefinitions({
            viewer,
            refetch,
            responsive,
          })}
          emptyStateText='No external invoice items to display'
          items={codatIntegrationByUuid.externalInvoiceItemsPaginatedList.externalInvoiceItems}
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
      <Container>
        <PaginationBar pagination={pagination} />
      </Container>
      <Space height={96} />
      <ImportExternalInvoiceItemsModal
        key={importExternalInvoiceItemsModal.key}
        isOpen={importExternalInvoiceItemsModal.isOpen}
        handleClose={importExternalInvoiceItemsModal.handleClose}
        refetch={refetch}
        textObject={textObject}
      />
      <ConfirmationModal
        icon={Icon.Sync}
        title={'Import accounting items?'}
        subtitle={'Please allow 5-10 minutes for this to complete.'}
        isOpen={syncCodatLineItemsModal.isOpen}
        key={syncCodatLineItemsModal.key}
        handleSecondaryAction={syncCodatLineItemsModal.handleClose}
        handlePressOutside={syncCodatLineItemsModal.handleClose}
        secondaryActionText={'Cancel'}
        primaryActionText={'Confirm'}
        handlePrimaryAction={handleSubmit}
        isSubmitting={submitting}
      />
    </React.Fragment>
  );
};

const AccountingItemSettingsLoading = () => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '1280px'}}>
        <Column>
          <Title responsive={responsive}>Accounting Items Settings</Title>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export items from your accounting integration ` +
              `to automatically match with your Supermove billing libraries.`}
          </Description>
        </Column>
        <Space height={12} />
        <ButtonContainer>
          <SecondaryButton text={'Import Items'} isDisabled />
          <Space width={16} />
          <DropdownButton
            text={'CSV'}
            isDisabled
            actions={[]}
            ButtonComponent={SecondaryButton}
            iconLeft={Icon.Upload}
            menuWidth={160}
          />
        </ButtonContainer>
      </Row>
      <Space height={24} />
      <ScrollView
        horizontal
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <TableContainer>
          <Table
            columnDefinitions={[]}
            emptyStateText='No external invoice items to display'
            items={[]}
            loading
            itemKey={'id'}
            isDense
          />
        </TableContainer>
      </ScrollView>
      <Space height={24} />
    </React.Fragment>
  );
};

// DEPRECATED
const OrganizationSettingsCompanyIntegrationsAccountingItemsPage = () => {
  const {navigator, params} = useNavigationDOM();
  const {uuid} = params;
  const [currentPage, setCurrentPage] = useState(1);
  const {loading, data, refetch} = useQuery(
    OrganizationSettingsCompanyIntegrationsAccountingItemsPage.query,
    {
      // 'network-only' is used to avoid using empty cached values
      fetchPolicy: 'network-only',
      variables: {
        pagination: {
          page: currentPage,
          resultsPerPage: 20,
        },
        uuid,
      },
    },
  );

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.codatIntegrationByUuid.externalInvoiceItemsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {
          name: 'Company Settings',
          onPress: () => navigator.push('/settings/company/business-info'),
        },
        {name: 'Accounting', onPress: () => navigator.push('/settings/company/accounting')},
        {name: 'Accounting Items'},
      ]}
      tabs={[
        {
          label: 'General',
          url: `/settings/company/accounting/${params.uuid}/general`,
        },
        {
          label: 'Import',
          url: `/settings/company/accounting/${params.uuid}/imports`,
        },
        {
          label: 'Logs',
          url: `/settings/company/accounting/${params.uuid}/logs`,
        },
        {
          label: 'Exports',
          url: `/settings/company/accounting/${params.uuid}/exports`,
        },
      ]}
    >
      {loading ? (
        <AccountingItemSettingsLoading />
      ) : (
        <AccountingItemsContent
          organization={data.viewer.viewingOrganization}
          refetch={refetch}
          pagination={pagination}
          viewer={data.viewer}
          navigator={navigator}
          params={params}
        />
      )}
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyIntegrationsAccountingItemsPage.query = gql`
  ${CodatIntegrationForm.edit.fragment}
  ${usePagination.fragment}
  ${EditBillItemTypeDrawer.fragment}

  query OrganizationSettingsCompanyIntegrationsAccountingItemsPage(
  $pagination: PaginationInput!
    $uuid: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        apiToken
        codatIntegrationByUuid(uuid: $uuid){
          id
          codatLastSync
          sourceKindDisplayName
          externalInvoiceItemsPaginatedList(
            pagination: $pagination
          ) {
            externalInvoiceItems: results {
              category
              description
              id
              identifier
              name
              lastSyncedAt
              billItemTypes {
                id
                name
                moverPosition {
                  id
                  name
                }
                parentBillItemType {
                  id
                  name
                  ...EditBillItemTypeDrawer
                }
                ...EditBillItemTypeDrawer
              }
            }
            paginationMetadata {
              ...usePagination
            }
          }
          ...CodatIntegrationForm_edit
        }
      }
    }
  }
`;

export default OrganizationSettingsCompanyIntegrationsAccountingItemsPage;
