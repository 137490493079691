// Libararies
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useState, useTextInput, useQuery, useModal} from '@supermove/hooks';

// App
import ThreadKind from '@shared/modules/Thread/enums/ThreadKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import EmailThreads from 'modules/Communication/components/EmailThreads';
import SendEmailForProjectModal from 'modules/Email/Project/components/SendEmailForProjectModal';

const ProjectEmailsWidget = ({project}: any) => {
  const editClientModal = useModal({name: 'Edit Client Modal - Project Emails Widget'});
  const sendEmailForProjectModal = useModal({
    name: 'Send Email For Project Modal - Project Emails Widget',
  });
  const [selectedEmailTemplateUuid, setSelectedEmailTemplateUuid] = useState('');
  const [selectedThread, setSelectedThread] = useState(null);
  const searchInput = useTextInput();
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeQuery = useDebouncedCallback((query) => {
    setSearchQuery(query);
    setSelectedThread(null);
  }, 250);
  const {loading, data, refetch} = useQuery(ProjectEmailsWidget.query, {
    fetchPolicy: 'network-only',
    variables: {
      userUuid: project.client.primaryContact.uuid,
      kind: ThreadKind.EMAIL,
      searchQuery,
      projectId: project.id,
      projectUuid: project.uuid,
    },
  });

  return (
    <Loading loading={loading || !data} as={PageLoadingIndicator}>
      {() => (
        <React.Fragment>
          <EmailThreads
            emailThreads={data.filteredThreads}
            handleMissingContactInfo={editClientModal.handleOpen}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            searchInput={searchInput}
            searchQuery={searchQuery}
            handleChangeQuery={handleChangeQuery}
            isLoading={loading}
            customer={data.user}
            organization={data.project.organization}
            refetch={refetch}
            handleComposeEmail={({emailTemplateUuid = ''}) => {
              setSelectedEmailTemplateUuid(emailTemplateUuid);
              sendEmailForProjectModal.handleOpen();
            }}
          />
          <EditClientModal
            key={editClientModal.key}
            isOpen={editClientModal.isOpen}
            handleClose={editClientModal.handleClose}
            clientUuid={project.client.uuid}
            // A refetch is not needed since the mutation response updates client cache
            refetch={() => {}}
          />
          <SendEmailForProjectModal
            key={sendEmailForProjectModal.key}
            project={data.project}
            isOpen={sendEmailForProjectModal.isOpen}
            handleClose={() => {
              sendEmailForProjectModal.handleClose();
              setSelectedEmailTemplateUuid('');
              refetch();
            }}
            emailTemplateUuid={selectedEmailTemplateUuid}
          />
        </React.Fragment>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEmailsWidget.query = gql`
  ${EmailThreads.fragment}
  ${SendEmailForProjectModal.fragment}
  query ProjectEmailsWidget(
    $userUuid: String!,
    $projectId: Int,
    $kind: String,
    $searchQuery: String,
    $projectUuid: String!,
    ) {
      ${gql.query}
      user(uuid: $userUuid) {
        id
        ...EmailThreads_User
      }
      filteredThreads(projectId: $projectId, kind: $kind, searchQuery: $searchQuery) {
        id
        ...EmailThreads_Thread
      }
      project(uuid: $projectUuid) {
        id
        organization {
          id
          ...EmailThreads_Organization
        }
        ...SendEmailForProjectModal
      }
  }
`;

ProjectEmailsWidget.fragment = gql`
  fragment ProjectEmailsWidget on Project {
    id
    uuid
    client {
      id
      uuid
      primaryContact {
        id
        uuid
      }
    }
  }
`;

export default ProjectEmailsWidget;
