/**
 * TODO(herrick): Replace this file with ProjectJobInfoBlock to consolidate
 * logic once create/update project have been migrated.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Responsive, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, ResponsiveType} from '@supermove/hooks';
import {Job, OrganizationModel} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import JobForm from '@shared/modules/Job/forms/JobForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import JobTypeDropdown from 'modules/Job/components/JobTypeDropdown';
import DateRangeInput from 'modules/Project/V2/components/DateRangeInput';

const Section = Styled.View<{index: number}>`
  z-index: ${(props) => 100 - props.index}px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View<{mobile?: boolean; index?: number}>`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: flex-start;
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View<{mobile: boolean}>`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const RowField = Styled.View`
`;

const RowFieldLabel = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const RowFieldFields = Styled.View`
  flex-direction: row;
`;

const RowFieldSpace = Styled.View`
  margin-left: 10px;
`;

const DesktopRow = Styled.View<{responsive: ResponsiveType; index?: number}>`
  flex-direction: ${({responsive}) => (responsive.mobile ? 'column' : 'row')};
  align-items: flex-start;
  z-index: ${({index = 0}) => 100 - index};
  width: ${({responsive}) => (responsive.desktop ? '510px' : '100%')};
`;

const FieldSpace = Styled.View`
  margin: 5px;
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
`;

const DateRangeToggleButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const DateRangeToggleText = Styled.Text<{color: string}>`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const getFieldName = ({field, name}: {field?: string; name: string}) => {
  return field ? `${field}.${name}` : name;
};

const getFieldValue = ({form, field, name}: {form: any; field: string; name: string}) => {
  return _.get(form.values, `${field}.${name}`);
};

const handleToggleDateRange = ({
  form,
  field,
  wasEstimatedRange,
  startDate,
}: {
  form: any;
  field: string;
  wasEstimatedRange?: boolean;
  startDate?: unknown;
}) => {
  // When toggling the date, we reset the current values that we're toggling away
  // from, but the date value and the startDate value get passed to each other.
  if (wasEstimatedRange) {
    form.setFieldValue(`${field}.isEstimatedRange`, false);
    form.setFieldValue(`${field}.date`, startDate || _.get(form.values, `${field}.startDate`));
    form.setFieldValue(`${field}.startDate`, '');
    form.setFieldValue(`${field}.endDate`, '');
  } else {
    form.setFieldValue(`${field}.isEstimatedRange`, true);
    form.setFieldValue(`${field}.startDate`, startDate || _.get(form.values, `${field}.date`));
    form.setFieldValue(`${field}.date`, '');
  }
};

interface BaseJobScheduleFieldsProps {
  disabled?: boolean;
  field: string;
  form: any;
}

interface BaseJobScheduleFieldPropsWithOrg extends BaseJobScheduleFieldsProps {
  responsive: Responsive;
  organization: OrganizationModel;
}

const DateRangeToggle = ({
  form,
  field,
  isEstimatedRange,
  disabled,
}: BaseJobScheduleFieldsProps & {
  isEstimatedRange?: boolean;
}) => {
  const color = disabled ? colors.gray.tertiary : colors.blue.interactive;

  return (
    <DateRangeToggleButton
      onPress={() => handleToggleDateRange({form, field, wasEstimatedRange: isEstimatedRange})}
      disabled={disabled}
    >
      <Icon source={isEstimatedRange ? Icon.Trash : Icon.Plus} size={10} color={color} />
      <Space width={5} />
      <DateRangeToggleText color={color}>End Date</DateRangeToggleText>
    </DateRangeToggleButton>
  );
};

const SingleDateInput = ({
  disabled,
  field,
  form,
  label,
  required,
  organization,
}: BaseJobScheduleFieldsProps & {
  label: string;
  required?: boolean;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  const dateFieldName = getFieldName({field, name: 'date'});
  const date = _.get(form.values, dateFieldName);
  const projectTypeId = _.get(form.values, 'projectForm.projectTypeId');
  const projectType = organization.moveProjectTypes.find(
    (projectType) => projectType.id === _.toString(projectTypeId),
  );
  const jobTypeId = _.get(form.values, `${field}.jobTypeId`);
  const jobType = organization.jobTypes.find((jobType) => jobType.id === _.toString(jobTypeId));
  const hasDispatchLocks = jobType && !['ESTIMATE', 'REQUEST'].includes(jobType.kind);
  const excludeDates =
    projectType && isEnabledCreateProjectDispatchLocks && hasDispatchLocks
      ? projectType.futureSalesLockedProjectTypeDays.map(
          (projectTypeDay) => projectTypeDay.day.date,
        )
      : [];

  const isDateInputDisabled = isEnabledCreateProjectDispatchLocks && !projectTypeId;
  const isDateInPast = Datetime.isPast(date);

  return (
    // FieldInput is not memoized because the DateInput is listening for changes to excludeDates
    // excludeDates is being set when ProjectTypeDropdown is changed
    <FieldInput
      {...form}
      index={0}
      component={DateInput}
      name={dateFieldName}
      warningMessage={isDateInPast ? 'Selected date is in the past.' : undefined}
      hasWarning={isDateInPast}
      label={
        <React.Fragment>
          {label}{' '}
          {excludeDates.length !== 0 && (
            <TextTooltip
              isEnabledMobileToast={false}
              placement={'right'}
              text={`Disabled dates have been locked by dispatch.`}
            >
              <IconContainer>
                <Icon
                  color={colors.gray.tertiary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          )}
        </React.Fragment>
      }
      action={
        <DateRangeToggle
          form={form}
          field={field}
          isEstimatedRange={false}
          disabled={isDateInputDisabled}
        />
      }
      input={{
        disabled: disabled || isDateInputDisabled,
        required: required && !date,
        onChangeDate: () => {
          form.setTouched({...form.touched, [dateFieldName]: true});
        },
        placeholder: 'Enter date',
        setFieldValue: form.setFieldValue,
        style: responsive.desktop ? {width: 250} : {width: '100%'},
        excludeDates,
      }}
      style={responsive.desktop ? {width: 250} : {flex: 1}}
    />
  );
};

const DateRangeInputs = ({
  disabled,
  field,
  form,
  label,
  required,
}: BaseJobScheduleFieldsProps & {label: string; required?: boolean}) => {
  const responsive = useResponsive();
  const startDateField = getFieldName({field, name: 'startDate'});
  const startDate = _.get(form.values, startDateField);
  const endDateField = getFieldName({field, name: 'endDate'});
  const endDate = _.get(form.values, endDateField);

  return (
    <DateRangeInput
      form={form}
      field={field}
      index={0}
      startDateField={startDateField}
      toggleRangeOnSameDate={(newDate) => {
        handleToggleDateRange({form, field, wasEstimatedRange: true, startDate: newDate});
      }}
      startDateProps={{label, style: responsive.desktop ? null : {flex: 1}}}
      startDateInputProps={{
        disabled,
        required: required && !startDate,
        placeholder: 'Start date',
        style: {width: responsive.desktop ? 250 : '100%'},
      }}
      endDateField={endDateField}
      endDateProps={{
        action: (
          <React.Fragment>
            <Space style={{flex: 1}} />
            <DateRangeToggle form={form} field={field} isEstimatedRange />
          </React.Fragment>
        ),
        style: responsive.desktop ? null : {flex: 1},
      }}
      endDateInputProps={{
        disabled,
        required: required && !endDate,
        placeholder: 'End date',
        style: {width: responsive.desktop ? 250 : '100%'},
      }}
      containerStyle={{flex: 1}}
    />
  );
};

const DateField = ({
  disabled,
  field,
  form,
  label,
  required,
  organization,
}: BaseJobScheduleFieldsProps & {
  label: string;
  required?: boolean;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  const isEstimatedRange = _.get(form.values, `${field}.isEstimatedRange`);

  return (
    <RowFieldFields
      style={{
        alignItems: 'flex-end',
        flex: responsive.desktop ? undefined : 1,
        width: responsive.mobile ? '100%' : undefined,
      }}
    >
      {isEstimatedRange ? (
        <DateRangeInputs
          disabled={disabled}
          field={field}
          form={form}
          label={label}
          required={required}
        />
      ) : (
        <SingleDateInput
          disabled={disabled}
          field={field}
          form={form}
          label={label}
          required={required}
          organization={organization}
        />
      )}
    </RowFieldFields>
  );
};

const JobIdentifierRow = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldPropsWithOrg & {
  index: number;
}) => {
  if (!organization.features.isEnabledJobIdentifierField) {
    return null;
  }

  return (
    <React.Fragment>
      <Row index={index} {...responsive}>
        <FieldInput.Memoized
          {...form}
          index={0}
          name={getFieldName({field, name: 'identifier'})}
          label={'Job Identifier'}
          input={{
            disabled,
            required: !getFieldValue({form, field, name: 'identifier'}),
            placeholder: 'Enter job identifier',
          }}
          style={{
            width: responsive.desktop ? 510 : '100%',
          }}
        />
      </Row>
      <RowSpace {...responsive} />
    </React.Fragment>
  );
};

const StartTimeFields = ({
  disabled,
  field,
  form,
  organization,
}: BaseJobScheduleFieldsProps & {organization: OrganizationModel}) => {
  const responsive = useResponsive();
  return (
    <RowField style={responsive.mobile ? {width: '100%'} : {flex: 1}}>
      <RowFieldLabel>{organization.jobStartTimeField}</RowFieldLabel>
      <Space height={4} />
      <RowFieldFields>
        <FieldInput.Memoized
          {...form}
          index={1}
          component={TimeInput}
          name={getFieldName({field, name: 'startTime1'})}
          input={{
            disabled,
            required:
              organization.features.isEnabledJobStartTime1IsRequired &&
              !getFieldValue({form, field, name: 'startTime1'}),
            component: TextInput,
            placeholder: 'Start',
            setFieldValue: form.setFieldValue,
          }}
          style={responsive.desktop ? {width: 120} : {flex: 1}}
        />
        <RowFieldSpace />
        <FieldInput.Memoized
          {...form}
          index={2}
          component={TimeInput}
          name={getFieldName({field, name: 'startTime2'})}
          input={{
            disabled,
            component: TextInput,
            placeholder: 'End',
            setFieldValue: form.setFieldValue,
          }}
          style={responsive.desktop ? {width: 120} : {flex: 1}}
        />
      </RowFieldFields>
    </RowField>
  );
};

const EstimatedHoursRow = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldsProps & {
  index: number;
  responsive: Responsive;
  organization: OrganizationModel;
}) => {
  const {isMinEstimateHoursInputVisible, isMaxEstimateHoursInputVisible} =
    JobForm.getDispatchFieldVisibility(_.get(form.values, field), {
      isEnabledHideHardcodedBillingInputs:
        organization.features.isEnabledHideHardcodedBillingInputs,
    });
  if (!isMinEstimateHoursInputVisible && !isMaxEstimateHoursInputVisible) {
    return null;
  }
  return (
    <Row index={index}>
      {isMinEstimateHoursInputVisible && (
        <FieldInput.Memoized
          {...form}
          index={1}
          name={getFieldName({field, name: 'estimateHours1'})}
          label={'Estimate Hours Min'}
          input={{
            disabled,
            placeholder: 'Enter estimate hours min',
            keyboardType: 'numeric',
          }}
          style={responsive.desktop ? {width: 250} : {flex: 1}}
        />
      )}
      {isMinEstimateHoursInputVisible && isMaxEstimateHoursInputVisible && <FieldSpace />}
      {isMaxEstimateHoursInputVisible && (
        <FieldInput.Memoized
          {...form}
          index={2}
          name={getFieldName({field, name: 'estimateHours2'})}
          label={'Estimate Hours Max'}
          input={{
            disabled,
            placeholder: 'Enter estimate hours max',
            keyboardType: 'numeric',
          }}
          style={responsive.desktop ? {width: 250} : {flex: 1}}
        />
      )}
    </Row>
  );
};

const JobDescriptionRow = ({
  disabled,
  index,
  field,
  form,
  responsive,
}: BaseJobScheduleFieldsProps & {index: number; responsive: Responsive}) => {
  return (
    <Section index={index}>
      <FieldInput.LabelText>Job Description (shown to customer)</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveTextInput.Memoized
        form={form}
        field={getFieldName({field, name: 'description'})}
        minHeight={100}
        input={{
          style: {
            width: responsive.desktop ? 510 : '100%',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 9,
            paddingBottom: 9,
          },
          placeholder: 'Enter a description for the job. This will be shown to the customer.',
          disabled,
        }}
      />
    </Section>
  );
};

const JobTypeRow = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
}: BaseJobScheduleFieldPropsWithOrg & {
  index: number;
  shouldResetJobDateOnJobTypeChange?: boolean;
  jobFormField: string;
}) => {
  return (
    <React.Fragment>
      <Row data-test-id='form-job-type-dropdown-row' index={index} {...responsive}>
        <JobTypeDropdown
          organization={organization}
          projectTypeId={_.get(form.values, 'projectForm.projectTypeId')}
          field={field}
          form={form}
          shouldResetJobDateOnJobTypeChange={shouldResetJobDateOnJobTypeChange}
          jobFormField={jobFormField}
          style={responsive.tablet ? {flex: 1} : {width: responsive.desktop ? 250 : '100%'}}
        />
        <FieldSpace />
        <FieldInput.Memoized
          {...form}
          index={1}
          name={getFieldName({field, name: 'name'})}
          label={'Job Name'}
          input={{
            disabled,
            placeholder: 'Enter job name',
          }}
          style={responsive.tablet ? {flex: 1} : {width: responsive.desktop ? 250 : '100%'}}
        />
      </Row>
      <RowSpace {...responsive} />
    </React.Fragment>
  );
};

const EstimateSection = ({
  disabled,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldPropsWithOrg) => {
  return (
    <React.Fragment>
      <DesktopRow index={2} responsive={responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Date'}
          organization={organization}
        />
        <FieldSpace />
        <StartTimeFields
          disabled={disabled}
          field={field}
          form={form}
          organization={organization}
        />
      </DesktopRow>
    </React.Fragment>
  );
};

const ResidentialMoveSection = ({
  disabled,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldPropsWithOrg) => {
  return (
    <React.Fragment>
      <DesktopRow index={2} responsive={responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Date'}
          organization={organization}
        />
        <FieldSpace />
        <StartTimeFields
          disabled={disabled}
          field={field}
          form={form}
          organization={organization}
        />
      </DesktopRow>
      <RowSpace {...responsive} />
      <EstimatedHoursRow
        disabled={disabled}
        index={3}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </React.Fragment>
  );
};

const CommercialMoveSection = ({
  disabled,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldPropsWithOrg) => {
  return (
    <React.Fragment>
      <DesktopRow index={2} responsive={responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Move Date'}
          organization={organization}
        />
        <FieldSpace />
        <RowField style={responsive.mobile ? {width: '100%'} : {flex: 1}}>
          <RowFieldLabel>{organization.jobStartTimeField}</RowFieldLabel>
          <Space height={4} />
          <RowFieldFields>
            <FieldInput.Memoized
              {...form}
              index={1}
              component={TimeInput}
              name={getFieldName({field, name: 'startTime1'})}
              input={{
                disabled,
                required:
                  organization.features.isEnabledJobStartTime1IsRequired &&
                  !getFieldValue({form, field, name: 'startTime1'}),
                component: TextInput,
                placeholder: 'Start',
                setFieldValue: form.setFieldValue,
              }}
              style={responsive.desktop ? {width: 120} : {flex: 1}}
            />
            <RowFieldSpace />
            <FieldInput.Memoized
              {...form}
              index={2}
              component={TimeInput}
              name={getFieldName({field, name: 'startTime2'})}
              input={{
                disabled,
                required: !getFieldValue({form, field, name: 'startTime2'}),
                component: TextInput,
                placeholder: 'End',
                setFieldValue: form.setFieldValue,
              }}
              style={responsive.desktop ? {width: 120} : {flex: 1}}
            />
          </RowFieldFields>
        </RowField>
      </DesktopRow>
      <RowSpace {...responsive} />
      <EstimatedHoursRow
        disabled={disabled}
        index={3}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </React.Fragment>
  );
};

const DefaultMoveSection = ({
  disabled,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldPropsWithOrg) => {
  return (
    <React.Fragment>
      <DesktopRow index={2} responsive={responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Date'}
          organization={organization}
        />
        <FieldSpace />
        <StartTimeFields
          disabled={disabled}
          field={field}
          form={form}
          organization={organization}
        />
      </DesktopRow>
      <RowSpace {...responsive} />
      <EstimatedHoursRow
        disabled={disabled}
        index={3}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </React.Fragment>
  );
};

const JobScheduleCustomFields = ({
  disabled,
  field,
  form,
  responsive,
  organization,
}: BaseJobScheduleFieldPropsWithOrg) => {
  const kind = _.get(form.values, getFieldName({field, name: 'kind'}));

  switch (kind) {
    case Job.KIND.COMMERCIAL:
      return (
        <CommercialMoveSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
    case Job.KIND.ESTIMATE:
      return (
        <EstimateSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
    case Job.KIND.RESIDENTIAL:
      return (
        <ResidentialMoveSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
    default:
      return (
        <DefaultMoveSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
  }
};

const JobScheduleFields = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
}: BaseJobScheduleFieldPropsWithOrg & {
  index: number;
  shouldResetJobDateOnJobTypeChange?: boolean;
  jobFormField: string;
}) => {
  return (
    <Section index={index}>
      {/* TODO(jay): before merge see if this is actually needed */}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Title vars={responsive}>Job Information</Title>
      <JobIdentifierRow
        disabled={disabled}
        index={0}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
      <JobTypeRow
        disabled={disabled}
        index={1}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
        shouldResetJobDateOnJobTypeChange={shouldResetJobDateOnJobTypeChange}
        jobFormField={jobFormField}
      />
      <JobScheduleCustomFields
        disabled={disabled}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
      <RowSpace {...responsive} />
      <JobDescriptionRow
        disabled={disabled}
        index={4}
        field={field}
        form={form}
        responsive={responsive}
      />
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobScheduleFields.fragment = gql`
  ${JobTypeDropdown.fragment}

  fragment JobScheduleFields on Organization {
    id
    jobStartTimeField
    features {
      isEnabledJobIdentifierField: isEnabled(feature: "JOB_IDENTIFIER_FIELD")
      isEnabledJobStartDateIsRequired: isEnabled(feature: "JOB_START_DATE_IS_REQUIRED")
      isEnabledJobStartTime1IsRequired: isEnabled(feature: "JOB_START_TIME_1_IS_REQUIRED")
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
      isEnabledHideHardcodedBillingInputs: isEnabled(feature: "HIDE_HARDCODED_BILLING_INPUTS")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      futureSalesLockedProjectTypeDays {
        id
        day {
          id
          date
        }
      }
    }
    jobTypes {
      id
      kind
    }
    ...JobTypeDropdown
  }
`;

export default JobScheduleFields;
