// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {UserFlowRunStepModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import UserFlowRunStepStatus from '@shared/modules/UserFlows/enums/UserFlowRunStepStatus';

const Container = Styled.View<{backgroundColor: string; borderColor: string}>`
  flex: 1;
  padding: 12px;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-radius: 8px;
  border-color: ${({borderColor}) => borderColor};
  border-width: 1px;
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const StatusText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Micro}
  color: ${({color}) => color}
`;

const DescriptionNone = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const DocumentFlowRunStepsProgressItem = ({
  index,
  userFlowRunStep,
}: {
  index: number;
  userFlowRunStep: UserFlowRunStepModel;
}) => {
  const responsive = useResponsive();
  const {document} = userFlowRunStep;
  const {documentTemplate} = document.documentTemplateVersion;

  const isExpired =
    userFlowRunStep.userFlowRun.isExpired &&
    userFlowRunStep.status !== UserFlowRunStepStatus.COMPLETED;

  const color = isExpired
    ? colors.red700
    : UserFlowRunStepStatus.convertStatusToColor(userFlowRunStep.status);
  const backgroundColor = colors.getBackgroundColor(color);

  return (
    <Container backgroundColor={backgroundColor} borderColor={color}>
      <Row>
        <Column style={{flex: 1}}>
          <Row style={{justifyContent: 'space-between'}}>
            <Row>
              <Icon source={Icon.FileLines} color={colors.gray.secondary} />
              <Space width={8} />
              <TitleText responsive={responsive}>{document.displayName}</TitleText>
              <Space width={8} />
            </Row>
            <Row>
              <StatusText responsive={responsive} color={color}>
                {isExpired
                  ? 'Expired'
                  : UserFlowRunStepStatus.convertUserFlowRunStepStatusToLabel(
                      userFlowRunStep.status,
                    )}
              </StatusText>
            </Row>
          </Row>
          <Space height={4} />
          <Row>
            <Space width={28} />
            {documentTemplate.description ? (
              <DescriptionText responsive={responsive}>
                {documentTemplate.description}
              </DescriptionText>
            ) : (
              <DescriptionNone responsive={responsive}>None</DescriptionNone>
            )}
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

DocumentFlowRunStepsProgressItem.fragment = gql`
  fragment DocumentFlowRunStepsProgressItem on UserFlowRunStep {
    id
    status
    document {
      id
      displayName
      documentTemplateVersion {
        id
        documentTemplate {
          id
          description
        }
      }
    }
    userFlowRun {
      id
      isExpired
    }
  }
`;

export default DocumentFlowRunStepsProgressItem;
