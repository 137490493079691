// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {AttachmentModel, FileModel} from '@supermove/models';
import {S3} from '@supermove/sdk';

// App
import CompleteUploadFileForm from '@shared/modules/File/forms/CompleteUploadFileForm';
import CreateAttachmentForm from '@shared/modules/File/forms/CreateAttachmentForm';
import RequestUploadFileForm from '@shared/modules/File/forms/RequestUploadFileForm';
import UploadFileForm, {
  UploadFileFormType,
  UploadFileFormTypeWrapper,
} from '@shared/modules/File/forms/UploadFileForm';

// TODO(Hammad): Using onError: (errors: MutationError[]) => void; requires downstream changes to the onError.
// Need to investigate the onError arguments in actual usage before making the change, keeping type as is for now.
const useUploadAttachmentForm = ({
  uploadFileForm,
  onSuccess,
  onError,
}: {
  uploadFileForm: UploadFileFormType;
  onSuccess: (response: {attachment: AttachmentModel}) => void;
  onError: (e: any) => void;
}) => {
  const form = useForm<UploadFileFormTypeWrapper>({
    initialValues: {
      uploadFileForm: UploadFileForm.toForm(uploadFileForm),
    },
  });

  // Create Attachment (#4)
  const {handleSubmit: handleSubmitCreateAttachment} = useFormMutation<UploadFileFormTypeWrapper>({
    form,
    mutation: useUploadAttachmentForm.createAttachmentMutation,
    variables: {
      createAttachmentForm: CreateAttachmentForm.toMutation(
        form.values.uploadFileForm.createAttachmentForm,
      ),
    },
    onSuccess,
    onError,
  });

  // Complete Upload File (#3)
  const {submitting: submittingCompleteUploadFile, handleSubmit: handleSubmitCompleteUploadFile} =
    useFormMutation<UploadFileFormTypeWrapper>({
      form,
      mutation: useUploadAttachmentForm.completeUploadFileMutation,
      variables: {
        completeUploadFileForm: CompleteUploadFileForm.toMutation(
          form.values.uploadFileForm.completeUploadFileForm,
        ),
      },
      onSuccess: () => {
        handleSubmitCreateAttachment();
      },
      onError,
    });

  // Upload to S3 (#2)
  const uploadFile = async ({
    url,
    fields,
    file,
    setSubmitting,
  }: {
    url: string;
    fields: string;
    file: File;
    setSubmitting: (newValue: boolean) => void;
  }) => {
    setSubmitting(true);
    try {
      console.log('Starting upload.');
      await S3.uploadFile({
        url,
        fields: JSON.parse(fields),
        file,
        onProgress: (progress) => console.log({progress, submitting: form.isSubmitting}),
      });
      console.log('Upload success.');
      await handleSubmitCompleteUploadFile();
    } catch (error) {
      // Error thrown by S3.uploadFile is just a string, so format the error as a MutationError
      // because the onError callback expects MutationError objects. This co-opts the
      // uploadFileForm.file field because no other code sets errors on this field.
      const errorReason = error ? `${error}` : '';
      const mutationError = {
        field: `uploadFileForm.file`,
        message: `File upload failed: ${errorReason}`,
      };
      form.setFieldError(mutationError.field, mutationError.message);
      onError([error]);
    } finally {
      setSubmitting(false);
    }
  };

  // Request Upload File (#1)
  const {submitting: submittingRequestUploadFile, handleSubmit: handleSubmitRequestUploadFile} =
    useFormMutation<UploadFileFormTypeWrapper>({
      form,
      mutation: useUploadAttachmentForm.requestUploadFileMutation,
      variables: {
        requestUploadFileForm: RequestUploadFileForm.toMutation(
          form.values.uploadFileForm.requestUploadFileForm,
        ),
      },
      onSuccess: async ({file}: {file: FileModel}) => {
        await form.setFieldValue('uploadFileForm.createAttachmentForm.fileId', file.id);
        await form.setFieldValue('uploadFileForm.completeUploadFileForm.uuid', file.uuid);
        await uploadFile({
          url: file.s3PresignedPostMetadata.url,
          fields: file.s3PresignedPostMetadata.fields,
          file: form.values.uploadFileForm.file as File,
          setSubmitting: form.setSubmitting,
        });
      },
      onError: (errors) => {
        _.forEachRight(errors, (error) =>
          form.setFieldError(_.camelCase(error.field), error.message),
        );
        onError(errors);
      },
    });

  return {
    form,
    submitting: submittingRequestUploadFile || submittingCompleteUploadFile || form.isSubmitting,
    handleSubmit: handleSubmitRequestUploadFile,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUploadAttachmentForm.requestUploadFileMutation = gql`
  mutation useUploadAttachmentForm($requestUploadFileForm: RequestUploadFileForm!) {
    response: requestUploadFile(requestUploadFileForm: $requestUploadFileForm) {
      ${gql.errors}
      file {
        id
        uuid
        s3PresignedPostMetadata {
          url
          fields
        }
      }
    }
  }
`;

useUploadAttachmentForm.completeUploadFileMutation = gql`
  mutation useUploadAttachmentForm($completeUploadFileForm: CompleteUploadFileForm!) {
    response: completeUploadFile(completeUploadFileForm: $completeUploadFileForm) {
      ${gql.errors}
      file {
        id
      }
    }
  }
`;

useUploadAttachmentForm.createAttachmentMutation = gql`
  mutation useCreateAttachmentForm($createAttachmentForm: CreateAttachmentForm!) {
    response: createAttachment(createAttachmentForm: $createAttachmentForm) {
      ${gql.errors}
      attachment {
        id
      }
    }
  }
`;

export default useUploadAttachmentForm;
