// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Styled} from '@supermove/components';
import {usePopover, useState} from '@supermove/hooks';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';

const Container = Styled.View``;

interface RateTableRateInputProps extends React.ComponentProps<typeof CurrencyInput> {
  onCopy: (type: 'ROW' | 'COLUMN') => void;
  value: string;
}

const RateTableRateInput = (props: RateTableRateInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const copyCellPopover = usePopover();
  return (
    <Container>
      <CurrencyInput
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{...props.style, paddingLeft: 10, paddingRight: isFocused ? 24 : 0}}
      />
      <ActionMenuPopover
        popover={copyCellPopover}
        width={200}
        actions={[
          {
            text: 'Copy rate to entire row',
            onPress: () => props.onCopy('ROW'),
          },
          {
            text: 'Copy rate to entire column',
            onPress: () => props.onCopy('COLUMN'),
          },
        ]}
      >
        {isFocused || copyCellPopover.isOpen ? (
          <IconButton
            tooltip={props.value === '' ? 'Enter a rate to copy.' : ''}
            isDisabled={props.value === ''}
            source={Icon.Copy}
            onPress={() => copyCellPopover.handleToggle()}
            size={12}
            buttonStyle={{position: 'absolute', right: 4, bottom: 6}}
          />
        ) : (
          <React.Fragment />
        )}
      </ActionMenuPopover>
    </Container>
  );
};

export default RateTableRateInput;
