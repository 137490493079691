// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, useSearch} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import Line from '@shared/design/components/Line';
import SearchBar from '@shared/design/components/SearchBar';
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import useUpdateSuperAdminOrganizationMutation from '@shared/modules/Organization/hooks/useUpdateSuperAdminOrganzationMutation';

const Container = Styled.View`
  height: 380px;
`;

const ContentContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SkeletonOption = () => (
  <Column>
    <Space height={8} />
    <Row>
      <Column style={{flex: 1}}>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Body} width={_.random(150, 250)} />
        <Space height={4} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Body} width={_.random(75, 150)} />
      </Column>
      <Space width={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={75} />
    </Row>
    <Space height={8} />
  </Column>
);

const SkeletonList = () => {
  const responsive = useResponsive();
  return (
    <ContentContainer>
      {responsive.desktop && <Space height={16} />}
      <SkeletonLoader height={36} isFullWidth />
      <Space height={16} />
      <FlatList
        data={_.range(5)}
        renderItem={() => <SkeletonOption />}
        ItemSeparatorComponent={() => <Line color={colors.gray.border} />}
      />
    </ContentContainer>
  );
};

const Option = ({
  selectedOrganizationId,
  organization,
  handlePress,
  isSubmitting,
}: {
  selectedOrganizationId?: string;
  organization: OrganizationModel;
  handlePress: ({organization}: {organization: OrganizationModel}) => void;
  isSubmitting: boolean;
}) => {
  const responsive = useResponsive();
  return (
    <Column>
      <Space height={8} />
      <Row>
        <Column style={{flex: 1}}>
          <Text responsive={responsive} numberOfLines={1}>
            {organization.name}
          </Text>
          <Space height={4} />
          <Text responsive={responsive} style={{color: colors.gray.secondary}} numberOfLines={1}>
            {organization.slug}
          </Text>
        </Column>
        <Space width={16} />
        <QuaternaryButton
          textColor={colors.blue.interactive}
          text={'Select'}
          onPress={() => handlePress({organization})}
          isDisabled={
            isSubmitting && !!selectedOrganizationId && selectedOrganizationId !== organization.id
          }
          // @ts-expect-error
          isSubmitting={selectedOrganizationId === organization.id && isSubmitting}
        />
      </Row>
      <Space height={8} />
    </Column>
  );
};

const SuperAdminOrganizationListContent = ({
  organizations,
  form,
  field,
  submitting,
  handleSubmit,
}: {
  organizations: OrganizationModel[];
  form: any;
  field: string;
  submitting: boolean;
  handleSubmit: () => void;
}) => {
  const responsive = useResponsive();
  const sortedOrganizations = _.sortBy(organizations, ['slug']);
  const {
    query,
    results: filteredOrganizations,
    setQuery,
  } = useSearch({
    initialQuery: '',
    items: sortedOrganizations,
    options: {keys: ['slug', 'name']},
    limit: 20,
  });

  return (
    <ContentContainer>
      {responsive.desktop && <Space height={16} />}
      <SearchBar
        autoFocus
        isResponsive
        isClearable
        placeholder={'Search for an organization by name'}
        style={{flex: 1}}
        defaultValue={query}
        onChangeText={(text: any) => {
          setQuery(text);
        }}
        onEnterPress={() => {
          form.setFieldValue(field, filteredOrganizations[0].id);
          setImmediate(() => handleSubmit());
        }}
      />
      <Space height={16} />
      <FlatList
        data={filteredOrganizations}
        keyExtractor={(organization: OrganizationModel) => organization.id}
        renderItem={({item: organization}: {item: OrganizationModel}) => (
          <Option
            selectedOrganizationId={_.get(form.values, field)}
            organization={organization}
            handlePress={() => {
              form.setFieldValue(field, organization.id);
              setImmediate(() => handleSubmit());
            }}
            isSubmitting={submitting}
          />
        )}
        ItemSeparatorComponent={() => <Line color={colors.gray.border} />}
      />
    </ContentContainer>
  );
};

const SuperAdminOrganizationList = ({supermoveProductKind}: {supermoveProductKind: string}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(SuperAdminOrganizationList.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      supermoveProductKind,
    },
  });
  const {form, handleSubmit, submitting} = useUpdateSuperAdminOrganizationMutation({
    onSuccess: () => {
      window.location.href = '/';
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <Container responsive={responsive}>
      <Loading loading={loading} as={<SkeletonList />}>
        {() => (
          <SuperAdminOrganizationListContent
            organizations={data.activeOrganizations}
            form={form}
            field={'updateSuperAdminOrganizationForm.organizationId'}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminOrganizationList.query = gql`
  query SuperAdminOrganizationList($supermoveProductKind: String!) {
    ${gql.query}
    activeOrganizations: activePrimaryOrganizationsForProduct(kind: $supermoveProductKind) {
      id
      name
      slug
    }
  }
`;

export default SuperAdminOrganizationList;
