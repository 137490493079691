// Libraries
import pluralize from 'pluralize';
import React from 'react';

// Supermove
import Space from '@supermove/components/src/Space';
import Styled from '@supermove/components/src/Styled';
import {gql} from '@supermove/graphql';
import {UserFlowModel} from '@supermove/models';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import {convertUserFlowExpirationUnitTypeToLabel} from '@shared/modules/UserFlows/enums/UserFlowExpirationUnitType';

const Row = Styled.View`
  flex-direction: row;
`;

const DocumentFlowDetailsDrawerSettings = ({userFlow}: {userFlow: UserFlowModel}) => {
  return (
    <React.Fragment>
      <Row>
        <FieldValue
          style={{flex: 1}}
          isResponsive
          label={'Default Email Template'}
          value={userFlow.defaultEmailTemplate.name}
        />
        <Space width={16} />
        <FieldValue
          style={{flex: 1}}
          isResponsive
          label={'Default Expiration Timeframe'}
          value={
            userFlow.expirationValue && userFlow.expirationUnitType
              ? `${pluralize(
                  convertUserFlowExpirationUnitTypeToLabel(userFlow.expirationUnitType),
                  userFlow.expirationValue,
                  true,
                )} after sending`
              : undefined
          }
          empty={'None'}
        />
      </Row>
      <Space height={16} />
      <Row>
        <FieldValue
          style={{flex: 1}}
          isResponsive
          label={'Include PDFs in Emails'}
          value={userFlow.shouldIncludePdfs ? 'Yes' : 'No'}
        />
        <Space width={16} />
        <FieldValue
          style={{flex: 1}}
          isResponsive
          label={'Project Types'}
          value={userFlow.projectTypeUserFlows
            .map((projectTypeUserFlow) => projectTypeUserFlow.projectType.name)
            .join(', ')}
        />
      </Row>
    </React.Fragment>
  );
};

DocumentFlowDetailsDrawerSettings.fragment = gql`
  fragment DocumentFlowDetailsDrawerSettings on UserFlow {
    id
    expirationUnitType
    expirationValue
    shouldIncludePdfs
    defaultEmailTemplate {
      id
      name
    }
    projectTypeUserFlows {
      id
      projectType {
        id
        name
      }
    }
  }
`;

export default DocumentFlowDetailsDrawerSettings;
