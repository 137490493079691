// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PreventPropagation, Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';
import useManagerRestriction from 'modules/App/hooks/useManagerRestriction';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FilterContainer = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

const getProjectTypeDropdownOptions = (projectTypes: any) => {
  return _.map(projectTypes, (projectType) => ({
    label: projectType.name,
    value: projectType.id,
  }));
};

const getSalespersonDropdownOptions = (salespersons: any, organization: any) => {
  const options = _.map(salespersons, (salesperson) => ({
    label: salesperson.label,
    value: _.toString(salesperson.value),
  }));
  if (organization.features.isEnabledAllowEmptyBookedBy) {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    options.unshift({label: 'No salesperson', value: null});
  }
  return options;
};

const getActiveFilterCount = ({params, viewer, organization}: any) => {
  const {date, slugs, searchQuery, ...countedFilters} = params;
  const activeFilters = _.compact([..._.values(countedFilters)]);
  const count = activeFilters.length;
  const isRestrictedSalesperson =
    !UserRole.hasManagerPermissions(viewer?.role) &&
    !organization.features.isEnabledSalespersonViewAllProjects;
  return isRestrictedSalesperson ? count - 1 : count;
};

const getSalespersonIdsFromParams = (salespersons: any) => {
  return salespersons?.map((salesperson: any) => (salesperson === '' ? null : salesperson)) ?? [];
};

const TagsFilter = ({index, handleUpdateParam, organization}: any) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  const filterByArchivedTag = organization.companySettings.tags.filter(
    (tag: any) => !tag.isArchived,
  );

  const projectTags = filterByArchivedTag.filter(
    (tag: any) => tag.category === TagCategory.PROJECT,
  );
  const jobTags = filterByArchivedTag.filter((tag: any) => tag.category === TagCategory.JOB);

  return (
    <React.Fragment>
      <TagDropdownInputField
        options={
          projectTags
            ? projectTags.map((tag: any) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={'Select project tags'}
        label={'Project Tags'}
        index={index}
        value={_.get(params, 'projectTagIds', [])}
        onChangeValue={(projectTagIds: any) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'projectTagIds',
            paramValue: projectTagIds,
          });
        }}
      />
      <Space height={12} />
      <TagDropdownInputField
        options={
          jobTags
            ? jobTags.map((tag: any) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={'Select job tags'}
        label={'Job Tags'}
        index={index + 1}
        value={_.get(params, 'jobTagIds', [])}
        onChangeValue={(jobTagIds: any) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'jobTagIds',
            paramValue: jobTagIds,
          });
        }}
      />
    </React.Fragment>
  );
};

const ProjectTypesFilter = ({index, handleUpdateParam, organization}: any) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText isResponsive>Project Types</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownCheckboxInput
        options={
          organization
            ? getProjectTypeDropdownOptions(Organization.getProjectTypes(organization))
            : []
        }
        placeholder={'Select project types'}
        style={{width: '100%'}}
        value={_.get(params, 'projectTypes', [])}
        onChangeValue={(projectTypes: any) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'projectTypes',
            paramValue: projectTypes,
          });
        }}
        isSearchable
        isResponsive
        usePills
      />
    </FilterContainer>
  );
};

const SalespersonFilter = ({index, handleUpdateParam, organization}: any) => {
  const {params, navigator} = useNavigationDOM();
  const salespersons = getSalespersonIdsFromParams(params?.salespersons);
  const {location} = navigator;

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText isResponsive>Salespeople</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownCheckboxInput
        options={
          organization
            ? getSalespersonDropdownOptions(
                Organization.getSalespersonOptions(organization),
                organization,
              )
            : []
        }
        placeholder={'Select salespeople'}
        style={{width: '100%'}}
        value={salespersons}
        onChangeValue={(salespersons: any) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'salespersons',
            paramValue: salespersons,
          });
        }}
        isSearchable
        isResponsive
        usePills
      />
    </FilterContainer>
  );
};

const CapacityProjectListPopoverContent = ({
  handleUpdateParam,
  organization,
  isRestricted,
  responsive,
  handleClose,
}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <ContentContainer style={{paddingHorizontal: responsive.desktop ? null : 16}}>
      <ProjectTypesFilter
        index={1}
        handleUpdateParam={handleUpdateParam}
        organization={organization}
      />
      <Space height={16} />
      <TagsFilter index={2} handleUpdateParam={handleUpdateParam} organization={organization} />
      <Space height={16} />
      {!isRestricted && (
        <React.Fragment>
          <SalespersonFilter
            // For some reason, if we make this index 3 the tags filter dropdown appears below the salesperson filter
            index={5}
            handleUpdateParam={handleUpdateParam}
            organization={organization}
          />
          {/* Without this the default option becomes unselectable until you mouse over a different option and back */}
          {/* Not a good long term solution, look into the root cause */}
          <Space height={16} />
        </React.Fragment>
      )}
      {!responsive.desktop && (
        <React.Fragment>
          <Space height={8} />
          <Button text={'Done'} onPress={handleClose} isWidthOfContainer />
          <Space height={8} />
        </React.Fragment>
      )}
    </ContentContainer>
  );
};

const CapacityProjectListFiltersPopover = ({
  activeFilterCount,
  popover,
  handleUpdateParam,
  handleClearProjectsListFilters,
  organization,
  responsive,
}: any) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;
  const {isRestricted, viewerId} = useManagerRestriction({
    isBypassed: organization.features.isEnabledSalespersonViewAllProjects,
    handleRestriction: (userId) => {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'salespersons',
        paramValue: [userId],
      });
    },
  });

  const handleClear = () => {
    handleClearProjectsListFilters({
      baseUrl: location.pathname,
      navigator,
      params,
      isRestricted,
      viewerId,
    });
  };

  return (
    <PopoverFilter
      activeFiltersCount={activeFilterCount}
      hideActionButtons
      popover={popover}
      responsive={responsive}
      handleApply={popover.handleClose}
      handleClear={handleClear}
    >
      <CapacityProjectListPopoverContent
        handleUpdateParam={handleUpdateParam}
        organization={organization}
        isRestricted={isRestricted}
        responsive={responsive}
        handleClose={popover.handleClose}
      />
    </PopoverFilter>
  );
};

const CapacityProjectListFilters = ({
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
}: any) => {
  const responsive = useResponsive();
  const {viewer} = useAppContext();
  const {params} = useNavigationDOM();
  const activeFilterCount = getActiveFilterCount({params, viewer, organization});
  const capacityProjectListFiltersPopover = usePopover({
    name: 'Capacity project list filters popover',
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={capacityProjectListFiltersPopover.ref}>
        <PreventPropagation>
          <SecondaryButton
            text={
              responsive.desktop
                ? `Filters${activeFilterCount ? ` (${activeFilterCount})` : ''}`
                : activeFilterCount > 0
                  ? `(${activeFilterCount})`
                  : undefined
            }
            onPress={capacityProjectListFiltersPopover.handleOpen}
            iconLeft={Icon.Filter}
            iconSize={responsive.desktop ? 12 : 14}
            isResponsive
            style={responsive.desktop ? undefined : {padding: 14}}
          />
        </PreventPropagation>
      </Popover.Content>
      <PreventPropagation>
        <CapacityProjectListFiltersPopover
          activeFilterCount={activeFilterCount}
          popover={capacityProjectListFiltersPopover}
          handleUpdateParam={handleUpdateParam}
          handleClearProjectsListFilters={handleClearProjectsListFilters}
          organization={organization}
          responsive={responsive}
        />
      </PreventPropagation>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityProjectListFilters.fragment = gql`
  ${Organization.getProjectTypes.fragment}
  ${Organization.getSalespersonOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment CapacityProjectListFilters on Organization {
    id
    companySettings {
      tags {
        id
        name
        emoji
        category
        isArchived
        ...TagDropdownInputField
      }
    }
    features {
      isEnabledSalespersonViewAllProjects: isEnabled(feature: "SALESPERSON_VIEW_ALL_PROJECTS")
      isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
    }
    ...Organization_getProjectTypes
    ...Organization_getSalespersonOptions
  }
`;

export default CapacityProjectListFilters;
