// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import SwitchField from '@shared/design/components/Field/SwitchField';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import OrganizationMoverNotificationSettingsForm from '@shared/modules/Organization/forms/OrganizationMoverNotificationSettingsForm';
import useUpdateOrganizationMoverNotificationSettingsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationMoverNotificationSettingsMutation';

const LabelText = Styled.Text`
  ${Typography.Responsive.Label};
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body};
`;

interface PermissionsContentProps {
  organization: OrganizationModel;
  refetch: () => void;
}

const PermissionsContent = ({organization, refetch}: PermissionsContentProps) => {
  const responsive = useResponsive();
  const organizationMoverNotificationSettingsForm =
    OrganizationMoverNotificationSettingsForm.edit(organization);
  const {form, handleSubmit} = useUpdateOrganizationMoverNotificationSettingsMutation({
    organizationMoverNotificationSettingsForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const allowEmployeeTextField = 'organizationMoverNotificationSettingsForm.allowEmployeeText';
  const sendReminderSetScheduleField =
    'organizationMoverNotificationSettingsForm.sendReminderSetSchedule';
  const allowMoverAcceptDeclineJobField =
    'organizationMoverNotificationSettingsForm.allowMoverAcceptDeclineJob';

  return (
    <React.Fragment>
      <LabelText responsive={responsive}>Text Messaging to Movers</LabelText>
      <Space height={8} />
      <BodyText responsive={responsive} style={{color: colors.gray.secondary}}>
        Enabling SMS will allow you to communicate with your Movers via text message, while granting
        them access to the Mover app.
      </BodyText>
      <Space height={8} />
      <SwitchField
        isResponsive
        index={0}
        form={form}
        field={allowEmployeeTextField}
        labelRight={`Enable text messaging to Movers`}
        onChangeValue={(value) => {
          // These fields are turned off and disabled if texting for employees is disabled
          if (!value) {
            form.setFieldValue(sendReminderSetScheduleField, false);
            form.setFieldValue(allowMoverAcceptDeclineJobField, false);
          }
          setImmediate(() => handleSubmit());
        }}
      />
      <Space height={24} />
      <LabelText responsive={responsive}>Reminders</LabelText>
      <Space height={8} />
      <SwitchField
        isResponsive
        index={1}
        form={form}
        field={sendReminderSetScheduleField}
        labelRight={`Send weekly reminder text to Movers to set their schedule`}
        disabled={!_.get(form.values, allowEmployeeTextField)}
        onChangeValue={() => setImmediate(() => handleSubmit())}
      />
      <Space height={24} />
      <LabelText responsive={responsive}>Job Permissions</LabelText>
      <Space height={8} />
      <SwitchField
        isResponsive
        index={2}
        form={form}
        field={allowMoverAcceptDeclineJobField}
        labelRight={`Allow Movers to accept or decline jobs`}
        disabled={!_.get(form.values, allowEmployeeTextField)}
        onChangeValue={() => setImmediate(() => handleSubmit())}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsStaffPermissionsContent = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffPermissionsContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <ActionPanel
            BodyComponent={({organization, refetch}: PermissionsContentProps) => (
              <PermissionsContent organization={organization} refetch={refetch} />
            )}
            bodyComponentProps={{organization: data.viewer.viewingOrganization, refetch}}
            title={'SMS Permissions'}
            width={Panel.WIDTH.DEFAULT}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffPermissionsContent.query = gql`
  ${OrganizationMoverNotificationSettingsForm.edit.fragment}
  query OrganizationSettingsStaffPermissionsContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...OrganizationMoverNotificationSettingsForm_edit
      }
    }
  }
`;

export default OrganizationSettingsStaffPermissionsContent;
