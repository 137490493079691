// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {FilteredProjectsForm, FilteredProjectsFormType} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  Form,
  useResponsive,
  useNavigationDOM,
  useQuery,
  useEffect,
  useMountEffect,
  usePagination,
  useRef,
  useForm,
} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import Line from '@shared/design/components/Line';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import useManagerRestriction from 'modules/App/hooks/useManagerRestriction';
import MoveProjectsTabSalesStatusFilters from 'modules/Project/List/components/MoveProjectsTabSalesStatusFilters';
import MoveProjectsTabSearchAndFilters from 'modules/Project/List/components/MoveProjectsTabSearchAndFilters';
import MoveProjectsTable from 'modules/Project/components/MoveProjectsTable';

const ContentContainer = Styled.View`
  flex: 1;
`;

const ListContentContainer = Styled.View`
  backgroundColor: ${colors.white};
  borderColor: ${colors.gray.border};
  borderRadius: 4px;
`;

const Section = Styled.View<{sectionIndex: number}>`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

type StatusKey = keyof typeof FilteredProjectsForm.URL_TO_VARIABLE_MAP;
type FormType = Form<{filteredProjectsForm: FilteredProjectsFormType}>;

const getStatusFilters = (
  status: StatusKey,
  isEnabledProjectHoldStatus: boolean,
  isEnabledCancelProject: boolean,
) => {
  const mainStatusFilter = FilteredProjectsForm.URL_TO_VARIABLE_MAP[status];
  if (!isEnabledProjectHoldStatus && mainStatusFilter === ProjectStatus.LEAD) {
    // If the holds flag is turned off, then all "holds" act as "leads"
    return [mainStatusFilter, ProjectStatus.HOLD];
  } else if (mainStatusFilter === 'ACTIVE') {
    // Return all active statuses, excluding cancelled
    return FilteredProjectsForm.ACTIVE_STATUSES;
  } else if (mainStatusFilter === 'CANCELLED' && isEnabledCancelProject) {
    // Do not filter by status and return all cancelled
    return [];
  } else {
    return [mainStatusFilter];
  }
};

const handleInitialVariables = ({
  navigator,
  params,
  variables,
}: {
  navigator: any;
  params: Record<string, string>;
  variables: Record<string, any>;
}) => {
  // If required params are not set in the route on mount,
  // reload the page with the correct variables
  if (!params.pagination || !params.status || !params.salesStatuses) {
    navigator.replace(getUrlWithVariables(variables));
  }
};

const handleUrlUpdates = ({
  form,
  navigator,
  queryParams,
  variables,
}: {
  form: FormType;
  navigator: any;
  queryParams: Record<string, any>;
  variables: Record<string, any>;
}) => {
  const newQueryParams = FilteredProjectsForm.toQueryParams(form.values.filteredProjectsForm);
  if (!queryParams.current) {
    // Set the initial ref to avoid unnecessary URL updates
    queryParams.current = newQueryParams;
    return;
  }

  // Only push new URL when variables have changed
  if (!_.isEqual(queryParams.current, newQueryParams)) {
    queryParams.current = newQueryParams;
    const url = getUrlWithVariables({
      ...newQueryParams,
      pagination: PaginationBar.DEFAULT_PAGINATION,
    });
    navigator.replace(url);
  }
};

const getVariablesFromParams = (
  params: Record<string, any>,
  isEnabledProjectHoldStatus: boolean,
  isEnabledSalesStatusDepositReceived: boolean,
  isEnabledCancelProject: boolean,
) => {
  const {status, salespersonIds, coordinatorIds, statuses, ...rest} = params;
  // Redirect to leads if no status is specified in URL
  const urlStatus = status || ProjectStatus.getUrlFilter(ProjectStatus.LEAD);
  const updatedStatuses = getStatusFilters(
    urlStatus,
    isEnabledProjectHoldStatus,
    isEnabledCancelProject,
  );
  const defaultSalesStatuses = FilteredProjectsForm.getAvailableSalesStatuses({
    status: urlStatus,
    isEnabledProjectHoldStatus,
    isEnabledSalesStatusDepositReceived,
  });
  // When URL encoded, null turns into empty string. Correct this by turning it back
  const correctedSalespersonIds = URL.decodeEmptyStringToNull(salespersonIds) ?? [];
  const correctedCoordinatorIds = URL.decodeEmptyStringToNull(coordinatorIds) ?? [];
  const mainStatusFilter = FilteredProjectsForm.URL_TO_VARIABLE_MAP[urlStatus as StatusKey];

  return {
    statuses: updatedStatuses,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    salesStatuses: defaultSalesStatuses.length ? defaultSalesStatuses : null,
    status: urlStatus,
    salespersonIds: correctedSalespersonIds,
    coordinatorIds: correctedCoordinatorIds,
    isCancelled: mainStatusFilter === ProjectStatus.CANCELLED ? true : undefined,
    ...rest,
  };
};

const getUrlWithVariables = (variables: Record<string, any>) => {
  const {statuses, ...rest} = variables;
  const baseRoute = `/moves/projects`;
  return URL.getUrlFromVariables(baseRoute, variables);
};

const useRestrictedSalesperson = ({
  organization,
  form,
}: {
  organization: OrganizationModel;
  form: FormType;
}) => {
  // Set salespersons filter to viewer if not an admin. The backend also handles the
  // salespersonIds this way when querying and will only return results for the current
  // user if the user is a salesperson. Handling it here in the url however allows the
  // salesperson to share their link with admins and show the exact same results.
  const {isRestricted, viewerId} = useManagerRestriction({
    isBypassed: organization.features.isEnabledSalespersonViewAllProjects,
    handleRestriction: (userId) => {
      form.setFieldValue('filteredProjectsForm.salespersonIds', [userId]);
    },
  });

  return {isRestricted, viewerId};
};

const ListContentWrapper = ({
  hasContent,
  children,
}: {
  hasContent: boolean;
  children: React.ReactElement;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ListContentContainer style={{flex: 1, borderWidth: 1}}>
          <ScrollView contentContainerStyle={{flex: 1}}>
            <Space height={16} />
            {children}
            <Space height={16} />
          </ScrollView>
        </ListContentContainer>
      ) : (
        <ListContentContainer>
          <Line />
          {/* On mobile there is a ScrollView at the top level of the page. */}
          {children}
          {hasContent && <Line />}
        </ListContentContainer>
      )}
    </React.Fragment>
  );
};

const MoveProjectsTabContent = ({
  organization,
  PageLoadingComponent,
}: {
  organization: OrganizationModel;
  PageLoadingComponent: React.FC;
}) => {
  const responsive = useResponsive();
  const queryParams = useRef();
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(
    params,
    organization.features.isEnabledProjectHoldStatus,
    organization.features.isEnabledSalesStatusDepositReceived,
    organization.features.isEnabledCancelProject,
  );
  const form = useForm({
    initialValues: {
      filteredProjectsForm: FilteredProjectsForm.toForm(variables),
    },
  }) as FormType;
  const {loading, data, refetch, error} = useQuery(MoveProjectsTabContent.query, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  useMountEffect(() => {
    handleInitialVariables({navigator, params, variables});
  });
  useEffect(() => {
    handleUrlUpdates({form, navigator, queryParams, variables});
  }, [form, navigator, queryParams, variables]);

  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page), // The URL is the single source of truth for currentPage
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      const url = getUrlWithVariables({
        ...variables,
        pagination: {
          page,
          resultsPerPage: variables.pagination.resultsPerPage,
        },
      });
      navigator.push(url);
    },
  });

  const {isRestricted, viewerId} = useRestrictedSalesperson({organization, form});
  const isFilteringCancelledProjects =
    form.values.filteredProjectsForm.status === ProjectStatus.getUrlFilter(ProjectStatus.CANCELLED);
  const availableSalesStatuses = FilteredProjectsForm.getAvailableSalesStatuses({
    status: variables.status,
    isEnabledProjectHoldStatus: organization.features.isEnabledProjectHoldStatus,
    isEnabledSalesStatusDepositReceived: organization.features.isEnabledSalesStatusDepositReceived,
  });

  if (!data) {
    // We don't use a Loading component here because it interferes with the nested loading
    // state on the MoveProjectsTable component.
    return <PageLoadingComponent />;
  }

  const {projects} = data.paginatedList;

  return (
    <ContentContainer style={{paddingHorizontal: responsive.desktop ? 24 : 0}}>
      <Space height={16} />
      <Section sectionIndex={0}>
        <MoveProjectsTabSearchAndFilters
          form={form}
          organization={organization}
          filteredProjectCountsByStatus={data.filteredProjectCountsByStatus}
          isRestricted={isRestricted}
          viewerId={viewerId}
        />
      </Section>
      <ListContentWrapper hasContent={_.some(projects) && !error}>
        <React.Fragment>
          {_.some(availableSalesStatuses) && (
            <Section sectionIndex={0}>
              <ScrollView
                horizontal
                style={
                  responsive.desktop ? {paddingBottom: 16, paddingHorizontal: 16} : {padding: 16}
                }
              >
                <MoveProjectsTabSalesStatusFilters
                  status={variables.status}
                  form={form}
                  filteredProjectCountsBySalesStatus={data.filteredProjectCountsBySalesStatus}
                  isEnabledProjectHoldStatus={organization.features.isEnabledProjectHoldStatus}
                  isEnabledSalesStatusDepositReceived={
                    organization.features.isEnabledSalesStatusDepositReceived
                  }
                />
              </ScrollView>
              {!responsive.desktop && <Line />}
            </Section>
          )}
          <Section sectionIndex={1}>
            <MoveProjectsTable
              isLoading={loading}
              refetch={refetch}
              organization={organization}
              projects={projects}
              projectStatus={ProjectStatus.getStatusFromUrlStatus(variables.status)}
              hasError={!!error}
              isEnabledProjectDetailsFollowUp={
                organization.features.isEnabledProjectDetailsFollowUp
              }
              isFilteredByFollowUpDate={
                !!form.values.filteredProjectsForm.followUpStartDate ||
                !!form.values.filteredProjectsForm.followUpEndDate
              }
              isEnabledMovesListMultiBranchSupport={
                organization.features.isEnabledMovesListMultiBranchSupport
              }
              isPrimaryOrganization={organization.isPrimary}
              isFilteringCancelledProjects={isFilteringCancelledProjects}
            />
          </Section>
        </React.Fragment>
      </ListContentWrapper>
      {_.some(projects) && (
        <Section sectionIndex={2}>
          <Space height={responsive.desktop ? 32 : 16} />
          <PaginationBar pagination={pagination} />
          <Space height={responsive.desktop ? 48 : 16} />
        </Section>
      )}
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveProjectsTabContent.query = gql`
  ${usePagination.fragment}
  ${MoveProjectsTabSearchAndFilters.countsFragment}
  ${MoveProjectsTabSalesStatusFilters.countsFragment}
  ${MoveProjectsTable.fragment}

  query MoveProjectsTabContent(
    $pagination: PaginationInput!,
    $statuses: [String],
    $salesStatuses: [String],
    $salespersonIds: [Int],
    $coordinatorIds: [Int],
    $fromDate: String,
    $toDate: String,
    $searchQuery: String,
    $followUpStartDate: String,
    $followUpEndDate: String,
    $sortSettings: [ProjectSortSetting],
    $projectTypeCategory: String,
    $crewOrganizationIds: [String],
    $projectTypeIds: [Int],
    $projectTagIds: [Int],
    $referralSources: [String],
    $isCancelled: Boolean,
  ) {
    ${gql.query}
    filteredProjectCountsByStatus(
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      followUpStartDate: $followUpStartDate,
      followUpEndDate: $followUpEndDate,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      crewOrganizationIds: $crewOrganizationIds,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      referralSources: $referralSources,
    ) {
      ...MoveProjectsTabSearchAndFilters_countsFragment
    }
    filteredProjectCountsBySalesStatus(
      statuses: $statuses,
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      followUpStartDate: $followUpStartDate,
      followUpEndDate: $followUpEndDate,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      crewOrganizationIds: $crewOrganizationIds,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      referralSources: $referralSources,
      isCancelled: $isCancelled,
    ) {
      ...MoveProjectsTabSalesStatusFilters_countsFragment
    }
    paginatedList: filteredProjectsPaginatedList(
      pagination: $pagination,
      statuses: $statuses,
      salesStatuses: $salesStatuses,
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      followUpStartDate: $followUpStartDate,
      followUpEndDate: $followUpEndDate,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      crewOrganizationIds: $crewOrganizationIds,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      referralSources: $referralSources,
      isCancelled: $isCancelled,
    ) {
      projects: results {
        id
        ...MoveProjectsTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

MoveProjectsTabContent.fragment = gql`
  ${MoveProjectsTabSearchAndFilters.fragment}
  ${MoveProjectsTabSalesStatusFilters.fragment}
  ${MoveProjectsTable.organizationFragment}

  fragment MoveProjectsTabContent on Organization {
    id
    isPrimary
    features {
      isEnabledCancelProject: isEnabled(feature: "CANCEL_PROJECT")
      isEnabledProjectHoldStatus: isEnabled(feature: "PROJECT_HOLD_STATUS")
      isEnabledProjectDetailsFollowUp: isEnabled(feature: "PROJECT_DETAILS_FOLLOW_UP")
      isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
      isEnabledMovesListMultiBranchSupport: isEnabled(feature: "MOVES_LIST_MULTI_BRANCH_SUPPORT")
      isEnabledSalespersonViewAllProjects: isEnabled(feature: "SALESPERSON_VIEW_ALL_PROJECTS")
    }
    ...MoveProjectsTabSearchAndFilters
    ...MoveProjectsTabSalesStatusFilters
    ...MoveProjectsTable_Organization
  }
`;

export default MoveProjectsTabContent;
