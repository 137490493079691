// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import {DocumentTemplateCategoryType} from '@shared/modules/Document/enums/DocumentTemplateCategory';
import {
  SelectedDocumentItemType,
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import DocumentTemplateDocumentItems from 'modules/Organization/Settings/Document/components/DocumentTemplateDocumentItems';
import DocumentTemplateSettingsButton from 'modules/Organization/Settings/Document/components/DocumentTemplateSettingsButton';

const TemplateStructureEditorContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View<{index?: number; style?: React.CSSProperties}>`
  flex-direction: row;
  width: 100%;
  z-index: ${({index = 0}) => 99999 - index};
`;

const DocumentItemText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const DocumentItemDescriptionText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const PaddingContainer = Styled.View`
  padding-horizontal: 24px;
`;

const DocumentTemplateEditorDocumentItems = ({
  organization,
  setDraftDocumentContentJson,
  draftDocumentContentJson,
  setSelectedDocumentItem,
  selectedDocumentItem,
  documentTemplateCategory,
  hoveredSectionIndex,
  setHoveredSectionIndex,
}: {
  organization: OrganizationModel;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  draftDocumentContentJson: DocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  selectedDocumentItem: SelectedDocumentItemType;
  documentTemplateCategory: DocumentTemplateCategoryType;
  hoveredSectionIndex?: number;
  setHoveredSectionIndex?: (index?: number) => void;
}) => {
  const responsive = useResponsive();
  const {documentItems} = draftDocumentContentJson;

  return (
    <TemplateStructureEditorContainer>
      <PaddingContainer>
        <Space height={16} />
        <Row style={{alignItems: 'center'}}>
          <DocumentItemText responsive={responsive}>{'Document'}</DocumentItemText>
          <Space style={{flex: 1}} />
          <DocumentTemplateSettingsButton
            selectedDocumentItem={selectedDocumentItem}
            setSelectedDocumentItem={setSelectedDocumentItem}
            draftDocumentContentJson={draftDocumentContentJson}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
        </Row>
        <Space height={16} />
        <DocumentItemDescriptionText responsive={responsive}>
          {
            'Create a custom document by configuring the sections and adding document items into each section.'
          }
        </DocumentItemDescriptionText>
        <Space height={16} />
      </PaddingContainer>
      <Line />
      <ScrollView>
        <Space height={16} />
        {documentItems.length === 0 && (
          <PaddingContainer>
            <DocumentItemDescriptionText
              responsive={responsive}
              style={{color: colors.gray.tertiary}}
            >
              {"This document doesn't have any items yet."}
            </DocumentItemDescriptionText>
            <Space height={16} />
          </PaddingContainer>
        )}
        <DocumentTemplateDocumentItems
          organization={organization}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          draftDocumentContentJson={draftDocumentContentJson}
          setSelectedDocumentItem={setSelectedDocumentItem}
          selectedDocumentItem={selectedDocumentItem}
          documentTemplateCategory={documentTemplateCategory}
          hoveredSectionIndex={hoveredSectionIndex}
          setHoveredSectionIndex={setHoveredSectionIndex}
        />
        <Space height={160} />
      </ScrollView>
    </TemplateStructureEditorContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateEditorDocumentItems.fragment = gql`
  ${DocumentTemplateDocumentItems.fragment}

  fragment DocumentTemplateEditorDocumentItems on Organization {
    id
    ...DocumentTemplateDocumentItems
  }
`;

export default DocumentTemplateEditorDocumentItems;
