// Libraries
import React from 'react';

// Supermove
import {Space, ScrollView, Styled, Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ModalType, useNavigationDOM, useToast} from '@supermove/hooks';
import {EmailTemplateModel} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useDeleteEmailTemplateForm from 'modules/Organization/Settings/Email/logic/useDeleteEmailTemplateForm';

const Row = Styled.View`
  flex-direction: row;
`;

const StyledScrollView = Styled(ScrollView)`
  max-height: 400px;
  padding: 8px;
`;

const PaddedLoadingIndicator = () => {
  return (
    <React.Fragment>
      <Space height={32} />
      <PageLoadingIndicator />
      <Space height={32} />
    </React.Fragment>
  );
};

const ListItems = <T extends {id: string}>({
  listItems,
  getListItemText,
  getListItemUrl,
}: {
  listItems: T[];
  getListItemText: (listItem: T) => string;
  getListItemUrl: (listItem: T) => string;
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <StyledScrollView>
      {listItems.map((listItem) => (
        <Row key={listItem.id}>
          <TertiaryButton
            style={{cursor: 'pointer'}}
            onPress={() => navigator.pushNewTab(getListItemUrl(listItem))}
            text={`• ${getListItemText(listItem)}`}
          />
        </Row>
      ))}
    </StyledScrollView>
  );
};

const DeleteEmailTemplateModalContent = ({
  isOpen,
  emailTemplate,
  refetch,
  handleClose,
}: {
  isOpen: boolean;
  emailTemplate: EmailTemplateModel;
  refetch: () => void;
  handleClose: () => void;
}) => {
  const deleteEmailTemplateToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Email template deleted',
  });
  const {submitting, handleSubmit} = useDeleteEmailTemplateForm({
    emailTemplate,
    onSuccess: () => {
      refetch();
      handleClose();
      deleteEmailTemplateToast.handleToast();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${emailTemplate.name}?`}
      subtitle={'Are you sure you want to delete this email template?'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

const CannotDeleteEmailTemplateConnectedToUserFlowsContent = ({
  isOpen,
  emailTemplate,
  handleClose,
}: {
  isOpen: boolean;
  emailTemplate: EmailTemplateModel;
  handleClose: () => void;
}) => {
  return (
    <SuccessModal
      isOpen={isOpen}
      icon={undefined}
      title={'Unable To Delete'}
      subtitle={`“${emailTemplate.name}” is used in the following document flows:`}
      primaryActionText='I understand'
      handlePrimaryAction={handleClose}
    >
      <ListItems
        listItems={emailTemplate.userFlows}
        getListItemText={(userFlow) => userFlow.name}
        getListItemUrl={(userFlow) => `/settings/documents/document-flows/${userFlow.uuid}`}
      />
    </SuccessModal>
  );
};

const DeleteEmailTemplateModal = ({
  emailTemplateUuid,
  deleteEmailTemplateModal,
  refetch,
}: {
  emailTemplateUuid: string;
  deleteEmailTemplateModal: ModalType;
  refetch: () => void;
}) => {
  const {data, loading} = useQuery<{emailTemplate: EmailTemplateModel}>(
    DeleteEmailTemplateModal.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        uuid: emailTemplateUuid,
      },
      skip: !deleteEmailTemplateModal.isOpen,
    },
  );

  if (!data) {
    return (
      <SmallModal
        handlePressOutside={deleteEmailTemplateModal.handleClose}
        isOpen={deleteEmailTemplateModal.isOpen}
      >
        <Loading loading={loading} as={PaddedLoadingIndicator} />
      </SmallModal>
    );
  } else {
    const hasUserFlowSteps = data.emailTemplate.userFlows.length > 0;
    if (hasUserFlowSteps) {
      return (
        <CannotDeleteEmailTemplateConnectedToUserFlowsContent
          isOpen={deleteEmailTemplateModal.isOpen}
          emailTemplate={data.emailTemplate}
          handleClose={deleteEmailTemplateModal.handleClose}
        />
      );
    }
    return (
      <DeleteEmailTemplateModalContent
        isOpen={deleteEmailTemplateModal.isOpen}
        emailTemplate={data.emailTemplate}
        refetch={refetch}
        handleClose={deleteEmailTemplateModal.handleClose}
      />
    );
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteEmailTemplateModal.query = gql`
  ${useDeleteEmailTemplateForm.fragment}

  query DeleteEmailTemplateModal($uuid: String!) {
    ${gql.query}
    emailTemplate(uuid: $uuid) {
      id
      name
      userFlows {
        id
        uuid
        name
      }
      ...useDeleteEmailTemplateForm
    }
  }
`;

export default DeleteEmailTemplateModal;
