// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {RateTableValueModel} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

const _new = ({
  primaryMetric1Value,
  primaryMetric2Value,
  secondaryMetric1Value,
}: {
  primaryMetric1Value: number;
  primaryMetric2Value?: number;
  secondaryMetric1Value?: string;
}) => ({
  rateTableValueId: undefined,
  primaryMetric1Value,
  primaryMetric2Value: primaryMetric2Value || undefined,
  secondaryMetric1Value: secondaryMetric1Value || undefined,
  rate: undefined,
  isDeleted: false,
});

const edit = withFragment(
  (rateTableValue: RateTableValueModel) => ({
    rateTableValueId: rateTableValue.id,
    primaryMetric1Value: rateTableValue.primaryMetric1Value,
    primaryMetric2Value: rateTableValue.primaryMetric2Value,
    secondaryMetric1Value: rateTableValue.secondaryMetric1Value,
    rate: rateTableValue.rate,
    isDeleted: rateTableValue.isDeleted,
  }),
  gql`
    fragment RateTableValueForm_edit on RateTableValue {
      id
      primaryMetric1Value
      primaryMetric2Value
      secondaryMetric1Value
      rate
      isDeleted
    }
  `,
);

const duplicate = withFragment(
  (rateTableValue: RateTableValueModel) => ({
    ...edit(rateTableValue),
    rateTableValueId: undefined,
  }),
  gql`
    ${edit.fragment}
    fragment RateTableValueForm_duplicate on RateTableValue {
      id
      ...RateTableValueForm_edit
    }
  `,
);

export interface RateTableValueFormType {
  rateTableValueId?: string;
  primaryMetric1Value: number;
  primaryMetric2Value?: number;
  secondaryMetric1Value?: string;
  rate?: number;
  isDeleted: boolean;
}

const toForm = ({
  rateTableValueId,
  primaryMetric1Value,
  primaryMetric2Value,
  secondaryMetric1Value,
  rate,
  isDeleted,
}: RateTableValueFormType) => ({
  rateTableValueId,
  primaryMetric1Value: _.toString(primaryMetric1Value),
  primaryMetric2Value: _.toString(primaryMetric2Value),
  secondaryMetric1Value,
  rate: Currency.toForm(rate),
  isDeleted,
});

export type RateTableValueFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({
  rateTableValueId,
  primaryMetric1Value,
  primaryMetric2Value,
  secondaryMetric1Value,
  rate,
  isDeleted,
}: RateTableValueFormToFormType) => ({
  rateTableValueId,
  primaryMetric1Value: primaryMetric1Value === '' ? null : _.toNumber(primaryMetric1Value),
  primaryMetric2Value: primaryMetric1Value === '' ? null : _.toNumber(primaryMetric2Value),
  secondaryMetric1Value,
  rate: rate ? Currency.toMutation(rate) : null,
  isDeleted,
});

const RateTableValueForm = {
  new: _new,
  edit,
  duplicate,
  toForm,
  toMutation,
};

export default RateTableValueForm;
