// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {useModal, usePopover} from '@supermove/hooks';
import {DocumentItemType} from '@supermove/models';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import {ReadonlySectionSettingsType} from '@shared/modules/Document/forms/DocumentItemForm';
import DocumentContentJson from '@shared/modules/Document/helpers/DocumentContentJson';
import {
  SetSelectedDocumentItemType,
  DocumentContentJsonType,
  SetDocumentContentJsonType,
  SelectedDocumentItemType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import AddColumnCautionModal from 'modules/Organization/Settings/Document/components/AddColumnCautionModal';
import CreateSectionModal from 'modules/Organization/Settings/Document/components/CreateSectionModal';
import MergeTextBlocksModal from 'modules/Organization/Settings/Document/components/MergeTextBlocksModal';

const handleCreateDocumentSection = ({
  name,
  readonlySectionSettings,
  setDraftDocumentContentJson,
  setSelectedDocumentItem,
  isViewingSections,
}: {
  name: string;
  readonlySectionSettings?: ReadonlySectionSettingsType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  isViewingSections: boolean;
}) => {
  const isCreatingFirstSection = !isViewingSections;
  setDraftDocumentContentJson((prevState) => {
    const rootDocumentItems = prevState.documentItems as DocumentItemType[];
    const sectionDocumentItem = {
      ...DocumentItemKindV2.getNewDocumentItem(DocumentItemKindV2.SECTION, {
        name,
        // When creating the first section, we add all the current document items into it.
        documentItems: isCreatingFirstSection ? rootDocumentItems : [],
      }),
      readonlySectionSettings,
    };
    setSelectedDocumentItem({});
    return {
      ...prevState,
      documentItems: isCreatingFirstSection
        ? [sectionDocumentItem]
        : [...rootDocumentItems, sectionDocumentItem],
    };
  });
};

const DocumentTemplateSettingsButton = ({
  selectedDocumentItem,
  setSelectedDocumentItem,
  draftDocumentContentJson,
  setDraftDocumentContentJson,
}: {
  selectedDocumentItem: SelectedDocumentItemType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  draftDocumentContentJson: DocumentContentJsonType;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const documentItemSettingsPopover = usePopover({name: 'Document Item Settings Popover'});
  const addColumnCautionModal = useModal({name: 'Add Column Caution Modal', enableTracking: true});
  const createSectionModal = useModal({name: 'Create Section Modal', enableTracking: true});
  const mergeTextModal = useModal({name: 'Merge Text Modal', enableTracking: true});

  const firstDocumentItem = _.get(draftDocumentContentJson, 'documentItems.0');
  const isViewingSections = firstDocumentItem?.itemKind === DocumentItemKindV2.SECTION;
  const isViewingColumns = firstDocumentItem?.itemKind === DocumentItemKindV2.COLUMN;

  const hasRestrictedColumnItem = DocumentContentJson.getHasColumnRestrictedDocumentItem(
    draftDocumentContentJson,
    {selectedDocumentItem},
  );
  const addColumn = () => {
    setDraftDocumentContentJson((prevState) => {
      return DocumentContentJson.handleAddColumn(_.cloneDeep(prevState), {selectedDocumentItem});
    });
    if (hasRestrictedColumnItem) {
      // Reset the selected item if items are getting removed and moving around.
      setSelectedDocumentItem({...selectedDocumentItem, index: undefined, documentItem: undefined});
    } else {
      setSelectedDocumentItem({...selectedDocumentItem, columnIndex: 0});
    }
  };

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={documentItemSettingsPopover}
        placement='right-start'
        width={400}
        actions={[
          {
            text: 'Add column',
            description: 'Splits the document into two columns.',
            onPress: hasRestrictedColumnItem ? addColumnCautionModal.handleOpen : addColumn,
            isDisabled: isViewingSections || isViewingColumns,
            tooltip: isViewingSections
              ? 'Select a section to add columns.'
              : isViewingColumns
                ? 'Columns have already been added.'
                : undefined,
          },
          {
            text: isViewingSections ? 'Add section' : 'Create section',
            description: isViewingSections
              ? 'Adds a new section to your document.'
              : 'Groups your current items into one section, allowing you to create multiple sections.',
            onPress: createSectionModal.handleOpen,
          },
          {
            text: 'Merge text blocks',
            description: 'Merges all adjacent "Text" document items into one item.',
            onPress: mergeTextModal.handleOpen,
            isDisabled: isViewingSections,
            tooltip: isViewingSections ? 'Select a section to merge text blocks.' : undefined,
          },
        ]}
      >
        <IconButton
          onPress={documentItemSettingsPopover.handleOpen}
          source={Icon.Cog}
          isSecondary
        />
      </ActionMenuPopover>
      <AddColumnCautionModal
        isOpen={addColumnCautionModal.isOpen}
        handleClose={addColumnCautionModal.handleClose}
        handleAddColumn={addColumn}
        documentContentJson={draftDocumentContentJson}
        selectedDocumentItem={selectedDocumentItem}
      />
      <CreateSectionModal
        documentContentJson={draftDocumentContentJson}
        isOpen={createSectionModal.isOpen}
        handleClose={createSectionModal.handleClose}
        handleCreateSection={(name, readonlySectionSettings) => {
          handleCreateDocumentSection({
            name,
            readonlySectionSettings,
            setDraftDocumentContentJson,
            setSelectedDocumentItem,
            isViewingSections,
          });
        }}
      />
      <MergeTextBlocksModal
        isOpen={mergeTextModal.isOpen}
        handleClose={mergeTextModal.handleClose}
        selectedDocumentItem={selectedDocumentItem}
        setSelectedDocumentItem={setSelectedDocumentItem}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
    </React.Fragment>
  );
};

export default DocumentTemplateSettingsButton;
