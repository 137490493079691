// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UserFlowRunForm, {
  UserFlowRunFormType,
} from '@shared/modules/UserFlows/forms/UserFlowRunForm';

const useCreateAndSendUserFlowRunMutation = ({
  userFlowRunForm,
  onSuccess,
  onError,
}: {
  userFlowRunForm: UserFlowRunFormType;
  onSuccess: (response: {userFlowRun: {id: string}}) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      userFlowRunForm: UserFlowRunForm.toForm(userFlowRunForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateAndSendUserFlowRunMutation.mutation,
    variables: {
      userFlowRunForm: UserFlowRunForm.toMutation(form.values.userFlowRunForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateAndSendUserFlowRunMutation.mutation = gql`
  mutation useCreateAndSendUserFlowRunMutation($userFlowRunForm: UserFlowRunForm!) {
    response: createAndSendUserFlowRun(userFlowRunForm: $userFlowRunForm) {
      ${gql.errors}
      userFlowRun {
        id
      }
    }
  }
`;

export default useCreateAndSendUserFlowRunMutation;
