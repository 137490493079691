// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import RadioButton from '@shared/design/components/RadioButton';
import RateTableMetricKind, {
  RateTableMetricKindType,
} from '@shared/modules/RateTable/enums/RateTableMetricKind';

const Row = Styled.View`
    flex-direction: row;
    align-items: center;
`;

const SelectedOptionKinds = {
  ALL: 'ALL',
  SPECIFIC: 'SPECIFIC',
};

const CopyRatesModal = ({
  isOpen,
  handleClose,
  columnHeader,
  secondaryMetricKind,
  handleSubmit,
}: {
  isOpen: boolean;
  handleClose: () => void;
  columnHeader: string;
  secondaryMetricKind: RateTableMetricKindType;
  handleSubmit: (selectedColumns: number[]) => void;
}) => {
  const secondaryColumns = RateTableMetricKind.getSecondaryColumns(secondaryMetricKind);
  const [selectedOption, setSelectedOption] = useState(SelectedOptionKinds.ALL);
  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    _.range(secondaryColumns.length),
  );
  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      handlePressOutside={handleClose}
      title={'Copy Rates To'}
      description={`This will override any selected days with rates from ${columnHeader}.`}
    >
      <RadioButton
        label={'All Columns'}
        isOn={selectedOption === SelectedOptionKinds.ALL}
        onPress={() => {
          setSelectedOption(SelectedOptionKinds.ALL);
          setSelectedColumns(_.range(secondaryColumns.length));
        }}
      />
      <Space height={6} />
      <RadioButton
        label={'Specific Columns'}
        isOn={selectedOption === SelectedOptionKinds.SPECIFIC}
        onPress={() => {
          setSelectedOption(SelectedOptionKinds.SPECIFIC);
          setSelectedColumns([]);
        }}
      />
      {selectedOption === SelectedOptionKinds.SPECIFIC &&
        secondaryColumns.map(({name}, index) => (
          <React.Fragment key={index}>
            <Space height={8} />
            <Row>
              <Space width={24} />
              <Checkbox
                label={name}
                isChecked={_.includes(selectedColumns, index)}
                handleToggle={() => setSelectedColumns(_.xor(selectedColumns, [index]))}
              />
            </Row>
          </React.Fragment>
        ))}
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={16} />
        <SmallModal.Button
          iconLeft={Icon.Check}
          color={colors.blue.interactive}
          onPress={() => handleSubmit(selectedColumns)}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CopyRatesModal;
