// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  CodeEditor,
  Icon,
  ScrollView,
  Space,
  Styled,
  RichTextInput,
  RichTextInputV1,
  SanitizedHTML,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useModal, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import Tabs from '@shared/design/components/Tabs';
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import Field from 'modules/App/components/Field';
import OrganizationEmailTemplateFieldsV2 from 'modules/Organization/Settings/Email/componentsV2/OrganizationEmailTemplateFieldsV2';

import {prettifyHtmlOnce} from './prettifyHtml';

const PageContainer = Styled.View`
    flex: 1;
`;

const Container = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
`;

const EmailTemplateFieldContainer = Styled.View`
  height: 100vh;
`;

const EmailBodyContainer = Styled.View`
    flex: 1;
    height: 90vh;
    padding: 24px;
`;

const HeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const BackButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading1};
`;

const BodyHeaderText = Styled.Text`
    ${Typography.Label};
`;

const BodyHeaderContainer = Styled.View`
    padding: 12px 24px;
    background-color: ${colors.gray.background};
    display: flex;
    border: 1px solid ${colors.gray.border};
    flex-direction: row;
`;

const BodyBorderContainer = Styled.View`
    flex: 1;
`;

const BodyEditorBodyContainer = Styled.View`
    flex: 1;
`;

const TemplatePreviewContainer = Styled.View`
`;

// Tab option enums
const TEMPLATE_DETAILS = 'TEMPLATE_DETAILS';
const TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW';

const tabs = [
  {
    label: 'Template Details',
    key: TEMPLATE_DETAILS,
  },
  {
    label: 'Template Preview',
    key: TEMPLATE_PREVIEW,
  },
];

const Header = ({
  handleSubmit,
  hasEmailChanged,
  isSubmitting,
  isDisabled,
  title,
  submitButtonText,
}: {
  handleSubmit: () => void;
  hasEmailChanged: boolean;
  isSubmitting: boolean;
  isDisabled: boolean;
  title: string;
  submitButtonText: string;
}) => {
  const {navigator} = useNavigationDOM();
  const quitEditingModal = useModal({name: 'Quit Editing Modal'});
  const navigateBack = () => {
    navigator.push('/settings/communication/email-templates');
  };

  return (
    <HeaderContainer>
      <BackButton onPress={hasEmailChanged ? quitEditingModal.handleOpen : navigateBack}>
        <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
      </BackButton>
      <Space width={16} />
      <HeaderText>{title}</HeaderText>
      <Space style={{flex: 1}} />
      <Button
        text={submitButtonText}
        isSubmitting={isSubmitting}
        isDisabled={isDisabled}
        onPress={handleSubmit}
      />
      <MessageModal
        key={quitEditingModal.key}
        isOpen={quitEditingModal.isOpen}
        title={'Quit editing?'}
        // @ts-expect-error TS(2322): Type '{ key: string; isOpen: boolean; title: strin... Remove this comment to see the full error message
        message={`Your changes will not be saved.`}
        handlePressOutside={quitEditingModal.handleClose}
        handlePrimaryAction={quitEditingModal.handleClose}
        handleSecondaryAction={navigateBack}
        primaryActionText={'Keep Editing'}
        secondaryActionText={'Quit Without Saving'}
      />
    </HeaderContainer>
  );
};

const BodyEditorHeader = ({
  form,
  setCurrentTab,
  isEnabledEmailSeamlessSwitchHtml,
}: {
  form: Form<any>;
  setCurrentTab: (tab: string) => void;
  isEnabledEmailSeamlessSwitchHtml: boolean;
}) => {
  const switchEditorExperienceModal = useModal({name: 'Switch Editor Experience Modal'});
  const currentBodyKind = _.get(form.values, 'emailTemplateForm.bodyKind');
  const editorSwitchText =
    currentBodyKind === EmailTemplateBodyKind.HTML ? 'Visual Editor' : 'HTML Editor';

  const handleSwitchEditor = () => {
    if (currentBodyKind === EmailTemplateBodyKind.WYSIWYG) {
      form.setFieldValue('emailTemplateForm.bodyKind', EmailTemplateBodyKind.HTML);
    } else {
      form.setFieldValue('emailTemplateForm.bodyKind', EmailTemplateBodyKind.WYSIWYG);
    }
    setCurrentTab(TEMPLATE_DETAILS);
  };

  return (
    <BodyHeaderContainer
      style={{borderBottomWidth: currentBodyKind === EmailTemplateBodyKind.HTML ? 1 : 0}}
    >
      <BodyHeaderText>
        {currentBodyKind === EmailTemplateBodyKind.HTML ? 'HTML' : 'Visual'} Editor
      </BodyHeaderText>
      <Space style={{flex: 1}} />
      <TertiaryButton
        text={`Switch to ${editorSwitchText}`}
        onPress={
          isEnabledEmailSeamlessSwitchHtml
            ? () => {
                if (currentBodyKind === EmailTemplateBodyKind.WYSIWYG) {
                  // First time going from WYSIWYG to HTML, prettify the HTML
                  const currentBody = _.get(form.values, 'emailTemplateForm.body') ?? '';
                  const prettyHtml = prettifyHtmlOnce(currentBody);
                  form.setFieldValue('emailTemplateForm.body', prettyHtml);
                  handleSwitchEditor();
                } else {
                  switchEditorExperienceModal.handleOpen();
                }
              }
            : switchEditorExperienceModal.handleOpen
        }
      />
      <WarningModal
        icon={currentBodyKind === EmailTemplateBodyKind.WYSIWYG ? Icon.Code : Icon.PenField}
        title={`Switch to ${editorSwitchText}?`}
        message={
          isEnabledEmailSeamlessSwitchHtml
            ? 'Saving could erase less common HTML'
            : 'This will erase all content'
        }
        isOpen={switchEditorExperienceModal.isOpen}
        primaryActionText={'Switch'}
        secondaryActionText={'Cancel'}
        handlePrimaryAction={() => {
          handleSwitchEditor();
          if (!isEnabledEmailSeamlessSwitchHtml) {
            form.setFieldValue('emailTemplateForm.body', '');
          }
          switchEditorExperienceModal.handleClose();
        }}
        handleSecondaryAction={switchEditorExperienceModal.handleClose}
        handlePressOutside={switchEditorExperienceModal.handleClose}
      />
    </BodyHeaderContainer>
  );
};

const EmailTemplatePageContentV2 = ({
  form,
  organization,
  title,
  isKindEditable,
  submitButtonText,
  handleSubmit,
  isSubmitting,
}: any) => {
  const {subject, body, bodyKind} = form.values.emailTemplateForm;
  const isDisabled = !subject || !body;
  const [currentTab, setCurrentTab] = useState(TEMPLATE_DETAILS);
  const currentTabIndex = tabs.findIndex((tab) => tab.key === currentTab);

  return (
    <PageContainer>
      <Header
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        isDisabled={isDisabled}
        title={title}
        submitButtonText={submitButtonText}
        hasEmailChanged={form.dirty}
      />
      <Container>
        <EmailTemplateFieldContainer
          style={{
            width: currentTab === TEMPLATE_DETAILS ? 450 : 700,
            transitionProperty: 'width',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease',
          }}
        >
          <ScrollView style={{flex: 1, padding: 24}}>
            {bodyKind === EmailTemplateBodyKind.HTML && (
              <React.Fragment>
                <Tabs
                  tabs={tabs}
                  handlePressTab={(tab) => setCurrentTab(tab.key)}
                  activeTabIndex={currentTabIndex}
                  isEnclosed
                  style={{flex: 1}}
                />
                <Space height={24} />
              </React.Fragment>
            )}
            {currentTab === TEMPLATE_DETAILS ? (
              <OrganizationEmailTemplateFieldsV2
                form={form}
                field={'emailTemplateForm'}
                organization={organization}
                isKindEditable={isKindEditable}
              />
            ) : (
              <TemplatePreviewContainer>
                <SanitizedHTML rawHTML={body} />
              </TemplatePreviewContainer>
            )}
            <Space height={180} />
          </ScrollView>
        </EmailTemplateFieldContainer>
        <EmailBodyContainer>
          <BodyBorderContainer>
            <BodyEditorHeader
              form={form}
              setCurrentTab={setCurrentTab}
              isEnabledEmailSeamlessSwitchHtml={
                organization.features.isEnabledEmailSeamlessSwitchHtml
              }
            />
            <BodyEditorBodyContainer
              style={
                bodyKind === EmailTemplateBodyKind.HTML
                  ? {
                      borderBottomWidth: 1,
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderColor: colors.gray.border,
                    }
                  : null
              }
            >
              {bodyKind === EmailTemplateBodyKind.WYSIWYG ? (
                <React.Fragment>
                  {organization.features.isEnabledEmailRichTextEditorV2 ? (
                    <Field
                      {...form}
                      name={'emailTemplateForm.body'}
                      component={RichTextInput}
                      input={{
                        placeholder: 'Enter body',
                        setFieldValue: form.setFieldValue,
                        hasAdvancedFormatting: false,
                        editorStyle: {
                          backgroundColor: colors.white,
                          height: 500,
                        },
                      }}
                    />
                  ) : (
                    <Field
                      {...form}
                      name={'emailTemplateForm.body'}
                      component={RichTextInputV1}
                      input={{
                        multiline: true,
                        placeholder: 'Enter body',
                        setFieldValue: form.setFieldValue,
                        style: {
                          width: '100%',
                          height: '90%',
                          paddingVertical: 9,
                          backgroundColor: colors.white,
                        },
                      }}
                      style={{
                        height: '100%',
                      }}
                    />
                  )}
                </React.Fragment>
              ) : (
                <CodeEditor
                  form={form}
                  field={'emailTemplateForm.body'}
                  isEnabledEmailSeamlessSwitchHtml={
                    organization.features.isEnabledEmailSeamlessSwitchHtml
                  }
                />
              )}
            </BodyEditorBodyContainer>
          </BodyBorderContainer>
        </EmailBodyContainer>
      </Container>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailTemplatePageContentV2.fragment = gql`
  ${OrganizationEmailTemplateFieldsV2.fragment}

  fragment EmailTemplatePageContentV2 on Organization {
    id
    features {
      isEnabledEmailRichTextEditorV2: isEnabled(feature: "EMAIL_RICH_TEXT_EDITOR_V2")
      isEnabledEmailSeamlessSwitchHtml: isEnabled(feature: "EMAIL_SEAMLESS_SWITCH_HTML")
    }
    ...OrganizationEmailTemplateFieldsV2
  }
`;

export default EmailTemplatePageContentV2;
