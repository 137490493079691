// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobUser, User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App

// Components
import {Avatar} from 'modules/App/components';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const Left = Styled.View`
  align-items: center;
`;

const Position = Styled.H7`
  color: ${colors.gray.primary};
`;

const Middle = Styled.View`
  margin-left: 10px;
`;

const Name = Styled.H7`
  color: ${colors.gray.primary};
`;

const PhoneNumber = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Right = Styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

const Status = Styled.H7`
  padding-vertical: 3px;
  padding-horizontal: 8px;
  border-width: 2px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isBold ? colors.gray.primary : colors.gray.tertiary)};
  border-radius: 3px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isBold ? colors.gray.primary : colors.gray.tertiary)};
  ${fontWeight(700)}
  text-transform: uppercase;
`;

const CrewJobUserItem = ({schedulingType, jobUser}: any) => (
  <Container>
    <Left>
      <Avatar size={40} text={User.getInitials(jobUser.user)} />
    </Left>
    <Middle>
      <Name>{User.getFullName(jobUser.user)}</Name>
      <ExternalLink url={Phone.createUrl(jobUser.user.phoneNumber)}>
        <PhoneNumber>
          <Icon
            color={colors.gray.primary}
            size={Icon.Sizes.ExtraSmall}
            source={Icon.Phone}
            style={{
              marginBottom: 1,
              marginRight: 5,
            }}
          />
          {Phone.display(jobUser.user.phoneNumber)}
        </PhoneNumber>
      </ExternalLink>
      <Position>{jobUser.moverPosition.name}</Position>
    </Middle>
    <Right>
      {schedulingType === 'DEFAULT_UNKNOWN' && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Status vars={{isBold: jobUser.status === 'CONFIRMED'}}>
          {JobUser.getDisplayStatus(jobUser)}
        </Status>
      )}
    </Right>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobUserItem.fragment = gql`
  ${JobUser.getDisplayStatus.fragment}

  fragment CrewJobUserItem on JobUser {
    id
    jobId
    userId
    status
    user {
      id
      firstName
      lastName
      phoneNumber
    }
    moverPosition {
      id
      name
    }
    ...JobUser_getDisplayStatus
  }
`;

export default CrewJobUserItem;
