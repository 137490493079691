// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  useRef,
  useResponsive,
  useState,
  useEffect,
} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import JobForm from '@shared/modules/Job/forms/JobForm';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import SourceKind from '@shared/modules/Project/enums/SourceKind';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import useCreateProjectMutation from '@shared/modules/Project/hooks/useCreateProjectMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import NewJobSuccessModal from 'modules/App/Job/components/NewJobSuccessModal';
import ModalPage from 'modules/App/components/ModalPage';
import Navigation from 'modules/App/components/Navigation';
import Panel from 'modules/App/components/Panel';
import SplitPage from 'modules/App/components/SplitPage';
import JobFields from 'modules/Job/components/JobFields';
import JobMap from 'modules/Job/components/JobMap';
import ProjectFieldsForJob from 'modules/Project/components/ProjectFieldsForJob';

const Container = Styled.View`
  flex: 1;
`;

const ExtraSpace = Styled.View`
  height: 200px;
`;

const Footer = Styled.View`
  padding: 10px;
  background-color: ${colors.white};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.blue.accent};
`;

const SaveButton = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const SaveText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const Title = Styled.Text`
  ${Typography.Heading3}
  margin-top: 20px;
  margin-horizontal: 30px;
`;

const Section = Styled.View<{index: number}>`
  z-index: ${({index = 0}) => 100 - index};
`;

// Form object is expecting the string version of this object.
const getAdditionalItems = ({organizationSelectedForCreateProject}: any) => {
  return Json.toString(
    Organization.getJobFormAdditionalItemsDefaultValues(organizationSelectedForCreateProject),
  );
};

const getWarehouseLongitudeLatitude = ({form}: any) => {
  const warehouseLocationFromForm = _.get(
    form.values,
    `projectForm.jobForms.0.warehouseLocationForm`,
  );

  return warehouseLocationFromForm
    ? {
        latitude: warehouseLocationFromForm.latitude,
        longitude: warehouseLocationFromForm.longitude,
      }
    : null;
};

const NewProjectPageContent = ({
  viewer,
  client,
  viewingOrganization,
  organizationSelectedForCreateProject,
  setSlug,
  initialOrganizationForCreateProject,
}: any) => {
  const {
    isEnabledMultibranchCreateProjectForAnyOrganizationFromParentAccount,
    isEnabledGooglePlacesCenterLocation,
    isEnabledCreateStorageMultipleWarehouse,
  } = viewingOrganization.features;
  const canEditProjectOrganization =
    viewingOrganization.isPrimary &&
    _.includes(UserRole.MANAGER_ROLES_PLUS_SUPER, viewer.role) &&
    isEnabledMultibranchCreateProjectForAnyOrganizationFromParentAccount;
  const primaryOrganization = viewingOrganization.company.organizations.find(
    (organization: any) => organization.isPrimary,
  );
  const responsive = useResponsive();
  const scrollView = useRef();
  const mapRef = useRef();
  const {isOpen, handleOpen, handleClose} = useModal();
  const [job, setJob] = useState();
  const projectForm = ProjectForm.new(organizationSelectedForCreateProject, {
    creatorId: viewer.id,
    bookedById: viewingOrganization.features.isEnabledNoAutoAssignBookedByToViewer
      ? undefined
      : viewer.id,
    organizationId: initialOrganizationForCreateProject.id,
    primaryLaborSourceOrganizationId: viewingOrganization.id,
    sourceId: organizationSelectedForCreateProject.source.id,
    additionalItems: getAdditionalItems({organizationSelectedForCreateProject}),
    clientForm: client
      ? ClientForm.edit(client)
      : ClientForm.new({
          organizationId: organizationSelectedForCreateProject.id,
          isOkayDuplicatePhoneNumber: true,
        }),
  });

  const {form, submitting, handleSubmit} = useCreateProjectMutation({
    projectForm,
    organization: organizationSelectedForCreateProject,
    // Navigate to the job page on success. We assume there is always one job created when
    // the project is created and thus why [0] works.
    onSuccess: ({project}: any) => {
      setJob(project.jobs[0]);
      handleOpen();
    },
    onError: () => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      scrollView.current.scrollTo({y: 0});
    },
  });

  // The primaryLaborSourceOrganizationId is set by the branch dropdown
  // When the id changes, we update the slug used in the organization query
  // The organizationSelectedForCreateProject is used for all form data
  const primaryLaborSourceOrganizationId = _.get(
    form.values,
    'projectForm.primaryLaborSourceOrganizationId',
  );
  useEffect(() => {
    if (primaryLaborSourceOrganizationId) {
      const selectedBranch = viewingOrganization.company.organizations.find(
        (organization: any) => _.toString(organization.id) === primaryLaborSourceOrganizationId,
      );
      setSlug(
        selectedBranch.kind !== OrganizationKind.FRANCHISE
          ? primaryOrganization.slug
          : selectedBranch.slug,
      );
    }
  }, [
    primaryLaborSourceOrganizationId,
    primaryOrganization.slug,
    setSlug,
    viewingOrganization.company.organizations,
  ]);

  // The warehouseLocation is always pulled from the selected branch (dependent or independent)
  const selectedBranch = viewingOrganization.company.organizations.find(
    (organization: any) => _.toString(organization.id) === primaryLaborSourceOrganizationId,
  );

  const warehouseLocation = !isEnabledCreateStorageMultipleWarehouse
    ? selectedBranch.warehouseLocation
    : getWarehouseLongitudeLatitude({form});
  const moveLocations = JobForm.getLocations(_.get(form, 'values.projectForm.jobForms.0'));
  const boundLocations = warehouseLocation ? [warehouseLocation, ...moveLocations] : moveLocations;

  return (
    <React.Fragment>
      <SplitPage
        split={
          responsive.desktop && (
            <Panel>
              <JobMap
                locations={moveLocations}
                boundLocations={boundLocations}
                warehouseLocation={warehouseLocation}
                mapRef={mapRef}
              />
            </Panel>
          )
        }
      >
        <Container>
          <ScrollView ref={scrollView} style={{flex: 1}}>
            <Title>{'New Project'}</Title>
            <Section index={1}>
              <ProjectFieldsForJob
                field={'projectForm'}
                form={form}
                organization={organizationSelectedForCreateProject}
                disabled={submitting}
                shouldResetJobDateOnProjectTypeChange
                jobFormField={'projectForm.jobForms.0'}
                canEditProjectOrganization={canEditProjectOrganization}
              />
            </Section>
            <Section index={2}>
              <JobFields
                field={'projectForm'}
                form={form}
                organization={organizationSelectedForCreateProject}
                jobIndex={0}
                shouldResetJobDateOnJobTypeChange
                jobFormField={'projectForm.jobForms.0'}
                warehouseLocation={isEnabledGooglePlacesCenterLocation ? warehouseLocation : null}
                mapRef={mapRef}
                jobMapComponent={
                  <JobMap
                    locations={moveLocations}
                    boundLocations={boundLocations}
                    warehouseLocation={warehouseLocation}
                    mapRef={mapRef}
                  />
                }
              />
            </Section>
            <ExtraSpace />
          </ScrollView>
          <Footer {...responsive}>
            <SaveButton data-test-id='form-save-button' loading={submitting} onPress={handleSubmit}>
              <SaveText>Save</SaveText>
            </SaveButton>
          </Footer>
        </Container>
      </SplitPage>
      <NewJobSuccessModal.Content isOpen={isOpen} job={job} onClose={handleClose} />
    </React.Fragment>
  );
};

const NewProjectPage = () => {
  // No slug passed in will default to viewing org, otherwise will read all other fields based on the org from the slug
  const [slug, setSlug] = useState(null);
  const {params} = useNavigationDOM();
  return (
    <ModalPage
      navigation={({loading, data, navigator, params}: any) => (
        <Navigation
          side={() => 60}
          title={loading ? 'Loading...' : data.organizationSelectedForCreateProject.name}
          subtitle={'New Project'}
          left={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
        />
      )}
      query={NewProjectPage.query}
      variables={{
        clientUuid: params.clientUuid ? params.clientUuid : null,
        sourceKind: SourceKind.OFFICE_CREATE_PROJECT,
        slug,
      }}
    >
      {({data}: any) => (
        <NewProjectPageContent
          viewer={data.viewer}
          source={data.source}
          client={data.client}
          viewingOrganization={data.viewingOrganization}
          organizationSelectedForCreateProject={data.organizationSelectedForCreateProject}
          setSlug={setSlug}
          initialOrganizationForCreateProject={data.viewer.organizationForCreateProject}
        />
      )}
    </ModalPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewProjectPage.query = gql`
  ${useCreateProjectMutation.fragment}
  ${ClientForm.edit.fragment}
  ${ProjectForm.new.fragment}
  ${JobFields.fragment}
  ${ProjectFieldsForJob.fragment}
  ${Organization.getJobFormAdditionalItemsDefaultValues.fragment}

  query NewProjectPage($clientUuid: String, $sourceKind: String!, $slug: String) {
    ${gql.query}
    viewer {
      id
      role
      organizationForCreateProject {
        id
        slug
      }
    }
    client(clientUuid: $clientUuid) {
      id
      ...ClientForm_edit
    }
    viewingOrganization: organization {
      id
      isPrimary
      slug
      company {
        id
        organizations {
          id
          name
          slug
          kind
          isPrimary
          warehouseLocation {
            latitude
            longitude
          }
        }
      }
      features {
        isEnabledMultibranchCreateProjectForAnyOrganizationFromParentAccount: isEnabled(
          feature: "MULTIBRANCH_CREATE_PROJECT_FOR_ANY_ORGANIZATION_FROM_PARENT_ACCOUNT"
        )
        isEnabledGooglePlacesCenterLocation: isEnabled(feature: "GOOGLE_PLACES_CENTER_LOCATION")
        isEnabledCreateStorageMultipleWarehouse: isEnabled(feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE")
        isEnabledNoAutoAssignBookedByToViewer: isEnabled(feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER")
      }
    }
    organizationSelectedForCreateProject: organization(slug: $slug) {
      id
      name
      source(sourceKind: $sourceKind) {
        id
      }
      ...useCreateProjectMutation
      ...ProjectForm_new
      ...JobFields
      ...ProjectFieldsForJob
      ...Organization_getJobFormAdditionalItemsDefaultValues
    }
  }
`;

export default NewProjectPage;
