// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive, useState} from '@supermove/hooks';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import EditCostCostItemsModalV2 from 'modules/Cost/components/EditCostCostItemsModalV2';
import NewCompensationEntryModal from 'modules/Cost/components/NewCompensationEntryModal';
import CostAndCompTable from 'modules/Job/V2/Move/components/CostAndCompTable';
import ProjectCostsBlockRevenueSummaryV1 from 'modules/Project/components/ProjectCostsBlockRevenueSummaryV1';
import ProjectCostsTable from 'modules/Project/components/ProjectCostsTable';

const SectionContainer = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const PaddingContainer = Styled.View`
  padding: 16px;
`;

const ProjectCostsSection = ({job, refetch, responsive}: any) => {
  return (
    <React.Fragment>
      <ProjectCostsTable project={job.project} refetch={refetch} />
      {job.project.organization.settings.isCostAndCompensationEnabled && (
        <React.Fragment>
          <Space height={responsive.mobile ? 8 : 16} />
          <ProjectCostsBlockRevenueSummaryV1 project={job.project} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ProjectAndJobCostsSection = ({job, newCompensationEntryModal, refetch, responsive}: any) => {
  return (
    <CollapsibleContent
      title={'Accounting'}
      secondaryActionText={'Create Entry'}
      secondaryActionLeftIcon={Icon.Plus}
      handleSecondaryAction={newCompensationEntryModal.handleOpen}
    >
      <PaddingContainer>
        <CostAndCompTable project={job.project} refetch={refetch} />
        {job.project.organization.settings.isCostAndCompensationEnabled && (
          <React.Fragment>
            <Space height={responsive.mobile ? 8 : 16} />
            <ProjectCostsBlockRevenueSummaryV1 project={job.project} />
          </React.Fragment>
        )}
      </PaddingContainer>
    </CollapsibleContent>
  );
};

const JobProjectCostsBlockContent = ({sectionIndex, job, refetch}: any) => {
  const responsive = useResponsive();
  const newCompensationEntryModal = useModal({name: 'New Compensation Entry Modal'});
  const editCostCostItemsModalV2 = useModal({name: 'Edit Cost Cost Items Modal V2'});
  const [costId, setCostId] = useState();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SectionContainer sectionIndex={sectionIndex}>
      <React.Fragment>
        {job.project.organization.features.isEnabledJobLevelCosts ? (
          <ProjectAndJobCostsSection
            job={job}
            newCompensationEntryModal={newCompensationEntryModal}
            refetch={refetch}
            responsive={responsive}
          />
        ) : (
          <CollapsibleContent title={'Accounting'}>
            <PaddingContainer>
              <ProjectCostsSection job={job} refetch={refetch} responsive={responsive} />
            </PaddingContainer>
          </CollapsibleContent>
        )}
        <NewCompensationEntryModal
          key={newCompensationEntryModal.key}
          isOpen={newCompensationEntryModal.isOpen}
          setCostId={setCostId}
          handleOpenEditCostCostItemsModal={editCostCostItemsModalV2.handleOpen}
          handleClose={newCompensationEntryModal.handleClose}
          project={job.project}
          refetch={refetch}
        />
        <EditCostCostItemsModalV2
          key={editCostCostItemsModalV2.key}
          isOpen={editCostCostItemsModalV2.isOpen}
          handleClose={editCostCostItemsModalV2.handleClose}
          costId={costId}
          aggregateCost={job.project.aggregateCost}
          refetch={refetch}
        />
      </React.Fragment>
    </SectionContainer>
  );
};

const JobProjectCostsBlockQuery = ({sectionIndex, job}: any) => {
  const {data, loading, refetch} = useQuery(JobProjectCostsBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {jobUuid: job.uuid},
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <JobProjectCostsBlockContent
            sectionIndex={sectionIndex}
            job={data.job}
            refetch={refetch}
          />
        );
      }}
    </Loading>
  );
};

const JobProjectCostsBlock = ({sectionIndex, job}: any) => {
  return (
    <JobProjectCostsBlockQuery
      key={`${job.project.costsHash}_${job.project.costItemRatesHash}_${job.project.totalRevenue}`}
      sectionIndex={sectionIndex}
      job={job}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectCostsBlock.listener = gql`
  fragment JobProjectCostsBlock_listener on Project {
    id
    costsHash
    costItemRatesHash
    totalRevenue
  }
`;

JobProjectCostsBlock.fragment = gql`
  ${JobProjectCostsBlock.listener}

  fragment JobProjectCostsBlock on Job {
    id
    uuid
    project {
      id
      ...JobProjectCostsBlock_listener
    }
  }
`;

JobProjectCostsBlock.query = gql`
  ${JobProjectCostsBlock.listener}
  ${EditCostCostItemsModalV2.fragment}
  ${ProjectCostsBlockRevenueSummaryV1.fragment}
  ${CostAndCompTable.fragment}
  ${NewCompensationEntryModal.fragment}
  ${ProjectCostsTable.fragment}

  query JobProjectCostsBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        aggregateCost {
          ...EditCostCostItemsModalV2
        }
        organization {
          id
          features {
            isEnabledJobLevelCosts: isEnabled(feature: "JOB_LEVEL_COSTS")
          }
          settings {
            id
            isCostAndCompensationEnabled
          }
        }
        ...CostAndCompTable
        ...NewCompensationEntryModal
        ...JobProjectCostsBlock_listener
        ...ProjectCostsBlockRevenueSummaryV1
        ...ProjectCostsTable
      }
    }
  }
`;

export default JobProjectCostsBlock;
