// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {JobRequestForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useRef, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Float, Json} from '@supermove/utils';

// App
import {useCreateJobRequestV2Form} from 'modules/Customer/JobRequest/New/logic';
import {
  JobRequestBookedBy,
  JobRequestDisclosure,
  JobRequestFields,
  JobRequestOrganizationLogo,
} from 'modules/Customer/JobRequest/components';
import {CustomerPage} from 'modules/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  align-self: center;
  width: ${(props) => ((props as any).mobile ? '100%' : '570px')};
  padding-horizontal: ${(props) => ((props as any).mobile ? 15 : 30)}px;
  padding-bottom: 240px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}px;
`;

const SectionSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 30 : 40)}px;
`;

const BookedBy = Styled.View`
  margin-top: 20px;
`;

const Disclosure = Styled.View`
  margin-top: 20px;
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const SubmitText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const NewJobRequestPageContent = ({organization}: any) => {
  const {navigator, params} = useNavigationDOM();
  const {preferredDate, slug} = params;
  const responsive = useResponsive();
  const scrollView = useRef();
  const bookedById = _.get(organization, 'user.id');
  const {form, submitting, handleSubmit} = useCreateJobRequestV2Form({
    jobRequestForm: JobRequestForm.new({
      bookedById,
      organizationId: organization.id,
      preferredDate,
      additionalItems: Json.toString(
        Organization.getJobFormAdditionalItemsDefaultValues(organization),
      ),
    }),
    organization,
    onSuccess: ({jobRequest}: any) => {
      navigator.push(`/0/${slug}/requests/${jobRequest.uuid}/success`);
    },
    onError: () => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      scrollView.current.scrollTo({y: 0});
    },
  });

  return (
    <Container>
      <ScrollView ref={scrollView} style={{flex: 1}}>
        <Content {...responsive}>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={0}>
            {organization.features.isEnabledHideMoveRequestLogo ? null : (
              <JobRequestOrganizationLogo organization={organization} />
            )}
            {!!organization.user && (
              <BookedBy>
                <JobRequestBookedBy user={organization.user} />
              </BookedBy>
            )}
            <Disclosure>
              <JobRequestDisclosure />
            </Disclosure>
          </Section>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <SectionSpace {...responsive} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={1}>
            <JobRequestFields field={'jobRequestForm'} form={form} organization={organization} />
          </Section>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <SectionSpace {...responsive} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={2} style={{margin: 0}}>
            <SubmitButton loading={submitting} onPress={handleSubmit}>
              <SubmitText>Submit Request</SubmitText>
            </SubmitButton>
          </Section>
        </Content>
      </ScrollView>
    </Container>
  );
};

const NoOrganizationFound = () => {
  return <Container />;
};

const getTitle = ({loading, data}: any) => {
  if (loading) {
    return 'Loading...';
  }
  if (data.organization) {
    const {businessName, name} = data.organization;
    return businessName || name;
  }
  return 'Company not found';
};

const NewJobRequestPage = () => {
  const {params} = useNavigationDOM();
  const {referrer, slug} = params;

  return (
    <CustomerPage
      variables={{
        slug,
        userId: Float.toFloat(referrer),
      }}
      query={NewJobRequestPage.query}
      title={getTitle}
      subtitle={() => 'Request a move'}
    >
      {({data}: any) =>
        data.organization ? (
          <NewJobRequestPageContent organization={data.organization} />
        ) : (
          <NoOrganizationFound />
        )
      }
    </CustomerPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewJobRequestPage.query = gql`
  ${JobRequestBookedBy.fragment}
  ${JobRequestOrganizationLogo.fragment}
  ${JobRequestFields.fragment}
  ${Organization.getJobFormAdditionalItemsDefaultValues.fragment}
  ${useCreateJobRequestV2Form.fragment}

  query NewJobRequestPage($slug: String!, $userId: Int) {
    ${gql.query}
    organization(slug: $slug) {
      id
      name
      slug
      businessName
      user(id: $userId) {
        id
        ...JobRequestBookedBy
      }
      features {
        isEnabledHideMoveRequestLogo: isEnabled(feature: "HIDE_MOVE_REQUEST_LOGO")
      }
      ...JobRequestFields
      ...JobRequestOrganizationLogo
      ...Organization_getJobFormAdditionalItemsDefaultValues
      ...useCreateJobRequestV2Form
    }
  }
`;

export default NewJobRequestPage;
