// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  DateInput,
  DropdownInput,
  Icon,
  MultiDropdownInput,
  Styled,
  Space,
  Popover,
  ScrollView,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, usePopover, useModal, useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime, List, pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ExportStatus from '@shared/modules/Billing/enums/ExportStatus';
import InvoicePaymentStatus from '@shared/modules/Billing/enums/InvoicePaymentStatus';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import {getDefaultFiltersForOrganization} from '@shared/modules/Billing/hooks/useFilteredInvoicesReducer';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import ConfirmationBulkCodatExportModal from 'modules/Accounting/components/ConfirmationBulkCodatExportModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import useAppContext from 'modules/App/context/useAppContext';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex: 3;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex: 2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Label}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  zIndex: ${(props) => 1000 - (props as any).index}
`;

const TabsContainer = Styled.View`
  zIndex: ${(props) => 1000 - (props as any).index}
`;

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FilterTitle = Styled.Text`
  ${Typography.Heading2}
`;

const FilterContainer = Styled.View`
  flex-direction: row;
  zIndex: ${(props) => 1000 - (props as any).index}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
  padding-top: 32px;
`;

const Label = Styled.H7`
  ${Typography.Label}
`;

const View = Styled.View`
`;

const ERROR = 'ERROR';

const getInputValueForDate = (date: any) => {
  const isValidDate = Datetime.isValidDateString(date);
  if (isValidDate) {
    return Datetime.fromDate(date);
  }
  return '';
};

const Loader = Styled.Loading`
  height: 1px;
  transform: scale(.6);
  top: 6px;
`;

const InvoiceStatusLoader = () => {
  return <Loader color={colors.gray.border} />;
};

const getTabs = (filteredInvoiceCountsByStatus: any, isLoading: any) => [
  {
    key: InvoiceStatus.DRAFT,
    label: 'Draft',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.draftCount,
  },
  {
    key: InvoiceStatus.SCHEDULED,
    label: 'Scheduled',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.scheduledCount,
  },
  {
    key: InvoiceStatus.UNPAID,
    label: 'Unpaid',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.unpaidCount,
  },
  {
    key: InvoiceStatus.OVERDUE,
    label: 'Overdue',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.overdueCount,
  },
  {
    key: InvoiceStatus.PAID,
    label: 'Paid',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.paidCount,
  },
  {
    key: InvoiceStatus.FINALIZED,
    label: 'Finalized',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.finalizedCount,
  },
  {
    key: InvoiceStatus.CANCELLED,
    label: 'Cancelled',
    count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.cancelledCount,
  },
  // NOTE(cooper): The error tab is a special non-status tab that indicates
  // invoices that have an email error associated with them
  ...List.insertIf(
    !_.isEmpty(filteredInvoiceCountsByStatus) && filteredInvoiceCountsByStatus.erroredCount > 0,
    {
      key: ERROR,
      label: 'Error',
      count: isLoading ? <InvoiceStatusLoader /> : filteredInvoiceCountsByStatus.erroredCount,
      colorOverride: colors.red.warning,
    },
  ),
];

const TabHeader = ({
  filteredInvoiceCountsByStatus,
  filters,
  dispatch,
  actions,
  index,
  loading,
}: any) => {
  const isLoading = loading || _.isEmpty(filteredInvoiceCountsByStatus);
  const tabs = getTabs(filteredInvoiceCountsByStatus, isLoading);
  const {statuses, hasEmailSendError} = filters;
  const [status] = statuses;
  const activeTabIndex = _.findIndex(tabs, (definition) =>
    hasEmailSendError ? definition.key === ERROR : definition.key === status,
  );

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <TabsContainer index={index}>
      <Tabs
        tabs={tabs}
        handlePressTab={({key}) => {
          if (key === ERROR) {
            dispatch({type: actions.SET_HAS_EMAIL_SEND_ERROR, payload: true});
          } else {
            dispatch({type: actions.SET_STATUS, payload: key});
          }
        }}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const POPOVER_FILTERS = [
  'projectTypeIds',
  'dateFrom',
  'dateTo',
  'dueDateFrom',
  'dueDateTo',
  'invoicePayments',
  'exportStatuses',
  'projectStatuses',
  'isTraining',
  'slugs',
];

const ProjectsFilterButton = ({
  organization,
  projectTypeFilterOptions,
  projectTypeCategory,
  isMultiBranch,
  filters,
  dispatch,
  actions,
  params,
}: any) => {
  const listInvoicesPageFiltersPopover = usePopover({name: 'List Invoices Page Filters Popover'});
  const {visibleCalendarStatuses} = organization.settings;
  const initialFiltersForOrganization = getDefaultFiltersForOrganization(
    visibleCalendarStatuses,
    filters.projectTypeCategories,
  );
  const appliedFiltersCount = _.reduce(
    filters,
    (appliedFiltersCount, filterValue, filterKey) => {
      if (_.includes(POPOVER_FILTERS, filterKey) && !!filterValue) {
        if (
          (filterKey !== 'projectStatuses' &&
            filterKey !== 'isTraining' &&
            filterKey !== 'slugs') ||
          (filterKey === 'projectStatuses' &&
            !_.isEqual(filterValue, initialFiltersForOrganization.projectStatuses)) ||
          (filterKey === 'isTraining' &&
            filterValue !== initialFiltersForOrganization.isTraining) ||
          (filterKey === 'slugs' && isMultiBranch && !filterValue.includes('ALL_ORGANIZATIONS'))
        ) {
          return appliedFiltersCount + 1;
        }
      }
      return appliedFiltersCount;
    },
    0,
  );

  return (
    <React.Fragment>
      <Popover.Content innerRef={listInvoicesPageFiltersPopover.ref}>
        <SecondaryButton
          text={appliedFiltersCount === 0 ? 'Filter' : `Filter (${appliedFiltersCount})`}
          iconLeft={Icon.Filter}
          onPress={listInvoicesPageFiltersPopover.handleToggle}
        />
      </Popover.Content>
      <ListInvoicesPageFiltersPopover
        isMultiBranch={isMultiBranch}
        organization={organization}
        projectTypeFilterOptions={projectTypeFilterOptions}
        projectTypeCategory={projectTypeCategory}
        filters={filters}
        dispatch={dispatch}
        actions={actions}
        popover={listInvoicesPageFiltersPopover}
        params={params}
      />
    </React.Fragment>
  );
};

const ProjectTagFilter = ({organization, filters, dispatch, actions}: any) => {
  const filterByArchivedTag = organization.companySettings.tags.filter(
    (tag: any) => !tag.isArchived,
  );

  const projectTags = filterByArchivedTag.filter(
    (tag: any) => tag.category === TagCategory.PROJECT,
  );

  return (
    <TagDropdownInputField
      options={
        projectTags
          ? projectTags.map((tag: any) => ({
              value: tag.id,
              label: `${tag.emoji} ${tag.name}`,
            }))
          : []
      }
      placeholder={'Select project tag'}
      label={'Project Tags'}
      index={8}
      value={filters.projectTagIds}
      onChangeValue={(projectTagIds: any) => {
        dispatch({
          type: actions.SET_PROJECT_TAGS,
          payload: projectTagIds.length ? projectTagIds : null,
        });
      }}
    />
  );
};

const ListInvoicesPageFiltersPopover = ({
  organization,
  projectTypeFilterOptions,
  projectTypeCategory,
  isMultiBranch,
  filters,
  dispatch,
  actions,
  popover,
  params,
}: any) => {
  const isTrainingOptions = [
    {label: 'Show Training Projects', value: null},
    {label: 'Hide Training Projects', value: false},
    {label: 'Show Only Training Projects', value: true},
  ];
  const organizations = _.get(organization, 'company.organizations', []);

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={320} maxHeight={600}>
        <ScrollView style={{maxHeight: 600}}>
          <ContentContainer>
            <Space height={16} />
            <FilterTitle>Filters</FilterTitle>
            <Space height={16} />
            <Label>Invoice Date</Label>
            <Space height={8} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <FilterContainer index={0}>
              <FieldInput
                index={0}
                component={DateInput}
                label={'Start Date'}
                input={{
                  isPortaled: true,
                  value: getInputValueForDate(filters.dateFrom),
                  setFieldValue: () => {},
                  placeholder: 'MM/DD/YY',
                  style: {width: 132},
                  onChangeDate: (dateFrom: any) =>
                    dispatch({
                      type: actions.SET_DATE_FROM,
                      payload: dateFrom,
                    }),
                }}
              />
              <Space width={8} />
              <BodyText>-</BodyText>
              <Space width={8} />
              <FieldInput
                index={1}
                component={DateInput}
                label={'End Date'}
                input={{
                  isPortaled: true,
                  value: getInputValueForDate(filters.dateTo),
                  setFieldValue: () => {},
                  placeholder: 'MM/DD/YY',
                  style: {width: 132},
                  onChangeDate: (dateTo: any) =>
                    dispatch({
                      type: actions.SET_DATE_TO,
                      payload: dateTo,
                    }),
                }}
              />
            </FilterContainer>
            <Space height={16} />
            <Label>Invoice Due Date</Label>
            <Space height={8} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <FilterContainer index={1}>
              <FieldInput
                index={2}
                component={DateInput}
                label={'Start Date'}
                input={{
                  value: getInputValueForDate(filters.dueDateFrom),
                  setFieldValue: () => {},
                  placeholder: 'MM/DD/YY',
                  style: {width: 132},
                  onChangeDate: (dueDateFrom: any) =>
                    dispatch({
                      type: actions.SET_DUE_DATE_FROM,
                      payload: dueDateFrom,
                    }),
                }}
              />
              <Space width={8} />
              <BodyText>-</BodyText>
              <Space width={8} />
              <FieldInput
                index={3}
                component={DateInput}
                label={'End Date'}
                input={{
                  value: getInputValueForDate(filters.dueDateTo),
                  setFieldValue: () => {},
                  placeholder: 'MM/DD/YY',
                  style: {width: 132},
                  onChangeDate: (dueDateTo: any) =>
                    dispatch({
                      type: actions.SET_DUE_DATE_TO,
                      payload: dueDateTo,
                    }),
                }}
              />
            </FilterContainer>
            <Space height={16} />
            <FieldInput
              label={'Payment Status'}
              component={MultiDropdownCheckboxInput}
              index={4}
              input={{
                value: filters.invoicePayments,
                options: InvoicePaymentStatus.OPTIONS,
                placeholder: `Select payment status(es)`,
                onChangeValue: (invoicePayments: any) =>
                  dispatch({
                    type: actions.SET_INVOICE_PAYMENTS,
                    payload: invoicePayments.length ? invoicePayments : null,
                  }),
              }}
            />
            <Space height={16} />
            <FieldInput
              label={'Export Status'}
              component={MultiDropdownCheckboxInput}
              index={5}
              input={{
                value: filters.exportStatuses,
                options: ExportStatus.OPTIONS,
                placeholder: `Select export status(es)`,
                onChangeValue: (exportStatuses: any) =>
                  dispatch({
                    type: actions.SET_EXPORT_STATUSES,
                    payload: exportStatuses.length ? exportStatuses : null,
                  }),
              }}
            />
            {isMultiBranch && (
              <React.Fragment>
                <Space height={16} />
                <MultiBranchOrganizationField
                  label={'Branch'}
                  width={'100%'}
                  index={6}
                  isPortaled={false}
                  value={params.slugs || []}
                  disableAllOption
                  organizations={organizations}
                  onChangeValue={(newSlugs: any) => {
                    dispatch({
                      type: actions.SET_SLUGS,
                      payload: newSlugs,
                    });
                  }}
                />
              </React.Fragment>
            )}
            <Space height={16} />
            <FieldInput
              label={'Project Type'}
              component={MultiDropdownInput}
              index={7}
              input={{
                value: filters.projectTypeIds,
                options: isMultiBranch
                  ? _.sortBy(projectTypeFilterOptions, ['name', 'organizationName']).map(
                      (projectType) => ({
                        label: projectType.name,
                        description: projectType.organizationName,
                        value: projectType.id,
                      }),
                    )
                  : projectTypeFilterOptions.map(({id, name}: any) => ({
                      label: name,
                      value: id,
                    })),
                style: {flex: 1},
                placeholder: `Select project type(s)`,
                onChangeValue: (projectTypeIds: any) =>
                  dispatch({
                    type: actions.SET_PROJECT_TYPES,
                    payload: projectTypeIds.length ? projectTypeIds : null,
                  }),
                components: {
                  Option: (optionData: any) => (
                    <MultiDropdownCheckboxInput.CheckboxOption
                      {...optionData}
                      isDescriptionBelow={isMultiBranch}
                    />
                  ),
                },
              }}
            />
            {projectTypeCategory === ProjectTypeCategory.MOVE && (
              <React.Fragment>
                <Space height={16} />
                <ProjectTagFilter
                  organization={organization}
                  filters={filters}
                  dispatch={dispatch}
                  actions={actions}
                />
              </React.Fragment>
            )}
            {projectTypeCategory === ProjectTypeCategory.MOVE && (
              <React.Fragment>
                <Space height={16} />
                <FieldInput
                  label={'Project Status'}
                  component={MultiDropdownCheckboxInput}
                  index={9}
                  input={{
                    value: filters.projectStatuses,
                    options: ProjectStatus.OPTIONS.filter(
                      (option) => option.value !== ProjectStatus.CANCELLED,
                    ),
                    placeholder: `Select project status(es)`,
                    onChangeValue: (projectStatuses: any) =>
                      dispatch({
                        type: actions.SET_PROJECT_STATUSES,
                        payload: projectStatuses.length ? projectStatuses : null,
                      }),
                  }}
                />
                <Space height={16} />
                <FieldInput
                  label={'Training Projects'}
                  component={DropdownInput}
                  index={10}
                  input={{
                    value: filters.isTraining,
                    options: isTrainingOptions,
                    placeholder: `Hide training projects`,
                    style: {width: 300},
                    setFieldValue: (name: any, isTraining: any) =>
                      dispatch({
                        type: actions.SET_IS_TRAINING,
                        payload: isTraining,
                      }),
                  }}
                />
              </React.Fragment>
            )}
            <Space height={16} />
            <SecondaryButton
              text={'Clear Filters'}
              onPress={() => dispatch({type: actions.RESET_FILTERS})}
            />
            <Space height={16} />
          </ContentContainer>
        </ScrollView>
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ActionsButton = ({
  filters,
  invoices,
  selectedInvoices,
  organization,
  handleFinalizePress,
  handleUnfinalizePress,
  handleSubmitBulkSendInvoicesToCodat,
  setSelectedInvoices,
}: any) => {
  const [status] = filters.statuses;
  const hasCodatIntegration = Boolean(organization.activeCodatIntegration);
  const confirmationBulkCodatExportModal = useModal({
    name: 'Confirmation Bulk Codat Export Modal',
    enableTracking: true,
  });

  if (status === InvoiceStatus.PAID || status === InvoiceStatus.FINALIZED) {
    return (
      <React.Fragment>
        <DropdownButton
          text={'Actions'}
          isDisabled={!selectedInvoices.length}
          actions={[
            ...List.insertIf(hasCodatIntegration, {
              text: 'Queue for Quickbooks Export',
              onPress: () => {
                if (
                  organization.isPrimaryMultibranch &&
                  organization.features.isEnabledMultibranchAccounting
                ) {
                  confirmationBulkCodatExportModal.handleOpen();
                } else {
                  handleSubmitBulkSendInvoicesToCodat();
                }
              },
            }),
            ...List.insertIf(status === InvoiceStatus.PAID, {
              text: 'Finalize Invoice(s)',
              onPress: () => handleFinalizePress(),
            }),
            ...List.insertIf(status === InvoiceStatus.FINALIZED, {
              text: 'Unfinalize Invoice(s)',
              onPress: () => handleUnfinalizePress(),
            }),
          ]}
          menuWidth={200}
          menuPosition={DropdownButton.MENU_POSITION.RIGHT}
          ButtonComponent={Button}
        />
        <ConfirmationBulkCodatExportModal
          organization={organization}
          selectedInvoices={selectedInvoices}
          isOpen={confirmationBulkCodatExportModal.isOpen}
          handleClose={confirmationBulkCodatExportModal.handleClose}
          handleSubmitBulkSendInvoicesToCodat={handleSubmitBulkSendInvoicesToCodat}
          setSelectedInvoices={setSelectedInvoices}
          invoices={invoices}
        />
      </React.Fragment>
    );
  }

  if (hasCodatIntegration) {
    return (
      <React.Fragment>
        <Button
          isDisabled={!selectedInvoices.length}
          text={'Queue for Quickbooks Export'}
          onPress={() => {
            confirmationBulkCodatExportModal.handleOpen();
          }}
        />
        <ConfirmationBulkCodatExportModal
          organization={organization}
          selectedInvoices={selectedInvoices}
          isOpen={confirmationBulkCodatExportModal.isOpen}
          handleClose={confirmationBulkCodatExportModal.handleClose}
          handleSubmitBulkSendInvoicesToCodat={handleSubmitBulkSendInvoicesToCodat}
          setSelectedInvoices={setSelectedInvoices}
          invoices={invoices}
        />
      </React.Fragment>
    );
  }

  return null;
};

const ListInvoicesPageFilters = ({
  organization,
  filters,
  dispatch,
  actions,
  invoices,
  selectedInvoices,
  projectTypeCategory,
  handleFinalizePress,
  handleUnfinalizePress,
  handleSubmitBulkSendInvoicesToCodat,
  setSelectedInvoices,
  params,
}: any) => {
  const {viewer} = useAppContext();
  const {isAuthorizedAccountingActions} = viewer || {};

  const handleChangeSearch = useDebouncedCallback(
    (input) => dispatch({type: actions.SET_SEARCH_QUERY, payload: input}),
    250,
    {leading: true},
  );

  const projectTypeFilterOptions = organization.projectTypes.filter(
    (projectType: any) => projectType.category === projectTypeCategory,
  );
  const multiBranchProjectTypeFilterOptions = Organization.getProjectTypesForMultibranchSlugs(
    organization,
    params.slugs,
  );

  const isMultibranchFeatureFlag =
    organization.features.isEnabledMultibranchFilterView ||
    organization.features.isEnabledMultibranchAccounting;

  const isMultiBranch = organization.isPrimaryMultibranch && isMultibranchFeatureFlag;

  const {loading, data} = useQuery(ListInvoicesPageFilters.query, {
    fetchPolicy: 'cache-and-network',
    variables: filters,
    skip: !params.pagination || !params.statuses,
  });

  return (
    <HeaderContainer>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FiltersContainers index={1}>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by customer name or invoice details'
            style={{width: '348px'}}
            defaultValue={filters.searchQuery}
          />
          <Space width={12} />
          <View>
            <ProjectsFilterButton
              organization={organization}
              projectTypeFilterOptions={
                organization.isPrimaryMultibranch && isMultibranchFeatureFlag
                  ? multiBranchProjectTypeFilterOptions
                  : projectTypeFilterOptions
              }
              isMultiBranch={isMultiBranch}
              projectTypeCategory={projectTypeCategory}
              filters={filters}
              dispatch={dispatch}
              actions={actions}
              params={params}
            />
          </View>
          <Space width={12} />
        </LeftContainer>
        <RightContainer>
          <SelectedItemsText
            color={selectedInvoices.length ? colors.blue.interactive : colors.gray.tertiary}
          >{`${_.size(selectedInvoices)} ${pluralize(
            'invoices',
            _.size(selectedInvoices),
          )} selected`}</SelectedItemsText>
          <Space width={12} />
          {isAuthorizedAccountingActions && (
            <ActionsButton
              filters={filters}
              invoices={invoices}
              selectedInvoices={selectedInvoices}
              organization={organization}
              handleFinalizePress={handleFinalizePress}
              handleUnfinalizePress={handleUnfinalizePress}
              handleSubmitBulkSendInvoicesToCodat={handleSubmitBulkSendInvoicesToCodat}
              setSelectedInvoices={setSelectedInvoices}
            />
          )}
        </RightContainer>
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        loading={loading}
        filteredInvoiceCountsByStatus={data?.filteredInvoiceCountsByStatus}
        filters={filters}
        dispatch={dispatch}
        actions={actions}
        index={2}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListInvoicesPageFilters.query = gql`
  query ListInvoicesPageFilters(
    $projectIds: [Int],
    $projectTypeIds: [Int],
    $projectTagIds: [Int],
    $projectTypeCategories: [String],
    $dateFrom: String,
    $dateTo: String,
    $dueDateFrom: String,
    $dueDateTo: String,
    $invoicePayments: [String],
    $exportStatuses: [String],
    $projectStatuses: [String],
    $slugs: [String],
    $isTraining: Boolean,
    $searchQuery: String,
  ) {
    ${gql.query}
    filteredInvoiceCountsByStatus(
      projectIds: $projectIds,
      projectTypeIds: $projectTypeIds,
      slugs: $slugs,
      projectTagIds: $projectTagIds,
      projectTypeCategories: $projectTypeCategories,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      dueDateFrom: $dueDateFrom,
      dueDateTo: $dueDateTo,
      invoicePayments: $invoicePayments,
      exportStatuses: $exportStatuses,
      projectStatuses: $projectStatuses,
      isTraining: $isTraining,
      searchQuery: $searchQuery,
    ) {
      draftCount
      scheduledCount
      unpaidCount
      overdueCount
      paidCount
      finalizedCount
      cancelledCount
      erroredCount
    }
  }
`;

ListInvoicesPageFilters.organizationFragment = gql`
  ${TagDropdownInputField.fragment}
  ${MultiBranchOrganizationField.fragment}
  ${Organization.getProjectTypesForMultibranchSlugs.fragment}

  fragment ListInvoicesPageFilters_organizationFragment on Organization {
    id
    isPrimaryMultibranch
    hasMultipleOrganizations
    projectTypes {
      id
      name
      category
    }
    activeCodatIntegration {
      id
    }
    companySettings {
      tags {
        id
        name
        emoji
        category
        isArchived
        ...TagDropdownInputField
      }
    }
    features {
      isEnabledMultibranchFilterView: isEnabled(feature: "MULTIBRANCH_FILTER_VIEW")
      isEnabledMultibranchAccounting: isEnabled(feature: "MULTIBRANCH_ACCOUNTING")
    }
    settings {
      id
      visibleCalendarStatuses
    }
    ...MultiBranchOrganizationField
    ...Organization_getProjectTypesForMultibranchSlugs
  }
`;

ListInvoicesPageFilters.invoiceFragment = gql`
  ${ConfirmationBulkCodatExportModal.fragment}

  fragment ListInvoicesPageFilters_invoiceFragment on Invoice {
    id
    ...ConfirmationBulkCodatExportModal
  }
`;

export default ListInvoicesPageFilters;
