/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectJobDispatchPreviewPopover from 'modules/Project/Update/components/ProjectJobDispatchPreviewPopover';
import ProjectJobSection from 'modules/Project/Update/components/ProjectJobSection';

const EmptySpace = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const FieldGroupContainer = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  margin-left: ${(props) => ((props as any).mobile ? 0 : 10)}px;
`;

const ButtonContainer = Styled.ButtonV2`
  flex-direction: row;
  border: 1px solid ${colors.gray.border};
  border-radius: 20px;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  justify-content: center;
  background-color: ${colors.white};
`;

const ActionsButtonText = Styled.H7`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const getHourlyRate = ({crewSize, jobFormCustomValues}: any) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'hourlyRate', ''));
};

const getTravelFee = ({crewSize, jobFormCustomValues}: any) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'travelFee', ''));
};

const getFuelFee = ({crewSize, jobFormCustomValues}: any) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  const value = String(_.get(option, 'fuelFee', ''));
  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  return value ? Currency.display(value, {shouldHideCentsIfZero: true}) : '';
};

const updateFieldsFromCrewSize = ({crewSize, jobField, form, jobFormCustomValues}: any) => {
  const hourlyRate = getHourlyRate({crewSize, jobFormCustomValues});
  const travelFee = getTravelFee({crewSize, jobFormCustomValues});
  const fuelFee = getFuelFee({crewSize, jobFormCustomValues});
  // Set the hourlyRate, travelFee, and fuelFee on the form if custom values are available.
  form.setFieldValue(`${jobField}.hourlyRate`, hourlyRate);
  form.setFieldValue(`${jobField}.travelFee`, travelFee);
  form.setFieldValue(`${jobField}.fuelFee`, fuelFee);
};

const NumberOfTrucksField = ({form, jobField}: any) => {
  const responsive = useResponsive();
  return (
    <FieldInput.Memoized
      {...form}
      name={`${jobField}.numberOfTrucks`}
      label={'Number of Trucks'}
      input={{
        placeholder: 'Enter Number',
        style: {
          width: responsive.mobile ? '100%' : 180,
        },
      }}
    />
  );
};

const NumberOfMoversField = ({form, jobField, organization}: any) => {
  const responsive = useResponsive();
  return (
    <FieldInput.Memoized
      {...form}
      name={`${jobField}.crewSize`}
      label={'Number of Movers'}
      input={{
        required: !_.get(form.values, `${jobField}.crewSize`),
        placeholder: 'Enter Number',
        keyboardType: 'phone-pad',
        style: {
          width: responsive.mobile ? '100%' : 180,
        },
      }}
    />
  );
};

const NumberOfMoversDropdownField = ({form, jobField, organization}: any) => {
  const responsive = useResponsive();
  const {isEnabledCrewSizeIsRequired, isEnabledCrewSizeIsNumberInput} = organization.features;
  const isRequired = isEnabledCrewSizeIsRequired && !_.get(form.values, `${jobField}.crewSize`);

  if (isEnabledCrewSizeIsNumberInput) {
    return (
      <FieldInput.Memoized
        {...form}
        name={`${jobField}.crewSize`}
        label={'Number of Movers'}
        input={{
          required: isRequired,
          placeholder: 'Enter number',
          keyboardType: 'phone-pad',
        }}
        style={{
          width: responsive.mobile ? '100%' : 180,
        }}
      />
    );
  }

  return (
    <FieldInput.Memoized
      {...form}
      name={`${jobField}.crewSize`}
      label={'Number of Movers'}
      component={DropdownInput}
      input={{
        placeholder: 'Enter Number',
        required: isRequired,
        isSearchable: true,
        options: form.values.jobFormCustomValues.crewSize,
        setFieldValue: form.setFieldValue,
        onChangeValue: (value: any) => {
          updateFieldsFromCrewSize({
            crewSize: value,
            jobField,
            form,
            jobFormCustomValues: form.values.jobFormCustomValues,
          });
        },
        style: {
          width: responsive.mobile ? '100%' : 180,
        },
      }}
    />
  );
};

const AdditionalNotesField = ({form, jobField}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      name={`${jobField}.additionalNotes`}
      label={'Crew Notes'}
      input={{
        placeholder: 'Enter any details for the crew',
        multiline: true,
        numberOfLines: 4,
        style: {
          paddingVertical: 9,
          minHeight: 56,
        },
      }}
      style={{
        width: '100%',
      }}
    />
  );
};
const DispatchNotesField = ({form, jobField}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      name={`${jobField}.dispatchNotes`}
      label={'Dispatch Notes'}
      input={{
        placeholder: 'Enter any dispatch details',
        multiline: true,
        numberOfLines: 4,
        style: {
          paddingVertical: 9,
          minHeight: 56,
        },
      }}
      style={{
        width: '100%',
      }}
    />
  );
};
const CommercialMoveFields = ({
  form,
  jobField,
  isMoversInputVisible,
  isTrucksInputVisible,
}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={0} {...responsive}>
        {isTrucksInputVisible && <NumberOfTrucksField form={form} jobField={jobField} />}
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        {isMoversInputVisible && isTrucksInputVisible && <FieldSpace {...responsive} />}
        {isMoversInputVisible && <NumberOfMoversField form={form} jobField={jobField} />}
      </FieldGroupContainer>
      <Space height={12} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={1} {...responsive}>
        <AdditionalNotesField form={form} jobField={jobField} />
      </FieldGroupContainer>
      <Space height={12} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={2} {...responsive}>
        <DispatchNotesField form={form} jobField={jobField} />
      </FieldGroupContainer>
    </React.Fragment>
  );
};

const EstimateMoveFields = ({form, jobField}: any) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldGroupContainer index={0} {...responsive}>
      <AdditionalNotesField form={form} jobField={jobField} />
    </FieldGroupContainer>
  );
};

const DefaultMoveFields = ({
  form,
  jobField,
  organization,
  isMoversInputVisible,
  isTrucksInputVisible,
}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={0} {...responsive}>
        {isTrucksInputVisible && <NumberOfTrucksField form={form} jobField={jobField} />}
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        {isMoversInputVisible && isTrucksInputVisible && <FieldSpace {...responsive} />}
        {isMoversInputVisible && (
          <NumberOfMoversDropdownField
            form={form}
            jobField={jobField}
            organization={organization}
          />
        )}
      </FieldGroupContainer>
      <Space height={12} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={1} {...responsive}>
        <AdditionalNotesField form={form} jobField={jobField} />
      </FieldGroupContainer>
      <Space height={12} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={2} {...responsive}>
        <DispatchNotesField form={form} jobField={jobField} />
      </FieldGroupContainer>
    </React.Fragment>
  );
};

const ProjectJobDispatchInfoBlockFields = ({
  form,
  jobField,
  organization,
  isMoversInputVisible,
  isTrucksInputVisible,
}: any) => {
  const kind = _.get(form.values, `${jobField}.kind`);
  switch (kind) {
    case Job.KIND.COMMERCIAL:
      return (
        <CommercialMoveFields
          form={form}
          jobField={jobField}
          isMoversInputVisible={isMoversInputVisible}
          isTrucksInputVisible={isTrucksInputVisible}
        />
      );
    case Job.KIND.ESTIMATE:
      return <EstimateMoveFields form={form} jobField={jobField} />;
    default:
      return (
        <DefaultMoveFields
          form={form}
          jobField={jobField}
          organization={organization}
          isMoversInputVisible={isMoversInputVisible}
          isTrucksInputVisible={isTrucksInputVisible}
        />
      );
  }
};

const ProjectJobDispatchInfoBlock = ({index, form, jobField, organization}: any) => {
  const dispatchPreviewPopover = usePopover();
  const jobForm = _.get(form.values, jobField);
  const {isMoversInputVisible, isTrucksInputVisible} = JobForm.getDispatchFieldVisibility(
    _.get(form.values, `${jobField}`),
    {
      isEnabledHideHardcodedBillingInputs:
        organization.features.isEnabledHideHardcodedBillingInputs,
    },
  );

  return (
    <ProjectJobSection
      index={index}
      title={
        <React.Fragment>
          <SectionTitle>Dispatch</SectionTitle>
          <EmptySpace />
          {isMoversInputVisible && isTrucksInputVisible && (
            <React.Fragment>
              <Popover.Content innerRef={dispatchPreviewPopover.ref}>
                <ButtonContainer onPress={dispatchPreviewPopover.handleToggle}>
                  <ActionsButtonText>Dispatch Preview</ActionsButtonText>
                </ButtonContainer>
              </Popover.Content>
              <ProjectJobDispatchPreviewPopover
                popover={dispatchPreviewPopover}
                jobForm={jobForm}
              />
              <Space width={8} />
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      <ProjectJobDispatchInfoBlockFields
        form={form}
        jobField={jobField}
        organization={organization}
        isMoversInputVisible={isMoversInputVisible}
        isTrucksInputVisible={isTrucksInputVisible}
      />
    </ProjectJobSection>
  );
};

ProjectJobDispatchInfoBlock.fragment = gql`
  fragment ProjectJobDispatchInfoBlock on Organization {
    id
    features {
      isEnabledCrewSizeIsNumberInput: isEnabled(feature: "CREW_SIZE_IS_NUMBER_INPUT")
      isEnabledCrewSizeIsRequired: isEnabled(feature: "CREW_SIZE_IS_REQUIRED")
      isEnabledHideHardcodedBillingInputs: isEnabled(feature: "HIDE_HARDCODED_BILLING_INPUTS")
    }
  }
`;

export default ProjectJobDispatchInfoBlock;
