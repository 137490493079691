// Supermove
import _ from 'lodash';

import {uuid} from '@supermove/utils';

export interface CreateAndSendUserFlowRunActionFormType {
  workflowRunStepUuid: string;
  userFlowId: string | null;
}

const _new = (): CreateAndSendUserFlowRunActionFormType => ({
  workflowRunStepUuid: uuid(),
  userFlowId: null,
});

const edit = (
  createAndSendUserFlowRunActionForm: CreateAndSendUserFlowRunActionFormType,
): CreateAndSendUserFlowRunActionFormType => ({
  workflowRunStepUuid: createAndSendUserFlowRunActionForm.workflowRunStepUuid,
  userFlowId: _.toString(createAndSendUserFlowRunActionForm.userFlowId),
});

const toForm = (
  createAndSendUserFlowRunActionForm: CreateAndSendUserFlowRunActionFormType,
): CreateAndSendUserFlowRunActionFormType => ({
  workflowRunStepUuid: createAndSendUserFlowRunActionForm.workflowRunStepUuid,
  userFlowId: createAndSendUserFlowRunActionForm.userFlowId,
});

const toMutation = (
  createAndSendUserFlowRunActionForm: CreateAndSendUserFlowRunActionFormType,
): CreateAndSendUserFlowRunActionFormType => ({
  workflowRunStepUuid: createAndSendUserFlowRunActionForm.workflowRunStepUuid,
  userFlowId: createAndSendUserFlowRunActionForm.userFlowId,
});

const CreateAndSendUserFlowRunActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default CreateAndSendUserFlowRunActionForm;
