/**
 * TODO(herrick): Replace this file with ProjectJobLocationsInfoBlock to consolidate
 * logic once create/update project have been migrated.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import ContextSwitcher from '@shared/design/components/ContextSwitcher';

import JobMap from './JobMap';
import LocationFormsSection from './LocationFormsSection';

const Section = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.secondary};
`;

const MobileMapContainer = Styled.View<{mobile: boolean}>`
  height: ${({mobile}) => (mobile ? 320 : 480)}px;
  border-radius: 4px;
  overflow: hidden;
`;

const getFieldName = ({field, name}: any) => {
  return field ? `${field}.${name}` : name;
};

const getLocationForms = ({field, form}: any) => {
  return _.get(form.values, getFieldName({field, name: 'locationForms'}));
};

const JobLocationsFields = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
  warehouseLocation,
  mapRef,
  jobMapComponent,
}: any) => {
  const locationDistancesFieldName = getFieldName({field, name: 'locationDistances'});
  const locationDistances = _.get(form.values, locationDistancesFieldName);
  const miles = _.sum(locationDistances).toFixed(2);
  const [isMapView, setIsMapView] = useState(false);

  return (
    <Section index={index}>
      <Row>
        <Title>Location Information</Title>
        <Space width={8} />
        <Subtitle>{`(Mileage: ${miles} mi)`}</Subtitle>
      </Row>
      <Space height={8} />
      {!responsive.desktop && (
        <React.Fragment>
          <Row>
            <ContextSwitcher
              isFullWidth
              contextDefinitions={[
                {
                  label: `Route`,
                  isSelected: !isMapView,
                  onPress: () => {
                    setIsMapView(false);
                  },
                },
                {
                  label: `Map`,
                  isSelected: isMapView,
                  onPress: () => {
                    setIsMapView(true);
                  },
                },
              ]}
            />
          </Row>
          <Space height={16} />
        </React.Fragment>
      )}
      {isMapView ? (
        <MobileMapContainer {...responsive}>
          {jobMapComponent}
          <Space height={16} />
        </MobileMapContainer>
      ) : (
        <LocationFormsSection
          disabled={disabled}
          field={getFieldName({field, name: 'locationForms'})}
          form={form}
          locationForms={getLocationForms({field, form})}
          responsive={responsive}
          organization={organization}
          isBuildingTypeRequired={false}
          warehouseLocation={warehouseLocation}
          mapRef={mapRef}
          isFullWidth={responsive.tablet}
        />
      )}
      <JobMap
        isHidden
        locations={getLocationForms({field, form})}
        onRouteUpdate={({distances}: any) => {
          form.setFieldValue(locationDistancesFieldName, distances);
        }}
      />
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsFields.fragment = gql`
  ${LocationFormsSection.fragment}

  fragment JobLocationsFields on Organization {
    id
    ...LocationFormsSection
  }
`;

export default JobLocationsFields;
