// Libraries
import React from 'react';

// App
import {ModalType} from '@supermove/hooks';

import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import {ItemFormV2Type} from '@shared/modules/Inventory/forms/ItemFormV2';

const RemoveInventoryItemModal = ({
  removeInventoryItemModal,
  itemForm,
  handleRemoveItem,
}: {
  removeInventoryItemModal: ModalType;
  itemForm: ItemFormV2Type;
  handleRemoveItem: () => void;
}) => {
  return (
    <DeleteModal
      handleDelete={() => {
        handleRemoveItem();
        removeInventoryItemModal.handleClose();
      }}
      handleClose={removeInventoryItemModal.handleClose}
      isOpen={removeInventoryItemModal.isOpen}
      title={`Remove "${itemForm.name}"?`}
      titleNumberOfLines={1}
      subtitle={`Removing this item will also delete its attached photos.`}
    />
  );
};

export default RemoveInventoryItemModal;
