// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App

import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import VariableSectionsCollapsedStates from '@shared/modules/Variable/enums/VariableSectionsCollapsedStates';
import BillingValueFields from 'modules/Project/Billing/components/BillingValueFields';
import ValueFields from 'modules/Project/Billing/components/ValueFields';

const CollapsibleContentContainer = Styled.View`
    flex: 1;
`;
const PaddingContainer = Styled.View`
  padding-horizontal: 16px;
  padding-bottom: 16px;
  padding-top: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 16 : 4)}px;
`;

const Divider = Styled.View`
  height: 4px;
  width: 100%;
  background-color: ${colors.gray.border};
`;

const JobBillingValuesSectionContent = ({
  variableSection,
  jobUuid,
  updateValuesObject,
  isCollapsedSections,
  setIsCollapsedSections,
  index,
  jobId,
  responsive,
  isDisabledValuesHidden,
}: any) => {
  const {loading, data} = useQuery(JobBillingValuesSectionContent.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid,
      jobTypeVariableSectionId: variableSection.id,
    },
  });

  return (
    <CollapsibleContent
      style={{
        backgroundColor: responsive.desktop ? colors.white : colors.gray.background,
        zIndex: 1000 - index,
      }}
      title={variableSection.name}
      isCollapsedOverride={VariableSectionsCollapsedStates.getIsSectionCollapsed({
        isCollapsedSections,
        sectionId: variableSection.id,
        projectOrJobId: jobId,
      })}
      handlePress={(isCollapsed) => {
        VariableSectionsCollapsedStates.toggleSectionCollapsed({
          isCollapsedSections,
          sectionId: variableSection.id,
          setIsCollapsedSections,
          isCollapsed,
          projectOrJobId: jobId,
        });
      }}
    >
      <PaddingContainer responsive={responsive}>
        <Loading loading={loading} as={ValueFields.SkeletonLoader}>
          {() => {
            return (
              <BillingValueFields
                orderedValues={data.job.orderedValues}
                updateValuesObject={updateValuesObject}
                isDisabledValuesHidden={isDisabledValuesHidden}
              />
            );
          }}
        </Loading>
      </PaddingContainer>
    </CollapsibleContent>
  );
};

const JobBillingValuesSection = ({
  variableSections,
  jobUuid,
  isCollapsedSections,
  setIsCollapsedSections,
  index,
  jobId,
  updateValuesObject,
  isDisabledValuesHidden,
}: any) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <CollapsibleContentContainer index={index}>
      {variableSections.map((variableSection: any, variableSectionIndex: any) => {
        return (
          <React.Fragment key={variableSection.id}>
            {!responsive.desktop && variableSectionIndex > 0 && <Divider />}
            <Space height={12} />
            <JobBillingValuesSectionContent
              variableSection={variableSection}
              jobUuid={jobUuid}
              updateValuesObject={updateValuesObject}
              isCollapsedSections={isCollapsedSections}
              setIsCollapsedSections={setIsCollapsedSections}
              index={variableSectionIndex}
              jobId={jobId}
              responsive={responsive}
              isDisabledValuesHidden={isDisabledValuesHidden}
            />
          </React.Fragment>
        );
      })}
    </CollapsibleContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobBillingValuesSectionContent.query = gql`
${BillingValueFields.fragment}
query JobBillingValuesSection($jobUuid: String!, $jobTypeVariableSectionId: Int!) {
  ${gql.query}
  job(uuid: $jobUuid){
    id
    orderedValues(jobTypeVariableSectionId: $jobTypeVariableSectionId){
      id
      ...BillingValueFields
    }
  }
}
`;

JobBillingValuesSection.fragment = gql`
  fragment JobBillingValuesSection on JobTypeVariableSection {
    id
    name
  }
`;

export default JobBillingValuesSection;
