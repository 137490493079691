// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useTabs,
  useState,
  useDebouncedCallback,
  useTextInput,
  useNavigationDOM,
} from '@supermove/hooks';
import {ThreadModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App

import Drawer from '@shared/design/components/Drawer';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import ThreadKind from '@shared/modules/Thread/enums/ThreadKind';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EmailThreads from 'modules/Communication/components/EmailThreads';
import SmsEditor from 'modules/Communication/components/SmsEditor';

const Row = Styled.View`
  flex-direction: row;
`;

const Button = Styled.ButtonV2`
`;

const Container = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
`;

const HeaderTextContainer = Styled.View`
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading2}
`;

const TabsWrapper = Styled.View`
`;

const getCustomerCommunicationDrawerVariables = (
  clientId: any,
  projectId: any,
  searchQuery: any,
) => {
  return {
    searchQuery,
    ...(projectId ? {projectId} : {clientId}),
  };
};

const getPhoneNumberText = ({customer}: any) => {
  if (customer.phoneNumber) {
    return Phone.display(customer.phoneNumber);
  }
  return 'No phone number';
};

const getEmailText = ({customer}: any) => {
  if (customer.email) {
    return customer.email;
  }
  return 'No email';
};

const Tab = ({tab, isSelected, handlePress}: any) => {
  return (
    <Tabs.TabContainer onPress={handlePress} isSelected={isSelected} style={{flexGrow: 1}}>
      <Tabs.TabText isSelected={isSelected}>{tab.label}</Tabs.TabText>
    </Tabs.TabContainer>
  );
};

const CustomerCommunicationDrawerHeader = ({customer, handleClose, tabs, setSearchQuery}: any) => {
  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <Space height={24} />
        <Row>
          <TitleText>{customer.fullName}</TitleText>
          <Space style={{flex: 1}} />
          <Button
            onPress={() => {
              setSearchQuery('');
              handleClose();
            }}
          >
            <Icon source={Icon.Times} size={24} color={colors.gray.primary} />
          </Button>
        </Row>
        <Space height={8} />
        <Text color={colors.gray.secondary}>
          {tabs.selectedIndex === 0 ? getPhoneNumberText({customer}) : getEmailText({customer})}
        </Text>
      </HeaderTextContainer>
      <Space height={16} />
      <TabsWrapper>
        <Tabs
          scrollEnabled={false}
          tabs={tabs}
          TabComponent={Tab}
          tabBarStyle={{width: '100%'}}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Tabs.Slide key={0} tab={{label: 'SMS', value: 0}} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Tabs.Slide key={1} tab={{label: 'EMAIL', value: 1}} />
        </Tabs>
      </TabsWrapper>
    </HeaderContainer>
  );
};

const handleSelectThread = (
  thread: ThreadModel,
  isEnabledFullPageEmail: boolean,
  navigator: SupermoveNavigatorType,
  setSelectedThread: (thread: ThreadModel) => void,
) => {
  if (isEnabledFullPageEmail) {
    navigator.push(`/projects/${thread.project.uuid}/emails/${thread.uuid}`);
  } else {
    setSelectedThread(thread);
  }
};

const CustomerCommunicationDrawer = ({
  client,
  projectId,
  handleMissingContactInfo,
  isOpen,
  handleClose,
  initialTabIndex,
  width,
}: any) => {
  const {navigator} = useNavigationDOM();
  const [selectedThread, setSelectedThread] = useState<ThreadModel | null>(null);

  const searchInput = useTextInput();
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeQuery = useDebouncedCallback((query) => {
    setSearchQuery(query);
    setSelectedThread(null);
  }, 250);

  const variables = getCustomerCommunicationDrawerVariables(client.id, projectId, searchQuery);

  const {loading, data, refetch} = useQuery(CustomerCommunicationDrawer.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      userUuid: client.primaryContact.uuid,
      kind: ThreadKind.EMAIL,
      categories: [TextMessageTemplateCategory.CUSTOMER],
      ...variables,
    },
  });

  // @ts-expect-error TS(2322): Type '(variables?: { projectId: any; searchQuery: ... Remove this comment to see the full error message
  const tabs = useTabs({initialIndex: initialTabIndex || 0, onChangeIndex: refetch});

  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; isOpen: any; onClose: a... Remove this comment to see the full error message
    <Drawer isOpen={isOpen} onClose={handleClose} width={width || 500}>
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <Container>
              <CustomerCommunicationDrawerHeader
                customer={data.user}
                tabs={tabs}
                handleClose={handleClose}
                setSearchQuery={setSearchQuery}
              />
              <Line />
              {tabs.selectedIndex === 0 ? (
                <SmsEditor
                  user={data.user}
                  viewer={data.viewer}
                  handleMissingPhoneNumber={handleMissingContactInfo}
                  refetch={refetch}
                  projectId={(variables as any).projectId}
                  textMessageTemplates={
                    data.viewer.viewingOrganization.textMessageTemplatesByCategory
                  }
                />
              ) : (
                <EmailThreads
                  emailThreads={data.filteredThreads}
                  handleMissingContactInfo={handleMissingContactInfo}
                  selectedThread={selectedThread}
                  setSelectedThread={(thread: ThreadModel) =>
                    handleSelectThread(
                      thread,
                      data.viewer.viewingOrganization.features.isEnabledFullPageEmail,
                      navigator,
                      setSelectedThread,
                    )
                  }
                  searchInput={searchInput}
                  searchQuery={searchQuery}
                  handleChangeQuery={handleChangeQuery}
                  isLoading={loading}
                  customer={data.user}
                  organization={data.viewer.viewingOrganization}
                  refetch={refetch}
                />
              )}
            </Container>
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerCommunicationDrawer.query = gql`
  ${SmsEditor.fragment}
  ${EmailThreads.fragment}

  query CustomerCommunicationDrawer(
    $userUuid: String!,
    $clientId: Int,
    $projectId: Int,
    $categories: [String],
    $kind: String,
    $searchQuery: String,
    ) {
      ${gql.query}
      viewer {
        id
        viewingOrganization {
          id
          features {
            isEnabledFullPageEmail: isEnabled(feature: "FULL_PAGE_EMAIL")
          }
          textMessageTemplatesByCategory(categories: $categories) {
            id
            ...SmsEditor_TextMessageTemplate
          }
          ...EmailThreads_Organization
        }
        ...SmsEditor_Viewer
      }
      user(uuid: $userUuid) {
        id
        email
        ...SmsEditor_User
        ...EmailThreads_User
      }
      filteredThreads(clientId: $clientId, projectId: $projectId, kind: $kind, searchQuery: $searchQuery) {
        id
        uuid
        project {
          id
          uuid
        }
        ...EmailThreads_Thread
      }
  }
`;

CustomerCommunicationDrawer.fragment = gql`
  fragment CustomerCommunicationDrawer on Client {
    id
    primaryContact {
      id
      uuid
    }
  }
`;

export default CustomerCommunicationDrawer;
