// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form, useResponsive, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import RadioButton from '@shared/design/components/RadioButton';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import RateTableMetricKind, {
  RateTableMetricKindType,
} from '@shared/modules/RateTable/enums/RateTableMetricKind';
import RateTableForm, {
  RateTableFormToFormType,
} from '@shared/modules/RateTable/forms/RateTableForm';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LabelText = Styled.Text<{color?: string}>`
  ${Typography.Responsive.Body};
  color: ${({color}) => color || colors.gray.primary};
`;

const ConfigureRateTableMetricsModal = ({
  isOpen,
  handleClose,
  form,
}: {
  isOpen: boolean;
  handleClose: () => void;
  form: Form<{rateTableForm: RateTableFormToFormType}>;
}) => {
  const responsive = useResponsive();
  const [primaryMetrics, setPrimaryMetrics] = useState<Array<RateTableMetricKindType | undefined>>([
    form.values.rateTableForm.rateTablePrimaryMetric1Form.kind,
    ...List.insertIf(
      !!form.values.rateTableForm.rateTablePrimaryMetric2Form,
      form.values.rateTableForm.rateTablePrimaryMetric2Form?.kind,
    ),
  ]);
  const [secondaryMetric, setSecondaryMetric] = useState(
    form.values.rateTableForm.rateTableSecondaryMetric1Form
      ? form.values.rateTableForm.rateTableSecondaryMetric1Form.kind
      : null,
  );
  const validationErrorToast = useToast({ToastComponent: ErrorToast});
  const handleSubmit = () => {
    if (primaryMetrics.length === 0) {
      validationErrorToast.handleToast({message: 'Please select at least one primary metric.'});
      return;
    }
    const orderedPrimaryMetrics = primaryMetrics.sort();
    RateTableForm.handleResetMetrics({
      form,
      rateTablePrimaryMetric1Kind: orderedPrimaryMetrics[0]!,
      rateTablePrimaryMetric2Kind: orderedPrimaryMetrics[1],
      rateTableSecondaryMetric1Kind: secondaryMetric || undefined,
    });
    handleClose();
  };

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Configure Metrics'}
      description={'Select metrics to define hourly rates. Changing metrics will reset the table.'}
      handlePressOutside={handleClose}
    >
      <Row>
        <LabelText responsive={responsive} color={colors.red.warning}>
          *
        </LabelText>
        <LabelText responsive={responsive}>Primary</LabelText>
        <LabelText
          color={colors.gray.tertiary}
          style={{fontStyle: 'italic'}}
          responsive={responsive}
        >{` — select at least one`}</LabelText>
      </Row>
      <Space height={8} />
      {RateTableMetricKind.PRIMARY_METRICS.map((rateTableMetricKind, index) => {
        return (
          <React.Fragment key={index}>
            <Checkbox
              isResponsive
              label={RateTableMetricKind.getName(rateTableMetricKind)}
              isChecked={_.includes(primaryMetrics, rateTableMetricKind)}
              handleToggle={() => {
                setPrimaryMetrics(_.xor(primaryMetrics, [rateTableMetricKind]));
              }}
            />
            <Space height={8} />
          </React.Fragment>
        );
      })}
      <Space height={8} />
      <LabelText responsive={responsive}>Secondary</LabelText>
      <Space height={8} />
      <RadioButton
        label={'None'}
        isOn={secondaryMetric === null}
        onPress={() => setSecondaryMetric(null)}
      />
      <Space height={8} />
      {RateTableMetricKind.SECONDARY_METRICS.map((rateTableMetricKind, index) => {
        return (
          <React.Fragment key={index}>
            <RadioButton
              label={RateTableMetricKind.getName(rateTableMetricKind)}
              isOn={secondaryMetric === rateTableMetricKind}
              onPress={() => setSecondaryMetric(rateTableMetricKind)}
            />
            <Space height={8} />
          </React.Fragment>
        );
      })}
      <SmallModal.Footer>
        <SmallModal.Button isResponsive onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={16} />
        <SmallModal.Button isResponsive color={colors.blue.interactive} onPress={handleSubmit}>
          Submit
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default ConfigureRateTableMetricsModal;
