// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {RateTableModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import RateTableMetricKind, {
  RateTableMetricKindType,
} from '@shared/modules/RateTable/enums/RateTableMetricKind';
import RateTableAdditionalValueForm, {
  RateTableAdditionalValueFormType,
} from '@shared/modules/RateTable/forms/RateTableAdditionalValueForm';
import RateTableMetricForm, {
  RateTableMetricFormType,
} from '@shared/modules/RateTable/forms/RateTableMetricForm';
import RateTableMetricValuesForm, {
  RateTableMetricValuesFormType,
} from '@shared/modules/RateTable/forms/RateTableMetricValuesForm';
import RateTableValueForm, {
  RateTableValueFormType,
} from '@shared/modules/RateTable/forms/RateTableValueForm';

const _new = ({organizationId}: {organizationId: string}) => {
  const rateTablePrimaryMetric1Form = RateTableMetricForm.new({
    kind: RateTableMetricKind.NUMBER_OF_MOVERS,
    isPrimary: true,
  });
  return {
    rateTableId: undefined,
    organizationId,
    name: '',
    description: '',
    rateTablePrimaryMetric1Form,
    rateTablePrimaryMetric2Form: undefined,
    rateTableSecondaryMetric1Form: undefined,
    // Generate 6 forms for Number of Movers
    rateTableMetricValuesForms: _.range(1, 7).map((metricValue) =>
      RateTableMetricValuesForm.new({
        rateTablePrimaryMetric1Value: metricValue,
        rateTableValueForms: [RateTableValueForm.new({primaryMetric1Value: metricValue})],
      }),
    ),
    rateTableAdditionalValueForms: [
      RateTableAdditionalValueForm.new({rateTablePrimaryMetricForm: rateTablePrimaryMetric1Form}),
    ],
    deletedRateTableValueForms: [],
    deletedRateTableAdditionalValueForms: [],
  };
};

const makeRateTableMetricValuesForms = ({
  rateTableValueForms,
}: {
  rateTableValueForms: RateTableValueFormType[];
}) => {
  const groupedByPrimaryMetric1 = _.groupBy(rateTableValueForms, 'primaryMetric1Value');
  return Object.entries(groupedByPrimaryMetric1).flatMap(
    ([primaryMetric1Value, valueFormsByPrimary]) => {
      const groupedByPrimaryMetric2 = _.groupBy(valueFormsByPrimary, 'primaryMetric2Value');
      return Object.entries(groupedByPrimaryMetric2).map(
        ([primaryMetric2Value, rateTableValueForms]) =>
          RateTableMetricValuesForm.new({
            rateTablePrimaryMetric1Value: Number(primaryMetric1Value),
            rateTablePrimaryMetric2Value: primaryMetric2Value
              ? Number(primaryMetric2Value)
              : undefined,
            rateTableValueForms,
          }),
      );
    },
  );
};

const edit = withFragment(
  (rateTable: RateTableModel): RateTableFormType => {
    const rateTablePrimaryMetric1Form = RateTableMetricForm.edit(rateTable.rateTablePrimaryMetric1);
    const rateTablePrimaryMetric2Form = rateTable.rateTablePrimaryMetric2
      ? RateTableMetricForm.edit(rateTable.rateTablePrimaryMetric2)
      : undefined;
    const rateTableSecondaryMetric1Form = rateTable.rateTableSecondaryMetric1
      ? RateTableMetricForm.edit(rateTable.rateTableSecondaryMetric1)
      : undefined;
    const rateTableValueForms = rateTable.rateTableValues.map((rateTableValue) =>
      RateTableValueForm.edit(rateTableValue),
    );
    return {
      rateTableId: rateTable.id,
      organizationId: rateTable.organizationId,
      name: rateTable.name,
      description: rateTable.description,
      rateTablePrimaryMetric1Form,
      rateTablePrimaryMetric2Form,
      rateTableSecondaryMetric1Form,
      rateTableMetricValuesForms: makeRateTableMetricValuesForms({rateTableValueForms}),
      rateTableAdditionalValueForms: rateTable.rateTableAdditionalValues.map(
        (rateTableAdditionalValue) => RateTableAdditionalValueForm.edit(rateTableAdditionalValue),
      ),
      deletedRateTableValueForms: [],
      deletedRateTableAdditionalValueForms: [],
    };
  },
  gql`
    ${RateTableAdditionalValueForm.edit.fragment}
    ${RateTableMetricForm.edit.fragment}
    ${RateTableValueForm.edit.fragment}
    fragment RateTableForm_edit on RateTable {
      id
      organizationId
      name
      description
      rateTablePrimaryMetric1 {
        id
        ...RateTableMetricForm_edit
      }
      rateTablePrimaryMetric2 {
        id
        ...RateTableMetricForm_edit
      }
      rateTableSecondaryMetric1 {
        id
        ...RateTableMetricForm_edit
      }
      rateTableValues {
        id
        ...RateTableValueForm_edit
      }
      rateTableAdditionalValues {
        id
        ...RateTableAdditionalValueForm_edit
      }
    }
  `,
);

const duplicate = withFragment(
  (rateTable: RateTableModel): RateTableFormType => {
    const rateTablePrimaryMetric1Form = RateTableMetricForm.duplicate(
      rateTable.rateTablePrimaryMetric1,
    );
    const rateTablePrimaryMetric2Form = rateTable.rateTablePrimaryMetric2
      ? RateTableMetricForm.duplicate(rateTable.rateTablePrimaryMetric2)
      : undefined;
    const rateTableSecondaryMetric1Form = rateTable.rateTableSecondaryMetric1
      ? RateTableMetricForm.duplicate(rateTable.rateTableSecondaryMetric1)
      : undefined;
    const rateTableValueForms = rateTable.rateTableValues.map((rateTableValue) =>
      RateTableValueForm.duplicate(rateTableValue),
    );
    return {
      ...edit(rateTable),
      rateTableId: undefined,
      name: `Copy of ${rateTable.name}`,
      rateTablePrimaryMetric1Form,
      rateTablePrimaryMetric2Form,
      rateTableSecondaryMetric1Form,
      rateTableMetricValuesForms: makeRateTableMetricValuesForms({rateTableValueForms}),
      rateTableAdditionalValueForms: rateTable.rateTableAdditionalValues.map(
        (rateTableAdditionalValue) =>
          RateTableAdditionalValueForm.duplicate(rateTableAdditionalValue),
      ),
    };
  },
  gql`
    ${edit.fragment}
    ${RateTableAdditionalValueForm.duplicate.fragment}
    ${RateTableMetricForm.duplicate.fragment}
    ${RateTableValueForm.duplicate.fragment}
    fragment RateTableForm_duplicate on RateTable {
      id
      name
      rateTablePrimaryMetric1 {
        id
        ...RateTableMetricForm_duplicate
      }
      rateTablePrimaryMetric2 {
        id
        ...RateTableMetricForm_duplicate
      }
      rateTableSecondaryMetric1 {
        id
        ...RateTableMetricForm_duplicate
      }
      rateTableValues {
        id
        ...RateTableValueForm_duplicate
      }
      rateTableAdditionalValues {
        id
        ...RateTableAdditionalValueForm_duplicate
      }
      ...RateTableForm_edit
    }
  `,
);

export interface RateTableFormType {
  rateTableId: string | undefined;
  organizationId: string;
  name: string;
  description: string;
  rateTablePrimaryMetric1Form: RateTableMetricFormType;
  rateTablePrimaryMetric2Form?: RateTableMetricFormType;
  rateTableSecondaryMetric1Form?: RateTableMetricFormType;
  rateTableMetricValuesForms: RateTableMetricValuesFormType[];
  rateTableAdditionalValueForms: RateTableAdditionalValueFormType[];
  deletedRateTableValueForms: RateTableValueFormType[];
  deletedRateTableAdditionalValueForms: RateTableAdditionalValueFormType[];
}

const toForm = ({
  rateTableId,
  organizationId,
  name,
  description,
  rateTablePrimaryMetric1Form,
  rateTablePrimaryMetric2Form,
  rateTableSecondaryMetric1Form,
  rateTableMetricValuesForms,
  rateTableAdditionalValueForms,
  deletedRateTableValueForms,
  deletedRateTableAdditionalValueForms,
}: RateTableFormType) => ({
  rateTableId,
  organizationId,
  name,
  description,
  rateTablePrimaryMetric1Form: RateTableMetricForm.toForm(rateTablePrimaryMetric1Form),
  rateTablePrimaryMetric2Form: rateTablePrimaryMetric2Form
    ? RateTableMetricForm.toForm(rateTablePrimaryMetric2Form)
    : undefined,
  rateTableSecondaryMetric1Form: rateTableSecondaryMetric1Form
    ? RateTableMetricForm.toForm(rateTableSecondaryMetric1Form)
    : undefined,
  rateTableMetricValuesForms: rateTableMetricValuesForms.map((rateTableMetricValuesForm) =>
    RateTableMetricValuesForm.toForm(rateTableMetricValuesForm),
  ),
  rateTableAdditionalValueForms: rateTableAdditionalValueForms.map((rateTableAdditionalValueForm) =>
    RateTableAdditionalValueForm.toForm(rateTableAdditionalValueForm),
  ),
  deletedRateTableValueForms: deletedRateTableValueForms.map((rateTableValueForm) =>
    RateTableValueForm.toForm(rateTableValueForm),
  ),
  deletedRateTableAdditionalValueForms: deletedRateTableAdditionalValueForms.map(
    (rateTableAdditionalValueForm) =>
      RateTableAdditionalValueForm.toForm(rateTableAdditionalValueForm),
  ),
});

export type RateTableFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({
  rateTableId,
  organizationId,
  name,
  description,
  rateTablePrimaryMetric1Form,
  rateTablePrimaryMetric2Form,
  rateTableSecondaryMetric1Form,
  rateTableMetricValuesForms,
  rateTableAdditionalValueForms,
  deletedRateTableValueForms,
  deletedRateTableAdditionalValueForms,
}: RateTableFormToFormType) => ({
  rateTableId,
  organizationId,
  name,
  description,
  rateTablePrimaryMetric1Form: RateTableMetricForm.toMutation(rateTablePrimaryMetric1Form),
  rateTablePrimaryMetric2Form: rateTablePrimaryMetric2Form
    ? RateTableMetricForm.toMutation(rateTablePrimaryMetric2Form)
    : undefined,
  rateTableSecondaryMetric1Form: rateTableSecondaryMetric1Form
    ? RateTableMetricForm.toMutation(rateTableSecondaryMetric1Form)
    : undefined,
  rateTableMetricValuesForms: rateTableMetricValuesForms.map((rateTableMetricValuesForm) =>
    RateTableMetricValuesForm.toMutation(rateTableMetricValuesForm),
  ),
  rateTableAdditionalValueForms: rateTableAdditionalValueForms.map((rateTableAdditionalValueForm) =>
    RateTableAdditionalValueForm.toMutation(rateTableAdditionalValueForm),
  ),
  deletedRateTableValueForms: deletedRateTableValueForms.map((rateTableValueForm) =>
    RateTableValueForm.toMutation(rateTableValueForm),
  ),
  deletedRateTableAdditionalValueForms: deletedRateTableAdditionalValueForms.map(
    (rateTableAdditionalValueForm) =>
      RateTableAdditionalValueForm.toMutation(rateTableAdditionalValueForm),
  ),
});

// Returns a RateTableForm in toForm format with new metrics.
// When the metrics change, all of the underlying values are reset.
const handleResetMetrics = ({
  form,
  rateTablePrimaryMetric1Kind,
  rateTablePrimaryMetric2Kind,
  rateTableSecondaryMetric1Kind,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  rateTablePrimaryMetric1Kind: RateTableMetricKindType;
  rateTablePrimaryMetric2Kind?: RateTableMetricKindType;
  rateTableSecondaryMetric1Kind?: RateTableMetricKindType;
}) => {
  // Creates new RateTableMetricForms
  const rateTablePrimaryMetric1Form = RateTableMetricForm.new({
    kind: rateTablePrimaryMetric1Kind,
    isPrimary: true,
  });
  const hasPrimaryMetric2 = !!rateTablePrimaryMetric2Kind;
  const rateTablePrimaryMetric2Form = hasPrimaryMetric2
    ? RateTableMetricForm.new({kind: rateTablePrimaryMetric2Kind, isPrimary: true})
    : undefined;
  const hasSecondaryMetric1 = !!rateTableSecondaryMetric1Kind;
  const rateTableSecondaryMetric1Form = hasSecondaryMetric1
    ? RateTableMetricForm.new({kind: rateTableSecondaryMetric1Kind, isPrimary: false})
    : undefined;
  const secondaryMetricColumns = hasSecondaryMetric1
    ? RateTableMetricKind.getSecondaryColumns(rateTableSecondaryMetric1Kind)
    : [];

  const deletedRateTableValueForms = [
    ...form.values.rateTableForm.deletedRateTableValueForms,
    ...form.values.rateTableForm.rateTableMetricValuesForms.flatMap(
      (rateTableMetricValuesForm) => rateTableMetricValuesForm.rateTableValueForms,
    ),
  ];
  const deletedRateTableAdditionalValueForms = [
    ...form.values.rateTableForm.deletedRateTableAdditionalValueForms,
    ...form.values.rateTableForm.rateTableAdditionalValueForms,
  ];

  // Creates new RateTableMetricValuesForms
  // Handles possibly two primary metrics and an optional secondary metric
  const rateTableMetricValuesForms = _.range(1, 7).map((metricValue) => {
    const baseRateTableValue = {
      primaryMetric1Value: metricValue,
      primaryMetric2Value: hasPrimaryMetric2 ? 1 : undefined,
    };
    return RateTableMetricValuesForm.new({
      rateTablePrimaryMetric1Value: metricValue,
      rateTablePrimaryMetric2Value: hasPrimaryMetric2 ? 1 : undefined,
      rateTableValueForms: hasSecondaryMetric1
        ? secondaryMetricColumns.map(({name, value}) =>
            RateTableValueForm.new({
              ...baseRateTableValue,
              secondaryMetric1Value: value,
            }),
          )
        : [RateTableValueForm.new(baseRateTableValue)],
    });
  });

  // Create new RateTableAdditionalValueForms
  // There is one per primary metric by N secondary metric columns
  const rateTableAdditionalValueFormsPrimaryMetric1 = hasSecondaryMetric1
    ? secondaryMetricColumns.map(({name, value}) =>
        RateTableAdditionalValueForm.new({
          rateTablePrimaryMetricForm: rateTablePrimaryMetric1Form,
          rateTableSecondaryMetricForm: rateTableSecondaryMetric1Form,
          secondaryMetricValue: value,
        }),
      )
    : [RateTableAdditionalValueForm.new({rateTablePrimaryMetricForm: rateTablePrimaryMetric1Form})];
  const rateTableAdditionalValueFormsPrimaryMetric2 = hasPrimaryMetric2
    ? hasSecondaryMetric1
      ? secondaryMetricColumns.map(({name, value}) =>
          RateTableAdditionalValueForm.new({
            rateTablePrimaryMetricForm: rateTablePrimaryMetric2Form!,
            rateTableSecondaryMetricForm: rateTableSecondaryMetric1Form,
            secondaryMetricValue: value,
          }),
        )
      : [
          RateTableAdditionalValueForm.new({
            rateTablePrimaryMetricForm: rateTablePrimaryMetric2Form!,
          }),
        ]
    : undefined;
  const rateTableAdditionalValueForms = [
    ...rateTableAdditionalValueFormsPrimaryMetric1,
    ...(rateTableAdditionalValueFormsPrimaryMetric2 ?? []),
  ];

  form.setFieldValue('rateTableForm', {
    ...RateTableForm.toForm({
      ...form.values.rateTableForm,
      rateTablePrimaryMetric1Form,
      rateTablePrimaryMetric2Form,
      rateTableSecondaryMetric1Form,
      rateTableMetricValuesForms,
      rateTableAdditionalValueForms,
      deletedRateTableValueForms: [],
      deletedRateTableAdditionalValueForms: [],
    }),
    // These are already in toForm format
    deletedRateTableValueForms,
    deletedRateTableAdditionalValueForms,
  });
};

const getRateTableErrors = ({
  errors,
}: {
  errors: {
    field: string;
    message: string;
  }[];
}): string[] => {
  // Filter down to errors for the rate table and additional rate table rows
  const relevantErrors = errors.filter(
    (error) =>
      error.field.startsWith('rate_table_form.rate_table_metric_values_forms') ||
      error.field.startsWith('rate_table_form.rate_table_additional_value_forms'),
  );
  return Array.from(new Set(relevantErrors.map((error) => error.message)));
};

const RateTableForm = {
  new: _new,
  edit,
  duplicate,
  toForm,
  toMutation,

  handleResetMetrics,
  getRateTableErrors,
};

export default RateTableForm;
