// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form, ResponsiveType, useResponsive} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import {RateTableFormToFormType} from '@shared/modules/RateTable/forms/RateTableForm';

const DetailsPanelContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: ${({responsive}) => (responsive.desktop ? 'row' : 'column')};
`;

const RateTableDetailsPanelBody = ({
  form,
  field,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  field: string;
}) => {
  const responsive = useResponsive();
  return (
    <DetailsPanelContainer responsive={responsive}>
      <FieldInput
        {...form}
        label={'Name'}
        name={`${field}.name`}
        input={{placeholder: 'Enter name'}}
        isRequired
        isResponsive
        style={{flex: 1}}
      />
      <Space height={16} width={16} />
      <FieldInput
        {...form}
        label={'Description'}
        name={`${field}.description`}
        input={{placeholder: 'Enter description'}}
        isResponsive
        style={{flex: 1}}
      />
    </DetailsPanelContainer>
  );
};

const RateTableDetailsPanel = ({
  form,
  index,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  index: number;
}) => {
  return (
    <ActionPanel
      title={'Name & Description'}
      index={index}
      BodyComponent={RateTableDetailsPanelBody}
      bodyComponentProps={{form, field: 'rateTableForm'}}
      headerSubtitle={'These hourly rates can be used in formulas.'}
    />
  );
};

export default RateTableDetailsPanel;
