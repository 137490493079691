import React from 'react';

/**
 * Dynamic import is used to work around issues with @tiptap components that break the production Gatsby build. This
 * isn't an issue on the new standalone Webpack toolchain, so this can be a standard import in the future.
 *
 * By default, Webpack outputs separate JS files for dynamically imported dependencies. This means that the code for the
 * text editor isn't fetched until the page renders, causing flickering on the page. The magic `webpackMode` comment
 * fixes this by preventing Webpack from outputting separate files.
 *
 * https://webpack.js.org/api/module-methods/#webpackmode
 */
const RichTextEditor = React.lazy(
  () => import(/* webpackMode: "eager" */ './components/RichTextEditor'),
);

const RichTextInput = (props: React.ComponentProps<typeof RichTextEditor>) => {
  return (
    <React.Suspense fallback={<></>}>
      <RichTextEditor {...props} />
    </React.Suspense>
  );
};

export default RichTextInput;
