// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, SignatureInput, Space, Styled} from '@supermove/components';
import {useRef} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MOCK_SIGNATURE_IMAGE from '@shared/modules/Document/enums/mockSignatureImage';

const Row = Styled.View`
  flex-direction: row;
`;

const SignatureImage = Styled.Image`
`;

const SignatureInputContainer = Styled.View`
  flex: 1;
  height: 200px;
  border-width: 2px;
  border-radius: 1px;
  border-style: dashed;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'hasError' does not exist on type 'ThemeP... Remove this comment to see the full error message
    hasError,
  }) => (hasError ? colors.red.warning : colors.blue.accent)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isRequired' does not exist on type 'Them... Remove this comment to see the full error message
    isRequired,
  }) => (isRequired ? colors.alpha(colors.yellow.hover, 0.1) : colors.white)}
`;

const SignatureClearButton = Styled.ButtonV2`
  position: absolute;
  padding: 8px;
  top: 8;
  right: 12;
`;

const DateContainer = Styled.View`
  width: 100px;
  justify-content: flex-end;
`;

const DateText = Styled.Text`
  ${Typography.Body2}
`;

const Divider = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
  margin-vertical: 4px;
`;

const ViewingLabel = Styled.Text`
  ${Typography.Body3}
`;

const EditingLabel = Styled.Text`
  ${Typography.Body2};
  color: ${colors.gray.secondary};
`;

const DocumentV2GenericDrawImageContent = ({input, isPreview}: any) => {
  return (
    <React.Fragment>
      <Row data-avoid-page-break>
        <SignatureImage
          data-avoid-page-break
          source={{uri: _.get(input, 'value.image')}}
          resizeMode={'contain'}
          style={{flex: 1, height: 200}}
        />
        <Space width={32} />
        <DateContainer>
          <DateText>{input.value.date}</DateText>
        </DateContainer>
      </Row>

      <Divider />
      <Row>
        <ViewingLabel>{isPreview ? '{NAME}' : input.value.name}</ViewingLabel>
        <Space style={{flex: 1}} />
        <DateContainer>
          <ViewingLabel>Date</ViewingLabel>
        </DateContainer>
      </Row>
    </React.Fragment>
  );
};

const DocumentV2GenericSignatureInputPreview = () => {
  return <DocumentV2GenericDrawImageContent input={MOCK_INPUT_SIGNATURE_INPUT} isPreview />;
};

const SignatureSection = ({form, field}: any) => {
  const signatureRef = useRef();
  const signatureField = _.get(form.values, field);
  const hasInitialSignature = useRef(!!signatureField.input.value.image);
  const date = Datetime.toDisplayDate(Datetime.today, 'M/D/YYYY');
  const errorMessage = _.get(form.errors, `${field}.input.value.image`);
  const isRequired = _.get(form.values, `${field}.isRequired`);
  const hasError = !!errorMessage;

  return (
    <React.Fragment>
      <Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <SignatureInputContainer hasError={hasError} isRequired={isRequired}>
          {hasInitialSignature.current ? (
            <DocumentV2GenericDrawImageContent input={signatureField.input} />
          ) : (
            <SignatureInput
              // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
              ref={signatureRef}
              color={colors.black}
              onSave={(image) => {
                form.setFieldValue(`${field}.input.value.image`, image);
                form.setFieldValue(`${field}.input.value.date`, date);
              }}
              isEnabledAutoSave
            />
          )}
          <SignatureClearButton
            onPress={() => {
              if (hasInitialSignature.current) {
                hasInitialSignature.current = false;
              } else {
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                signatureRef.current.clear();
              }
              form.setFieldValue(`${field}.input.value.image`, '');
              form.setFieldValue(`${field}.input.value.date`, '');
            }}
          >
            <Icon source={Icon.Times} size={24} color={colors.gray.secondary} />
          </SignatureClearButton>
        </SignatureInputContainer>
        <Space width={32} />
        <DateContainer>
          <DateText>{date}</DateText>
        </DateContainer>
      </Row>
      <Divider />
      <Row>
        <FieldInput.Memoized
          {...form}
          name={`${field}.input.value.name`}
          size={FieldInput.SIZE.MEDIUM}
          style={{flex: 1}}
          input={{
            required: isRequired,
            placeholder: 'Enter name',
          }}
        />
        <Space style={{flex: 1}} />
        <DateContainer>
          <EditingLabel>Date</EditingLabel>
        </DateContainer>
      </Row>
      {hasError && (
        <React.Fragment>
          <Space height={4} />
          <FieldInput.CaptionText size={FieldInput.SIZE.MEDIUM} hasErrors>
            {errorMessage}
          </FieldInput.CaptionText>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DocumentV2GenericSignatureInputActualImage = ({input, form, field, isEditable}: any) => {
  if (isEditable) {
    return <SignatureSection form={form} field={field} />;
  }
  return <DocumentV2GenericDrawImageContent input={input} />;
};

type OwnProjectDocumentV2GenericSignatureInputProps = {
  input?: any;
  isPreview: boolean;
};

// @ts-expect-error TS(2456): Type alias 'ProjectDocumentV2GenericSignatureInput... Remove this comment to see the full error message
type ProjectDocumentV2GenericSignatureInputProps = OwnProjectDocumentV2GenericSignatureInputProps &
  typeof ProjectDocumentV2GenericSignatureInput.defaultProps;

// @ts-expect-error TS(7022): 'ProjectDocumentV2GenericSignatureInput' implicitl... Remove this comment to see the full error message
const ProjectDocumentV2GenericSignatureInput = ({
  input,
  form,
  field,
  isPreview,
  isEditable,
}: ProjectDocumentV2GenericSignatureInputProps) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2GenericSignatureInputPreview />
      ) : (
        <DocumentV2GenericSignatureInputActualImage
          input={input}
          isEditable={isEditable}
          form={form}
          field={field}
        />
      )}
    </React.Fragment>
  );
};

ProjectDocumentV2GenericSignatureInput.defaultProps = {
  input: null,
};

// Construct mock signature for when isPreview is true, since a signature will not exist
const MOCK_INPUT_SIGNATURE_INPUT = {
  value: {
    image: MOCK_SIGNATURE_IMAGE,
    date: Datetime.toDisplayDate(Datetime.today, 'M/D/YYYY'),
  },
};

export default ProjectDocumentV2GenericSignatureInput;
