import {AttachmentKindModel} from '@supermove/models';

export interface AttachmentFormType {
  attachmentId?: string;
  filename: string;
  mimetype: string;
  file: File;
  organizationId: string;
  projectId?: string;
  attachmentCategoryKinds: AttachmentKindModel[];
  identifier: string;
  description?: string;

  // Private
  hasUploadError?: boolean;
}

const _new = ({
  organizationId,
  projectId,
  file,
}: Pick<AttachmentFormType, 'organizationId' | 'projectId' | 'file'>) => ({
  attachmentId: null,
  filename: null,
  mimetype: null,
  file,
  organizationId,
  projectId,
  attachmentCategoryKinds: [],
  identifier: '',
  description: '',
});

const toForm = ({
  attachmentId,
  filename,
  mimetype,
  file,
  organizationId,
  projectId,
  attachmentCategoryKinds,
  identifier,
  description,
}: AttachmentFormType) => ({
  attachmentId,
  filename,
  mimetype,
  file,
  organizationId,
  projectId,
  attachmentCategoryKinds,
  identifier,
  description,
});

const toMutation = ({
  attachmentId,
  filename,
  mimetype,
  file,
  organizationId,
  projectId,
  attachmentCategoryKinds,
  identifier,
  description,
}: AttachmentFormType) => ({
  attachmentId,
  filename,
  mimetype,
  file,
  organizationId,
  projectId,
  attachmentCategoryKinds,
  identifier,
  description,
});

const AttachmentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default AttachmentForm;
