// Libraries
import React from 'react';

// App
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useQuery, useResponsive} from '@supermove/hooks';

// Components
import Callout from '@shared/design/components/Callout';
import StaffMoverPositionsSection from 'modules/Organization/Settings/Users/List/components/StaffMoverPositionsSection';

const PaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 24)}px;
`;

const OrganizationSettingsStaffPositionsContent = () => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffPositionsContent.query, {
    fetchPolicy: 'network-only',
  });

  return (
    <Loading loading={loading}>
      {() => {
        const isNotPrimaryOrg =
          data.viewer.viewingOrganization.features.isEnabledMoverPositionMultibranch &&
          !data.viewer.viewingOrganization.isPrimary;

        return isNotPrimaryOrg ? (
          <PaddingContainer responsive={responsive}>
            <Callout
              text={`Positions are set by the company. Please contact an admin at ${data.viewer.viewingOrganization.company.primaryOrganization.name} to make adjustments.`}
              width={responsive.desktop ? 780 : null}
            />
          </PaddingContainer>
        ) : (
          <ScrollView horizontal style={{flex: 1}}>
            <PaddingContainer responsive={responsive}>
              <StaffMoverPositionsSection
                organization={data.viewer.viewingOrganization}
                refetch={refetch}
              />
            </PaddingContainer>
          </ScrollView>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffPositionsContent.query = gql`
  ${StaffMoverPositionsSection.fragment}

  query OrganizationSettingsStaffPositionsContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        features {
          isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
        }
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        ...StaffMoverPositionsSection
      }
    }
  }
`;

export default OrganizationSettingsStaffPositionsContent;
