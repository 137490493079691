// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {ResponsiveType, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import HourlyRateTablePageHeader from 'modules/Organization/Settings/Billing/components/HourlyRateTablePageHeader';

const ContentContainer = Styled.View<{responsive: ResponsiveType}>`
  max-width: 1200px;
  width: 100%;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 0)}px;
`;

const HourlyRateTablePage = ({
  title,
  isDirty,
  isSubmitting,
  handleSubmit,
  children,
}: {
  title: string;
  isDirty: boolean;
  isSubmitting: boolean;
  handleSubmit: () => void;
  children: React.ReactNode;
}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const quitEditCautionModal = useModal({name: 'Quit Edit Warning Modal'});
  const handleExit = () => {
    if (isDirty) {
      quitEditCautionModal.handleOpen();
    } else {
      navigator.goBack();
    }
  };
  return (
    <React.Fragment>
      <HourlyRateTablePageHeader
        title={title}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handleExit={handleExit}
      />
      <ScrollView style={{flex: 1}} contentContainerStyle={{alignItems: 'center'}}>
        <ContentContainer responsive={responsive}>{children}</ContentContainer>
        <Space height={64} />
      </ScrollView>
      <CautionModal
        key={quitEditCautionModal.key}
        isOpen={quitEditCautionModal.isOpen}
        title={`Quit 'Create Hourly Rates'?`}
        message={'All changes will be lost. This cannot be undone.'}
        handlePrimaryAction={() => navigator.goBack()}
        handleSecondaryAction={quitEditCautionModal.handleClose}
        primaryActionText={'Confirm'}
        secondaryActionText={'Cancel'}
      />
    </React.Fragment>
  );
};

export default HourlyRateTablePage;
