// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {Form, useModal, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {RateTableFormToFormType} from '@shared/modules/RateTable/forms/RateTableForm';
import ConfigureRateTableMetricsModal from 'modules/Organization/Settings/Billing/components/ConfigureRateTableMetricsModal';

const SelectedText = Styled.Text<{isSelected: boolean}>`
  ${Typography.Responsive.Label};
  color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
`;

const Line = Styled.View`
  width: 1px;
  height: 26px;
  background-color: ${colors.gray.border};
`;

const handleDeleteRows = ({
  form,
  selectedRowIndexes,
  setSelectedRowIndexes,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  selectedRowIndexes: number[];
  setSelectedRowIndexes: (selectedRowIndexes: number[]) => void;
}) => {
  const deletedRateTableMetricValuesForms =
    form.values.rateTableForm.rateTableMetricValuesForms.filter(
      (rateTableMetricValuesForm, index) => _.includes(selectedRowIndexes, index),
    );
  const deletedRateTableValueForms = _.flatten(
    deletedRateTableMetricValuesForms.map(
      (rateTableMetricValuesForm) => rateTableMetricValuesForm.rateTableValueForms,
    ),
  );
  form.setFieldValue('rateTableForm.deletedRateTableValueForms', deletedRateTableValueForms);
  const filteredRateTableMetricValuesForms =
    form.values.rateTableForm.rateTableMetricValuesForms.filter(
      (rateTableMetricValuesForm, index) => !_.includes(selectedRowIndexes, index),
    );
  form.setFieldValue(
    'rateTableForm.rateTableMetricValuesForms',
    filteredRateTableMetricValuesForms,
  );
  setSelectedRowIndexes([]);
};

const RateTablePanelActions = ({
  form,
  selectedRowIndexes,
  setSelectedRowIndexes,
}: {
  form: Form<{rateTableForm: RateTableFormToFormType}>;
  selectedRowIndexes: number[];
  setSelectedRowIndexes: (selectedRowIndexes: number[]) => void;
}) => {
  const responsive = useResponsive();
  const rateTableConfigureMetricsModal = useModal({name: 'Rate Table Configure Metrics Modal'});
  return (
    <React.Fragment>
      <SelectedText
        isSelected={!!selectedRowIndexes.length}
        responsive={responsive}
      >{`${selectedRowIndexes.length} selected`}</SelectedText>
      <Space width={16} />
      {!!selectedRowIndexes.length && (
        <React.Fragment>
          <SecondaryButton
            isSmall
            text={'Remove Selected'}
            onPress={() => handleDeleteRows({form, selectedRowIndexes, setSelectedRowIndexes})}
            iconLeft={Icon.Trash}
            textColor={colors.red.warning}
          />
          <Space width={16} />
        </React.Fragment>
      )}
      <Line />
      <Space width={16} />
      <TertiaryButton
        text={'Configure Metrics'}
        iconLeft={Icon.Gear}
        onPress={rateTableConfigureMetricsModal.handleOpen}
      />
      <ConfigureRateTableMetricsModal
        key={rateTableConfigureMetricsModal.key}
        isOpen={rateTableConfigureMetricsModal.isOpen}
        handleClose={rateTableConfigureMetricsModal.handleClose}
        form={form}
      />
    </React.Fragment>
  );
};

export default RateTablePanelActions;
