// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}: any) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const ShipperAddressSettingsPanel = ({codatIntegration}: any) => {
  const {
    shipperAddressVariableId,
    organization: {variables},
  } = codatIntegration;

  const selectedVariable = variables.find(
    (variable: any) => Number(variable.id) === Number(shipperAddressVariableId),
  );
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Shipper Address Variable'}
            value={selectedVariable?.name}
            placeholder='Select variable'
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

ShipperAddressSettingsPanel.Edit = ({codatIntegration, form}: any) => {
  const {
    organization: {variables},
  } = codatIntegration;
  const options = variables
    .filter((variable: any) => {
      return VariableFormat.getStringVariableFormats().includes(variable.format);
    })
    .map((variable: any) => ({
      label: variable.name,
      value: variable.id,
    }));

  return (
    <React.Fragment>
      <Row style={{alignItems: 'center'}}>
        <Column>
          <FieldInput
            {...form}
            label={'Shipper Address Variable'}
            name={`codatIntegrationForm.shipperAddressVariableId`}
            component={DropdownInput}
            input={{
              isPortaled: true,
              options,
              isClearable: true,
              placeholder: 'Select Variable',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              width: 360,
            }}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

ShipperAddressSettingsPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <EditPanel.LabelText>{'Shipper Address Variable'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

ShipperAddressSettingsPanel.fragment = gql`
  fragment ShipperAddressSettingsPanel on CodatIntegration {
    id
    shipperAddressVariableId
    organization {
      id
      apiToken
      variables {
        id
        name
      }
    }
  }
`;

export default ShipperAddressSettingsPanel;
