// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {EmailTemplateModel, OrganizationModel, ThreadModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import CustomerEmailThreadEmailsV2 from 'modules/Communication/Email/components/CustomerEmailThreadEmailsV2';
import CustomerEmailThreadItemV2 from 'modules/Communication/Email/components/CustomerEmailThreadItemV2';
import ProjectSendEmailOptionsPopover from 'modules/Project/components/ProjectSendEmailOptionsPopover';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  overflow: auto;
`;

const LinkButton = Styled.ButtonV2`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const NoEmail = ({handleMissingContactInfo}: any) => {
  const responsive = useResponsive();

  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>Send an email</TitleText>
      <Space height={16} />
      <Row>
        <LinkButton onPress={handleMissingContactInfo}>
          <LinkText responsive={responsive}>Add your customer's email</LinkText>
        </LinkButton>
        <Text responsive={responsive}> to send emails.</Text>
      </Row>
    </EmptyStateContainer>
  );
};

const NoEmailThread = ({
  handleComposeEmail,
  emailTemplates,
  isEnabledOfficeUserFlowSettings,
  refetch,
}: {
  handleComposeEmail: ({emailTemplateUuid}: {emailTemplateUuid: string}) => void;
  emailTemplates: EmailTemplateModel[];
  isEnabledOfficeUserFlowSettings: boolean;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const projectSendEmailOptionsPopover = usePopover({name: 'Project Send Email Options Popover'});

  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>No emails</TitleText>
      <Space height={16} />
      <Text responsive={responsive}>Any emails you send will appear here.</Text>
      {handleComposeEmail && (
        <React.Fragment>
          <Space height={16} />
          {isEnabledOfficeUserFlowSettings ? (
            <ProjectSendEmailOptionsPopover
              actionMenuPopover={projectSendEmailOptionsPopover}
              refetch={refetch}
            >
              <Button
                iconLeft={Icon.Plus}
                text={'Send Email'}
                onPress={projectSendEmailOptionsPopover.handleOpen}
              />
            </ProjectSendEmailOptionsPopover>
          ) : (
            <DropdownButton
              iconLeft={Icon.Plus}
              text={'Compose Email'}
              actions={emailTemplates.map((emailTemplate: EmailTemplateModel) => ({
                text: `Compose ${emailTemplate.name}`,
                onPress: () => handleComposeEmail({emailTemplateUuid: emailTemplate.uuid}),
              }))}
              menuWidth={296}
              menuPosition={DropdownButton.MENU_POSITION.CENTER}
              ButtonComponent={Button}
              actionButtonContainerStyle={{height: 244, overflowY: 'auto'}}
            />
          )}
        </React.Fragment>
      )}
    </EmptyStateContainer>
  );
};

const SelectedEmailThreadModal = ({selectedThread, setSelectedThread}: any) => {
  const hasSelectedThread = !!selectedThread;

  return (
    <LargeModal
      isResponsive
      isScrollable
      isOpen={hasSelectedThread}
      title={selectedThread ? selectedThread.name : 'View Email'}
      primaryActionText={'Close'}
      handlePrimaryAction={() => setSelectedThread(null)}
      bodyStyle={{padding: 0}}
    >
      {hasSelectedThread && (
        <CustomerEmailThreadEmailsV2
          selectedThread={selectedThread}
          setSelectedThread={setSelectedThread}
        />
      )}
    </LargeModal>
  );
};

const EmailThreadsListContent = ({
  emailThreads,
  customer,
  organization,
  handleMissingContactInfo,
  selectedThread,
  setSelectedThread,
  handleComposeEmail,
  refetch,
}: {
  emailThreads: ThreadModel[];
  customer: UserModel;
  organization: OrganizationModel;
  handleMissingContactInfo: () => void;
  selectedThread?: ThreadModel | null;
  setSelectedThread: (thread: ThreadModel) => void;
  handleComposeEmail: (params: {emailTemplateUuid: string}) => void;
  refetch: () => void;
}) => {
  if (!customer.email) {
    return <NoEmail handleMissingContactInfo={handleMissingContactInfo} />;
  }

  if (_.isEmpty(emailThreads)) {
    return (
      <NoEmailThread
        handleComposeEmail={handleComposeEmail}
        emailTemplates={customer.organization.sortedEmailTemplates}
        isEnabledOfficeUserFlowSettings={organization.features.isEnabledOfficeUserFlowSettings}
        refetch={refetch}
      />
    );
  }

  return (
    <Container>
      {emailThreads.map((emailThread: any, index: any) => (
        <CustomerEmailThreadItemV2
          key={index}
          emailThread={emailThread}
          setSelectedThread={setSelectedThread}
        />
      ))}
      <SelectedEmailThreadModal
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
      />
    </Container>
  );
};

const EmailThreadsListV2 = ({
  emailThreads,
  customer,
  organization,
  handleMissingContactInfo,
  selectedThread,
  setSelectedThread,
  handleComposeEmail,
  refetch,
}: {
  emailThreads: ThreadModel[];
  customer: UserModel;
  organization: OrganizationModel;
  handleMissingContactInfo: () => void;
  selectedThread?: ThreadModel | null;
  setSelectedThread: (thread: ThreadModel) => void;
  handleComposeEmail: (params: {emailTemplateUuid: string}) => void;
  refetch: () => void;
}) => {
  return (
    <EmailThreadsListContent
      emailThreads={emailThreads}
      customer={customer}
      organization={organization}
      handleMissingContactInfo={handleMissingContactInfo}
      selectedThread={selectedThread}
      setSelectedThread={setSelectedThread}
      handleComposeEmail={handleComposeEmail}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadsListV2.fragment = gql`
  ${CustomerEmailThreadEmailsV2.fragment}
  ${CustomerEmailThreadItemV2.fragment}

  fragment EmailThreadsListV2_Thread on Thread {
    id
    name
    ...CustomerEmailThreadEmailsV2
    ...CustomerEmailThreadItemV2
  }

  fragment EmailThreadsListV2_User on User {
    id
    email
    organization {
      id
      sortedEmailTemplates {
        id
        name
        uuid
      }
    }
  }

  fragment EmailThreadsListV2_Organization on Organization {
    id
    features {
      isEnabledOfficeUserFlowSettings: isEnabled(feature: "OFFICE_USER_FLOW_SETTINGS")
    }
  }
`;

export default EmailThreadsListV2;
