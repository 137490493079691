// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {UserFlowRunStepModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import DocumentFlowRunStepsProgressItem from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowRunStepsProgressItem';

const Column = Styled.View`
`;

const StepButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const StepNumberContainer = Styled.View<{
  size: number;
}>`
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  border-radius: 16px;
  background-color: ${colors.gray.tertiary};
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-color: ${colors.gray.tertiary};
`;

const StepNumber = Styled.Text<{color: string}>`
  ${Typography.Responsive.MicroLabel}
  line-height: 0;
  color: ${({color}) => color};
`;

const ProgressLine = Styled.View<{color: string}>`
  margin-top: -26px;
  margin-bottom: -26px;
  height: 76px;
  width: 1px;
  left: 12px;
  background-color: ${({color}) => color};
`;

const Step = ({userFlowRunStep, index}: {userFlowRunStep: UserFlowRunStepModel; index: number}) => {
  const responsive = useResponsive();
  return (
    <StepButton>
      <StepNumberContainer size={24}>
        <StepNumber responsive={responsive} color={colors.white}>
          {index + 1}
        </StepNumber>
      </StepNumberContainer>
      <Space width={12} />
      <DocumentFlowRunStepsProgressItem index={index} userFlowRunStep={userFlowRunStep} />
    </StepButton>
  );
};

const DocumentFlowRunStepsProgress = ({
  userFlowRunSteps,
}: {
  userFlowRunSteps: UserFlowRunStepModel[];
}) => {
  return (
    <Column>
      {userFlowRunSteps
        .sort((a, b) => a.index - b.index)
        .map((userFlowRunStep, index) => {
          return (
            <React.Fragment key={userFlowRunStep.id}>
              {index > 0 && <ProgressLine color={colors.gray.tertiary} />}
              <Step userFlowRunStep={userFlowRunStep} index={index} />
            </React.Fragment>
          );
        })}
    </Column>
  );
};

DocumentFlowRunStepsProgress.fragment = gql`
  ${DocumentFlowRunStepsProgressItem.fragment}

  fragment DocumentFlowRunStepsProgress on UserFlowRunStep {
    id
    index
    ...DocumentFlowRunStepsProgressItem
  }
`;

export default DocumentFlowRunStepsProgress;
