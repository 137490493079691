// Libraries
import React from 'react';

// Supermove
import {PageLoader, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  DrawerType,
  ResponsiveType,
  useDrawer,
  useModal,
  useQuery,
  useResponsive,
} from '@supermove/hooks';
import {CancellationReasonModel, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import Table from '@shared/design/components/Table';
import {ActionType} from '@shared/design/components/Table/components/TableBuilder';
import ProjectSettingsPage from 'modules/Organization/Settings/Project/ProjectSettingsPage';
import AddCancellationReasonDrawer from 'modules/Organization/Settings/Project/components/AddCancellationReasonDrawer';
import DeleteCancellationReasonModal from 'modules/Organization/Settings/Project/components/DeleteCancellationReasonModal';
import EditCancellationReasonDrawer from 'modules/Organization/Settings/Project/components/EditCancellationReasonDrawer';

const ContentContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 0)}px;
`;

const getCancellationReasonActions = ({
  cancellationReason,
  refetch,
}: {
  cancellationReason: CancellationReasonModel;
  refetch: () => void;
}): ActionType<CancellationReasonModel>[] => {
  // Locked cancellation reasons cannot be edited or deleted
  if (cancellationReason.isLocked) {
    return [];
  }
  return [
    {
      text: 'Edit',
      desktopIcon: Icon.Pen,
      onPress: ({handleOpen}: any) => handleOpen(),
      actionHook: {
        hook: useDrawer,
        hookArgument: {
          name: 'Edit Cancellation Reason Drawer',
        },
        renderComponent: ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
          return (
            <EditCancellationReasonDrawer
              isOpen={isOpen}
              handleClose={handleClose}
              cancellationReason={cancellationReason}
              refetch={refetch}
            />
          );
        },
      },
    },
    {
      text: 'Delete',
      color: colors.red.warning,
      onPress: ({handleOpen}: any) => handleOpen(),
      actionHook: {
        hook: useModal,
        hookArgument: {
          name: 'Delete Cancellation Reason Modal',
        },
        renderComponent: ({
          isOpen,
          handleClose,
          hookKey,
        }: {
          isOpen: boolean;
          handleClose: () => void;
          hookKey?: string;
        }) => {
          return (
            <DeleteCancellationReasonModal
              key={hookKey}
              isOpen={isOpen}
              handleClose={handleClose}
              cancellationReason={cancellationReason}
              refetch={refetch}
            />
          );
        },
      },
    },
  ];
};

const ProjectSettingsPageCancellationsContent = ({
  organization,
  addCancellationReasonDrawer,
  refetch,
}: {
  organization: OrganizationModel;
  addCancellationReasonDrawer: DrawerType;
  refetch: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <ContentContainer responsive={responsive}>
      <Space height={responsive.desktop ? 32 : 24} />
      {!responsive.desktop && <Line />}
      <Table
        columnDefinitions={[
          {
            flex: 1,
            headerLabel: 'Name',
            cellText: (cancellationReason: CancellationReasonModel) => cancellationReason.name,
          },
          {
            actions: (cancellationReason: CancellationReasonModel) =>
              getCancellationReasonActions({cancellationReason, refetch}),
          },
        ]}
        items={organization.companySettings.cancellationReasons}
        containerStyle={responsive.desktop ? {width: 400} : undefined}
        emptyStateText={'No cancellation reasons to display'}
      />
      {!responsive.desktop && <Line />}
      <AddCancellationReasonDrawer
        isOpen={addCancellationReasonDrawer.isOpen}
        handleClose={addCancellationReasonDrawer.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </ContentContainer>
  );
};

const ProjectSettingsPageCancellations = () => {
  const {loading, data, refetch} = useQuery(ProjectSettingsPageCancellations.query, {
    fetchPolicy: 'cache-and-network',
  });

  const addCancellationReasonDrawer = useDrawer({name: 'Add Cancellation Reason Drawer'});

  return (
    <ProjectSettingsPage
      title='Cancellations'
      description='Manage the reasons for canceling projects.'
      actionText={'Add Cancellation Reason'}
      actionIcon={Icon.Plus}
      handleAction={addCancellationReasonDrawer.handleOpen}
      isLoadingAction={loading}
      isCompanySettings
    >
      <PageLoader loading={loading}>
        {() => {
          return (
            <ProjectSettingsPageCancellationsContent
              organization={data.viewer.viewingOrganization}
              addCancellationReasonDrawer={addCancellationReasonDrawer}
              refetch={refetch}
            />
          );
        }}
      </PageLoader>
    </ProjectSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSettingsPageCancellations.query = gql`
  ${DeleteCancellationReasonModal.fragment}
  ${EditCancellationReasonDrawer.fragment}

  query ProjectSettingsPageCancellations {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        companySettings {
          cancellationReasons {
            id
            name
            isLocked
            ...DeleteCancellationReasonModal
            ...EditCancellationReasonDrawer
          }
        }
      }
    }
  }
`;

export default ProjectSettingsPageCancellations;
