// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Location} from '@supermove/models';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import ViewLocationButtons from '@shared/modules/Location/components/ViewLocationButtons';

const LocationActionsPopover = ({
  location,
  popover,
  handleEdit,
  handleDelete,
  isStop,
  isDeletable,
  children,
}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const editActions = [
    {
      text: `Edit ${isStop ? 'stop' : 'location'}`,
      onPress: handleEdit,
    },
    {
      text: `${isStop ? 'Remove stop' : 'Delete location'}`,
      onPress: handleDelete,
      isDisabled: !isDeletable,
      tooltip: !isDeletable ? 'At least one stop is required on a job' : null,
    },
  ];

  return (
    <ActionMenuPopover
      popover={popover}
      placement={ActionMenuPopover.Position.BottomEnd}
      actions={
        responsive.desktop
          ? editActions
          : [
              {
                label: 'Open',
                actions: ViewLocationButtons.getMenuActions({navigator, location}),
              },
              {
                label: 'Edit',
                actions: editActions,
              },
            ]
      }
      width={140}
    >
      {children}
    </ActionMenuPopover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationActionsPopover.fragment = gql`
  ${Location.getZillowLink.fragment}
  ${Location.getStreetViewLink.fragment}
  ${ViewLocationButtons.fragment}

  fragment LocationActionsPopover on Location {
    id
    address
    ...Location_getZillowLink
    ...Location_getStreetViewLink
    ...ViewLocationButtons
  }
`;

export default LocationActionsPopover;
