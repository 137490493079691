// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {useResponsive, ResponsiveType, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import {FormFileType} from 'modules/Inventory/Edit/components/EditInventoryItemPhotosDrawer';

const IMAGES_FILE_INPUT_TYPE_TEXT = 'PNG, JPG';

const Container = Styled.View`
  flex: 1;
`;

const FileDragInputContainer = Styled.View<{
  isHighlighted: boolean;
}>`
  flex: 1;
  min-height: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-style: dashed;
  border-width: 1px;
  padding: 24px;
  cursor: ${({isHighlighted}) => (isHighlighted ? 'pointer' : 'default')};
  border-color: ${({isHighlighted}) =>
    isHighlighted ? colors.blue.interactive : colors.gray.tertiary};
`;

const HeadingText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.primary};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Responsive.Body}
  width: 100%;
  text-align: center;
  color: ${colors.gray.tertiary};
`;

const FileDragInputClickText = Styled.Text`
  ${Typography.Responsive.Link}
  width: 100%;
  text-align: center;
`;

const FileDragInputTypeText = Styled.Text`
  ${Typography.Responsive.Micro}
  width: 100%;
  text-align: center;
  color: ${colors.gray.tertiary};
`;

const DesktopInputText = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <FileDragInputText responsive={responsive}>
      {'Drag files here or '}
      <FileDragInputClickText responsive={responsive}>
        {'click here to upload'}
      </FileDragInputClickText>
    </FileDragInputText>
  );
};

const MobileInputText = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <FileDragInputText responsive={responsive}>
      <FileDragInputClickText responsive={responsive} style={{textDecoration: 'none'}}>
        {'Tap to upload'}
      </FileDragInputClickText>
    </FileDragInputText>
  );
};

const InventoryPhotosFileDropzone = ({
  formFiles,
  isDragActive,
}: {
  formFiles: FormFileType[];
  isDragActive?: boolean;
}) => {
  const responsive = useResponsive();
  const {ref, isHovered} = useHover();
  return (
    <Container>
      <FileDragInputContainer ref={ref} isHighlighted={isDragActive || isHovered}>
        {_.isEmpty(formFiles) && (
          <React.Fragment>
            <HeadingText responsive={responsive}>{'No Photos'}</HeadingText>
            <Space height={12} />
          </React.Fragment>
        )}
        {responsive.desktop ? (
          <DesktopInputText responsive={responsive} />
        ) : (
          <MobileInputText responsive={responsive} />
        )}
        <Space height={8} />
        <FileDragInputTypeText responsive={responsive}>
          {IMAGES_FILE_INPUT_TYPE_TEXT}
        </FileDragInputTypeText>
      </FileDragInputContainer>
    </Container>
  );
};

export default InventoryPhotosFileDropzone;
