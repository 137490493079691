// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {PaginationInput, useDebouncedCallback, useQuery, useState} from '@supermove/hooks';
import {ClientModel} from '@supermove/models';

const getQuery = ({
  useClientFieldsClientSearchFragment,
  useV2Search,
}: {
  useClientFieldsClientSearchFragment: string;
  useV2Search: boolean;
}) => {
  if (useV2Search) {
    return gql`
      ${useClientFieldsClientSearchFragment}
      query useClientFieldsClientSearchV2($searchQuery: String!) {
        ${gql.query}
        quickSearchClients(query: $searchQuery, options: {limit: 50}) {
          results {
            client {
              id
              ...useClientFieldsClientSearchFragment
            }
            distance
            terms
          }
        }
      }
    `;
  } else {
    // TODO(atsu): Remove this once project-client-fields-search-v2 flag is rolled out
    return gql`
      ${useClientFieldsClientSearchFragment}
      query useClientFieldsClientSearch($pagination: PaginationInput!, $searchQuery: String!, $slugs: [String],) {
        ${gql.query}
        viewer {
          id
          organizationForCreateProject {
            id
            filteredClientsPaginatedList(
              pagination: $pagination,
              searchQuery: $searchQuery,
              slugs: $slugs,
            ) {
              results {
                id
                ...useClientFieldsClientSearchFragment
              }
            }
          }
        }
      }
    `;
  }
};

// This hook is setup to separate some logic out of `ClientFields`. It is only used from
// that component and therefore can utilize a fragment passed through as an argument.
// This facilitates some separation of concerns and helps the readability of the component.
const useClientFieldsClientSearch = ({
  useClientFieldsClientSearchFragment,
  pagination,
  useV2Search,
}: {
  useClientFieldsClientSearchFragment: string;
  pagination: PaginationInput;
  useV2Search: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeSearch = useDebouncedCallback((text) => setSearchQuery(text), 250);

  // useQuery can't be placed in an `if (useV2Search) { ... }` block, so we work around this by
  // parameterizing the query & response fields
  const {data, loading} = useQuery(getQuery({useClientFieldsClientSearchFragment, useV2Search}), {
    fetchPolicy: 'cache-and-network',
    skip: useV2Search ? searchQuery.length < 3 : searchQuery.length === 0,
    variables: {
      searchQuery,
      slugs: ['ALL_ORGANIZATIONS'],
      pagination,
    },
  });

  // TODO: Fix ClientModel usage once GraphQL types are generated. ClientModel is an incorrect
  //   representation of the response type
  const clients = useV2Search
    ? data?.quickSearchClients.results.map(({client}: {client: ClientModel}) => client) || []
    : _.get(data, 'viewer.organizationForCreateProject.filteredClientsPaginatedList.results', []);

  return {
    clients,
    isSearching: loading,
    searchQuery,
    handleChangeSearch,
  };
};

// TODO(atsu): Remove once v2 search is rolled out
export const SearchContext = React.createContext<{useV2Search: boolean}>({
  useV2Search: false,
});

export default useClientFieldsClientSearch;
