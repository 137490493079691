// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {IconSource, ScrollView, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive, ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import Breadcrumbs, {BreadcrumbType} from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import Tabs, {TabType} from '@shared/design/components/Tabs';
import Line from 'modules/App/components/Line';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const PageContainer = Styled.View`
  flex: 1;
`;

const BreadcrumbsContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-vertical: 4px;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
`;

const BodyContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const BodyHeaderContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  max-width: 740px;
`;

interface SettingsPageTemplateProps {
  breadcrumbs: BreadcrumbType[];
  tabs?: (TabType & {url: string})[];
  title?: string;
  description?: string;
  actionText?: string;
  actionIcon?: IconSource;
  handleAction?: () => void;
  isLoadingAction?: boolean;
  isDisabledAction?: boolean;
  children: React.ReactNode;
}
type PageBodyProps = Omit<SettingsPageTemplateProps, 'tabs' | 'breadcrumbs'>;
type ActionButtonProps = Omit<PageBodyProps, 'title' | 'description' | 'children'>;

const ActionButton = ({
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  isDisabledAction,
}: ActionButtonProps) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {isLoadingAction ? (
        <SkeletonLoader
          width={150}
          height={
            responsive.desktop ? SkeletonLoader.HEIGHT.Button : SkeletonLoader.HEIGHT.ButtonMobile
          }
        />
      ) : (
        <Button
          text={actionText}
          iconLeft={actionIcon}
          onPress={handleAction}
          isDisabled={isDisabledAction}
          isResponsive
        />
      )}
    </React.Fragment>
  );
};

const PageBody = ({
  title,
  description,
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  isDisabledAction,
  children,
}: PageBodyProps) => {
  const responsive = useResponsive();

  return (
    <BodyContainer>
      <ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1}}>
        <BodyHeaderContainer responsive={responsive}>
          {!!title && (
            <React.Fragment>
              <Space height={responsive.desktop ? 24 : 16} />
              <Title responsive={responsive}>{title}</Title>
            </React.Fragment>
          )}
          {!!description && (
            <React.Fragment>
              <Space height={12} />
              <Description responsive={responsive}>{description}</Description>
            </React.Fragment>
          )}
          {(!!title || !!description) && <Space height={24} />}
          {(isLoadingAction || handleAction) && (
            <ActionButton
              actionText={actionText}
              actionIcon={actionIcon}
              handleAction={handleAction}
              isLoadingAction={isLoadingAction}
              isDisabledAction={isDisabledAction}
            />
          )}
        </BodyHeaderContainer>
        {children}
        <Space height={32} />
      </ScrollView>
    </BodyContainer>
  );
};

const SettingsPageTemplate = ({
  breadcrumbs = [],
  tabs = [],
  title = '',
  description = '',
  actionText,
  actionIcon,
  handleAction,
  isLoadingAction,
  isDisabledAction,
  children,
}: SettingsPageTemplateProps) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <SidebarPageV2 selected={'settings'}>
      <PageContainer>
        <Space height={16} />
        <BreadcrumbsContainer responsive={responsive}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </BreadcrumbsContainer>
        {!tabs ? (
          <Space height={16} />
        ) : (
          <Tabs
            tabs={tabs}
            handlePressTab={(tab) => navigator.push(tab.url)}
            activeTabIndex={currentTabIndex}
            scrollViewStyle={{paddingLeft: responsive.desktop ? 24 : 16, paddingTop: 8}}
          />
        )}
        <Line />
        <PageBody
          title={title}
          description={description}
          actionText={actionText}
          actionIcon={actionIcon}
          handleAction={handleAction}
          isLoadingAction={isLoadingAction}
          isDisabledAction={isDisabledAction}
        >
          {children}
        </PageBody>
      </PageContainer>
    </SidebarPageV2>
  );
};

export default SettingsPageTemplate;
