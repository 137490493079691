// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {Storage} from '@supermove/sdk';

const useUpdateSuperAdminOrganizationMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (viewer: UserModel) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      updateSuperAdminOrganizationForm: {
        organizationId: '',
      },
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateSuperAdminOrganizationMutation.mutation,
    variables: {
      organizationId: _.toNumber(form.values.updateSuperAdminOrganizationForm.organizationId),
    },
    onSuccess: async ({viewer, token, errors}: any) => {
      if (viewer) {
        await Storage.setItem('token', token);
        onSuccess(viewer);
      } else {
        onError(errors);
      }
    },
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateSuperAdminOrganizationMutation.mutation = gql`
  mutation useUpdateSuperAdminOrganizationMutation(
    $organizationId: Int!,
  ) {
    response: updateSuperAdminOrganization(
      organizationId: $organizationId,
    ) {
      ${gql.errors}
      token
      viewer {
        id
        role
      }
    }
  }
`;

export default useUpdateSuperAdminOrganizationMutation;
