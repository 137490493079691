// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm} from '@supermove/hooks';
import {DocumentTemplateModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import UserFlowStepForm, {
  UserFlowStepFormToFormType,
} from '@shared/modules/UserFlows/forms/UserFlowStepForm';
import DocumentFlowStepFields from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/components/DocumentFlowStepFields';

const DocumentFlowStepDrawer = ({
  isOpen,
  userFlowStep,
  documentTemplates,
  handleSave,
  handleClose,
}: {
  isOpen: boolean;
  userFlowStep: UserFlowStepFormToFormType;
  documentTemplates: DocumentTemplateModel[];
  handleSave: (userFlowStep: UserFlowStepFormToFormType) => void;
  handleClose: () => void;
}) => {
  const field = 'userFlowStepForm';
  // Add custom validation on submit
  const form = useForm({
    initialValues: {
      userFlowStepForm: UserFlowStepForm.toForm(userFlowStep),
    },
    enableReinitialize: true,
    validate: ({userFlowStepForm}) => {
      return UserFlowStepForm.validate({userFlowStepForm, field});
    },
    onSubmit: ({userFlowStepForm}) => {
      handleSave(userFlowStepForm);
    },
  });

  const userFlowStepForm = _.get(form.values, field);

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={userFlowStepForm.documentTemplateId ? 'Edit Document' : 'Add Document'}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      handleSubmit={form.handleSubmit}
    >
      <DocumentFlowStepFields form={form} field={field} documentTemplates={documentTemplates} />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowStepDrawer.fragment = gql`
  ${DocumentFlowStepFields.fragment}
  fragment DocumentFlowStepDrawer on DocumentTemplateV2 {
    id
    ...DocumentFlowStepFields
  }
`;

export default DocumentFlowStepDrawer;
