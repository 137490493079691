// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

const Container = Styled.View`
  align-items: center;
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const SubHeading = Styled.Text`
  ${Typography.Responsive.Body}
`;

const CreateDocumentFlowRunHeader = ({project}: {project: ProjectModel}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <PageHeading responsive={responsive}>{Project.getName(project)}</PageHeading>
      <Space height={16} />
      <SubHeading responsive={responsive}>
        Please configure the steps required in this flow and draft an email to send to{' '}
        {project.client.primaryContact.fullName}.
      </SubHeading>
    </Container>
  );
};

CreateDocumentFlowRunHeader.fragment = gql`
  ${Project.getName.fragment}
  fragment CreateDocumentFlowRunHeaderFragment_Project on Project {
    id
    name
    identifier
    client {
      id
      primaryContact {
        id
        fullName
      }
    }
    ...Project_getName
  }
`;

export default CreateDocumentFlowRunHeader;
