// Libraries
import _ from 'lodash';
import React from 'react';

// Supremove
import {DataLoader} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {UserFlowModel, WorkflowModel} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const SendDocumentFlowSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  handleSubmitValidateWorkflow,
}: {
  form: Form<{workflowForm: typeof WorkflowForm}>;
  workflowStepsField: string;
  workflowStepIndex: number;
  handleSubmitValidateWorkflow: () => void;
}) => {
  const {label, description} =
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.SEND_DOCUMENT_FLOW];

  const projectTypeIds = _.get(form, `values.workflowForm.projectTypeIdList`) as number[];
  // '-1' is the value for 'ALL Project Types'
  const allProjectTypes = projectTypeIds.includes(-1);
  const field = `${workflowStepsField}.${workflowStepIndex}.createAndSendUserFlowRunActionForm`;

  const {data, loading} = useQuery<{
    filteredUserFlows: UserFlowModel[];
  }>(SendDocumentFlowSlide.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      isDefault: allProjectTypes ? true : undefined,
      projectTypeIds: allProjectTypes ? [] : projectTypeIds,
    },
  });

  return (
    <DataLoader loading={loading} data={data}>
      {({loadedData}) => (
        <WorkflowStepSlide
          form={form}
          workflowStepsField={workflowStepsField}
          workflowStepIndex={workflowStepIndex}
          title={label}
          subtitle={description}
          showDoneButton
          handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
        >
          <FieldInput.Memoized
            {...form}
            component={DropdownInput}
            name={`${field}.userFlowId`}
            label={'User Flow'}
            isRequired
            input={{
              options: loadedData.filteredUserFlows.map((userFlow: UserFlowModel) => ({
                value: _.toString(userFlow.id),
                label: userFlow.name,
              })),
              placeholder: 'Select a user flow',
              isSearchable: true,
              isPortaled: true,
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </WorkflowStepSlide>
      )}
    </DataLoader>
  );
};

const SendDocumentFlowDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  isPreview,
}: {
  form: Form<{workflowForm: typeof WorkflowForm}>;
  workflowStepsField: string;
  workflowStepIndex: number;
  isPreview: boolean;
}) => {
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={'Send confirmation'}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepSendDocumentFlow = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: {
    form: Form<{workflowForm: typeof WorkflowForm}>;
    workflowStepsField: string;
    workflowStepIndex: number;
    workflow: WorkflowModel;
    handleSubmitValidateWorkflow: () => void;
    isPreview: boolean;
  }) => [
    <SendDocumentFlowSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <SendDocumentFlowDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      isPreview={isPreview}
    />,
  ],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendDocumentFlowSlide.query = gql`
  query SendDocumentFlowSlide($projectTypeIds: [String!]!) {
    ${gql.query}
    filteredUserFlows(
      projectTypeIds: $projectTypeIds,
    ) {
      id
      name
    }
  }
`;

export default WorkflowStepSendDocumentFlow;
