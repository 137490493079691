// Libraries
import React from 'react';

// Supermove
import {
  FlatList,
  Icon,
  Loading,
  Popover,
  SanitizedHTML,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useNavigationDOM,
  usePopover,
  useQuery,
  useResponsive,
} from '@supermove/hooks';
import {EmailModel, Project, ProjectModel, ThreadModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import IconButton from '@shared/design/components/IconButton';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import EmailStatus from '@shared/modules/Email/enums/EmailStatus';
import Line from 'modules/App/components/Line';
import EmailAttachments from 'modules/Communication/Email/components/EmailAttachments';
import EmailMetadataPopover from 'modules/Communication/Email/components/EmailMetadataPopover';
import NextPreviousThreadControls from 'modules/Communication/Email/components/NextPreviousThreadControls';
import {parseJsonRecipients} from 'modules/Communication/Email/utils/parseJsonRecipients';

const Container = Styled.View`
  flex: 1;
`;

const ProjectInfoContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: center;
  margin-horizontal: 30px;
  justify-content: ${({responsive}) => (responsive.mobile ? 'space-between' : 'flex-start')};
`;

// Placeholder to make justify content act correctly
const RightHeaderPlaceholder = Styled.View`
  width: 40px;  
`;

const SubjectAndNavigationRow = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${({responsive}) => (responsive.mobile ? '16px' : '24px')};
  margin-bottom: 0;
`;

const Subject = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const EmailContainer = Styled.View`
  margin-horizontal: 24px;
  flex: 1;
`;

const FromEmailAndTimestampContainer = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const ToEmailAndDetailsContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: ${({responsive}) => (responsive.mobile ? 'flex-start' : 'center')};
  justify-content: ${({responsive}) => (responsive.mobile ? 'space-between' : 'flex-start')};
`;

const TimestampAndOptionsContainer = Styled.View`
  flex: 1;
  align-items: flex-end;
`;

const Timestamp = Styled.Text`
  ${Typography.Responsive.Micro}
  text-align: right;
`;

const EmailDetailsContainer = Styled.View<{responsive: ResponsiveType}>`
  align-items: ${({responsive}) => (responsive.mobile ? 'flex-end' : 'flex-start')};
`;

const EmailDetailsText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
  flex: 1;
`;

// Negative margin makes up for inherent padding in the Sanitized HTML body tag
const EmailHtmlContainer = Styled.View`
  margin: -8px;
`;

const FromEmail = Styled.Text<{responsive: ResponsiveType}>`
  ${({responsive}) => (responsive.mobile ? Typography.Responsive.Label : Typography.Responsive.MicroLabel)}
  flex: 1;
`;

const RecipientInfo = Styled.Text<{responsive: ResponsiveType}>`
  ${Typography.Responsive.Micro}
  ${({responsive}) => (responsive.mobile ? 'flex: 1' : '')};
  `;

const ProjectInfo = ({
  project,
  responsive,
  navigator,
}: {
  project: ProjectModel;
  responsive: ResponsiveType;
  navigator: SupermoveNavigatorType;
}) => {
  const projectName = Project.getName(project);
  const handleClose = () => {
    // TODO(jay): Eventually this may need to go to different locations, handle via query params
    navigator.replace(`/projects/${project.uuid}/view?widget=EMAILS`);
  };
  return (
    <React.Fragment>
      <ProjectInfoContainer responsive={responsive}>
        <IconButton
          source={responsive.mobile ? Icon.ChevronLeft : Icon.Xmark}
          onPress={handleClose}
          isSecondary
          size={20}
        />
        <Space width={responsive.desktop ? 16 : 8} />
        <HeaderText responsive={responsive}>{projectName}</HeaderText>
        <RightHeaderPlaceholder />
      </ProjectInfoContainer>
      <Space height={20} />
      <Line />
    </React.Fragment>
  );
};

const SubjectAndNavigation = ({
  subject,
  responsive,
  currentThreadId,
  project,
}: {
  subject?: string;
  responsive: ResponsiveType;
  currentThreadId: string;
  project: ProjectModel;
}) => {
  return (
    <React.Fragment>
      <SubjectAndNavigationRow responsive={responsive}>
        <Subject responsive={responsive}>{subject}</Subject>
        {responsive.mobile ? null : (
          <NextPreviousThreadControls
            responsive={responsive}
            currentThreadId={currentThreadId}
            project={project}
          />
        )}
      </SubjectAndNavigationRow>
      {responsive.mobile ? <Line /> : null}
    </React.Fragment>
  );
};

const FromEmailAndTimestampSection = ({
  email,
  responsive,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
}) => {
  const hasFromName = !!email.fromName;
  const fromLine = `${email.fromName ?? ''}${hasFromName ? ` <${email.fromEmail}>` : email.fromEmail}`;

  return (
    <FromEmailAndTimestampContainer>
      <FromEmail responsive={responsive}>{fromLine}</FromEmail>
      <Space width={8} />
      <TimestampAndOptions email={email} responsive={responsive} />
    </FromEmailAndTimestampContainer>
  );
};

const ToEmailAndDetailsSection = ({
  email,
  responsive,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
}) => {
  const toNames = [
    ...parseJsonRecipients(email.toEmailRecipientsJson),
    ...parseJsonRecipients(email.ccEmailRecipientsJson),
    ...parseJsonRecipients(email.bccEmailRecipientsJson),
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <ToEmailAndDetailsContainer responsive={responsive}>
      <RecipientInfo responsive={responsive}>To: {toNames}</RecipientInfo>
      <Space width={8} />
      <EmailDetails email={email} responsive={responsive} />
    </ToEmailAndDetailsContainer>
  );
};

const EmailDetails = ({email, responsive}: {email: EmailModel; responsive: ResponsiveType}) => {
  const metadataPopover = usePopover({name: `emailMetadataPopover${email.id}`});
  return (
    <EmailDetailsContainer responsive={responsive}>
      <Popover.Content innerRef={metadataPopover.ref}>
        <TertiaryButton onPress={metadataPopover.handleOpen}>
          {responsive.mobile ? (
            <EmailDetailsText>Details</EmailDetailsText>
          ) : (
            <Icon source={Icon.ChevronDown} color={colors.blue.interactive} size={12} />
          )}
        </TertiaryButton>
      </Popover.Content>
      <EmailMetadataPopover email={email} responsive={responsive} popover={metadataPopover} />
    </EmailDetailsContainer>
  );
};

const TimestampAndOptions = ({
  email,
  responsive,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
}) => {
  // More options will be added here later
  return (
    <TimestampAndOptionsContainer>
      <Timestamp responsive={responsive}>
        {Datetime.convertToDisplayDatetime(email.sentAt || email.updatedAt)}
      </Timestamp>
    </TimestampAndOptionsContainer>
  );
};

const ErrorBanner = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <React.Fragment>
      <Space height={16} />
      <WarningCallout
        text={'This email could not be delivered, please verify the details and try again.'}
        width={responsive.desktop ? 520 : 300}
      />
      <Space height={8} />
    </React.Fragment>
  );
};

const Email = ({email, responsive}: {email: EmailModel; responsive: ResponsiveType}) => {
  return (
    <EmailContainer>
      <Space height={24} />
      <FromEmailAndTimestampSection email={email} responsive={responsive} />
      <Space height={8} />
      <ToEmailAndDetailsSection email={email} responsive={responsive} />
      {email.status === EmailStatus.ERROR && <ErrorBanner responsive={responsive} />}
      <Space height={16} />
      <EmailHtmlContainer>
        <SanitizedHTML rawHTML={email.html} />
      </EmailHtmlContainer>
      {!!email.emailAttachments.length && (
        <EmailAttachments email={email} responsive={responsive} />
      )}
    </EmailContainer>
  );
};

const EmailThreadContent = ({thread}: {thread: ThreadModel}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {subject} = thread.allEmails[0] ?? {};
  return (
    <Container>
      <Space height={24} />
      {thread.project ? (
        <ProjectInfo project={thread.project} responsive={responsive} navigator={navigator} />
      ) : null}
      <SubjectAndNavigation
        subject={subject}
        responsive={responsive}
        currentThreadId={thread.id}
        project={thread.project}
      />
      <FlatList
        // TODO(jay): We will later show all emails in a thread, for now just show one
        data={thread.allEmails?.slice(0, 1)}
        keyExtractor={(email: EmailModel) => email.id}
        renderItem={({item}: {item: EmailModel}) => <Email email={item} responsive={responsive} />}
      />
    </Container>
  );
};

const EmailThreadPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(EmailThreadPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {uuid: params.threadUuid},
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return <EmailThreadContent thread={data.threadByUuid} />;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadPage.query = gql`
  ${Project.getName.fragment}
  ${EmailAttachments.fragment}
  ${EmailMetadataPopover.fragment}
  ${NextPreviousThreadControls.fragment}
  query EmailThreadPage($uuid: String!) {
    ${gql.query}
    threadByUuid(uuid: $uuid) {
      id
      project {
        id
        uuid
        ...Project_getName
        ...NextPreviousThreadControls_Project
      }
      allEmails {
        id
        html
        fromName
        sentAt
        updatedAt
        toEmailRecipientsJson
        bccEmailRecipientsJson
        ccEmailRecipientsJson
        subject
        status
        ...EmailAttachments
        ...EmailMetadataPopover
      }
    }
  }
`;

export default EmailThreadPage;
