import {UserFlowStepModel} from '@supermove/models';

export type UserFlowRunStepFormType = {
  userFlowStepId?: string;
};

const _new = (userFlowStep: UserFlowStepModel): UserFlowRunStepFormType => ({
  userFlowStepId: userFlowStep.id,
});

const toForm = (userFlowRunStep: UserFlowRunStepFormType) => {
  return {
    userFlowStepId: userFlowRunStep.userFlowStepId,
  };
};

const toMutation = (userFlowRunStepForm: ReturnType<typeof toForm>) => {
  return {
    userFlowStepId: userFlowRunStepForm.userFlowStepId,
  };
};

const UserFlowRunStepForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UserFlowRunStepForm;
